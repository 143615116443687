import { Box } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import classNames from 'classnames';
import { QuoteScreenSelectors, QuoteSelectors } from 'rdx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { QuoteIcons } from '../../containers/Landing/Detail/QuoteIcons';
import { IChangeOrder, IQuote, QuoteTypes } from '../../interfaces';
import { ComponentIds } from '../../utils/component-ids';

interface QuoteNumberCellRendererProps extends ICellRendererParams {
  showQuoteIcons?: boolean;
}

export const QuoteNumberCellRenderer: React.FC<QuoteNumberCellRendererProps> = (props) => {
  const location = useLocation();
  const quoteSaved = useSelector(QuoteSelectors.saved);
  const quoteTouched = useSelector(QuoteScreenSelectors.touched);
  const quoteObject = props.data as IQuote | IChangeOrder;

  let pathname;
  let quoteIcons;
  if (quoteObject.typeId === QuoteTypes.CHANGE_ORDER && quoteObject.header) {
    pathname = `/quote/${quoteObject.header.main.parentQuoteId}/change-order/${quoteObject.id}`;
  } else if (quoteObject.typeId === QuoteTypes.QUOTE) {
    pathname = `/quote/${quoteObject.id}/customer`;
    if (props.showQuoteIcons) {
      quoteIcons = <QuoteIcons quote={quoteObject} />;
    }
  }

  return (
    <Box display="flex" alignItems="center">
      <Link
        to={{ pathname, state: { prev: location.pathname } }}
        className={classNames({ 'disabled-link': quoteSaved && quoteTouched, 'text-wrap-ellipsis': true })}
        data-test={ComponentIds.LpQuoteNumber}
      >
        {quoteObject.header?.main.code}
      </Link>
      {quoteIcons}
    </Box>
  );
};
