import { styled } from '@mui/material/styles';

export const SectionContainer = styled('div')`
  position: relative;
  user-select: none;

  & .section-title {
    display: flex;
    align-items: center;
    background-color: #5d8ab5;
    color: white;
    padding: 0 20px;
    height: 36px;
    position: relative;

    span {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
    }
  }

  & .section-content {
    padding: 25px 20px;
    position: relative;

    &.no-vertical-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.no-horizontal-padding {
      padding-left: 0;
      padding-right: 0;
    }

    &.no-padding {
      padding: 0;
    }
  }

  border-right: 1px solid #f0f0f0;
`;
