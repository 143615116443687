import { styled } from '@mui/material/styles';

export const TabsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  background-color: #86b0d9;
  border-bottom: 1px solid #b4cee8;

  @media (max-width: 1330px) {
    & > * {
      .flex-group {
        top: 2px;
      }
    }
  }

  & > *:last-child {
    margin-left: auto;
    margin-right: 0;
  }
`;
