import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OpsPackState, OpsPackSummary } from '../types';

const initialState: OpsPackState = {
  fetchingSummary: false,
  summaryFetched: false,
  exists: false,
  generating: false,
};

export const opsPackSlice = createSlice({
  name: 'opsPack',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    fetchOpsPackSummaryStarted(state) {
      state.fetchingSummary = true;
      state.summaryFetched = false;
    },
    fetchOpsPackSummaryError(state) {
      state.fetchingSummary = false;
      state.generating = false;
      state.exists = false;
      delete state.boxFolderUrl;
      delete state.boxFileIdentifier;
    },
    fetchOpsPackSummarySuccess(state, { payload }: PayloadAction<{ summary: OpsPackSummary }>) {
      const { summary } = payload;
      state.fetchingSummary = false;
      state.summaryFetched = true;
      state.generating = summary.processStatusId === 'PENDING';
      state.exists = summary.exists;
      state.boxFolderUrl = summary.boxFolderUrl;
      state.boxFileIdentifier = summary.boxFileIdentifier;
    },
    generateOpsPackStarted(state) {
      state.generating = true;
    },
  },
});
