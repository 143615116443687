import { Box, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { CloneInfo } from '../../interfaces';
import { i18nKeys } from '../../internationalization/i18nKeys';
import { ComponentIds } from '../../utils/component-ids';

interface CloneSourceLinkProps {
  clone: CloneInfo | undefined;
  ml?: number;
  mr?: number;
}

export const CloneSourceLink: React.FC<CloneSourceLinkProps> = ({ clone, ml, mr }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { cloned, sourceQuoteId, sourceQuoteCode } = clone ?? {};
  if (!cloned || !sourceQuoteId || !sourceQuoteCode) {
    return null;
  }
  return (
    <Box display="flex" ml={ml} mr={mr}>
      <Link
        to={{ pathname: `/quote/${sourceQuoteId}/customer`, state: { prev: location.pathname } }}
        style={{ display: 'flex', alignItems: 'center' }}
        data-test={ComponentIds.LpCloneSourceLink}
      >
        <Tooltip title={t(i18nKeys.tabBar.clonedFrom, { sourceQuoteCode }) ?? ''}>
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </Link>
    </Box>
  );
};
