import { ISalesGridRow } from '../../../../interfaces';
import { ValidationGroupNames } from '../review-tab.types';
import { makeValidationField, ProductValidationFieldsByColId } from './validation';

const SalesRowValidationCodes = {
  EMPTY_QUANTITY: '_4_0_0',
  EMPTY_PRICE_EACH: '_4_0_1',
  INVALID_LENGTH_DESCRIPTION: '_4_0_2',
  OBSOLETE_ITEM: '_4_0_4',
};

const OptionalSalesRowValidationCodes = {
  EMPTY_QUANTITY: '_4_1_0',
  EMPTY_PRICE_EACH: '_4_1_1',
  INVALID_LENGTH_DESCRIPTION: '_4_1_2',
  OBSOLETE_ITEM: '_4_1_4',
};

const makeFields = (
  validationGroup: ValidationGroupNames,
  codes: typeof SalesRowValidationCodes
): ProductValidationFieldsByColId<ISalesGridRow> => {
  const makeField = (errorCodes: string[]) => makeValidationField(validationGroup, errorCodes);
  return {
    code: makeField([codes.OBSOLETE_ITEM]),
    quantity: makeField([codes.EMPTY_QUANTITY]),
    priceEach: makeField([codes.EMPTY_PRICE_EACH]),
    description: makeField([codes.INVALID_LENGTH_DESCRIPTION]),
  };
};

export const SalesRowValidationFieldsByColId = makeFields('sales', SalesRowValidationCodes);
export const OptionalSalesRowValidationFieldsByColId = makeFields('optionalSales', OptionalSalesRowValidationCodes);
