import { IApiEnvelope, IStatementTemplate } from 'interfaces';
import { ConfigurableRequest } from './configurable-request';

export class StatementConfigurationService extends ConfigurableRequest {
  private static instance: StatementConfigurationService;

  private constructor() {
    super('configuration');
  }

  public async listTemplates(branchId: string): Promise<IStatementTemplate[]> {
    const result: IApiEnvelope<IStatementTemplate> = await this.get('/statements/templates', { branchId });
    return result.data;
  }

  public async readTemplate(templateId: string): Promise<IStatementTemplate> {
    const result: IApiEnvelope<IStatementTemplate> = await this.get(`/statements/templates/${templateId}`);
    return result.data[0];
  }

  public static getInstance(): StatementConfigurationService {
    if (!StatementConfigurationService.instance) {
      StatementConfigurationService.instance = new StatementConfigurationService();
    }
    return StatementConfigurationService.instance;
  }
}
