import { QuoteTabs } from 'enums';
import { IChangeOrder, IQuote } from '../interfaces';
import { FetchStatuses } from '../mobile/redux/types';
import { GridSelectionState, ProductsState, ProductsTabState } from './quote-screen/products-tab/products-tab.types';

export const getProductsStateByTab = (tab: QuoteTabs, state: ProductsTabState) => {
  if (tab === QuoteTabs.Products) {
    return state.products;
  } else if (tab === QuoteTabs.Optional) {
    return state.optional;
  } else {
    return null;
  }
};

export const makeInitialGridSelectionState = () => {
  const state: GridSelectionState = {
    selected: [],
    selectedById: {},
    insertionRow: {
      index: -1,
      number: -1,
      id: '',
    },
  };
  return state;
};

export const makeProductsInitialState: () => ProductsState = () => ({
  rental: {
    status: {
      touched: false,
      valid: true,
      fetching: false,
      fetched: false,
      profilesStatus: FetchStatuses.NotFetched,
    },
    ...makeInitialGridSelectionState(),
    rows: [],
  },
  sales: {
    status: {
      touched: false,
      valid: true,
      fetching: false,
      fetched: false,
      profilesStatus: FetchStatuses.NotFetched,
    },
    ...makeInitialGridSelectionState(),
    rows: [],
  },
  services: {
    status: {
      touched: false,
      valid: true,
      fetching: false,
      fetched: false,
      profilesStatus: FetchStatuses.NotFetched,
    },
    phases: {
      fetching: false,
      fetched: false,
      data: [],
    },
    grids: [],
    copyingServices: false,
    driverAndTruckTotals: {
      driverCount: 0,
      truckCount: 0,
    },
  },
});

export const updateQuoteFromResponse = <T extends IChangeOrder | IQuote>(
  original: T | null | undefined,
  update: T | null | undefined
): T => {
  if (original && update) {
    const result = {
      ...update,
      extensions: update.extensions || original.extensions,
      currentFees: update.currentFees || original.currentFees,
    };
    if (original.detail) {
      if (result.detail) {
        result.detail = {
          ...original.detail,
          ...update.detail,
        };
      } else {
        result.detail = original.detail;
      }
    }
    return result;
  }
  return original || update || ({} as T);
};

export const isLakeCompanyBranchCode = (branchCode?: string) => branchCode === '1094' || branchCode === '2094';
