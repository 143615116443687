import { Backdrop, CircularProgress, Portal } from '@mui/material';
import React from 'react';
import { ISpinnerProps } from './Spinner.props';

export const Spinner: React.FC<ISpinnerProps> = ({ visible = false, inline }) => {
  return inline ? (
    <Backdrop
      sx={{
        position: 'absolute',
        zIndex: 1,
        color: '#000',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      }}
      open={visible}
    >
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          animation: 'none',
        }}
        color="inherit"
        size={25}
      />
    </Backdrop>
  ) : (
    <Portal>
      <Backdrop sx={{ color: 'white', zIndex: ({ zIndex }) => zIndex.drawer + 1 }} open={visible}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Portal>
  );
};
