import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserService } from 'api';
import { BranchService } from '../../api/branch.service';

export const fetchUserProfileAction = createAsyncThunk('session/fetchUserProfile', async () => {
  return await UserService.getInstance().fetchProfile();
});

export const fetchUserBranchesAction = createAsyncThunk(
  'session/fetchUserBranches',
  async (payload: undefined, thunkAPI) => {
    try {
      return await UserService.getInstance().fetchBranches();
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchBranchUsersAction = createAsyncThunk('session/fetchBranchUsers', async (branchId: string) => {
  return await BranchService.getInstance().getBranchUsers(branchId);
});
