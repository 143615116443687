import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapValues } from 'lodash';
import { ChangeOrderState } from '../types';
import { updateQuoteFromResponse } from '../utils';
import { fetchChangeOrderGroupsAction, saveChangeOrderGroupsAction, saveChangeOrderGroupsStartAction } from './actions';
import { ChangeOrderGroups } from './types';

const initialState: ChangeOrderState = {
  groupStatuses: mapValues(ChangeOrderGroups, () => ({
    fetched: false,
    fetching: false,
    saving: false,
  })),
  headerTouched: false,
  scopeChanges: '',
};

const setGroupFetchStatuses = (
  state: ChangeOrderState,
  groups: ChangeOrderGroups[],
  fetched: boolean,
  fetching: boolean
) => {
  groups.forEach((group) => {
    const status = state.groupStatuses[group];
    status.fetched = fetched;
    status.fetching = fetching;
  });
};

const setGroupSaveStatuses = (state: ChangeOrderState, groups: ChangeOrderGroups[], saving: boolean) => {
  groups.forEach((group) => {
    state.groupStatuses[group].saving = saving;
  });
};

export const changeOrderSlice = createSlice({
  name: 'changeOrder',
  initialState,
  reducers: {
    setScopeChanges(state, action: PayloadAction<string>) {
      state.scopeChanges = action.payload;
      state.headerTouched = true;
    },
    setDeliveryTimestamp(state, action: PayloadAction<number | undefined>) {
      state.deliveryTimestamp = action.payload;
      state.headerTouched = true;
    },
    setCompletionTimestamp(state, action: PayloadAction<number | undefined>) {
      state.completionTimestamp = action.payload;
      state.headerTouched = true;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchChangeOrderGroupsAction.pending, (state, action) => {
      setGroupFetchStatuses(state, action.meta.arg.groups, false, true);
    });
    builder.addCase(fetchChangeOrderGroupsAction.rejected, (state, action) => {
      setGroupFetchStatuses(state, action.meta.arg.groups, false, false);
    });
    builder.addCase(fetchChangeOrderGroupsAction.fulfilled, (state, action) => {
      const { groups } = action.meta.arg;
      const { changeOrder } = action.payload;
      setGroupFetchStatuses(state, groups, true, false);

      if (groups.includes(ChangeOrderGroups.HEADER)) {
        state.scopeChanges = changeOrder.header!.main.scopeChange || '';

        let { deliveryTimestamp, completionTimestamp } = changeOrder.header!.schedule;
        state.deliveryTimestamp = deliveryTimestamp ?? undefined;
        state.completionTimestamp = completionTimestamp ?? undefined;

        state.headerTouched = false;
      }

      // Keep latest saved groups so that we can reset change order to last saved version.
      state.changeOrder = updateQuoteFromResponse(state.changeOrder, changeOrder);
    });

    builder.addCase(saveChangeOrderGroupsStartAction, (state, { payload: groups }) => {
      setGroupSaveStatuses(state, groups, true);
    });
    builder.addCase(saveChangeOrderGroupsAction.rejected, (state, { payload: groups }) => {
      if (groups) {
        setGroupSaveStatuses(state, groups, false);
      }
    });
    builder.addCase(saveChangeOrderGroupsAction.fulfilled, (state, action) => {
      setGroupSaveStatuses(state, action.payload.groups, false);
      state.headerTouched = false;

      // Merge save result into local CO data.
      state.changeOrder = updateQuoteFromResponse(state.changeOrder, action.payload.changeOrder);
    });
  },
});
