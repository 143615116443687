import { HttpMethod } from './http-method.types';

export abstract class BaseRequest<T> {
  private _baseUrl: string;

  protected constructor(private readonly plural: string) {
    this._baseUrl = '';
  }

  get baseUrl(): string {
    return this._baseUrl;
  }

  set baseUrl(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  protected post(endpoint: string, params = {}, query = {}, headers = {}, body = {}): Promise<T> {
    return this.request(`${this.plural}${endpoint}`, 'POST', params, query, headers, body);
  }

  protected get(endpoint: string, query = {}, headers = {}): Promise<T> {
    return this.request(`${this.plural}${endpoint}`, 'GET', null, query, headers);
  }

  protected put(endpoint: string, params = {}, query = {}, headers = {}, body: {}): Promise<T> {
    return this.request(`${this.plural}${endpoint}`, 'PUT', params, query, headers, body);
  }

  protected delete(endpoint: string, query = {}, headers = {}): Promise<T> {
    return this.request(`${this.plural}${endpoint}`, 'DELETE', null, query, headers);
  }

  protected options(endpoint: string, params = {}, query = {}, headers = {}): Promise<T> {
    return this.request(`${this.plural}${endpoint}`, 'OPTIONS', params, query, headers);
  }

  protected abstract request(
    url: string,
    method: HttpMethod,
    params?: {} | null,
    query?: {} | null,
    headers?: {} | null,
    body?: {} | null
  ): Promise<T>;
}
