import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStatementOfWork, IStatementTemplate } from '../../../interfaces';
import { fetchQuoteAction } from '../../session/quote.action-creators';
import { saveStatementOfWorkAction, statementOfWorkDeletedAction } from './saveStatementOfWorkAction';
import { fetchStatementOfWorkAction, fetchStatementOfWorkTemplatesAction } from './statement-tab.actions';

export interface StatementTabState {
  statementFetched: boolean;
  fetchingStatement: boolean;
  savedStatement?: IStatementOfWork;
  statement?: IStatementOfWork;
  isOriginalStatement: boolean;
  statementUpdateId: number;
  templatesFetched: boolean;
  fetchingTemplates: boolean;
  templates?: IStatementTemplate[];
  touched: boolean;
  touchedSinceTemplateChange: boolean;
  saving: boolean;
}

const initialState: StatementTabState = {
  statementFetched: false,
  fetchingStatement: false,
  isOriginalStatement: false,
  statementUpdateId: 0,
  templatesFetched: false,
  fetchingTemplates: false,
  touched: false,
  touchedSinceTemplateChange: false,
  saving: false,
};

export const statementTabSlice = createSlice({
  name: 'quote/statementTab',
  initialState,
  reducers: {
    touchStatement(state, { payload: templateChange }: PayloadAction<boolean>) {
      if (templateChange) {
        state.isOriginalStatement = false;
      }
      state.touchedSinceTemplateChange = !templateChange;
      state.touched = true;
    },
    setStatement(state, action: PayloadAction<IStatementOfWork | undefined>) {
      state.statement = action.payload;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // Fetch statement reducers.
    builder.addCase(fetchStatementOfWorkAction.pending, (state) => {
      state.fetchingStatement = true;
    });
    builder.addCase(
      fetchStatementOfWorkAction.fulfilled,
      (state, { payload }: PayloadAction<IStatementOfWork | undefined>) => {
        state.fetchingStatement = false;
        state.statementFetched = true;
        state.savedStatement = payload;
        state.statement = payload;
        state.isOriginalStatement = true;
        state.statementUpdateId++;
      }
    );
    builder.addCase(fetchStatementOfWorkAction.rejected, (state) => {
      state.fetchingStatement = false;
    });

    // Fetch templates reducers.
    builder.addCase(fetchStatementOfWorkTemplatesAction.pending, (state) => {
      state.fetchingTemplates = true;
    });
    builder.addCase(fetchStatementOfWorkTemplatesAction.fulfilled, (state, { payload }) => {
      state.fetchingTemplates = false;
      state.templatesFetched = true;
      state.templates = payload;
    });
    builder.addCase(fetchStatementOfWorkTemplatesAction.rejected, (state) => {
      state.fetchingTemplates = false;
    });

    // Save statement reducers.
    builder.addCase(saveStatementOfWorkAction.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(saveStatementOfWorkAction.fulfilled, (state) => {
      state.touched = false;
      state.saving = false;
      state.savedStatement = state.statement;
    });
    builder.addCase(saveStatementOfWorkAction.rejected, (state) => {
      state.saving = false;
    });

    builder.addCase(statementOfWorkDeletedAction, (state) => {
      delete state.savedStatement;
    });

    builder.addCase(fetchQuoteAction.pending, (state, action) => {
      if (action.meta.arg.reset) {
        state.touched = false;
        state.statement = state.savedStatement;
        state.statementUpdateId++;
      }
    });
  },
});
