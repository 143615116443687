import React from 'react';
import { TabButtonWrapper, StyledButton } from './TabButton.style';
import { ITabButtonProps } from './TabButton.props';
import classNames from 'classnames';

export const TabButton: React.FC<ITabButtonProps> = (props: ITabButtonProps) => {
  const { text, icon, disabled, ...rest }: ITabButtonProps = props;
  return (
    <TabButtonWrapper>
      <StyledButton className={classNames({ disabled })} activeClassName="active" {...rest}>
        {icon}
        <div>{text}</div>
      </StyledButton>
    </TabButtonWrapper>
  );
};
