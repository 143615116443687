import { QuoteInnerTabs } from 'enums';
import { IApiEnvelope, IProduct, IRentalProductProfile, ISalesProductProfile } from 'interfaces';
import { RentalProductCategory } from '../rdx/quote-screen/products-tab/products-tab.types';
import { ConfigurableRequest } from './configurable-request';

export class ProductsService extends ConfigurableRequest {
  private static instance: ProductsService;

  private constructor() {
    super('products');
  }

  public async searchProducts(
    innerTab: QuoteInnerTabs,
    search: string,
    equipmentTypes: string,
    categoryTypeId: string | null,
    branchId: string,
    page: number,
    perPage: number,
    sort?: string
  ): Promise<IProduct[]> {
    if (categoryTypeId === 'ALL') {
      categoryTypeId = 'BULK,SERIALIZED';
    }
    const queryParams: any = { query: search, categoryTypeId, branchId, perPage, page };
    if (equipmentTypes) {
      queryParams.type = equipmentTypes;
    }
    if (sort) {
      queryParams.sort = sort;
    }
    const result: IApiEnvelope<{ elements: any[]; metadata: any }> = await this.get(`/${innerTab}/search`, queryParams);
    const [data]: any[] = result.data;
    return data;
  }

  public async getRentalProfile(code: string, branchId: string, accountId: string, marketSegmentId: string) {
    const result: IApiEnvelope<IRentalProductProfile> = await this.get(`/rental/profile`, {
      code,
      branchId,
      accountId,
      marketSegmentId,
    });
    return result.data[0];
  }

  public async getSalesProfile(code: string, branchId: string) {
    const result: IApiEnvelope<ISalesProductProfile> = await this.get(`/sales/profile`, { code, branchId });
    return result.data[0];
  }

  public async getRentalCategories(branchId: string): Promise<RentalProductCategory[]> {
    const result: IApiEnvelope<any> = await this.get('/rental/categories', { branchId });
    return result.data?.[0]?.elements ?? [];
  }

  public static getInstance(): ProductsService {
    if (!ProductsService.instance) {
      ProductsService.instance = new ProductsService();
    }
    return ProductsService.instance;
  }
}
