import { IBranch } from './Branch';

export interface IUserProfile {
  id: string;
  externalIdentifier: string;
  details: IUserProfileDetails;
  hierarchy: IUserProfileHierarchy;
  approval: IUserProfileApproval;
  security: IUserProfileSecurity;
}

export interface IUserProfileDetails {
  objectGuid: string;
  username: string;
  fullName: string;
  firstName: string;
  lastName: string;
  title: string;
  emailAddress: string;
  timeZone: string;
  locale: string;
  department: string;
}

export interface IUserProfileHierarchy {
  branch: IBranch;
}

export interface IUserProfileApproval {
  enabled: boolean;
  levelId: string;
}

export interface IUserProfileOperationalArea {
  name: string;
  description: string;
  id: string;
  code: string;
  branches: IBranch[];
  regionId: string;
}

export interface IUserProfileRegion {
  operationalAreas: IUserProfileOperationalArea[];
  companyId: string;
  code: string;
  name: string;
  description: string;
  id: string;
  placeholder: boolean;
  countryId: string;
}

export interface IUserProfileAccessibleCompany {
  name: string;
  organizationId: string;
  description: string;
  id: string;
  code: string;
  regions: IUserProfileRegion[];
}

export enum Roles {
  READER = 'READER',
  WRITER = 'WRITER',
  APPROVER = 'APPROVER',
  ADMINISTRATOR = 'ADMINISTRATOR',
}

export interface IUserProfileSecurity {
  role: Roles;
  accessibleCompanies: IUserProfileAccessibleCompany[];
}
