import { Autocomplete, Box, TextField } from '@mui/material';
import { IBranch } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LAST_SELECTED_BRANCH } from '../../constants';
import { i18nKeys } from '../../internationalization/i18nKeys';
import { HelpTooltip } from '../HelpTooltip/HelpTooltip';
import { IBranchSelectorProps } from './BranchSelector.props';

const BranchSelector: React.FC<IBranchSelectorProps> = (props) => {
  const { t } = useTranslation();
  const { branches, value, onBranchChange, ...rest } = props;

  if (!branches.length) {
    return null;
  }

  const getPermissionType = (branch: IBranch): string => {
    if (branch.permissions.read && !branch.permissions.write) {
      return 'Read';
    } else if (branch.permissions.write) {
      return 'Write';
    }
    return 'Unknown';
  };

  const handleAutocompleteChange = (branch: IBranch) => {
    if (branch) {
      localStorage.setItem(LAST_SELECTED_BRANCH, branch.id);
      onBranchChange(branch.id);
    }
  };

  const options = branches.map((b) => ({ permissionType: getPermissionType(b), ...b }));
  const [active] = [options.find((b) => b.id === value) || branches[0]].map((b) => ({
    permissionType: getPermissionType(b),
    ...b,
  }));

  if (!options.length || !active) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', columnGap: 0.5, alignItems: 'center' }}>
      <HelpTooltip placement="left-start" maxWidth="60ex" html={t(i18nKeys.help.branchSelector)} />
      <Autocomplete
        {...rest}
        value={active as any}
        onChange={(e, value) => handleAutocompleteChange(value)}
        options={options}
        groupBy={(option) => option.permissionType}
        getOptionLabel={(option) => `${option.code} - ${option.name}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        style={{ width: 250 }}
        renderInput={(params) => <TextField {...params} label="Active Branch" />}
      />
    </Box>
  );
};

export { BranchSelector };
