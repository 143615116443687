import { Box } from '@mui/material';
import { ExpandableError } from 'components';
import React from 'react';
import { useSelector } from 'react-redux';
import { ReviewTabSelectors, ValidationGroup } from '../../rdx';

export const ErrorsDrawerContent: React.FC = () => {
  const groups = useSelector(ReviewTabSelectors.validationGroups);

  const sections: Array<{ group: ValidationGroup; critical: boolean }> = [];
  const addSection = (group: ValidationGroup, critical: boolean = false) => {
    if (group.items.length) {
      sections.push({ group, critical });
    }
  };
  addSection(groups.clientSide, true);
  addSection(groups.general);
  addSection(groups.customer);
  addSection(groups.details);
  addSection(groups.scopeChanges);
  addSection(groups.rental);
  addSection(groups.optionalRental);
  addSection(groups.sales);
  addSection(groups.optionalSales);
  addSection(groups.services);
  addSection(groups.optionalServices);
  addSection(groups.statement);

  return (
    <Box>
      {sections.map(({ group, critical }, index) => (
        <ExpandableError key={group.name} group={group} type={critical ? 'critical-error' : 'error'} index={index} />
      ))}
    </Box>
  );
};
