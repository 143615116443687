import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'rdx/types';
import { IUserRoles, IUserSummary } from '../../../interfaces';
import { QuoteSelectors, SessionSelectors } from '../../session';
import { RelatedUserWarnings, IRelatedUser } from './details-tab.types';

const details = (state: RootState) => state.quoteScreen.detailsTab.details;

const makeSelectUsersForRole = (
  role: keyof IUserRoles,
  selectCurrentUserId: (state: RootState) => string | null,
  selectSavedUser: (state: RootState) => IUserSummary | undefined
) =>
  createSelector(
    SessionSelectors.branchUsers,
    SessionSelectors.branchUsersFetched,
    selectCurrentUserId,
    selectSavedUser,
    (branchUsers, usersFetched, currentUserId, savedUser) => {
      const users: IRelatedUser[] = [];

      // If current saved user is not in the list of users, add them to the list.
      if (savedUser && !branchUsers.find(({ id }) => id === savedUser.id)) {
        users.push({
          id: savedUser.id,
          details: savedUser.details,
          relatedUserWarning: usersFetched ? RelatedUserWarnings.NotInBranch : undefined,
        });
      }

      for (const { id, details, security } of branchUsers) {
        const hasRole = !!security?.roles?.[role];
        if (hasRole || id === savedUser?.id) {
          const relatedUserWarning = hasRole ? undefined : RelatedUserWarnings.MissingRole;
          users.push({ id, details, relatedUserWarning });
        }
      }
      return users;
    }
  );

const getUserSummary = (user: IUserSummary | null): IUserSummary | undefined => {
  if (!user) {
    return undefined;
  }
  return {
    id: user.id,
    details: user.details,
  };
};

export const DetailsTabSelectors = {
  fetching: (state: RootState) => state.quoteScreen.detailsTab.status.fetching,
  fetched: (state: RootState) => state.quoteScreen.detailsTab.status.fetched,
  config: (state: RootState) => state.quoteScreen.detailsTab.config.data,
  configFetching: (state: RootState) => state.quoteScreen.detailsTab.config.status.fetching,
  configFetched: (state: RootState) => state.quoteScreen.detailsTab.config.status.fetched,
  details,

  project: (state: RootState) => details(state).project,
  notes: (state: RootState) => details(state).notes,
  schedule: (state: RootState) => details(state).schedule,
  engineering: (state: RootState) => details(state).engineering,
  application: (state: RootState) => details(state).application,

  projectName: (state: RootState) => DetailsTabSelectors.project(state).name,
  projectWhat: (state: RootState) => DetailsTabSelectors.project(state).what,
  projectDuration: (state: RootState) => DetailsTabSelectors.project(state).duration,
  projectDescription: (state: RootState) => DetailsTabSelectors.project(state).description,

  notesContent: (state: RootState) => DetailsTabSelectors.notes(state).content,

  scheduleDelivery: (state: RootState) => DetailsTabSelectors.schedule(state).deliveryTimestamp,
  scheduleCompletion: (state: RootState) => DetailsTabSelectors.schedule(state).completionTimestamp,

  engineeringJobNumber: (state: RootState) => DetailsTabSelectors.engineering(state).jobNumber,
  engineeringComments: (state: RootState) => DetailsTabSelectors.engineering(state).comments,
  estimators: makeSelectUsersForRole(
    'estimator',
    (state: RootState) => details(state).estimatorId,
    QuoteSelectors.estimator
  ),
  estimator: (state: RootState) => {
    const { estimatorId } = details(state);
    if (!estimatorId) {
      return null;
    }
    return DetailsTabSelectors.estimators(state).find(({ id }) => id === estimatorId) ?? null;
  },
  estimatorSummary: (state: RootState) => getUserSummary(DetailsTabSelectors.estimator(state)),

  supportSpecialists: makeSelectUsersForRole(
    'supportSpecialist',
    (state: RootState) => details(state).supportSpecialistId,
    QuoteSelectors.supportSpecialist
  ),
  supportSpecialist: (state: RootState) => {
    const { supportSpecialistId } = details(state);
    if (!supportSpecialistId) {
      return null;
    }
    return DetailsTabSelectors.supportSpecialists(state).find(({ id }) => id === supportSpecialistId) ?? null;
  },
  supportSpecialistSummary: (state: RootState) => getUserSummary(DetailsTabSelectors.supportSpecialist(state)),

  applicationId: (state: RootState) => DetailsTabSelectors.application(state).id,
  applicationFields: (state: RootState) => DetailsTabSelectors.application(state).fields,
};
