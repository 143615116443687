import { AppConfigurationService } from '../api';
import { cognitoCallbackPath } from '../routes/routes';

export interface ICognitoLocations {
  login(state: string): void;
  logout(): void;
}

export class CognitoLocations implements ICognitoLocations {
  login(state: string) {
    const { cognitoURL, clientID, baseRoute } = AppConfigurationService.getInstance().appConfiguration;

    const loginUrl = new URL(`${cognitoURL}/oauth2/authorize`);
    loginUrl.searchParams.append('response_type', 'code');
    loginUrl.searchParams.append('client_id', clientID);
    loginUrl.searchParams.append('redirect_uri', `${baseRoute}${cognitoCallbackPath}`);
    loginUrl.searchParams.append('scope', 'email openid phone profile');
    loginUrl.searchParams.append('state', state);

    window.location.href = loginUrl.toString();
  }

  logout(): void {
    const { cognitoURL, clientID, baseRoute } = AppConfigurationService.getInstance().appConfiguration;

    const logoutUrl = new URL(`${cognitoURL}/logout`);
    logoutUrl.searchParams.append('client_id', clientID);
    logoutUrl.searchParams.append('logout_uri', `${baseRoute}/auth/login`);

    window.location.href = logoutUrl.toString();
  }
}
