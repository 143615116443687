import React from 'react';
import { IQuote } from '../../interfaces';

export interface QuoteActionsCallbacks {
  markWon(quoteId: string, isProspect: boolean, onSuccess: () => void): void;
  markLost(quoteId: string, onSuccess: () => void): void;
  deleteQuote(quote: IQuote): void;
}

export const QuoteActionsContext = React.createContext<QuoteActionsCallbacks>({
  markWon() {},
  markLost() {},
  deleteQuote() {},
});
