import { IApiEnvelope, IBranch, IUserProfile } from 'interfaces';
import { ConfigurableRequest } from './configurable-request';

export class UserService extends ConfigurableRequest {
  private static instance: UserService;

  private constructor() {
    super('user');
  }

  public async fetchProfile(): Promise<IUserProfile> {
    const result: IApiEnvelope<IUserProfile> = await this.get('/profile');
    const [userProfile]: IUserProfile[] = result.data;
    return userProfile;
  }

  public async fetchBranches(): Promise<IBranch[]> {
    const result: IApiEnvelope<IBranch> = await this.get('/branches');
    return result.data;
  }

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }
}
