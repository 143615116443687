import { CustomerTabState, QuoteBillingState } from './customer-tab.types';

export const setBillRequirements = (
  state: CustomerTabState,
  { payload }: { payload: { touched?: boolean; valid?: boolean; billRequirements: QuoteBillingState } }
) => {
  if (payload.touched !== undefined) {
    state.billRequirements.status.touched = payload.touched;
  }
  if (payload.valid !== undefined) {
    state.billRequirements.status.valid = payload.valid;
  }
  state.billRequirements.taxExempt = payload.billRequirements.taxExempt;
  state.billRequirements.workOfImprovement = payload.billRequirements.workOfImprovement;
  state.billRequirements.prevailingWage = payload.billRequirements.prevailingWage;
};

export const resetBillRequirements = (state: CustomerTabState) => {
  state.billRequirements.status.touched = false;
  state.billRequirements.status.valid = true;
  state.billRequirements.taxExempt = undefined;
  state.billRequirements.workOfImprovement = undefined;
  state.billRequirements.prevailingWage = undefined;
};
