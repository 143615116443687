import { QuoteGroups, Statuses } from 'enums';
import { IAccount, IContact, IOutboundAccountGroup, IQuote } from 'interfaces';
import { CustomerTabSelectors, JobsiteState, QuoteBillingState, SessionSelectors } from 'rdx';
import { v4 } from 'uuid';
import { RootState } from '../rdx/types';

/*
 * Fetch
 */
export const convertFetchResponseToAccount = (quote: IQuote): IAccount => {
  return {
    accountTypeId: quote.header.main.account.accountTypeId || '',
    activeSince: quote.header.main.account.activeSince,
    address: quote.header.main.account.address || '',
    city: quote.header.main.account.city || '',
    code: quote.header.main.account.code || '',
    creditLimit: quote.header.main.account.creditLimit || 0,
    creditPasses: quote.header.main.account.creditPasses || false,
    crmLinkIdentifier: quote.header.main.account.crmLinkIdentifier || '',
    currentBalance: quote.header.main.account.currentBalance || 0,
    erpAccountIdentifier: quote.header.main.account.erpAccountIdentifier || '',
    id: quote.header.main.account.id || '',
    name: quote.header.main.account.name || '',
    nationalCustomer: quote.header.main.account.nationalCustomer || false,
    poRequired: quote.header.main.account.poRequired || false,
    pricingGroup: quote.header.main.account.pricingGroup || '',
    state: quote.header.main.account.state || '',
    statusId: quote.header.main.account.statusId || '',
    zipCode: quote.header.main.account.zipCode || '',
  } as IAccount;
};

export const convertFetchResponseToContact = (quote: IQuote): IContact => {
  return {
    accountId: quote.header.main.contact?.accountId || '',
    additionalInformation: quote.header.main.contact?.address || '',
    address: quote.header.main.contact?.address || '',
    city: quote.header.main.contact?.city || '',
    createdBy: quote.header.main.contact?.createdBy || '',
    createdDate: quote.header.main.contact?.createdDate || 0,
    emailAddress: quote.header.main.contact?.emailAddress || '',
    externalIdentifier: quote.header.main.contact?.externalIdentifier || '',
    faxNumber: quote.header.main.contact?.faxNumber || '',
    id: quote.header.main.contact?.id || '',
    mobileExtension: quote.header.main.contact?.mobileExtension || '',
    mobilePhoneNumber: quote.header.main.contact?.mobilePhoneNumber || '',
    name: quote.header.main.contact?.name || '',
    officeExtension: quote.header.main.contact?.officeExtension || '',
    officePhoneNumber: quote.header.main.contact?.officePhoneNumber || '',
    state: quote.header.main.contact?.state || '',
    statusId: quote.header.main.contact?.statusId || ('' as Statuses),
    title: quote.header.main.contact?.title || '',
    zipCode: quote.header.main.contact?.zipCode || '',
  } as IContact;
};

export const convertFetchResponseToMarketSegment = (quote: IQuote): string => {
  return quote.header.main.marketSegmentId || '';
};

export const convertFetchResponseToJobsite = (quote: IQuote): JobsiteState => {
  const { jobsite } = quote.header;
  return {
    id: jobsite?.id || '',
    jobName: jobsite?.jobName || '',
    poNumber: jobsite?.poNumber || '',
    address: jobsite?.address || '',
    city: jobsite?.city || '',
    state: jobsite?.state || '',
    zipCode: jobsite?.zipCode || '',
    siteContact: jobsite?.siteContact || '',
    sitePhoneNumber: jobsite?.sitePhoneNumber || '',
    siteExtension: jobsite?.siteExtension || '',
    siteMobilePhoneNumber: jobsite?.siteMobilePhoneNumber || '',
    marketSector: jobsite?.marketSector || '',
    marketSectorCode: jobsite?.marketSectorCode || '',
  };
};

export const convertFetchResponseToBillRequirements = (quote: IQuote): QuoteBillingState => ({
  taxExempt: quote.header.billing?.taxExempt || false,
  workOfImprovement: quote.header.billing?.workOfImprovement || false,
  prevailingWage: quote.header.billing?.prevailingWage || false,
});

/*
 * Save
 */
export const convertCustomerTabToSaveRequest = (state: RootState, cloning: boolean): IOutboundAccountGroup => {
  const contactId = CustomerTabSelectors.contact(state)?.id;
  const saveJobsite = cloning || CustomerTabSelectors.jobsiteTouched(state);
  const saveBillingRequirements = cloning || CustomerTabSelectors.billRequirementsTouched(state);

  const sitePhoneNumber: string = CustomerTabSelectors.jobsiteSitePhoneNumber(state) ?? '';
  const siteExtension: string = CustomerTabSelectors.jobsiteSiteExtension(state) ?? '';
  const siteMobilePhoneNumber: string = CustomerTabSelectors.jobsiteSiteMobile(state) ?? '';

  const content = {
    header: {
      main: {
        account: { id: CustomerTabSelectors.account(state)?.id || '' },
        contact: contactId ? { id: CustomerTabSelectors.contact(state)?.id } : undefined,
        marketSegmentId: CustomerTabSelectors.marketSegmentId(state),
        branch: { id: CustomerTabSelectors.activeBranchId(state) },
      },
      jobsite: saveJobsite
        ? {
            id: SessionSelectors.jobsiteId(state) || v4(),
            jobName: CustomerTabSelectors.jobsiteJobName(state),
            poNumber: CustomerTabSelectors.jobsitePoNumber(state),
            address: CustomerTabSelectors.jobsiteAddress(state),
            city: CustomerTabSelectors.jobsiteCity(state),
            state: CustomerTabSelectors.jobsiteState(state),
            zipCode: CustomerTabSelectors.jobsiteZipCode(state),
            siteContact: CustomerTabSelectors.jobsiteSiteContact(state),
            sitePhoneNumber: `${parseInt(sitePhoneNumber) || ''}`,
            siteExtension: `${parseInt(siteExtension) || ''}`,
            siteMobilePhoneNumber: `${parseInt(siteMobilePhoneNumber) || ''}`,
            marketSector: CustomerTabSelectors.jobsiteMarketSector(state),
            marketSectorCode: CustomerTabSelectors.jobsiteMarketSectorCode(state),
          }
        : undefined,
      billing: saveBillingRequirements
        ? {
            taxExempt: CustomerTabSelectors.billingTaxExempt(state),
            workOfImprovement: CustomerTabSelectors.billingWorkOfImprovement(state),
            prevailingWage: CustomerTabSelectors.billingPrevailingWage(state),
          }
        : undefined,
    },
  } as any;

  return {
    id: QuoteGroups.ACCOUNT,
    content,
  };
};
