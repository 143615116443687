import { CrudActions, QuoteGroups, QuoteProductTabs, QuoteTabs } from 'enums';
import { IOutboundGroup, IRentalDetailEvent, IRentalDetailEventBody, IRentalGridRow } from 'interfaces';
import { getProductSelectors, makeRentalRowDefaults } from 'rdx';
import { ChangeOrderGroups } from '../rdx/change-order/types';
import { RootState } from '../rdx/types';
import { convertRowsToSaveEvents, createProductsGroup, makeDetailEventMetadata } from './common';

/*
 * Save
 */
const convertToRentalDetailEvent = (
  state: RootState,
  row: IRentalGridRow,
  timestamp: number,
  action: CrudActions,
  rowIndex: number
) => {
  const event: IRentalDetailEvent = {
    metadata: makeDetailEventMetadata(row.rowId, timestamp, action, rowIndex),
  };
  if (action !== CrudActions.DELETE) {
    event.data = {
      rentalProduct: {
        ...row.product!,
      },
      id: row.rowId,
      rowIndex,
      description: row.description,
      quantity: row.quantity,
      unitId: row.unitId,
      periodId: row.periodId,
      duration: row.duration ?? null,
      extension: row.extension || 0,
      percentageOfRate: row.percentageOfRate,
      aqFee: row.aqFee || 0,
      rppFee: row.rppFee || 0,
      erFee: row.erFee || 0,

      quotedRateDay: row.dayPeriod.quotedRate,
      listRateDay: row.dayPeriod.listRate,
      lockdownRateDay: row.dayPeriod.lockdownRate,

      quotedRateWeek: row.weekPeriod.quotedRate,
      listRateWeek: row.weekPeriod.listRate,
      lockdownRateWeek: row.weekPeriod.lockdownRate,

      quotedRateCycle: row.cyclePeriod.quotedRate,
      listRateCycle: row.cyclePeriod.listRate,
      lockdownRateCycle: row.cyclePeriod.lockdownRate,

      ratedManually: row.ratedManually,
      rateTierId: row.rateTierId,
      redlineLevelId: row.redlineLevelId,

      rateTierColor: row.rateTierColor,
      redlineLevelColor: row.redlineLevelColor,

      woscoRate: row.woscoRate,
      woscoRatePercentage: row.woscoRatePercentage,
    };
  }
  return event;
};

const isRentalRowSavable = (row: IRentalGridRow) => {
  return !!row.product;
};

export const convertRentalTabToSaveRequest = (
  state: RootState,
  tab: QuoteTabs,
  groupId: QuoteGroups | ChangeOrderGroups
): IOutboundGroup | null => {
  const rows = getProductSelectors(tab).rentalRows(state);
  const events = convertRowsToSaveEvents(state, rows, isRentalRowSavable, convertToRentalDetailEvent);
  return createProductsGroup(events, groupId);
};

/*
 * Fetch
 */
export const convertToRentalGridRow = (event: IRentalDetailEventBody, tab: QuoteProductTabs) => {
  const row: IRentalGridRow = {
    status: {
      existingItem: true,
      action: CrudActions.READ,
      fetched: false,
      fetching: false,
      error: false,
      touched: false,
    },
    ...makeRentalRowDefaults(),
    tab,
    rowId: event.id,
    rentalProductId: event.rentalProduct?.id!,
    product: event.rentalProduct,
    description: event.description,
    quantity: event.quantity,
    unitId: event.unitId,
    code: event.rentalProduct?.code!,

    periodId: event.periodId,
    duration: event.duration ?? undefined,
    extension: event.extension,
    percentageOfRate: event.percentageOfRate,

    ratedManually: event.ratedManually,
    rateTierId: event.rateTierId,
    redlineLevelId: event.redlineLevelId,

    rppFee: event.rppFee,
    aqFee: event.aqFee,
    erFee: event.erFee,

    rateTierColor: event.rateTierColor,
    redlineLevelColor: event.redlineLevelColor,

    woscoRate: event.woscoRate || undefined,
    woscoRatePercentage: event.woscoRatePercentage ?? undefined,
  };

  row.dayPeriod.quotedRate = event.quotedRateDay;
  row.dayPeriod.listRate = event.listRateDay;
  row.dayPeriod.lockdownRate = event.lockdownRateDay;
  row.weekPeriod.quotedRate = event.quotedRateWeek;
  row.weekPeriod.listRate = event.listRateWeek;
  row.weekPeriod.lockdownRate = event.lockdownRateWeek;
  row.cyclePeriod.quotedRate = event.quotedRateCycle;
  row.cyclePeriod.listRate = event.listRateCycle;
  row.cyclePeriod.lockdownRate = event.lockdownRateCycle;

  return row;
};
