import { SvgIcon } from '@mui/material';
import React from 'react';

export const ZohoSignIcon: React.FC<{ size?: 'small' | 'medium' | 'large' }> = (props) => {
  return (
    <SvgIcon fontSize={props.size ?? 'medium'}>
      <svg width="24" height="24" viewBox="0 0 122.88 107.95">
        <g>
          <path d="M82.19,0c1.22-0.01,2.27,0.4,3.18,1.27l13.27,12.7c0.86,0.82,1.28,1.91,1.3,3.11 c0.02,1.22-0.34,2.33-1.18,3.18l-7.06,7.35L72.09,8.67l6.99-7.28C79.94,0.5,80.98,0.02,82.19,0L82.19,0z M20.08,68.29 c2.4-0.74,4.93,0.61,5.67,3c0.74,2.4-0.61,4.93-3,5.67c-6.98,2.14-11.66,6.28-13.21,10.17c-0.41,1.04-0.55,1.99-0.39,2.77 c0.12,0.59,0.48,1.13,1.07,1.56c2.55,1.86,8.04,2.34,17.26,0.1c5.46-1.33,11.31-3.13,16.49-4.73c5.29-1.63,9.91-3.06,13.12-3.66 c5.92-1.1,11.83-1.85,16.6-1.61c6.26,0.31,10.96,2.32,12.57,7.13c1.04,3.11,0.75,5.81,0.49,8.23c-0.09,0.87-0.18,1.68-0.14,2.21 c0,0.02,0.95-0.05,5.53-0.41c5.3-0.42,10.79-2.51,16.21-4.58c2.78-1.06,5.54-2.11,8.67-3.07c2.4-0.73,4.93,0.63,5.65,3.02 c0.73,2.4-0.63,4.93-3.02,5.65c-2.43,0.74-5.24,1.82-8.06,2.89c-6.04,2.3-12.14,4.63-18.74,5.15c-12.07,0.95-14.87-2.46-15.3-7.95 c-0.1-1.29,0.03-2.54,0.17-3.88c0.16-1.5,0.34-3.18-0.07-4.39c-0.18-0.54-1.89-0.82-4.39-0.94c-3.99-0.2-9.22,0.48-14.54,1.47 c-2.65,0.49-7.05,1.85-12.09,3.41c-5.27,1.63-11.22,3.46-17.02,4.87c-12.14,2.95-20.21,1.7-24.73-1.6c-2.54-1.85-4.05-4.27-4.63-7 c-0.54-2.54-0.23-5.28,0.85-8C3.56,77.63,10.33,71.27,20.08,68.29L20.08,68.29z M41.27,48.45L53.3,60.02l-14.71,3.95 c-0.53,0.1-0.74-0.11-0.65-0.6L41.27,48.45L41.27,48.45z M65.87,15.11l19.59,18.87L58.7,62.23l-23.98,6.43 c-0.86,0.17-1.2-0.17-1.06-0.98l5.43-24.31L65.87,15.11L65.87,15.11z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
