import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import React from 'react';

interface IConfirmSnackBarProps {
  snackBarKey: SnackbarKey;
  variant?: VariantType;
  title: string;
  message: string;
  closeButtonText?: string;
}

export const ConfirmSnackBar = React.forwardRef<any, IConfirmSnackBarProps>((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const { snackBarKey, message, title, closeButtonText } = props;
  const variant = !props.variant || props.variant === 'default' ? 'info' : props.variant;

  return (
    <Paper
      sx={{
        maxWidth: 400,
        minWidth: 344,
        bgcolor: (theme) => theme.palette[variant].main,
        p: '8px 8px 8px 16px',
        display: 'flex',
        alignItems: 'center',
      }}
      ref={ref}
    >
      <Box
        sx={{
          display: 'flex',
          '.MuiSvgIcon-root': {
            color: 'white',
            fontSize: 25,
            pr: '4px',
          },
        }}
      >
        {variant === 'error' && <ErrorIcon />}
        {variant === 'warning' && <WarningIcon />}
        {variant === 'info' && <InfoIcon />}
        {variant === 'success' && <SuccessIcon />}
      </Box>
      <div style={{ flex: 1 }}>
        <Typography variant="subtitle2" fontWeight="bold" color="white">
          {title}
        </Typography>
        <Typography variant="body2" color="white">
          {message}
        </Typography>
      </div>
      {!!closeButtonText && (
        <Box mx={1}>
          <Button
            variant="text"
            sx={{ color: 'white', border: '1px solid white' }}
            onClick={() => closeSnackbar(snackBarKey)}
          >
            {closeButtonText}
          </Button>
        </Box>
      )}
    </Paper>
  );
});
