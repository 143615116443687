import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountsService } from 'api';
import { IAccount } from 'interfaces';
import { CustomerTabState } from './customer-tab.types';

export const fetchAccountAction = createAsyncThunk(
  'quote/customerTab/fetchAccountAction',
  async (payload: string, thunkAPI) => {
    try {
      const service: AccountsService = AccountsService.getInstance();
      const response: IAccount = await service.getAccount(payload);
      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchAccountPendingAction = (state: CustomerTabState) => {
  state.account.status.fetching = true;
  state.account.status.touched = true;

  state.contact.status.fetching = true;
};

export const fetchAccountRejectAction = (state: CustomerTabState) => {
  state.account.status.fetching = false;
  state.account.status.fetched = false;
};

export const fetchAccountFulfillAction = (state: CustomerTabState, action: any) => {
  const payload: IAccount = action.payload;
  state.account.status.fetching = false;
  state.account.status.fetched = true;
  state.account.status.valid = true;
  state.account.active = payload;
};

export const setAccountAction = (state: CustomerTabState, action: any) => {
  const payload: { touched?: boolean; valid?: boolean; account: IAccount } = action.payload;
  if (payload.touched !== undefined) {
    state.account.status.touched = payload.touched;
  }
  if (payload.valid !== undefined) {
    state.account.status.valid = payload.valid;
  }

  state.account.active = payload.account;
};

export const resetAccountAction = (state: CustomerTabState) => {
  state.account.status.fetching = false;
  state.account.status.fetched = false;
  state.account.status.valid = false;
  state.account.active = null;
};
