import { RootState } from 'rdx/types';
import { QuoteValidationFields, QuoteValidationFieldKeys } from './validation/validation';

const validation = (state: RootState) => state.quoteScreen.reviewTab.validation;

export const ReviewTabSelectors = {
  fetching: (state: RootState): boolean => state.quoteScreen.reviewTab.status.fetching,
  printOption: (state: RootState) => state.quoteScreen.reviewTab.quotePrintOptions.printOption,
  summaryDescription: (state: RootState) => state.quoteScreen.reviewTab.quotePrintOptions.summaryDescription,

  quoteValid: (state: RootState) => validation(state).valid,
  validationRequestFailed: (state: RootState) => validation(state).requestFailed,
  validationErrorCount: (state: RootState) => validation(state).errorCount,
  validationGroups: (state: RootState) => validation(state).groups,

  showErrorsDrawer: (state: RootState): boolean => state.quoteScreen.reviewTab.errorsDrawer.show,
  errorsDrawerPinned: (state: RootState): boolean => state.quoteScreen.reviewTab.errorsDrawer.pinned,
};

export const IsFieldInvalidSelectors = Object.values(QuoteValidationFields).reduce((selectors, field) => {
  selectors[field.key] = (state: RootState) => {
    const codes = validation(state).groups[field.validationGroup].errorCodes;
    return field.errorCodes.some((code) => codes[code]);
  };
  return selectors;
}, {} as { [FieldKey in QuoteValidationFieldKeys]: (state: RootState) => boolean });
