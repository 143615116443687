import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridRowLocation, IRentalRatePeriods, WorkflowStates } from '../../../interfaces';
import { FetchStatuses } from '../../../mobile/redux/types';
import { round2Decimals } from '../../../utils';
import { fetchChangeOrderGroupsAction, saveChangeOrderGroupsAction } from '../../change-order/actions';
import { saveQuoteAction } from '../../session';
import { fetchQuoteAction } from '../../session/quote.action-creators';
import { makeProductsInitialState } from '../../utils';
import { setOptionalProductsNotes, updateNotesStatusAction } from './notes.actions';
import {
  addRentalRowsAction,
  addSalesRowsAction,
  addServiceRowsAction,
  deleteItemInternalAction,
  rerateDraftQuoteAction,
  updateFscMultiplierAction,
} from './products-tab.action-creators';
import {
  addProductRows,
  addServiceRows,
  afterFetchQuoteProductsFulfillAction,
  afterFetchQuoteProductsPendingAction,
  afterFetchQuoteProductsRejectAction,
  afterSaveQuoteProductsFulfillAction,
  bulkRentalDurationUpdate,
  bulkRentalPeriodUpdate,
  bulkRentalQuantityUpdate,
  deleteItem,
  fetchChangeOrderGroupsFulfilled,
  saveChangeOrderGroupsFulfilled,
  setGridSelection,
  updateWaiveAirQuality,
  updateWaiveEnvironmentalRecovery,
} from './products-tab.actions';
import { updateProductRowAfterProfileRejection, updateProductRowBeforeFetchingProfile } from './products-tab.helpers';
import {
  ProductsTabState,
  QuoteProductsState,
  RentalRowUpdate,
  ReratingStates,
  SalesRowUpdate,
  ServiceRowUpdate,
} from './products-tab.types';
import {
  bulkRentalDurationUpdateInternalAction,
  bulkRentalPeriodUpdateInternalAction,
  bulkRentalQuantityUpdateInternalAction,
  fetchRentalCategoriesAction,
  fetchRentalPeriodsAction,
  fetchRentalProductUnitsAction,
  fetchRentalProfileInternalAction,
  fetchRentalProfilesAction,
  fetchSalesProductUnitsAction,
  resetRentalPercentOfRateAction,
  updateRentalPercentOfRateAction,
} from './rental.action-creators';
import {
  resetRentalPercentOfRate,
  resetRentalProfile,
  updateRateLockDown,
  updateRentalPercentOfRate,
} from './rental.actions';
import { findRentalRow, recalculateRentalRow, updateRentalRowFromProfile, updateRentalRowProp } from './rental.helpers';
import {
  fetchSalesProfileInternalAction,
  fetchSalesProfilesAction,
  resetSalesMarginPercentAction,
  updateSalesMarginPercentAction,
} from './sales.action-creators';
import { resetSalesMarginPercent, resetSalesProfile, updateSalesMarginPercent } from './sales.actions';
import { findSalesRow, updateSalesRowFromProfile, updateSalesRowProp } from './sales.helpers';
import {
  addServicePackageAction,
  deleteServicesPhaseInternalAction,
  fetchServiceProfileAction,
  fetchServiceProfilesAction,
  fetchServicesPhasesAction,
  setCopyingServicesAction,
  updateServicesDriverAndTruckTotalsAction,
} from './services.actions';
import {
  findServiceRow,
  getServiceKey,
  updateServiceRowFromProfile,
  updateServiceRowProp,
  updateServicesDriverAndTruckTotals,
} from './services.helpers';
import {
  addOneServicePackageForEachPhase,
  addServicePackage,
  deleteServicesPhase,
  fetchServicesPhasesFulfilled,
  fetchServicesPhasesPending,
  fetchServicesPhasesRejected,
  moveServiceRows,
  setCopyingServices,
  shiftServiceRow,
  updateServicesPhaseDesc,
} from './services.reducers';
import { clearInitialSearchQuery, saveSearchWindowBounds, toggleSearchDrawer } from './ui.reducers';

const initialQuoteProductsState: QuoteProductsState = {
  searchOpened: false,
  initialSearchQuery: '',
  fscPercentage: 0,
  fscMultiplier: 0,
  feesStatus: {
    touched: false,
  },
  applyEnvironmentalRecovery: false,
  waiveEnvironmentalRecovery: false,
  applyAirQuality: false,
  waiveAirQuality: false,
  rateLockDown: {
    rowId: '',
    field: undefined,
  },
  products: makeProductsInitialState(),
  optional: {
    notes: {
      status: {
        touched: false,
        valid: true,
        fetching: false,
        fetched: false,
      },
      content: '',
    },
    ...makeProductsInitialState(),
  },
  rentalCategories: {
    fetched: false,
    categories: [],
  },
  profiles: {
    rental: {},
    sales: {},
    services: {},
  },
  reratingState: ReratingStates.NotRerated,
};

const initialState: ProductsTabState = {
  ...initialQuoteProductsState,
  rentalPeriods: [],
  rentalProductUnits: [],
  salesProductUnits: [],
};

export const productsTabSlice = createSlice({
  name: 'quote/productsTab',
  initialState,
  reducers: {
    /**
     * Reset quote-specific portion of the state but do not reset global products state.
     */
    reset: ({ rentalPeriods, rentalProductUnits, salesProductUnits }) => ({
      ...initialQuoteProductsState,
      rentalPeriods,
      rentalProductUnits,
      salesProductUnits,
    }),

    /**
     * Reset product and service profile cache.
     */
    emptyProfileCache(state) {
      state.profiles = initialState.profiles;
    },

    /* UI */
    updateNotesStatusAction,
    toggleSearchDrawer,
    clearInitialSearchQuery,
    saveSearchWindowBounds,

    /* Common */
    setGridSelection,
    updateFscPercentage(state, action: PayloadAction<{ value: number }>) {
      const { value } = action.payload;
      state.feesStatus.touched = true;
      state.fscPercentage = value;
      state.fscMultiplier = round2Decimals(value / 100);
    },
    setDefaultFscMultiplier(state, { payload }: PayloadAction<{ defaultFscMultiplier: number }>) {
      state.defaultFscMultiplier = payload.defaultFscMultiplier;
      state.feesStatus.touched = true;
    },
    updateWaiveEnvironmentalRecovery,
    updateWaiveAirQuality,

    /* Rental */
    updateRateLockDown,
    resetRentalProfile,

    updateRentalDescription(state, { payload }: PayloadAction<RentalRowUpdate<'description'>>) {
      updateRentalRowProp(state, payload, false);
    },

    updateRentalUnit(state, { payload }: PayloadAction<RentalRowUpdate<'unitId'>>) {
      updateRentalRowProp(state, payload, false);
    },

    updateRentalQuantity(state, { payload }: PayloadAction<RentalRowUpdate<'quantity'>>) {
      updateRentalRowProp(state, payload, true);
    },

    updateRentalDuration(state, { payload }: PayloadAction<RentalRowUpdate<'duration'>>) {
      updateRentalRowProp(state, payload, true);
    },

    updateRentalRate(
      state,
      { payload }: PayloadAction<GridRowLocation & { field: keyof IRentalRatePeriods; rate: number }>
    ) {
      const row = findRentalRow(state, payload);
      if (row) {
        const { field, rate } = payload;
        const updatedPeriodId = row[field].id;
        for (const ratePeriod of [row.dayPeriod, row.weekPeriod, row.cyclePeriod]) {
          // AG products do not use Day rate. Day rate is not editable and is always
          // set to the same value as week rate. Here we update rate for the actual
          // changed period and for any periods that depend on the changed period.
          if (ratePeriod.id === updatedPeriodId || ratePeriod.sourceId === updatedPeriodId) {
            ratePeriod.quotedRate = rate;
          }
        }
        recalculateRentalRow(state, row, true);
      }
    },

    updateRentalPeriod(state, { payload }: PayloadAction<RentalRowUpdate<'periodId'>>) {
      updateRentalRowProp(state, payload, true);
    },

    /* Sales */
    resetSalesProfile,

    updateSalesDescription(state, { payload }: PayloadAction<SalesRowUpdate<'description'>>) {
      updateSalesRowProp(state, payload, false);
    },

    updateSalesUnit(state, { payload }: PayloadAction<SalesRowUpdate<'unitId'>>) {
      updateSalesRowProp(state, payload, false);
    },

    updateSalesRowQuantity(state, { payload }: PayloadAction<SalesRowUpdate<'quantity'>>) {
      updateSalesRowProp(state, payload, true);
    },

    updateSalesRowPriceEach(state, { payload }: PayloadAction<SalesRowUpdate<'priceEach'>>) {
      updateSalesRowProp(state, payload, true);
    },

    updateSalesRowOriginalCost(state, { payload }: PayloadAction<SalesRowUpdate<'originalCost'>>) {
      updateSalesRowProp(state, payload, true);
    },

    /* Services */
    updateServicesPhaseDesc,
    shiftServiceRow,
    moveServiceRows,
    addOneServicePackageForEachPhase,

    updateServiceDescription(state, { payload }: PayloadAction<ServiceRowUpdate<'description'>>) {
      updateServiceRowProp(state, payload, false);
    },

    updateServiceComments(state, { payload }: PayloadAction<ServiceRowUpdate<'comments'>>) {
      updateServiceRowProp(state, payload, false);
    },

    updateServiceQuantity(state, { payload }: PayloadAction<ServiceRowUpdate<'quantity'>>) {
      updateServiceRowProp(state, payload, true);
    },

    updateServiceRegularHours(state, { payload }: PayloadAction<ServiceRowUpdate<'regularHours'>>) {
      updateServiceRowProp(state, payload, true);
    },

    updateServiceOvertimeHours(state, { payload }: PayloadAction<ServiceRowUpdate<'overtimeHours'>>) {
      updateServiceRowProp(state, payload, true);
    },

    updateServiceDoubleTimeHours(state, { payload }: PayloadAction<ServiceRowUpdate<'doubleTimeHours'>>) {
      updateServiceRowProp(state, payload, true);
    },

    updateServiceRate(state, { payload }: PayloadAction<ServiceRowUpdate<'regularRate'>>) {
      updateServiceRowProp(state, payload, true);
    },

    /* Notes */
    setOptionalProductsNotes,

    quoteStateChanged(state, { payload: quoteStateId }: PayloadAction<WorkflowStates>) {
      if (quoteStateId === WorkflowStates.OPEN && state.quoteStateId !== WorkflowStates.OPEN) {
        // Quote was reopened, so we need to rerate it based on latest fees and rates.
        state.reratingState = ReratingStates.NotRerated;
      }
      state.quoteStateId = quoteStateId;
    },

    setReratingState(state, { payload }: PayloadAction<ReratingStates>) {
      state.reratingState = payload;
    },
  },
  extraReducers: (builder) => {
    /* Shared Action When Quote is fetched */
    builder.addCase(fetchQuoteAction.pending, afterFetchQuoteProductsPendingAction);
    builder.addCase(fetchQuoteAction.rejected, afterFetchQuoteProductsRejectAction);
    builder.addCase(fetchQuoteAction.fulfilled, afterFetchQuoteProductsFulfillAction);
    /* Shared Action When Quote is saved */
    builder.addCase(saveQuoteAction.fulfilled, afterSaveQuoteProductsFulfillAction);

    /* Rental */
    builder.addCase(fetchRentalProfileInternalAction.pending, (state, { meta }) => {
      updateProductRowBeforeFetchingProfile(findRentalRow(state, meta.arg), meta.arg.code);
    });
    builder.addCase(fetchRentalProfileInternalAction.rejected, (state, action) => {
      updateProductRowAfterProfileRejection(findRentalRow(state, action.meta.arg));
    });
    builder.addCase(fetchRentalProfileInternalAction.fulfilled, (state, action) => {
      const profile = action.payload;
      state.profiles.rental[profile.body.entity.code] = profile;
      const row = findRentalRow(state, action.meta.arg);
      if (row) {
        updateRentalRowFromProfile(state, row, profile);
      }
    });

    /* Sales */
    builder.addCase(fetchSalesProfileInternalAction.pending, (state, { meta }) => {
      updateProductRowBeforeFetchingProfile(findSalesRow(state, meta.arg), meta.arg.code);
    });
    builder.addCase(fetchSalesProfileInternalAction.rejected, (state, action) => {
      updateProductRowAfterProfileRejection(findSalesRow(state, action.meta.arg));
    });
    builder.addCase(fetchSalesProfileInternalAction.fulfilled, (state, action) => {
      const profile = action.payload;
      state.profiles.sales[profile.body.entity.code] = profile;
      const row = findSalesRow(state, action.meta.arg);
      if (row) {
        updateSalesRowFromProfile(state, row, profile);
      }
    });

    /* Services */
    builder.addCase(fetchServicesPhasesAction.pending, fetchServicesPhasesPending);
    builder.addCase(fetchServicesPhasesAction.rejected, fetchServicesPhasesRejected);
    builder.addCase(fetchServicesPhasesAction.fulfilled, fetchServicesPhasesFulfilled);

    builder.addCase(fetchServiceProfileAction.pending, (state, action) => {
      const { row } = findServiceRow(state, action.meta.arg);
      if (row) {
        row.status.fetching = true;
        row.status.error = false;
      }
    });

    builder.addCase(fetchServiceProfileAction.rejected, (state, action) => {
      const { row } = findServiceRow(state, action.meta.arg);
      if (row) {
        row.status.fetching = false;
        row.status.fetched = false;
        row.status.error = true;
      }
    });

    builder.addCase(fetchServiceProfileAction.fulfilled, (state, action) => {
      const profile = action.payload.profile;
      const { servicePhaseId, code } = profile.body.entity;
      state.profiles.services[getServiceKey(servicePhaseId, code)] = profile;
      const { grid, row } = findServiceRow(state, action.meta.arg);
      if (row && grid) {
        updateServiceRowFromProfile(state, grid, row, action.payload);
      }
    });

    builder.addCase(addRentalRowsAction, (state, { payload }) => addProductRows(state[payload.tab].rental, payload));
    builder.addCase(addSalesRowsAction, (state, { payload }) => addProductRows(state[payload.tab].sales, payload));
    builder.addCase(addServiceRowsAction, addServiceRows);

    builder.addCase(deleteItemInternalAction, deleteItem);
    builder.addCase(resetRentalPercentOfRateAction, resetRentalPercentOfRate);
    builder.addCase(updateRentalPercentOfRateAction, updateRentalPercentOfRate);
    builder.addCase(resetSalesMarginPercentAction, resetSalesMarginPercent);
    builder.addCase(updateSalesMarginPercentAction, updateSalesMarginPercent);
    builder.addCase(setCopyingServicesAction, setCopyingServices);

    builder.addCase(updateServicesDriverAndTruckTotalsAction, (state, { payload }) => {
      updateServicesDriverAndTruckTotals(state, payload.tab);
    });

    builder.addCase(addServicePackageAction, addServicePackage);
    builder.addCase(deleteServicesPhaseInternalAction, deleteServicesPhase);

    builder.addCase(fetchRentalCategoriesAction.fulfilled, (state: ProductsTabState, { payload }) => {
      state.rentalCategories.fetched = true;
      state.rentalCategories.categories = payload;
    });

    builder.addCase(fetchChangeOrderGroupsAction.fulfilled, fetchChangeOrderGroupsFulfilled);
    builder.addCase(saveChangeOrderGroupsAction.fulfilled, saveChangeOrderGroupsFulfilled);

    builder.addCase(bulkRentalPeriodUpdateInternalAction, bulkRentalPeriodUpdate);
    builder.addCase(bulkRentalQuantityUpdateInternalAction, bulkRentalQuantityUpdate);
    builder.addCase(bulkRentalDurationUpdateInternalAction, bulkRentalDurationUpdate);

    builder.addCase(fetchRentalPeriodsAction.fulfilled, (state, { payload }) => {
      state.rentalPeriods = payload;
    });
    builder.addCase(fetchRentalProductUnitsAction.fulfilled, (state, { payload }) => {
      state.rentalProductUnits = payload;
    });
    builder.addCase(fetchSalesProductUnitsAction.fulfilled, (state, { payload }) => {
      state.salesProductUnits = payload;
    });

    builder.addCase(fetchRentalProfilesAction.pending, (state, action) => {
      state[action.meta.arg.tab].rental.status.profilesStatus = FetchStatuses.Fetching;
    });
    builder.addCase(fetchRentalProfilesAction.fulfilled, (state, action) => {
      state[action.meta.arg.tab].rental.status.profilesStatus = FetchStatuses.Fetched;
    });
    builder.addCase(fetchSalesProfilesAction.pending, (state, action) => {
      state[action.meta.arg.tab].sales.status.profilesStatus = FetchStatuses.Fetching;
    });
    builder.addCase(fetchSalesProfilesAction.fulfilled, (state, action) => {
      state[action.meta.arg.tab].sales.status.profilesStatus = FetchStatuses.Fetched;
    });
    builder.addCase(fetchServiceProfilesAction.pending, (state, action) => {
      state[action.meta.arg.tab].services.status.profilesStatus = FetchStatuses.Fetching;
    });
    builder.addCase(fetchServiceProfilesAction.fulfilled, (state, action) => {
      state[action.meta.arg.tab].services.status.profilesStatus = FetchStatuses.Fetched;
    });

    // Rerating action never fails, so no need to handle "rejected" action.
    builder.addCase(rerateDraftQuoteAction.pending, (state) => {
      state.reratingState = ReratingStates.Rerating;
    });
    builder.addCase(rerateDraftQuoteAction.fulfilled, (state) => {
      state.reratingState = ReratingStates.Rerated;
    });

    builder.addCase(updateFscMultiplierAction, (state, { payload }) => {
      state.feesStatus.touched = true;
      state.fscMultiplier = payload;
      state.fscPercentage = round2Decimals(payload * 100);
    });
  },
});
