import {
  IApplication,
  IConfigurationApplication,
  IQuoteEngineering,
  IQuoteNotes,
  IQuoteProject,
  IUserSummary,
} from '../../../interfaces';
import { FetchStatusState, UiStatusState } from '../../types';

export interface DetailsTabState {
  status: UiStatusState & FetchStatusState;
  config: {
    status: FetchStatusState;
    data: IConfigurationApplication[];
  };
  details: {
    project: IQuoteProject;
    application: IApplication;
    notes: IQuoteNotes;
    engineering: IQuoteEngineering;
    schedule: {
      deliveryTimestamp?: number;
      completionTimestamp?: number;
    };
    estimatorId: string | null;
    supportSpecialistId: string | null;
  };
}

export enum RelatedUserWarnings {
  NotInBranch = 'NotInBranch',
  MissingRole = 'MissingRole',
}

export interface IRelatedUser extends IUserSummary {
  relatedUserWarning?: RelatedUserWarnings;
}
