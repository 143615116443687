import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { QuoteService, StatementConfigurationService } from '../../../api';
import { IStatementTemplate } from '../../../interfaces';
import { i18nKeys } from '../../../internationalization/i18nKeys';
import { enqueueNotificationAction, QuoteSelectors } from '../../session';
import { fetchQuoteAction } from '../../session/quote.action-creators';
import { RootState } from '../../types';

export const fetchStatementOfWorkAction = createAsyncThunk(
  'quote/statementTab/fetchStatementOfWorkAction',
  async (quoteId: string, thunkAPI) => {
    try {
      return await QuoteService.getInstance().readStatementOfWork(quoteId);
    } catch (e) {
      thunkAPI.dispatch(
        enqueueNotificationAction({
          message: i18next.t(i18nKeys.statementTab.errorLoadingStatement),
          options: { variant: 'error' },
        })
      );
      throw e;
    }
  }
);

export const fetchStatementOfWorkTemplatesAction = createAsyncThunk<IStatementTemplate[], string>(
  'quote/statementTab/fetchStatementOfWorkTemplatesAction',
  async (quoteId: string, thunkAPI) => {
    const showErrorMessage = () => {
      thunkAPI.dispatch(
        enqueueNotificationAction({
          message: i18next.t(i18nKeys.statementTab.errorLoadingTemplates),
          options: { variant: 'error' },
        })
      );
    };
    try {
      // Fetch quote HEADER
      await thunkAPI.dispatch(fetchQuoteAction({ quoteId, groups: [] }));
      const branchId = QuoteSelectors.branchId(thunkAPI.getState() as RootState);
      if (branchId) {
        return await StatementConfigurationService.getInstance().listTemplates(branchId);
      } else {
        showErrorMessage();
        return thunkAPI.rejectWithValue('No branch ID');
      }
    } catch (e) {
      showErrorMessage();
      throw e;
    }
  }
);
