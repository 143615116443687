import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccountsService } from 'api';
import { IContact } from 'interfaces';
import { QuoteSelectors } from '../../session';
import { RootState } from '../../types';
import { CustomerTabState } from './customer-tab.types';

export const fetchAccountContactsAction = createAsyncThunk(
  'quote/customerTab/fetchAccountContactsAction',
  async (accountId: string, thunkAPI) => {
    const quoteSaved = QuoteSelectors.saved(thunkAPI.getState() as RootState);
    return {
      contacts: await AccountsService.getInstance().getAccountContacts(accountId),
      quoteSaved,
    };
  }
);

export const setAccountContactAction = (state: CustomerTabState, action: any) => {
  const payload: { touched?: boolean; valid?: boolean; contact: IContact } = action.payload;
  if (payload.touched !== undefined) {
    state.contact.status.touched = payload.touched;
  }
  if (payload.valid !== undefined) {
    state.contact.status.valid = payload.valid;
  }
  state.contact.active = payload.contact;
};
