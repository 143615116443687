import { IQuoteReview, IQuoteReviewPrintOptions } from 'interfaces';
import { ReviewTabState } from './review-tab.types';

export const updateReviewValuesFromQuote = (state: ReviewTabState, action: any) => {
  if (action.payload.quote.header.review) {
    const review = action.payload.quote.header.review as IQuoteReview;
    state.quotePrintOptions.printOption = review.printOption;
    state.quotePrintOptions.summaryDescription = review.summaryDescription;
  }
};

export const setQuotePrintOptions = (state: ReviewTabState, { payload }: { payload: IQuoteReviewPrintOptions }) => {
  // Merge instead of replacing.
  Object.assign(state.quotePrintOptions, payload);
};
