import i18next from 'i18next';
import { OptionsObject } from 'notistack';
import React from 'react';
import { ConfirmSnackBar } from '../../../components';
import { i18nKeys } from '../../../internationalization/i18nKeys';
import { enqueueNotificationAction } from '../../session';
import { AppDispatch } from '../../store';

const showCustomSnackBar = (
  dispatch: AppDispatch,
  options: OptionsObject,
  title: string,
  message: string,
  closeButtonText?: string
) => {
  dispatch(
    enqueueNotificationAction({
      message,
      options: {
        ...options,
        content: (key) => (
          <ConfirmSnackBar
            snackBarKey={key}
            variant={options.variant}
            title={title}
            message={message}
            closeButtonText={closeButtonText}
          />
        ),
      },
    })
  );
};

export const showProductNotFoundMessage = (dispatch: AppDispatch) => {
  showCustomSnackBar(
    dispatch,
    {
      variant: 'error',
    },
    i18next.t(i18nKeys.productsTab.productNotFoundTitle),
    i18next.t(i18nKeys.productsTab.productNotFoundMessage)
  );
};
