import { RootState } from 'rdx/types';

export const CustomerTabSelectors = {
  fetched: (rootState: RootState) => rootState.quoteScreen.customerTab.status.fetched,
  fetching: (rootState: RootState) => rootState.quoteScreen.customerTab.status.fetching,

  account: (rootState: RootState) => rootState.quoteScreen.customerTab.account.active,
  accountFetching: (rootState: RootState) => rootState.quoteScreen.customerTab.account.status.fetching,
  contactsFetching: (rootState: RootState) => rootState.quoteScreen.customerTab.contact.status.fetching,
  accountId: (rootState: RootState) => rootState.quoteScreen.customerTab.account.active?.id,
  activeBranchId: (rootState: RootState) => rootState.session.user.branches.active,
  billingPrevailingWage: (rootState: RootState) => CustomerTabSelectors.billRequirements(rootState).prevailingWage,
  billingTaxExempt: (rootState: RootState) => CustomerTabSelectors.billRequirements(rootState).taxExempt,
  billingWorkOfImprovement: (rootState: RootState) =>
    CustomerTabSelectors.billRequirements(rootState).workOfImprovement,
  billRequirements: (rootState: RootState) => rootState.quoteScreen.customerTab.billRequirements,
  billRequirementsTouched: (rootState: RootState) => rootState.quoteScreen.customerTab.billRequirements.status.touched,
  contact: (rootState: RootState) => rootState.quoteScreen.customerTab.contact.active,
  contactFetched: (rootState: RootState) => rootState.quoteScreen.customerTab.contact.status.fetched,
  contactFetching: (rootState: RootState) => rootState.quoteScreen.customerTab.contact.status.fetching,
  contactList: (rootState: RootState) => rootState.quoteScreen.customerTab.contact.data,
  jobsite: (rootState: RootState) => rootState.quoteScreen.customerTab.jobsite.jobsite,
  jobsiteAddress: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).address,
  jobsiteCity: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).city,
  jobsiteJobName: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).jobName,
  jobsitePoNumber: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).poNumber,
  jobsiteSiteContact: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).siteContact,
  jobsiteSiteExtension: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).siteExtension,
  jobsiteSiteMobile: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).siteMobilePhoneNumber,
  jobsiteSitePhoneNumber: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).sitePhoneNumber,
  jobsiteState: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).state,
  jobsiteTouched: (rootState: RootState) => rootState.quoteScreen.customerTab.jobsite.status.touched,
  jobsiteZipCode: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).zipCode,
  jobsiteMarketSector: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).marketSector,
  jobsiteMarketSectorCode: (rootState: RootState) => CustomerTabSelectors.jobsite(rootState).marketSectorCode,
  marketSegmentId: (rootState: RootState) => rootState.quoteScreen.customerTab.marketSegment.active,
};
