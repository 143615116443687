import { styled } from '@mui/material/styles';

export const StyledErrorRowList = styled('ul')`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: #f0f0f0;
  box-sizing: border-box;
`;

export const StyledErrorRow = styled('li')<{ color?: string; cursor?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 8px;
  margin: 0 14px;
  width: auto;
  color: ${({ color }) => color || '#626264'};
  background-color: inherit;
  box-sizing: border-box;
  cursor: ${({ cursor }) => cursor || 'pointer'};
  transition: all 348ms ease;

  & > *:last-child {
    margin: 0 10px;
  }

  &:hover {
    color: #222222;
  }

  & + * {
    border-top: 1px solid #dcdcdc;
  }
`;
