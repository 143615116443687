import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { saveQuoteAction } from '../../session';
import { fetchQuoteAction } from '../../session/quote.action-creators';
import {
  afterFetchQuoteDetailsFulfillAction,
  afterFetchQuoteDetailsPendingAction,
  afterFetchQuoteDetailsRejectAction,
  afterSaveQuoteDetailsFulfillAction,
  fetchDetailsConfigAction,
  setDetailsApplicationCodeAction,
  updateDetailsTab,
  updateDetailsTabStatusAction,
} from './details-tab.actions';
import { DetailsTabState } from './details-tab.types';

const initialState: DetailsTabState = {
  status: {
    touched: false,
    valid: true,
    fetching: false,
    fetched: false,
  },
  config: {
    status: {
      fetching: false,
      fetched: false,
    },
    data: [],
  },
  details: {
    project: {
      name: '',
      what: '',
      duration: '',
      description: '',
    },
    application: {
      id: '',
      templateId: '',
      fields: [],
    },
    notes: {
      content: '',
    },
    engineering: {
      jobNumber: '',
      comments: '',
    },
    schedule: {},
    estimatorId: null,
    supportSpecialistId: null,
  },
};

export const detailsTabSlice = createSlice({
  name: 'quote/detailsTab',
  initialState,
  reducers: {
    /* Reset */
    reset: () => initialState,

    setDetailsApplicationCodeAction,
    updateDetailsTab,
    updateDetailsTabStatusAction,

    estimatorUpdate(state, action: PayloadAction<string | null>) {
      state.details.estimatorId = action.payload;
      state.status.touched = true;
    },
    supportSpecialistUpdate(state, action: PayloadAction<string | null>) {
      state.details.supportSpecialistId = action.payload;
      state.status.touched = true;
    },
  },
  extraReducers: (builder) => {
    /* Shared Action When Quote is fetched */
    builder.addCase(fetchQuoteAction.pending, afterFetchQuoteDetailsPendingAction);
    builder.addCase(fetchQuoteAction.rejected, afterFetchQuoteDetailsRejectAction);
    builder.addCase(fetchQuoteAction.fulfilled, afterFetchQuoteDetailsFulfillAction);
    /* Shared Action When Quote is saved */
    builder.addCase(saveQuoteAction.fulfilled, afterSaveQuoteDetailsFulfillAction);

    /* Details Configuration */
    builder.addCase(fetchDetailsConfigAction.pending, (state) => {
      state.config.status.fetching = true;
    });
    builder.addCase(fetchDetailsConfigAction.rejected, (state) => {
      state.config.status.fetching = false;
      state.config.status.fetched = false;
    });
    builder.addCase(fetchDetailsConfigAction.fulfilled, (state, { payload }) => {
      state.config.status.fetching = false;
      state.config.status.fetched = true;
      state.config.data = payload;
    });
  },
});
