import { Box, Link } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../internationalization/i18nKeys';
import { IAboutModalProps } from './AboutModal.props';

export const AboutModal: React.FC<IAboutModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <MuiDialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <div>{t('aboutModal.salesPro')}</div>
            {onClose ? (
              <IconButton aria-label="close" onClick={onClose} size="small">
                <CloseIcon style={{ color: '#ffffff' }} />
              </IconButton>
            ) : null}
          </Box>
        </MuiDialogTitle>
        <DialogContent dividers>
          <div>
            <p>Copyright {new Date().getFullYear()} by Rain for Rent</p>
            <p>
              <Link href="/changelog.html" target="_blank" underline="none">
                {t(i18nKeys.aboutModal.version, { version: process.env.REACT_APP_VERSION })}
              </Link>
            </p>
            <p>
              <a href="mailto:helpdesk@rainforrent.com?subject=SalesPro%20Support%20Request">
                E-mail RFR IT Help Desk for support
              </a>{' '}
              <a href="tel:+1-661-634-6800">+1 (661) 634-6800</a>
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
