import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchQuoteForCloneAction, saveQuoteAction } from '../../session';
import { fetchQuoteAction } from '../../session/quote.action-creators';
import {
  fetchAccountAction,
  fetchAccountFulfillAction,
  fetchAccountPendingAction,
  fetchAccountRejectAction,
  resetAccountAction,
} from './account.actions';
import { resetBillRequirements, setBillRequirements } from './bill-requirements.actions';
import { fetchAccountContactsAction, setAccountContactAction } from './contact.actions';
import {
  fetchQuoteCustomerFulfilled,
  fetchQuoteCustomerPending,
  fetchQuoteCustomerRejected,
  saveQuoteCustomerFulfilled,
} from './customer-tab.actions';
import { CustomerTabState } from './customer-tab.types';
import { setJobsiteAction } from './jobsite.action-creators';
import { updateJobsite } from './jobsite.actions';
import { setMarketSegment } from './market-segment.reducers';

const initialState: CustomerTabState = {
  status: {
    fetching: false,
    fetched: false,
  },
  account: {
    status: {
      touched: false,
      valid: true,
      fetching: false,
      fetched: false,
    },
    active: null,
  },
  contact: {
    status: {
      touched: false,
      valid: true,
      fetching: false,
      fetched: false,
    },
    active: null,
    data: [],
    clonedEmptyContact: false,
  },
  jobsite: {
    status: {
      touched: false,
    },
    jobsite: {
      id: '',
      jobName: '',
      siteContact: '',
      poNumber: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      sitePhoneNumber: '',
      siteExtension: '',
      siteMobilePhoneNumber: '',
      marketSectorCode: '',
      marketSector: '',
    },
  },
  marketSegment: {
    status: {
      touched: false,
    },
    active: '',
  },
  billRequirements: {
    status: {
      touched: false,
      valid: true,
    },
  },
};

export const customerTabSlice = createSlice({
  name: 'quote/customerTab',
  initialState,
  reducers: {
    /* Reset */
    reset: () => initialState,

    /* Account */
    resetAccountAction,

    /* Contact */
    setAccountContactAction,

    /* Market Segment */
    setMarketSegment,

    resetMarketSegment: (state) => {
      state.marketSegment.status.touched = false;
      state.marketSegment.active = '';
    },

    /* Bill Requirements */
    setBillRequirements,
    resetBillRequirements,

    setTaxExempt(state, { payload }: PayloadAction<boolean>) {
      state.billRequirements.status.touched = true;
      state.billRequirements.status.valid = true;
      state.billRequirements.taxExempt = payload;
    },

    setWorkOfImprovement(state, { payload }: PayloadAction<boolean>) {
      state.billRequirements.status.touched = true;
      state.billRequirements.status.valid = true;
      state.billRequirements.workOfImprovement = payload;
    },

    setPrevailingWage(state, { payload }: PayloadAction<boolean>) {
      state.billRequirements.status.touched = true;
      state.billRequirements.status.valid = true;
      state.billRequirements.prevailingWage = payload;
    },

    setMarketSector(state, { payload }: PayloadAction<{ code: string; description: string }>) {
      state.jobsite.jobsite.marketSector = payload.description;
      state.jobsite.jobsite.marketSectorCode = payload.code;
      state.jobsite.status.touched = true;
    },
  },
  extraReducers: (builder) => {
    /* Shared Action When Quote is fetched */
    builder.addCase(fetchQuoteAction.pending, fetchQuoteCustomerPending);
    builder.addCase(fetchQuoteAction.rejected, fetchQuoteCustomerRejected);
    builder.addCase(fetchQuoteAction.fulfilled, fetchQuoteCustomerFulfilled);

    builder.addCase(fetchQuoteForCloneAction.pending, fetchQuoteCustomerPending);
    builder.addCase(fetchQuoteForCloneAction.rejected, fetchQuoteCustomerRejected);
    builder.addCase(fetchQuoteForCloneAction.fulfilled, fetchQuoteCustomerFulfilled);

    /* Shared Action When Quote is saved */
    builder.addCase(saveQuoteAction.fulfilled, saveQuoteCustomerFulfilled);

    /* Account */
    builder.addCase(fetchAccountAction.pending, fetchAccountPendingAction);
    builder.addCase(fetchAccountAction.rejected, fetchAccountRejectAction);
    builder.addCase(fetchAccountAction.fulfilled, fetchAccountFulfillAction);

    /* Contact */
    builder.addCase(fetchAccountContactsAction.pending, (state) => {
      state.contact.status.fetching = true;
    });
    builder.addCase(fetchAccountContactsAction.rejected, (state) => {
      state.contact.status.fetching = false;
      state.contact.status.fetched = false;
    });
    builder.addCase(fetchAccountContactsAction.fulfilled, (state, action) => {
      const { contacts, quoteSaved } = action.payload;
      state.contact.status.fetching = false;
      state.contact.status.fetched = true;
      state.contact.data = contacts;

      // Update active contact.
      if (!quoteSaved) {
        state.contact.status.touched = false;
        if (contacts.length) {
          // Do not replace cloned empty contact until we fetch contacts for a different account.
          if (!state.contact.clonedEmptyContact) {
            const currentContactId = state.contact.active?.id;
            state.contact.active = contacts[0];
            if (currentContactId) {
              const currentContact = contacts.find(({ id }) => id === currentContactId);
              if (currentContact) {
                state.contact.active = currentContact;
              }
            }
          }
        } else {
          state.contact.active = null;
        }
        state.contact.clonedEmptyContact = false;
      }
    });

    builder.addCase(setJobsiteAction, (state, { payload }) => {
      updateJobsite(state, payload);
    });
  },
});
