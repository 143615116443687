import { AppConfigurationService } from './app-configuration.service';
import { ConfigurableRequest } from './configurable-request';

export class PdfService extends ConfigurableRequest {
  private static instance: PdfService;

  private constructor() {
    super('');
    this.baseUrl = AppConfigurationService.getInstance().appConfiguration.pdfURL;
  }

  public async generateQuotePdf(quoteId: string, changeOrderId: string | null): Promise<string | null> {
    let path;
    let query;
    if (changeOrderId) {
      path = `pdf/quote/${quoteId}/change-order/${changeOrderId}`;
      query = {};
    } else {
      path = '';
      query = { id: quoteId };
    }
    try {
      return (await this.get(path, query)) as unknown as string;
    } catch (e) {
      return null;
    }
  }

  public static getInstance(): PdfService {
    if (!PdfService.instance) {
      PdfService.instance = new PdfService();
    }
    return PdfService.instance;
  }
}
