import {
  ButtonBase,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Popover,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { QuoteFacadeContext } from '../../../facade/AbstractQuoteFacade';
import { ReviewTabSelectors } from '../../../rdx';
import { textFormatter } from '../../../utils';
import { ComponentIds } from '../../../utils/component-ids';
import { StyledBannerContainer } from './ErrorsDrawerBanner.style';

export type anchorModes = 'bottom' | 'left' | 'top' | 'right' | undefined;
export const ANCHOR_MODES: anchorModes[] = ['left', 'bottom', 'right'];

export interface IErrorDrawerBannerProps {
  onClickCloseButton: () => void;
  pinned: boolean;
  anchorMode: anchorModes;
  onAnchorModeChange: (mode: string) => void;
  onClickOnPin: () => void;
}

export const ErrorDrawerBanner: React.FC<IErrorDrawerBannerProps> = (props) => {
  const { pinned, anchorMode, onClickCloseButton, onAnchorModeChange, onClickOnPin } = props;
  const { saveAndValidate, selectFetchingOrSaving } = useContext(QuoteFacadeContext);

  const fetchingOrSavingQuote = useSelector(selectFetchingOrSaving);
  const reviewFetching = useSelector(ReviewTabSelectors.fetching);
  const loading = reviewFetching || fetchingOrSavingQuote;
  const loadingClass = loading ? 'spinning' : '';

  return (
    <>
      <StyledBannerContainer>
        <div className="wrapper icon-wpr">
          <ErrorIcon />
        </div>

        <div className="wrapper texts-wpr">
          <span className="title">Required field validation error.</span>
          <span className="sub-title">Please click the link(s) below in order to correct the field(s).</span>
        </div>

        <div className={`wrapper sync-icon-wrapper ${loadingClass}`}>
          <ButtonBase onClick={saveAndValidate}>
            <SyncIcon />
          </ButtonBase>
        </div>

        <div className="wrapper settings-icon-wpr">
          <SettingsMenu
            pinned={pinned}
            anchorMode={anchorMode}
            onAnchorModeChange={onAnchorModeChange}
            onClickOnPin={onClickOnPin}
          />
        </div>

        <div className="wrapper close-icon-wpr">
          <ButtonBase onClick={onClickCloseButton} data-test={ComponentIds.ErrorsDrawerCloseButton}>
            <CloseIcon />
          </ButtonBase>
        </div>
      </StyledBannerContainer>
    </>
  );
};

export interface ISettingsMenuProps {
  pinned: boolean;
  anchorMode: anchorModes;
  onAnchorModeChange: (mode: string) => void;
  onClickOnPin: () => void;
}

export const SettingsMenu: React.FC<ISettingsMenuProps> = (props) => {
  const { pinned, anchorMode, onAnchorModeChange, onClickOnPin } = props;
  const popupState = usePopupState({ variant: 'popover', popupId: 'settingsMenu' });
  const handleAnchorModeChange = (mode: string) => {
    popupState.close();
    onAnchorModeChange(mode);
  };
  return (
    <>
      <ButtonBase {...bindTrigger(popupState)}>
        <SettingsIcon />
      </ButtonBase>
      <Popover
        PaperProps={{ style: { padding: '1em' } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...bindMenu(popupState)}
      >
        <DrawerOptions
          pinned={pinned}
          anchorMode={anchorMode}
          handleAnchorModeChange={handleAnchorModeChange}
          handleClickOnPin={onClickOnPin}
        />
      </Popover>
    </>
  );
};

export interface IDrawerOptionsProps {
  pinned: boolean;
  anchorMode: anchorModes;
  handleAnchorModeChange: (value: string) => void;
  handleClickOnPin: () => void;
}

export const DrawerOptions: React.FC<IDrawerOptionsProps> = (props) => {
  const { pinned, anchorMode, handleAnchorModeChange, handleClickOnPin } = props;
  return (
    <FormControl className="p-10" component="fieldset" style={{ marginTop: 'auto' }}>
      <FormGroup>
        <FormControlLabel control={<Switch checked={pinned} onChange={handleClickOnPin} name="pin" />} label="Pin" />
      </FormGroup>
      <FormHelperText>Menu location on screen</FormHelperText>

      <FormLabel component="legend">Drawer options</FormLabel>
      <AnchorOptions options={ANCHOR_MODES} value={anchorMode} onChange={handleAnchorModeChange} />
    </FormControl>
  );
};

export interface IAnchorOptionsProps {
  options: anchorModes[];
  value: anchorModes;
  onChange: (value: string) => void;
}

export const AnchorOptions: React.FC<IAnchorOptionsProps> = ({ options, value, onChange }) => {
  return (
    <RadioGroup value={value} onChange={(event: any, value: string) => onChange(value)}>
      {options.map((option) => (
        <FormControlLabel
          key={option}
          value={option}
          control={<Radio />}
          label={textFormatter(option as string, 'capitalize')}
        />
      ))}
    </RadioGroup>
  );
};
