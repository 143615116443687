import LinkIcon from '@mui/icons-material/Link';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ReviewTabSelectors, reviewTabSlice, ValidationGroup } from '../../../rdx';
import { useAppDispatch } from '../../../rdx/redux-hooks';
import { StyledErrorRow, StyledErrorRowList } from './ErrorRowList.style';

export interface IErrorRowListProps {
  group: ValidationGroup;
  textColor?: string;
}

export const ErrorRowList: React.FC<IErrorRowListProps> = ({ group, textColor }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const pinned = useSelector(ReviewTabSelectors.errorsDrawerPinned);
  const isLink = !!group.routePath;
  const cursor = isLink ? 'pointer' : 'default';

  const onClick = () => {
    if (isLink) {
      if (!pinned) {
        dispatch(reviewTabSlice.actions.toggleErrorsDrawer(false));
      }
      history.push(`${match.url}/${group.routePath}`);
    }
  };

  return (
    <StyledErrorRowList>
      {group.items.map((item) => (
        <StyledErrorRow key={item.key} cursor={cursor} color={textColor} onClick={onClick}>
          <span>{item.message}</span>
          {isLink && <LinkIcon />}
        </StyledErrorRow>
      ))}
    </StyledErrorRowList>
  );
};
