import { QuoteInnerTabs, QuoteTabs } from '../../../enums';
import {
  GridRowLocation,
  IDriverAndTruckTotals,
  IGridRow,
  IProductUnit,
  IRentalGridRow,
  IRentalPeriod,
  IRentalProductProfile,
  IRentalRatePeriods,
  ISalesGridRow,
  ISalesProductProfile,
  IServicePhase,
  IServiceProfile,
  IServicesGrid,
  IServicesGridRow,
  ServiceRowLocation,
  WorkflowStates,
} from '../../../interfaces';
import { FetchStatuses } from '../../../mobile/redux/types';
import { PropUtils } from '../../../utils/object-utils';
import { FetchStatusState, UiStatusState } from '../../types';

interface NotesState {
  notes: {
    status: UiStatusState & FetchStatusState;
    content: string;
  };
}

export type ProductGroupStatus = UiStatusState & FetchStatusState & { profilesStatus: FetchStatuses };

export interface ProductGroupState<GridRowType> extends GridSelectionState {
  status: ProductGroupStatus;
  rows: GridRowType[];
}

export interface RentalProductsState extends ProductGroupState<IRentalGridRow> {}
export interface SalesProductsState extends ProductGroupState<ISalesGridRow> {}

export interface ServicesState {
  status: ProductGroupStatus;
  phases: {
    fetching: boolean;
    fetched: boolean;
    data: IServicePhase[];
  };
  grids: IServicesGrid[];
  copyingServices: boolean;
  driverAndTruckTotals: IDriverAndTruckTotals;
}

export interface ProductsState {
  rental: RentalProductsState;
  sales: SalesProductsState;
  services: ServicesState;
}

export interface RentalProductCategory {
  code: string;
  types: string[];
}

export enum ReratingStates {
  NotRerated = 'NotRerated',
  CheckingFees = 'CheckingFees',
  FeesChecked = 'FeesChecked',
  Rerating = 'Rerating',
  Rerated = 'Rerated',
  ReratingCompleted = 'ReratingCompleted',
}

/**
 * Portion of the state specific to the currently loaded quote/co.
 */
export interface QuoteProductsState {
  searchOpened: boolean;
  initialSearchQuery: string;
  searchWindowBounds?: WindowBounds;
  fscPercentage: number;
  fscMultiplier: number;
  defaultFscMultiplier?: number;
  feesStatus: {
    touched: boolean;
  };
  applyEnvironmentalRecovery: boolean;
  waiveEnvironmentalRecovery: boolean;
  applyAirQuality: boolean;
  waiveAirQuality: boolean;
  rateLockDown: {
    rowId: string;
    field: keyof IRentalRatePeriods | undefined;
  };
  products: ProductsState;
  optional: ProductsState & NotesState;
  rentalCategories: {
    fetched: boolean;
    categories: RentalProductCategory[];
  };
  quoteStateId?: WorkflowStates;
  profiles: {
    rental: {
      [key: string]: IRentalProductProfile;
    };
    sales: {
      [key: string]: ISalesProductProfile;
    };
    services: {
      [key: string]: IServiceProfile;
    };
  };
  reratingState: ReratingStates;
}

/**
 * Portion of the state that is independent of the loaded quote/co and
 * should not be reset when quote/co is unloaded.
 */
interface GlobalProductsState {
  rentalPeriods: IRentalPeriod[];
  rentalProductUnits: IProductUnit[];
  salesProductUnits: IProductUnit[];
}

export interface ProductsTabState extends QuoteProductsState, GlobalProductsState {}

interface Point {
  x: number;
  y: number;
}

interface Size {
  width: number;
  height: number;
}

export interface WindowBounds {
  position: Point;
  size: Size;
}

export interface AddProductParam extends Pick<IGridRow, 'quantity' | 'description' | 'unitId'> {
  code: string;
}

/**
 * Must contain only properties that also exist in `IRentalGridRow`
 */
export interface AddRentalProductParam extends AddProductParam, Pick<IRentalGridRow, 'duration' | 'periodId'> {}

export const addRentalProductParamProps = new PropUtils<AddRentalProductParam>(
  {
    code: undefined,
  },
  {},
  {
    unitId: undefined,
    duration: undefined,
    quantity: undefined,
    description: undefined,
    periodId: undefined,
  }
);

export interface AddRentalProductParamWithExtras extends AddRentalProductParam {
  dayRate?: number;
  weekRate?: number;
  cycleRate?: number;
}

export type CsvImportRentalRow = AddRentalProductParamWithExtras;

export const addRentalProductParamWithExtrasProps = new PropUtils<AddRentalProductParamWithExtras>(
  addRentalProductParamProps.requiredNotUndefinablePropsSample,
  {},
  {
    ...addRentalProductParamProps.optionalPropsSample,
    dayRate: undefined,
    weekRate: undefined,
    cycleRate: undefined,
  }
);

/**
 * Must contain only properties that also exist in `ISalesGridRow`
 */
export interface AddSalesProductParam
  extends AddProductParam,
    Pick<ISalesGridRow, 'priceEach' | 'originalCost' | 'rentalProduct'> {}

export interface GridRef {
  tab: QuoteTabs;
  innerTab: QuoteInnerTabs;
  gridId?: string;
}

export interface GridSelectionState {
  selected: string[];
  selectedById: {
    [RowId: string]: true;
  };
  insertionRow: {
    index: number;
    number: number;
    id: string;
  };
}

export type RowLocationType<RowType extends IGridRow> = RowType extends IServicesGridRow
  ? ServiceRowLocation
  : GridRowLocation;

export interface RowUpdate<RowType extends IGridRow, PropKey extends keyof RowType> {
  location: RowLocationType<RowType>;
  prop: PropKey;
  value: RowType[PropKey];
}

export interface RentalRowUpdate<PropKey extends keyof IRentalGridRow> extends RowUpdate<IRentalGridRow, PropKey> {}

export interface SalesRowUpdate<PropKey extends keyof ISalesGridRow> extends RowUpdate<ISalesGridRow, PropKey> {}

export interface ServiceRowUpdate<PropKey extends keyof IServicesGridRow>
  extends RowUpdate<IServicesGridRow, PropKey> {}

export interface FetchServiceProfileResult extends ServiceRowLocation {
  setRates: boolean;
  code: string;
  profile: IServiceProfile;
}

export interface FetchProductProfileParams extends GridRowLocation {
  code: string;
}

export interface MoveProductRowsResult {
  /**
   * Maps id of each moved row to its new id.
   */
  [OriginalId: string]: string | undefined;
}
