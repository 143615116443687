import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ToolBarSummary = styled('div')`
  margin-right: 20px;
`;

export const DropDownTextField = styled(OutlinedInput)`
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 45px;
  padding-left: 0;

  &.bordered {
    border: 1px solid #ccc;
  }

  .MuiInputBase-inputAdornedStart {
    padding-left: 8px;
    padding-right: 8px;
    border-left: 1px solid #ccc;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  .MuiSelect-select {
    font-size: 13px;
  }
`;
