import { ValueFormatterParams } from 'ag-grid-community';
import { isFiniteNumber, TimeUtils } from 'utils';

export const numberFormatter = (number: string, printZero = false) => {
  let output = '';
  if (number) {
    output = new Intl.NumberFormat('en-US', {}).format(parseFloat(number));
  }
  return output === '0' && !printZero ? '' : output;
};

const usCurrencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export const usCurrencyFormatNoFraction = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const currencyFormatter = (number: number | string) => {
  let output = '';
  if (number) {
    if (typeof number === 'string') {
      const parsed = parseFloat(number);
      number = !isNaN(parsed) ? parsed : 0;
    }
    output = usCurrencyFormat.format(number);
  } else if (number === 0) {
    return `$${number}.00`;
  }
  return output;
};

export const formatUsCurrency = (value: number | undefined | null) =>
  isFiniteNumber(value) ? usCurrencyFormat.format(value) : '';

export const percentageFormatter = (number: number | string) => {
  if (number !== null && number !== undefined && number !== '') {
    return `${number}%`;
  }
  return '';
};

export const formatPercentage = (value: number | undefined | null) => (isFiniteNumber(value) ? `${value}%` : '');

export type TextFormatterTransform = 'uppercase' | 'lowercase' | 'capitalize' | 'capitalizeAll' | 'none';

export const textFormatter = (text: string, transform: TextFormatterTransform) => {
  if (!text) {
    return '';
  }

  switch (transform) {
    case 'uppercase':
      return text.toUpperCase();
    case 'lowercase':
      return text.toLowerCase();
    case 'capitalize':
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    case 'capitalizeAll':
      const words = text.toLowerCase().split(' ');
      return words
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
        .join(' ')
        .toLowerCase();

    default:
      return text;
  }
};

export const rgbaFormatter = (rgba: number[] | undefined): string => {
  if (rgba && Array.isArray(rgba) && rgba.length === 4) {
    return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3]})`;
  }
  return '';
};

export const timestampFormatter = ({ value: timestamp }: ValueFormatterParams): string => {
  let formattedTs: string = '';
  if (timestamp) {
    formattedTs = TimeUtils.formatMmDdYy(timestamp);
  }
  return formattedTs;
};
