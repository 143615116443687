import { createSelector } from '@reduxjs/toolkit';
import { flow, isNil, keyBy, mapValues, sumBy } from 'lodash';
import { TabsSelector } from 'rdx';
import { CrudActions, QuoteInnerTabs, QuoteTabs, ServicesPhases } from '../../../enums';
import { IServicePhaseAggTotal, IServicesGrid, WorkflowStates } from '../../../interfaces';
import { FetchStatuses } from '../../../mobile/redux/types';
import { round2Decimals } from '../../../utils';
import { UserSelectors } from '../../session/user.selectors';
import { RootState } from '../../types';
import { ProductsState, ReratingStates } from './products-tab.types';

const slice = (state: RootState) => state.quoteScreen.productsTab;
const isDraftQuote = (state: RootState) => slice(state).quoteStateId === WorkflowStates.OPEN;
const isNonDraftQuote = (state: RootState) =>
  !isNil(slice(state).quoteStateId) && slice(state).quoteStateId !== WorkflowStates.OPEN;

const rentalProductUnits = (state: RootState) => state.quoteScreen.productsTab.rentalProductUnits;
const rentalProductUnitsById = createSelector(rentalProductUnits, (units) => keyBy(units, ({ id }) => id));
const rentalProductUnitNamesById = createSelector(rentalProductUnitsById, (unitsById) =>
  mapValues(unitsById, ({ name }) => name)
);

const rentalPeriods = (state: RootState) => state.quoteScreen.productsTab.rentalPeriods;
const rentalPeriodsById = createSelector(rentalPeriods, (periods) => keyBy(periods, ({ id }) => id));
const rentalPeriodNamesById = createSelector(rentalPeriodsById, (periodsById) =>
  mapValues(periodsById, ({ name }) => name)
);

const salesProductUnits = (state: RootState) => state.quoteScreen.productsTab.salesProductUnits;
const salesProductUnitsById = createSelector(salesProductUnits, (units) => keyBy(units, ({ id }) => id));
const salesProductUnitNamesById = createSelector(salesProductUnitsById, (unitsById) =>
  mapValues(unitsById, ({ name }) => name)
);

const fscMultiplier = (state: RootState) => state.quoteScreen.productsTab.fscMultiplier;

export interface IServicesGridInfo {
  grid: IServicesGrid;
  gridNumber: number;
  description: string;
  isFirst: boolean;
  isLast: boolean;
}

const makeServiceGridInfos = (servicesGrids: IServicesGrid[]) =>
  servicesGrids.map<IServicesGridInfo>((grid, idx) => ({
    grid,
    gridNumber: idx + 1,
    description: grid.phaseDesc || `${idx + 1} ${grid.phaseId}`,
    isFirst: idx === 0,
    isLast: idx === servicesGrids.length - 1,
  }));

const makeProductsStateSelectors = <State extends ProductsState>(selectProductsState: (state: RootState) => State) => {
  const pipe = <T>(selector: (state: State) => T) => flow(selectProductsState, selector);

  const servicesGrids = pipe(({ services }) => services.grids);

  const filteredServiceGrids = createSelector(servicesGrids, (grids) =>
    grids.filter(({ status }) => status.action !== CrudActions.DELETE)
  );

  const selectRentalInsertionRow = pipe(({ rental }) => rental.insertionRow);
  const selectSalesInsertionRow = pipe(({ sales }) => sales.insertionRow);

  const rentalRows = pipe(({ rental }) => rental.rows);
  const notDeletedRentalRows = createSelector(rentalRows, (rows) =>
    rows.filter((row) => row.product && row.status.action !== CrudActions.DELETE)
  );

  const rentalTotals = createSelector(notDeletedRentalRows, (rows) => {
    let rentalTotal = 0;
    let rentalDefaultRateTotal = 0;
    let quotedTotalForPercentageOfRate = 0;
    let defaultRateTotalForPercentageOfRate = 0;
    let rppEligibleTotal = 0;
    let rppFeeTotal = 0;
    let erFeeTotal = 0;
    let aqFeeTotal = 0;
    for (let row of rows) {
      if (row.extension) {
        rentalTotal = round2Decimals(rentalTotal + row.extension);
      }
      if (row.defaultRateExtension) {
        rentalDefaultRateTotal = round2Decimals(rentalDefaultRateTotal + row.defaultRateExtension);
      }

      // Only system-rated products are included in rentalPercentageOfRateTotal calculation.
      if (!row.ratedManually && !isNil(row.extension) && !isNil(row.defaultRateExtension)) {
        quotedTotalForPercentageOfRate = round2Decimals(quotedTotalForPercentageOfRate + row.extension);
        defaultRateTotalForPercentageOfRate = round2Decimals(
          defaultRateTotalForPercentageOfRate + row.defaultRateExtension
        );
      }

      if (row.rppFee && row.extension) {
        rppEligibleTotal = round2Decimals(rppEligibleTotal + row.extension);
        rppFeeTotal = round2Decimals(rppFeeTotal + row.rppFee);
      }

      if (row.erFee) {
        erFeeTotal = round2Decimals(erFeeTotal + row.erFee);
      }
      if (row.aqFee) {
        aqFeeTotal = round2Decimals(aqFeeTotal + row.aqFee);
      }
    }

    let rentalPercentageOfRateTotal;
    if (quotedTotalForPercentageOfRate === 0) {
      rentalPercentageOfRateTotal = 0;
    } else if (defaultRateTotalForPercentageOfRate === 0) {
      rentalPercentageOfRateTotal = 100;
    } else {
      rentalPercentageOfRateTotal =
        round2Decimals((quotedTotalForPercentageOfRate * 100) / defaultRateTotalForPercentageOfRate) || 100;
    }

    return {
      rentalProductCount: rows.length,
      rentalTotal,
      rentalDefaultRateTotal,
      rentalDollarDifference: round2Decimals(rentalTotal - rentalDefaultRateTotal),
      rentalPercentageOfRateTotal,
      rppEligibleTotal,
      rppFeeTotal,
      erFeeTotal,
      aqFeeTotal,
    };
  });

  const originalSalesRows = pipe(({ sales }) => sales.rows);

  const notDeletedSalesRows = createSelector(originalSalesRows, (rows) =>
    rows.filter((row) => (row.product || row.rentalProduct) && row.status.action !== CrudActions.DELETE)
  );

  /**
   * Augments sales rows with additional information.
   */
  const salesRows = createSelector(salesProductUnits, originalSalesRows, (units, rows) =>
    rows.map((row) => {
      if (row.rentalProductTransfer) {
        return {
          ...row,
          units,
        };
      } else {
        return row;
      }
    })
  );

  const filteredSalesRows = createSelector(salesRows, (rows) =>
    rows.filter((row) => (row.product || row.rentalProduct) && row.status.action !== CrudActions.DELETE)
  );

  const salesTotals = createSelector(filteredSalesRows, (rows) => {
    let salesTotal = 0;
    let salesPriceEachTotal = 0;
    let salesOriginalCostTotal = 0;
    let salesDollarMarginTotal = 0;
    for (let row of rows) {
      if (row.extension) {
        salesTotal = round2Decimals(salesTotal + row.extension);
      }
      if (row.priceEach) {
        salesPriceEachTotal = round2Decimals(salesPriceEachTotal + row.priceEach);
      }
      if (row.quantity && row.originalCost) {
        salesOriginalCostTotal = round2Decimals(
          salesOriginalCostTotal + round2Decimals(row.quantity * row.originalCost)
        );
      }
      if (row.marginDollars) {
        salesDollarMarginTotal = round2Decimals(salesDollarMarginTotal + row.marginDollars);
      }
    }
    return {
      salesProductCount: rows.length,
      salesTotal,
      salesPriceEachTotal,
      salesOriginalCostTotal,
      salesDollarMarginTotal,
      salesPercentMarginTotal:
        salesTotal > 0 ? round2Decimals(((salesTotal - salesOriginalCostTotal) / salesTotal) * 100) : 0,
    };
  });

  const serviceTotalsByPhaseId = createSelector(filteredServiceGrids, (grids) =>
    grids.reduce(
      (phaseTotals, grid) => {
        let packageTotal = 0;
        for (const row of grid.rows) {
          if (row.extension) {
            packageTotal = round2Decimals(packageTotal + row.extension);
          }
        }
        phaseTotals[grid.phaseId] = round2Decimals(phaseTotals[grid.phaseId] + packageTotal);
        return phaseTotals;
      },
      {
        [ServicesPhases.DELIVERY]: 0,
        [ServicesPhases.INSTALL]: 0,
        [ServicesPhases.REMOVE]: 0,
        [ServicesPhases.PICKUP]: 0,
        [ServicesPhases.SERVICE]: 0,
      }
    )
  );

  const servicePhasesAggTotals = createSelector(serviceTotalsByPhaseId, (totalsByPhaseId) =>
    [
      ServicesPhases.DELIVERY,
      ServicesPhases.INSTALL,
      ServicesPhases.REMOVE,
      ServicesPhases.PICKUP,
      ServicesPhases.SERVICE,
    ]
      .map<IServicePhaseAggTotal>((phase) => ({ phase, total: totalsByPhaseId[phase] }))
      .filter(({ total }) => total > 0)
  );

  const servicesTotals = createSelector(filteredServiceGrids, fscMultiplier, (grids, fscMultiplier) => {
    let servicesTotal = 0;
    let fscFeeTotal = 0;
    for (const grid of grids) {
      for (const { extension, service } of grid.rows) {
        if (extension) {
          servicesTotal = round2Decimals(servicesTotal + extension);
          if (fscMultiplier && service?.fscFeeApplies) {
            fscFeeTotal = round2Decimals(fscFeeTotal + round2Decimals(extension * fscMultiplier));
          }
        }
      }
    }
    return {
      servicePackageCount: grids.length,
      servicesTotal,
      fscFeeTotal,
    };
  });

  const totalsSummary = createSelector(
    rentalTotals,
    salesTotals,
    servicesTotals,
    ({ rentalTotal, erFeeTotal, aqFeeTotal }, { salesTotal }, { servicesTotal, fscFeeTotal }) => ({
      rentalTotal,
      erFeeTotal,
      aqFeeTotal,
      salesTotal,
      servicesTotal,
      fscFeeTotal,
      grandTotal: round2Decimals(rentalTotal + salesTotal + servicesTotal + erFeeTotal + aqFeeTotal + fscFeeTotal),
    })
  );

  const totals = createSelector(
    rentalTotals,
    salesTotals,
    servicesTotals,
    serviceTotalsByPhaseId,
    servicePhasesAggTotals,
    totalsSummary,
    (rental, sales, services, serviceTotalsByPhaseId, servicePhasesAggTotals, summary) => ({
      rental,
      sales,
      services,
      serviceTotalsByPhaseId,
      servicePhasesAggTotals,
      summary,
    })
  );

  const fetchingRental = pipe(({ rental }) => rental.status.fetching);
  const rentalFetched = pipe(({ rental }) => rental.status.fetched);
  const fetchingRentalProfiles = pipe(({ rental }) => rental.status.profilesStatus === FetchStatuses.Fetching);
  const rentalProfilesFetched = pipe(({ rental }) => rental.status.profilesStatus === FetchStatuses.Fetched);
  const shouldFetchRentalProfiles = (state: RootState) =>
    isNonDraftQuote(state) &&
    !fetchingRental(state) &&
    rentalFetched(state) &&
    !fetchingRentalProfiles(state) &&
    !rentalProfilesFetched(state);

  const fetchingSales = pipe(({ sales }) => sales.status.fetching);
  const salesFetched = pipe(({ sales }) => sales.status.fetched);
  const fetchingSalesProfiles = pipe(({ sales }) => sales.status.profilesStatus === FetchStatuses.Fetching);
  const salesProfilesFetched = pipe(({ sales }) => sales.status.profilesStatus === FetchStatuses.Fetched);
  const shouldFetchSalesProfiles = (state: RootState) =>
    isNonDraftQuote(state) &&
    !fetchingSales(state) &&
    salesFetched(state) &&
    !fetchingSalesProfiles(state) &&
    !salesProfilesFetched(state);

  const fetchingServices = pipe(({ services }) => services.status.fetching);
  const servicesFetched = pipe(({ services }) => services.status.fetched);
  const fetchingServiceProfiles = pipe(({ services }) => services.status.profilesStatus === FetchStatuses.Fetching);
  const serviceProfilesFetched = pipe(({ services }) => services.status.profilesStatus === FetchStatuses.Fetched);
  const shouldFetchServiceProfiles = (state: RootState) =>
    isNonDraftQuote(state) &&
    !fetchingServices(state) &&
    servicesFetched(state) &&
    !fetchingServiceProfiles(state) &&
    !serviceProfilesFetched(state);

  return {
    selectors: {
      loading: (state: RootState) => fetchingRental(state) || fetchingSales(state) || fetchingServices(state),

      rentalRows,
      notDeletedRentalRows,
      rentalTotals,

      salesRows,
      notDeletedSalesRows,
      salesTotals,

      getGridInsertionRowSelector(innerTab: QuoteInnerTabs) {
        return innerTab === QuoteInnerTabs.Rental ? selectRentalInsertionRow : selectSalesInsertionRow;
      },

      servicesGrids,

      /**
       * Service grids with deleted ones filtered out.
       */
      filteredServiceGrids,
      hasServicePackages: (state: RootState) => filteredServiceGrids(state).length > 0,

      /**
       * Wraps each service grid in an object that contains additional info needed
       * for displaying the grid in the UI.
       */
      serviceGridInfos: createSelector(filteredServiceGrids, makeServiceGridInfos),

      // Todo: Service phases should be fetched only once and should be stored outside of product state.
      servicePhases: pipe(({ services }) => services.phases),

      serviceCount: createSelector(filteredServiceGrids, (grids) => sumBy(grids, (grid) => grid.rows.length)),

      serviceTotalsByPhaseId,
      servicePhasesAggTotals,
      servicesTotals,

      totalsSummary,
      totals,

      rentalFetched,
      fetchingRentalProfiles,
      rentalProfilesFetched,
      shouldFetchRentalProfiles,
      selectedRentalRowIds: pipe(({ rental }) => rental.selected),
      selectedRentalRowIdsSet: pipe(({ rental }) => rental.selectedById),

      salesFetched,
      fetchingSalesProfiles,
      salesProfilesFetched,
      shouldFetchSalesProfiles,
      selectedSalesRowIds: pipe(({ sales }) => sales.selected),

      servicesFetched,
      fetchingServiceProfiles,
      serviceProfilesFetched,
      shouldFetchServiceProfiles,
    },
    pipe,
  };
};

export const ProductsTabSelectors = makeProductsStateSelectors(
  (state: RootState) => state.quoteScreen.productsTab.products
).selectors;

const makeOptionalTabSelectors = () => {
  const { selectors, pipe } = makeProductsStateSelectors((state: RootState) => state.quoteScreen.productsTab.optional);
  return {
    ...selectors,
    notes: pipe(({ notes }) => notes.content),
    notesFetched: pipe(({ notes }) => notes.status.fetched),
  };
};

export const OptionalTabSelectors = makeOptionalTabSelectors();

export const selectProductsState = (state: RootState) => {
  const productsState = state.quoteScreen.productsTab;
  return TabsSelector.activeTab(state) === QuoteTabs.Products ? productsState.products : productsState.optional;
};

const canRerate = (state: RootState) => isDraftQuote(state) && UserSelectors.permissions(state).write;

export const ProductSelectors = {
  services: (state: RootState) => selectProductsState(state).services,
  servicesGrids: (state: RootState) => ProductSelectors.services(state).grids,
  servicesGrid: (state: RootState, gridId: string) =>
    ProductSelectors.servicesGrids(state).find((g) => g.gridId === gridId),
  copyingServices: (state: RootState) => ProductSelectors.services(state).copyingServices,

  applyEnvironmentalRecovery: (state: RootState) =>
    !!state.session.quote.data?.header?.fees?.applyEnvironmentalRecovery,
  applyEnvironmentalRecoveryAvailable: (state: RootState) =>
    state.session.quote.data?.header?.fees?.applyEnvironmentalRecovery !== undefined,
  canWaiveEnvironmentalRecovery: (state: RootState) =>
    state.session.quote.data?.header?.fees?.canWaiveEnvironmentalRecovery,
  waiveEnvironmentalRecovery: (state: RootState) => state.quoteScreen.productsTab.waiveEnvironmentalRecovery,

  // Quote level setting. Currently, this is true only if the branch is in California.
  applyAirQuality: (state: RootState) => !!state.session.quote.data?.header?.fees?.applyAirQuality,

  applyAirQualityAvailable: (state: RootState) => state.session.quote.data?.header?.fees?.applyAirQuality !== undefined,

  // Currently, this is true only for prospects.
  canWaiveAirQuality: (state: RootState) => state.session.quote.data?.header?.fees?.canWaiveAirQuality,

  // True if a user chose to waive AQ fees for current prospect.
  waiveAirQuality: (state: RootState) => state.quoteScreen.productsTab.waiveAirQuality,

  searchOpened: (state: RootState) => state.quoteScreen.productsTab.searchOpened,
  initialSearchQuery: (state: RootState) => state.quoteScreen.productsTab.initialSearchQuery,
  searchWindowBounds: (state: RootState) => state.quoteScreen.productsTab.searchWindowBounds,

  rentalCategoriesFetched: (state: RootState) => rentalCategories(state).fetched,
  rentalCategories: (state: RootState) => rentalCategories(state).categories,

  rateLockDown: (state: RootState) => state.quoteScreen.productsTab.rateLockDown,
  fscPercentage: (state: RootState) => state.quoteScreen.productsTab.fscPercentage,
  fscMultiplier,
  defaultFscMultiplier: (state: RootState) => state.quoteScreen.productsTab.defaultFscMultiplier,

  rentalRowsSelected: createSelector(selectProductsState, ({ rental }) => rental.selected.length > 0),
  salesRowsSelected: createSelector(selectProductsState, ({ sales }) => sales.selected.length > 0),

  rentalProductUnits,
  rentalProductUnitsById,
  rentalProductUnitNamesById,
  rentalPeriods,
  rentalPeriodNamesById,
  salesProductUnits,
  salesProductUnitNamesById,

  isDraftQuote,

  canRerate,

  shouldCheckForFeeUpdates: (state: RootState) =>
    slice(state).reratingState === ReratingStates.NotRerated && canRerate(state),

  shouldRerateQuote: (state: RootState) =>
    slice(state).reratingState === ReratingStates.FeesChecked && canRerate(state),

  rerating: (state: RootState) => slice(state).reratingState === ReratingStates.Rerating,

  // Used to delay some operations, like quote validation, until rerating process is completed.
  reratingCompleted: (state: RootState) => slice(state).reratingState === ReratingStates.ReratingCompleted,
};

const rentalCategories = (state: RootState) => state.quoteScreen.productsTab.rentalCategories;

export const getProductSelectors = (tab: QuoteTabs) =>
  tab === QuoteTabs.Products ? ProductsTabSelectors : OptionalTabSelectors;
