import i18next from 'i18next';
import { compact, isNil } from 'lodash';
import { ApprovalOutcomes } from '../approval/types';
import { IQuoteApproval, IQuoteOutcome, QuoteOutcomes, WorkflowStates } from '../interfaces';
import { i18nKeys } from '../internationalization/i18nKeys';

export interface IQuoteStatusData {
  stateId?: WorkflowStates;
  header?: {
    approval?: IQuoteApproval;
    outcome?: IQuoteOutcome;
    migration?: {
      migrated: boolean;
    };
  };
}

export enum QuoteNotDeletableReasons {
  Migrated = 'Migrated',
  PreviouslyRejected = 'PreviouslyRejected',
  PreviouslyPartiallyApproved = 'PreviouslyPartiallyApproved',
  PreviouslyApproved = 'PreviouslyApproved',
  PreviouslyWon = 'PreviouslyWon',
  PreviouslyLost = 'PreviouslyLost',
  ApprovalPending = 'ApprovalPending',
  PartiallyApproved = 'PartiallyApproved',
  Rejected = 'Rejected',
  Approved = 'Approved',
  Lost = 'Lost',
  Won = 'Won',
}

export class QuoteStatusAdapter {
  private _statusText: string | null = null;

  constructor(private data: IQuoteStatusData) {}

  get stateId() {
    return this.data.stateId;
  }

  get valid() {
    return !isNil(this.data.stateId);
  }

  get approval() {
    return this.data.header?.approval;
  }

  get outcome() {
    return this.data.header?.outcome;
  }

  get outcomeAvailable() {
    return !!this.outcome;
  }

  get isOpen() {
    return this.stateId === WorkflowStates.OPEN;
  }

  get reopened() {
    return this.outcome?.reopened ?? false;
  }

  get approvalPending() {
    return this.stateId === WorkflowStates.APPROVAL_PENDING;
  }

  get approvalCompleted() {
    return this.stateId === WorkflowStates.APPROVAL_COMPLETED;
  }

  get approvalOutcomeId() {
    return this.approval?.outcomeId;
  }

  get approved() {
    return this.approvalCompleted && this.approval?.outcomeId === ApprovalOutcomes.APPROVED;
  }

  get rejected() {
    return this.approvalCompleted && this.approval?.outcomeId === ApprovalOutcomes.REJECTED;
  }

  get closed() {
    return this.stateId === WorkflowStates.CLOSED;
  }

  get outcomeId() {
    return this.outcome?.outcomeId;
  }

  get won() {
    return this.closed && this.outcomeId === QuoteOutcomes.WON;
  }

  get lost() {
    return this.closed && this.outcomeId === QuoteOutcomes.LOST;
  }

  private getApprovalKeys() {
    return compact([this.approval?.branchKey, this.approval?.regionKey, this.approval?.companyKey]);
  }

  get pendingApprovalTiers() {
    return this.getApprovalKeys()
      .filter((key) => key.required && !key.completed)
      .map(({ tierId }) => tierId);
  }

  get statusText() {
    if (!this._statusText) {
      const textKeys = i18nKeys.quoteStatus;
      let textKey = textKeys.unknown;
      if (this.isOpen) {
        textKey = textKeys.draft;
      } else if (this.approvalPending || this.rejected) {
        const approvalKeys = this.getApprovalKeys();
        if (this.approvalPending) {
          textKey = approvalKeys.reduce(
            (textKey, { completed, tierId }) => (completed ? textKey : `${textKey}-${tierId}`),
            textKeys.awaitingApproval
          );
        } else {
          const rejectedKey = approvalKeys.find(({ outcomeId }) => outcomeId === ApprovalOutcomes.REJECTED);
          textKey = rejectedKey ? `${textKeys.rejected}-${rejectedKey.tierId}` : textKeys.rejected;
        }
      } else if (this.approved) {
        textKey = textKeys.approved;
      } else if (this.won) {
        textKey = textKeys.won;
      } else if (this.lost) {
        textKey = textKeys.lost;
      }
      this._statusText = i18next.t(textKey);
    }
    return this._statusText!;
  }

  private hasCompletedKeys() {
    return this.getApprovalKeys().some(({ completed }) => completed);
  }

  getNotDeletableReason(): QuoteNotDeletableReasons | null {
    if (this.data.header?.migration?.migrated) {
      return QuoteNotDeletableReasons.Migrated;
    } else if (this.isOpen) {
      if (this.reopened) {
        if (this.outcomeId === QuoteOutcomes.WON) {
          return QuoteNotDeletableReasons.PreviouslyWon;
        }
        if (this.outcomeId === QuoteOutcomes.LOST) {
          return QuoteNotDeletableReasons.PreviouslyLost;
        }
        if (this.approvalOutcomeId === ApprovalOutcomes.REJECTED) {
          return QuoteNotDeletableReasons.PreviouslyRejected;
        }
        if (this.approvalOutcomeId === ApprovalOutcomes.APPROVED) {
          return QuoteNotDeletableReasons.PreviouslyApproved;
        }
        if (this.hasCompletedKeys()) {
          return QuoteNotDeletableReasons.PreviouslyPartiallyApproved;
        }
      }
    } else if (this.approvalPending) {
      return this.hasCompletedKeys()
        ? QuoteNotDeletableReasons.PartiallyApproved
        : QuoteNotDeletableReasons.ApprovalPending;
    } else if (this.approved) {
      return QuoteNotDeletableReasons.Approved;
    } else if (this.rejected) {
      return QuoteNotDeletableReasons.Rejected;
    } else if (this.won) {
      return QuoteNotDeletableReasons.Won;
    } else if (this.lost) {
      return QuoteNotDeletableReasons.Lost;
    }
    return null;
  }

  get canBeDeleted() {
    return isNil(this.getNotDeletableReason());
  }

  get canBeMarkedWon() {
    return this.approved || this.lost;
  }

  get canBeMarkedLost() {
    return this.approved || this.won;
  }
}
