import moment from 'moment';
import { CognitoTokensUnauthorizedError } from '../auth/cognito';
import { LocalStorage } from '../auth/localStorage';
import { getCognito } from '../di/utils';

export class TokenUtils {
  private static readonly TIME_BEHIND_AMOUNT = 2;
  private static readonly TIME_BEHIND_UNITS = 'minutes';

  private tokenPromise: Promise<string> | null = null;
  private logoutInProgress = false;

  public shouldRefreshToken(tokenString: string): boolean {
    const [, encodedToken] = tokenString.split('.');
    const tokenData: any = JSON.parse(atob(encodedToken));
    const preExpiration: moment.Moment = moment
      .unix(tokenData.exp)
      .subtract(TokenUtils.TIME_BEHIND_AMOUNT, TokenUtils.TIME_BEHIND_UNITS);
    return preExpiration < moment();
  }

  public onLogoutStart() {
    this.logoutInProgress = true;
  }

  async checkSalesProAuth() {
    // First call is processed, subsequent ones just wait for result.
    if (!this.tokenPromise) {
      this.tokenPromise = new Promise(async (resolve, reject) => {
        const idToken = LocalStorage.readIdToken();
        const accessToken = LocalStorage.readAccessToken();
        const refreshToken = LocalStorage.readRefreshToken();

        // Do not try to log in or out, or refresh tokens if logout is in progress.
        if (this.logoutInProgress) {
          if (idToken && accessToken && refreshToken && !this.shouldRefreshToken(idToken)) {
            resolve(idToken);
          } else {
            reject(new Error('Logout in progress'));
          }
          return;
        }

        // If any of the tokens are missing, perform Cognito login.
        if (!idToken || !accessToken || !refreshToken) {
          // The promise never settles because we are navigating to Cognito.
          await getCognito().login();
        }

        // If id token does not need to be refreshed, just return it.
        if (!this.shouldRefreshToken(idToken)) {
          resolve(idToken);
          return;
        }

        try {
          // Refresh tokens and return the resulting id token.
          resolve(await getCognito().refreshTokens());
        } catch (e: any) {
          if (e instanceof CognitoTokensUnauthorizedError) {
            // User must log in again.
            await getCognito().logout();
          }
          reject(e);
        }
      });

      this.tokenPromise
        // Clear the promise field once we are done.
        .finally(() => {
          this.tokenPromise = null;
        })
        // Attaching a rejection handler on this separate promise chain
        // prevents "unhandled promise rejection" errors in Cypress tests.
        .catch(() => {});
    }
    return this.tokenPromise;
  }

  private constructor() {}

  private static instance: TokenUtils;

  static getInstance() {
    if (!this.instance) {
      this.instance = new TokenUtils();
    }
    return this.instance;
  }
}
