import i18next from 'i18next';
import { OptionsObject } from 'notistack';
import { enqueueNotificationAction, getStore } from 'rdx';
import { AppConfigurationService, ConfigurableRequest } from '../../api';
import { i18nKeys } from '../../internationalization/i18nKeys';
import { opsPackSlice } from '../redux/slice';
import { OpsPackSummary } from '../types';

interface OpsPackChannelConnectedMessage {
  action: undefined;
  connectionId: string;
}

interface OpsPackGenerationResultMessage {
  action: 'opspack-generate';
  success: boolean;
  error: boolean;
  branchFolderMissing?: boolean;
}

enum OpsPackStatusCodes {
  NormalClosure = 1000,
  GenerateRequestError = 4000,
  InvalidMessageReceivedError,
  BranchFolderMissingError,
  GenerateError,
  GenerateSuccess,
}

type MessageVariant = OptionsObject['variant'];

const keys = i18nKeys.opsPack;

export class OpsPackService extends ConfigurableRequest {
  private static instance: OpsPackService;

  private readonly socketApiUrl: string;
  private socket: WebSocket | null = null;

  private constructor() {
    super('quotes');
    const { appConfiguration } = AppConfigurationService.getInstance();
    this.baseUrl = appConfiguration.opsPackURL;
    this.socketApiUrl = appConfiguration.opsPackSocketURL;
  }

  private showMessage(key: string, variant: MessageVariant) {
    getStore().dispatch(
      enqueueNotificationAction({
        message: i18next.t(key),
        options: {
          variant,
        },
      })
    );
  }

  private async fetchOpsPackSummary(quoteId: string) {
    getStore().dispatch(opsPackSlice.actions.fetchOpsPackSummaryStarted());
    try {
      const summary: OpsPackSummary = (await this.get(`/${quoteId}/opspack`)).data[0];
      getStore().dispatch(opsPackSlice.actions.fetchOpsPackSummarySuccess({ summary }));
      return summary;
    } catch {
      getStore().dispatch(opsPackSlice.actions.fetchOpsPackSummaryError());
      this.showMessage(keys.getSummaryError, 'error');
      return Promise.reject();
    }
  }

  /**
   * Get ops pack status. If generation is pending, subscribe to notification channel.
   */
  updateOpsPackStatus(quoteId: string) {
    if (!this.socket) {
      this.fetchOpsPackSummary(quoteId).then(
        (summary) => {
          if (summary.processStatusId === 'PENDING') {
            this.connect(quoteId, true);
          }
        },
        () => {}
      );
    }
  }

  generateOpsPack(quoteId: string) {
    getStore().dispatch(opsPackSlice.actions.generateOpsPackStarted());
    this.connect(quoteId, false);
  }

  private connect(quoteId: string, reconnect: boolean) {
    this.socket = new WebSocket(this.socketApiUrl);

    let connected = false;

    this.socket.onopen = () => {
      // Request connection id.
      this.socket?.send(JSON.stringify({ action: 'connection' }));
    };

    this.socket.onclose = (e: CloseEvent) => {
      // console.log(`Ops Pack channel closed with code ${e.code}`);
      this.socket = null;
      let messageKey: string | null;
      let messageVariant: MessageVariant = 'error';
      switch (e.code) {
        case OpsPackStatusCodes.NormalClosure:
          messageKey = null;
          break;
        case OpsPackStatusCodes.GenerateSuccess:
          messageKey = keys.generateSuccess;
          messageVariant = 'success';
          break;
        case OpsPackStatusCodes.BranchFolderMissingError:
          messageKey = keys.branchFolderMissingError;
          break;
        default:
          if (reconnect && !connected) {
            messageKey = keys.reconnectError;
          } else {
            messageKey = keys.generateError;
          }
      }
      if (messageKey) {
        this.showMessage(messageKey, messageVariant);
      }
      if (e.code !== OpsPackStatusCodes.NormalClosure) {
        this.fetchOpsPackSummary(quoteId).catch(() => {});
      }
    };

    this.socket.onmessage = async (e) => {
      let resultCode = NaN;
      try {
        const message = JSON.parse(e.data) as OpsPackChannelConnectedMessage | OpsPackGenerationResultMessage;
        if (message.action === undefined) {
          const { connectionId } = message;
          try {
            if (reconnect) {
              await this.put(`/${quoteId}/opspack`, {}, {}, {}, { connectionId });
            } else {
              await this.post(`/${quoteId}/opspack`, {}, {}, {}, { connectionId });
            }
            connected = true;
          } catch {
            resultCode = OpsPackStatusCodes.GenerateRequestError;
          }
        } else if (message.action === 'opspack-generate') {
          if (message.branchFolderMissing === true) {
            resultCode = OpsPackStatusCodes.BranchFolderMissingError;
          } else if (message.error) {
            resultCode = OpsPackStatusCodes.GenerateError;
          } else {
            resultCode = OpsPackStatusCodes.GenerateSuccess;
          }
        } else {
          resultCode = OpsPackStatusCodes.InvalidMessageReceivedError;
        }
      } catch {
        resultCode = OpsPackStatusCodes.InvalidMessageReceivedError;
      }
      if (!Number.isNaN(resultCode)) {
        this.socket?.close(resultCode);
      }
    };
  }

  reset() {
    this.socket?.close(OpsPackStatusCodes.NormalClosure);
  }

  public static getInstance(): OpsPackService {
    if (!OpsPackService.instance) {
      OpsPackService.instance = new OpsPackService();
    }
    return OpsPackService.instance;
  }
}
