import { IApiEnvelope, IUser } from 'interfaces';
import { ConfigurableRequest } from './configurable-request';

export class BranchService extends ConfigurableRequest {
  private static instance: BranchService;

  private constructor() {
    super('branches');
  }

  public async getBranchUsers(branchId: string): Promise<IUser[]> {
    const result: IApiEnvelope<IUser> = await this.get(`/${branchId}/users`);
    return result.data;
  }

  public static getInstance(): BranchService {
    if (!BranchService.instance) {
      BranchService.instance = new BranchService();
    }
    return BranchService.instance;
  }
}
