import { Typography } from '@mui/material/';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { StyledContainerErrorsFound } from './ErrorsFoundIndicator.style';

export interface IErrorsFoundIndicatorProps {
  count: number;
  onClick: () => void;
}

export const ErrorsFoundIndicator: React.FC<IErrorsFoundIndicatorProps> = (props) => {
  const { count, onClick } = props;
  return (
    <StyledContainerErrorsFound onClick={onClick}>
      <ErrorIcon />
      <Typography noWrap>{count} Error(s) found.</Typography>
    </StyledContainerErrorsFound>
  );
};
