import { Box } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import React from 'react';
import { CloneSourceLink } from '../../../components/CloneSourceLink/CloneSourceLink';
import { IQuote } from '../../../interfaces';

export const QuoteIcons: React.FC<{ quote: IQuote }> = ({ quote }) => {
  return (
    <Box display="flex" alignItems="center">
      {quote.hasDocuments && (
        <Box ml={0.25} display="flex">
          <AttachFileIcon fontSize="inherit" />
        </Box>
      )}
      <CloneSourceLink clone={quote.header.clone} ml={0.25} />
    </Box>
  );
};
