import { Box, Fade, Tooltip, tooltipClasses, Typography } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { Property } from 'csstype';
import React, { useMemo } from 'react';
import createDOMPurify from 'dompurify';

const domPurifyInstance = createDOMPurify();

domPurifyInstance.addHook('afterSanitizeAttributes', function (node) {
  // Securely open all links in a new tab
  if (node.tagName === 'A') {
    node.setAttribute('rel', 'noopener');
    node.setAttribute('target', '_blank');
  }
});

export const HELP_TOOLTIP_ICON_SIZE = 20;

const ANCHOR_OFFSET = '8px !important';
const TOOLTIP_BORDER = '1px solid #1d7abd';
const TOOLTIP_BG = 'white';

interface HelpTooltipProps {
  html: string;
  placement: TooltipProps['placement'];

  /** @default 'none' */
  maxWidth?: Property.MaxWidth;
}

export const HelpTooltip: React.FC<HelpTooltipProps> = ({ html, placement, maxWidth = 'none' }) => {
  const __html = useMemo(() => domPurifyInstance.sanitize(html), [html]);
  return (
    <Tooltip
      disableInteractive={false}
      arrow={true}
      title={<span dangerouslySetInnerHTML={{ __html }} />}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }}
      placement={placement}
      leaveDelay={100}
      componentsProps={{
        arrow: {
          sx: {
            color: TOOLTIP_BG,
            '&::before': {
              border: TOOLTIP_BORDER,
            },
          },
        },
        tooltip: {
          sx: {
            maxWidth,
            border: TOOLTIP_BORDER,
            backgroundColor: TOOLTIP_BG,
            color: 'black',
            fontWeight: 'normal',
            fontSize: '1rem',
            [`&.${tooltipClasses.tooltipPlacementTop}, &.${tooltipClasses.tooltipPlacementBottom}`]: {
              my: ANCHOR_OFFSET,
            },
            [`&.${tooltipClasses.tooltipPlacementRight}, &.${tooltipClasses.tooltipPlacementLeft}`]: {
              mx: ANCHOR_OFFSET,
            },
            a: {
              color: 'inherit !important',
              '&, &:hover': {
                textDecoration: 'underline',
              },
            },
            boxShadow: '0 2px 2px rgba(0,0,0,.3)',
          },
        },
      }}
    >
      <Box
        sx={{
          width: HELP_TOOLTIP_ICON_SIZE,
          minWidth: HELP_TOOLTIP_ICON_SIZE,
          height: HELP_TOOLTIP_ICON_SIZE,
          minHeight: HELP_TOOLTIP_ICON_SIZE,
          borderRadius: '50%',
          backgroundColor: '#ECA629',
          display: 'inline-flex',
          placeContent: 'center',
          placeItems: 'center',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
      >
        <Typography
          sx={{ color: 'white', fontSize: '120%', fontWeight: 'bold', lineHeight: `${HELP_TOOLTIP_ICON_SIZE}px` }}
        >
          ?
        </Typography>
      </Box>
    </Tooltip>
  );
};
