import { ServicesPhases, Statuses } from 'enums';
import { ApprovalOutcomes, ApprovalTiers } from '../approval/types';
import { IAccount } from './Account';
import { IBranch } from './Branch';
import { IContact } from './Contact';
import { IApplication } from './QuoteApplication';
import { IRentalDetailEventBody } from './RentalDetailEvent';
import { ISalesDetailEventBody } from './SalesDetailEvent';
import { IServicesDetailEventBody } from './ServicesDetailEvent';
import { IUser, IUserSummary } from './User';

export interface CommonPermissions {
  canBeAssigned: boolean;
  canUpdateContact: boolean;
  canUpdateJobsite: boolean;
  canUpdateTaxExempt: boolean;
  canUpdateWorkOfImprovement: boolean;
  canUpdatePrevailingWage: boolean;
  canUpdateDeliveryDate: boolean;
  canUpdateCompletionDate: boolean;
  canBeCompleted: boolean;
  canBeWon: boolean;
  canBeLost: boolean;
  canBeReopened: boolean;
  canBeDeleted: boolean;
  canChangePrintOption: boolean;
}

export interface QuoteOnlyPermissions {
  canCudDocuments: boolean;
  canCudStatement: boolean;
  canBeCloned: boolean;
  canGenerateOpsPack: boolean;
  canRegenerateOpsPack: boolean;
  canCudChangeOrders: boolean;
  canCudOptionalNotes: boolean;
}

export interface QuotePermissions extends CommonPermissions, QuoteOnlyPermissions {}

export interface IQuote {
  header: IQuoteHeader;
  detail?: IQuoteDetail;
  stateId: WorkflowStates;
  extensions?: {
    permissions?: QuotePermissions;
  };
  id: string;
  pk?: string;
  sk?: string;
  typeId: QuoteTypes.QUOTE;
  application?: IApplication;
  statusId: Statuses;
  hasDocuments?: boolean;
  currentFees: ICurrentFees;
}

export interface IServicePhaseAggTotal {
  phase: ServicesPhases;
  total: number;
}

export interface IQuoteObjectRequiredTotals {
  rentalRequiredTotal: number;
  rateLockdownRequired: boolean;
  erFeeRequiredTotal: number;
  aqFeeRequiredTotal: number;
  rppEligibleRequiredTotal: number;
  rppFeeRequiredTotal: number;
  salesRequiredTotal: number;
  servicesRequiredTotal: number;
  fscFeeRequiredTotal: number;
  servicePhasesAggRequiredTotal: IServicePhaseAggTotal[];
  grandTotalRequired: number;
}

export interface IQuoteObjectOptionalTotals {
  rentalOptionalTotal: number;
  rateLockdownOptional: boolean;
  erFeeOptionalTotal: number;
  aqFeeOptionalTotal: number;
  rppFeeOptionalTotal: number;
  salesOptionalTotal: number;
  servicesOptionalTotal: number;
  fscFeeOptionalTotal: number;
  servicePhasesAggOptionalTotal: IServicePhaseAggTotal[];
  grandTotalOptional: number;
}

export interface IQuoteTotals extends IQuoteObjectRequiredTotals, IQuoteObjectOptionalTotals {}

export interface IChangeOrderTotals extends IQuoteObjectRequiredTotals {}

interface IQuoteHeader {
  main: IQuoteMain;
  ownership: IQuoteOwnership;
  schedule: IQuoteSchedule;
  review: IQuoteReview;
  approval: IQuoteApproval;
  outcome: IQuoteOutcome;
  billing: IQuoteBilling;
  project?: IQuoteProject;
  jobsite: IQuoteJobsite;
  engineering?: IQuoteEngineering;
  notes?: IQuoteNotes;
  fees: IQuoteFees;
  totals: IQuoteTotals;
  migration?: {
    migrated: boolean;
  };
  clone: CloneInfo;
  change: {
    exist: boolean;
  };
}

export interface CloneInfo {
  cloned: boolean;
  sourceQuoteCode?: string;
  sourceQuoteId?: string;
}

export enum MarketSegments {
  AGRICULTURAL = 'AGRICULTURAL',
  INDUSTRIAL = 'INDUSTRIAL',
  // OIL_AND_GAS = 'OIL_AND_GAS',
}

export interface IQuoteMain {
  code: string;
  branch: Omit<IBranch, 'permissions'>;
  marketSegmentId: MarketSegments;
  account: IAccount;
  contact?: IContact;
}

export interface IQuoteApproval {
  outcomeId?: ApprovalOutcomes;
  branchKey?: IQuoteApprovalKey;
  regionKey?: IQuoteApprovalKey;
  companyKey?: IQuoteApprovalKey;
}

interface IQuoteApprovalKey {
  tierId: ApprovalTiers;
  required: boolean;
  completed: boolean;
  outcomeId?: ApprovalOutcomes;
}

export enum WorkflowStates {
  OPEN = 'OPEN',
  APPROVAL_PENDING = 'APPROVAL_PENDING',
  APPROVAL_COMPLETED = 'APPROVAL_COMPLETED',
  CLOSED = 'CLOSED',
}

export interface IQuoteBilling {
  taxExempt?: boolean;
  workOfImprovement?: boolean;
  prevailingWage?: boolean;
}

export interface IQuoteEngineering {
  jobNumber: string;
  comments: string;
}

export interface IQuoteOwnership {
  assignedTo: IUser;
  preparedBy: IUser;
  assignedTimestamp: number;
  preparedTimestamp: number;
  estimator?: IUserSummary;
  supportSpecialist?: IUserSummary;
}

export interface IQuoteSchedule {
  deliveryTimestamp?: number | null;
  completionTimestamp?: number | null;
}

export enum QuotePrintOptions {
  SUMMARY = 'SUMMARY',
  DETAIL = 'DETAIL',
}

export interface IQuoteReviewPrintOptions {
  summaryDescription?: string;
  printOption: QuotePrintOptions;
}

export enum ApprovalPriorities {
  STANDARD = 'STANDARD',
  URGENT = 'URGENT',
}

export interface IQuoteReviewMarkCompleteOptions {
  priorityId?: ApprovalPriorities;
  requestedTimestamp?: number;
  requestedReason?: string;
}

export interface IQuoteReview extends IQuoteReviewPrintOptions, IQuoteReviewMarkCompleteOptions {}

export interface IQuoteOutcome {
  outcomeId?: QuoteOutcomes;
  outcomeBy?: IUser;
  outcomeTimestamp?: number;
  reasonId?: string;
  reasonText?: string;
  reopened?: boolean;
}

export enum QuoteOutcomes {
  WON = 'WON',
  LOST = 'LOST',
}

export interface IQuoteProject {
  name: string;
  what: string;
  duration: string;
  description: string;
}

export interface IQuoteJobsite {
  id: string;
  jobName: string | null;
  poNumber: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
  siteContact: string | null;
  sitePhoneNumber: string | null;
  siteExtension: string | null;
  siteMobilePhoneNumber: string | null;
  marketSectorCode: string | null;
  marketSector: string | null;
}

export interface IQuoteNotes {
  content: string;
}

export interface IQuoteFees {
  fuelSurchargeMultiplier: number;
  defaultFuelSurchargeMultiplier?: number;
  applyEnvironmentalRecovery: boolean;
  canWaiveEnvironmentalRecovery: boolean;
  waiveEnvironmentalRecovery: boolean;
  applyAirQuality: boolean;
  canWaiveAirQuality: boolean;
  waiveAirQuality: boolean;
}

export interface ICurrentFees {
  fuelSurchargeMultiplier: number;
}

export interface IQuoteDetailProducts {
  rentalProducts: IRentalDetailEventBody[];
  salesProducts: ISalesDetailEventBody[];
  services: IServicesDetailEventBody[];
}

export interface IQuoteDetail extends IQuoteDetailProducts {
  optionalRentalProducts: IRentalDetailEventBody[];
  optionalSalesProducts: ISalesDetailEventBody[];
  optionalServices: IServicesDetailEventBody[];
  optionalNotes: any | null;
}

export interface IChangeOrderHeader {
  main: {
    code: string;
    scopeChange?: string;
    parentQuoteId: string;
    account: IAccount;
    marketSegmentId: string;
  };
  ownership: IQuoteOwnership;
  approval: IQuoteApproval;
  outcome: IQuoteOutcome;
  totals: IChangeOrderTotals;
  schedule: IQuoteSchedule;
  review: IQuoteReviewMarkCompleteOptions;
  migration?: {
    migrated: boolean;
  };
  // This should always be present in the response, but declared optional here to force checking.
  fees?: IQuoteFees;
}

export interface IChangeOrder {
  stateId: WorkflowStates;
  header?: IChangeOrderHeader;
  extensions?: {
    permissions?: CommonPermissions;
  };
  detail?: IQuoteDetailProducts;
  id: string;
  typeId: QuoteTypes.CHANGE_ORDER;
  currentFees: ICurrentFees;
}

export enum QuoteTypes {
  QUOTE = 'QUOTE',
  CHANGE_ORDER = 'CHANGE_ORDER',
}
