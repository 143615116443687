import { GridOptions, ICellRendererParams, ValueSetterParams } from 'ag-grid-community';
import { QuoteInnerTabs, QuoteProductTabs, QuoteTabs } from 'enums';
import { IGridRow, IRentalGridRow, ISalesGridRow, IServicesGridRow } from 'interfaces';
import { ValidationGroupNames } from 'rdx';
import {
  OptionalRentalRowValidationFieldsByColId,
  RentalRowValidationFieldsByColId,
} from '../../../../../rdx/quote-screen/review-tab/validation/rental';
import {
  OptionalSalesRowValidationFieldsByColId,
  SalesRowValidationFieldsByColId,
} from '../../../../../rdx/quote-screen/review-tab/validation/sales';
import {
  OptionalServiceRowValidationFieldsByColId,
  ServiceRowValidationFieldsByColId,
} from '../../../../../rdx/quote-screen/review-tab/validation/services';
import {
  ProductValidationFieldsByColId,
  ValidationField,
} from '../../../../../rdx/quote-screen/review-tab/validation/validation';

export interface ProductGridContext {
  innerTab: QuoteInnerTabs;
}

export interface ProductGridOptions extends GridOptions {
  context: ProductGridContext;
}

interface ProductGridValidationInfo {
  groupName: ValidationGroupNames;
  fields: ProductValidationFieldsByColId<IRentalGridRow | ISalesGridRow | IServicesGridRow>;
}

const getGridLocationKey = (tab: QuoteProductTabs, innerTab: QuoteInnerTabs) => `${tab}:${innerTab}`;

const gridValidationInfoByLocation: { [LocationKey: string]: ProductGridValidationInfo } = {
  [getGridLocationKey(QuoteTabs.Products, QuoteInnerTabs.Rental)]: {
    groupName: 'rental',
    fields: RentalRowValidationFieldsByColId,
  },
  [getGridLocationKey(QuoteTabs.Products, QuoteInnerTabs.Sales)]: {
    groupName: 'sales',
    fields: SalesRowValidationFieldsByColId,
  },
  [getGridLocationKey(QuoteTabs.Products, QuoteInnerTabs.Services)]: {
    groupName: 'services',
    fields: ServiceRowValidationFieldsByColId,
  },
  [getGridLocationKey(QuoteTabs.Optional, QuoteInnerTabs.Rental)]: {
    groupName: 'optionalRental',
    fields: OptionalRentalRowValidationFieldsByColId,
  },
  [getGridLocationKey(QuoteTabs.Optional, QuoteInnerTabs.Sales)]: {
    groupName: 'optionalSales',
    fields: OptionalSalesRowValidationFieldsByColId,
  },
  [getGridLocationKey(QuoteTabs.Optional, QuoteInnerTabs.Services)]: {
    groupName: 'optionalServices',
    fields: OptionalServiceRowValidationFieldsByColId,
  },
};

export const getGridValidationGroupNameByLocation = (tab: QuoteProductTabs, innerTab: QuoteInnerTabs) =>
  gridValidationInfoByLocation[getGridLocationKey(tab, innerTab)].groupName;

export const getGridValidationFieldsByLocation = (tab: QuoteProductTabs, innerTab: QuoteInnerTabs) =>
  gridValidationInfoByLocation[getGridLocationKey(tab, innerTab)].fields;

export const getValidationFieldForProductColumn = (
  tab: QuoteProductTabs,
  innerTab: QuoteInnerTabs,
  colId: string
): ValidationField | undefined => {
  return getGridValidationFieldsByLocation(tab, innerTab)?.[colId];
};

export const getValidationFieldForProductCell = (
  params: ICellRendererParams | ValueSetterParams
): ValidationField | undefined => {
  const { innerTab } = params.context as ProductGridContext;
  const { tab } = params.data as IGridRow;
  return getValidationFieldForProductColumn(tab, innerTab, params.column!.getId());
};
