import { PayloadAction } from '@reduxjs/toolkit';
import { IUserProfile, Roles } from 'interfaces';
import { ApprovalTiers } from '../../approval/types';
import { LAST_SELECTED_BRANCH } from '../../constants';
import { SessionState } from './session.types';

export const fetchUserProfilePending = (state: SessionState) => {
  state.user.profile.fetching = true;
};

export const fetchUserProfileRejected = (state: SessionState) => {
  state.user.profile.fetching = false;
  state.user.profile.fetched = false;
};

export const fetchUserProfileFulfilled = (state: SessionState, { payload }: { payload: IUserProfile }) => {
  const lastSelected = localStorage.getItem(LAST_SELECTED_BRANCH);
  const active = lastSelected || payload.hierarchy.branch.id;
  state.user.profile.fetching = false;
  state.user.profile.fetched = true;
  state.user.profile.data = payload;

  const role = payload.security.role;
  state.user.approval.approverViewEnabled = false;
  state.user.approval.tierFilters = {};
  if (role === Roles.APPROVER || role === Roles.ADMINISTRATOR) {
    const approvalLevel = Number(payload.approval.levelId.substr(1));
    if (!Number.isNaN(approvalLevel) && approvalLevel > 0) {
      state.user.approval.approverViewEnabled = true;
      if (approvalLevel === 1) {
        state.user.approval.tierFilters[ApprovalTiers.BRANCH] = true;
      } else if (approvalLevel < 5) {
        state.user.approval.tierFilters[ApprovalTiers.REGION] = true;
      } else {
        state.user.approval.tierFilters[ApprovalTiers.COMPANY] = true;
      }
    }
  }

  updateActiveBranch(state, active);
};

export const fetchUserBranchesPending = (state: SessionState) => {
  state.user.branches.fetching = true;
};

export const fetchUserBranchesRejected = (state: SessionState) => {
  state.user.branches.fetching = false;
  state.user.branches.fetched = false;
};

export const fetchUserBranchesFulfilled = (state: SessionState, { payload }: { payload: any }) => {
  state.user.branches.fetching = false;
  state.user.branches.fetched = true;
  state.user.branches.data = payload;
  updateActiveBranch(state, state.user.branches.active);
};

export const setActiveBranch = (state: SessionState, { payload }: PayloadAction<string>) => {
  updateActiveBranch(state, payload);
};

export const updateActiveBranch = (state: SessionState, branchId: string) => {
  state.user.branches.active = branchId;
  const branch = state.user.branches.data.find(({ id }) => id === branchId);
  if (branch) {
    state.user.branches.permissions.read = branch.permissions.read;
    state.user.branches.permissions.write = branch.permissions.write;
  }
};
