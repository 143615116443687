import axios from 'axios';
import { IAppConfiguration } from 'interfaces/AppConfiguration';

export class AppConfigurationService {
  private static readonly instance = new AppConfigurationService();

  private appConfigurationPromise: Promise<IAppConfiguration> | null = null;
  private _appConfiguration: IAppConfiguration | null = null;

  private constructor() {}

  public init(): Promise<IAppConfiguration> {
    if (!this.appConfigurationPromise) {
      this.appConfigurationPromise = axios.get('/config.json').then(
        (result) => {
          this._appConfiguration = result.data;
          return this.appConfiguration;
        },
        () => Promise.reject('App configuration could not be loaded.')
      );
    }
    return this.appConfigurationPromise;
  }

  get appConfiguration() {
    return this._appConfiguration!;
  }

  public static getInstance(): AppConfigurationService {
    return AppConfigurationService.instance;
  }
}
