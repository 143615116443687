import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { saveQuoteAction } from 'rdx';
import { ISaveChangesDialogProps } from './SaveChangesDialog.props';

export const SaveChangesDialog: React.FC<ISaveChangesDialogProps> = ({
  open,
  onCancel,
  onAccept,
}: ISaveChangesDialogProps) => {
  const dispatch: any = useDispatch();

  const handleSave = () => {
    dispatch(saveQuoteAction()).then(() => {
      onAccept();
    });
  };

  return (
    <Dialog open={open} keepMounted onClose={onCancel}>
      <DialogTitle className="text-white">Unsaved Changes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you proceed with this action your unsaved changes will be lost. Do you want to save your changes before?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="text">
          Discard
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
