export const QUANTITY_MASK = /^(?:0|[1-9]\d{0,6})$/;
export const MAX_QUANTITY = 9999999;

export const makeNonZeroQuantityMask = (maxLength: number) => new RegExp(`^[1-9]\\d{0,${maxLength - 1}}$`);

export const DURATION_MASK = makeNonZeroQuantityMask(4);
export const MAX_DURATION = 9999;

export const makeAnyCharactersMask = (length: number) => new RegExp(`^.{0,${length}}$`);

export const PRODUCT_CODE_MAX_LENGTH = 25;
export const PRODUCT_CODE_MASK = makeAnyCharactersMask(PRODUCT_CODE_MAX_LENGTH);

export const PRODUCT_COMMENTS_MASK = makeAnyCharactersMask(250);

export const PRODUCT_DESC_MAX_LENGTH = 255;
export const PRODUCT_DESC_MASK = makeAnyCharactersMask(PRODUCT_DESC_MAX_LENGTH);

export const formatPhoneNumber = (str: string | undefined | null) => {
  if (!str) {
    return '';
  }
  return `(${str.substring(0, 3)}) ${str.substring(3, 6)} - ${str.substring(6)}`;
};

export const PHONE_PATTERN_MASK = '(000) 000 - 0000';
