import { IServicesGridRow } from '../../../../interfaces';
import { ValidationGroupNames } from '../review-tab.types';
import { makeValidationField, ProductValidationFieldsByColId } from './validation';

const ServiceRowValidationCodes = {
  DRIVERS_TRUCKS_COUNT_NOT_MATCHING: '_5_0_0',
  MISSING_QUANTITY: '_5_0_1',
  MISSING_REGULAR: '_5_0_2',
  MISSING_REGULAR_OT_DT: '_5_0_3',
  MISSING_RATE: '_5_0_4',
  INVALID_LENGTH_DESCRIPTION: '_5_0_5',
  RATE_BELOW_MINIMUM: '_5_0_8',
};

const OptionalServiceRowValidationCodes = {
  DRIVERS_TRUCKS_COUNT_NOT_MATCHING: '_5_1_0',
  MISSING_QUANTITY: '_5_1_1',
  MISSING_REGULAR: '_5_1_2',
  MISSING_REGULAR_OT_DT: '_5_1_3',
  MISSING_RATE: '_5_1_4',
  INVALID_LENGTH_DESCRIPTION: '_5_1_5',
  RATE_BELOW_MINIMUM: '_5_1_8',
};

const makeFields = (
  validationGroup: ValidationGroupNames,
  codes: typeof ServiceRowValidationCodes
): ProductValidationFieldsByColId<IServicesGridRow> => {
  const makeField = (errorCodes: string[]) => makeValidationField(validationGroup, errorCodes);
  return {
    quantity: makeField([codes.MISSING_QUANTITY]),
    regularHours: makeField([codes.MISSING_REGULAR, codes.MISSING_REGULAR_OT_DT]),
    overtimeHours: makeField([codes.MISSING_REGULAR_OT_DT]),
    doubleTimeHours: makeField([codes.MISSING_REGULAR_OT_DT]),
    regularRate: makeField([codes.MISSING_RATE, codes.RATE_BELOW_MINIMUM]),
    description: makeField([codes.INVALID_LENGTH_DESCRIPTION]),
  };
};

export const ServiceRowValidationFieldsByColId = makeFields('services', ServiceRowValidationCodes);

export const OptionalServiceRowValidationFieldsByColId = makeFields(
  'optionalServices',
  OptionalServiceRowValidationCodes
);
