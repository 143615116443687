import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { QuoteFacadeContext } from '../../facade/AbstractQuoteFacade';
import { i18nKeys } from '../../internationalization/i18nKeys';
import { QuoteSelectors } from '../../rdx';
import { ComponentIds } from '../../utils/component-ids';
import { CloneSourceLink } from '../CloneSourceLink/CloneSourceLink';
import { QuoteDataContainer } from './QuoteData.style';

export const QuoteData: React.FC = () => {
  const { t } = useTranslation();
  const quote = useContext(QuoteFacadeContext);
  const accountName = useSelector(QuoteSelectors.accountName);
  const quoteNumber = useSelector(quote.selectors.quoteNumber);
  const clone = useSelector(quote.selectors.cloneData);

  return (
    <QuoteDataContainer>
      {!!accountName && (
        <span>
          {t(i18nKeys.tabBar.customerNameLabel)} <span style={{ userSelect: 'text' }}>{accountName}</span>
        </span>
      )}
      {!!quoteNumber && (
        <Box display="flex" alignItems="center">
          <CloneSourceLink clone={clone} mr={0.5} />
          <div>
            {t(i18nKeys.tabBar.quoteNumberLabel, { context: quote.type })}{' '}
            <span data-test={ComponentIds.QuoteNumber} style={{ userSelect: 'text' }}>
              {quoteNumber}
            </span>
          </div>
        </Box>
      )}
    </QuoteDataContainer>
  );
};
