import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

interface IHighlightCellRendererProps {
  highlight: string;
}

type Props = IHighlightCellRendererProps & ICellRendererParams;

export const HighlightCellRenderer: React.FC<Props> = (props: Props) => {
  let output = props.valueFormatted || props.value || '';
  (props.highlight || props.data.highlight || '')
    .split(' ')
    .forEach((word: string) => (output = output.replace(new RegExp(word, 'i'), `<b>$&</b>`)));
  return (
    <div
      style={{
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      dangerouslySetInnerHTML={{ __html: output }}
    />
  );
};
