import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { QuoteService } from 'api';
import { ITotalQuoteHealth } from 'interfaces/TotalQuoteHealth';
import { SessionState } from './session.types';

export const fetchQuoteHealthAction = createAsyncThunk(
  'session/fetchQuoteHealthAction',
  async (payload: { quoteId: string }): Promise<ITotalQuoteHealth> => {
    return await QuoteService.getInstance().getQuoteHealth(payload.quoteId);
  }
);

export const fetchChangeOrderHealthAction = createAsyncThunk(
  'session/fetchChangeOrderHealthAction',
  async (payload: { quoteId: string; changeOrderId: string }): Promise<ITotalQuoteHealth> => {
    return await QuoteService.getInstance().getChangeOrderHealth(payload.quoteId, payload.changeOrderId);
  }
);

export const fetchQuoteHealthPendingAction = (state: SessionState) => {
  state.tqh.status.fetching = true;
  state.tqh.status.fetched = false;
};

export const fetchQuoteHealthRejectAction = (state: SessionState) => {
  state.tqh.status.fetching = false;
  state.tqh.status.fetched = false;
};

export const fetchQuoteHealthFulfillAction = (state: SessionState, action: PayloadAction<ITotalQuoteHealth>) => {
  state.tqh.status.fetching = false;
  state.tqh.status.fetched = true;
  state.tqh.data = action.payload;
};
