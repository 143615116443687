export enum QuoteTabs {
  Customer = 'customer',
  Details = 'details',
  ScopeChanges = 'scopeChanges',
  Products = 'products',
  Optional = 'optional',
  Statement = 'statement',
  Documents = 'documents',
  Review = 'review',
  Approval = 'approval',
  ChangeOrder = 'changeOrder',
}

export type QuoteProductTabs = QuoteTabs.Products | QuoteTabs.Optional;

export const getOppositeProductsTab = (tab: QuoteProductTabs) =>
  tab === QuoteTabs.Products ? QuoteTabs.Optional : QuoteTabs.Products;

/**
 * Creates a function to check if a string is a member of the given string enum.
 * @param e Enum object
 */
const makeStringEnumTypeGuard = <T extends { [key: string]: string }>(e: T) => {
  const values = new Set(Object.values(e));
  return (value: string): value is T[keyof T] => values.has(value);
};

export const isQuoteTab = makeStringEnumTypeGuard(QuoteTabs);

export const isQuoteProductsTab = (tab: QuoteTabs): tab is QuoteProductTabs =>
  tab === QuoteTabs.Products || tab === QuoteTabs.Optional;
