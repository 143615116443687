import { createAsyncThunk } from '@reduxjs/toolkit';
import { QuoteInnerTabs, QuoteProductTabs, QuoteTabs } from 'enums';
import { GridRowLocation } from 'interfaces';
import { isNil } from 'lodash';
import { getProductsStateByTab } from 'rdx/utils';
import { round2Decimals } from 'utils';
import { enqueueNotificationAction } from '../../session';
import { AppThunkApiConfig, RootState } from '../../types';
import { addSalesRowsAction, deleteItemAction } from './products-tab.action-creators';
import { AddSalesProductParam, ProductsTabState } from './products-tab.types';
import {
  fetchSalesProfileAction,
  resetSalesMarginPercentAction,
  updateSalesMarginPercentAction,
} from './sales.action-creators';
import { createSalesRow, findSalesRow, recalculateSalesRow, resetSalesItemState } from './sales.helpers';

export const resetSalesProfile = (state: ProductsTabState, action: any) => {
  const { tab, rowId }: { tab: QuoteTabs; rowId: string } = action.payload;
  if (tab && rowId) {
    const productsState = getProductsStateByTab(tab, state);
    if (productsState) {
      const item = productsState.sales.rows.find((r) => r.rowId === rowId);
      if (item) {
        productsState.sales.status.touched = true;
        resetSalesItemState(item);
      }
    }
  }
};

export const addSalesItems = createAsyncThunk<
  boolean,
  {
    tab: QuoteProductTabs;
    atIndex: number | null;
    replaceRowId?: string;
    products: AddSalesProductParam[];
    transfer: boolean;
  },
  AppThunkApiConfig
>('quote/productsTab/addSalesItems', async (payload, { dispatch }) => {
  const { products, tab, transfer, atIndex, replaceRowId } = payload;
  if (Array.isArray(products)) {
    if (replaceRowId && !isNil(atIndex) && atIndex >= 0) {
      dispatch(
        deleteItemAction({
          tab,
          innerTab: QuoteInnerTabs.Sales,
          rowId: replaceRowId,
        })
      );
    }

    const newProductItems = products.map((p) => ({ ...createSalesRow(tab), ...p }));
    if (transfer) {
      for (let index = 0; index < newProductItems.length; index++) {
        const item = newProductItems[index];
        item.rentalProductTransfer = true;
        item.status.touched = true;
        item.status.fetched = true;
        item.profileFetched = true;
        item.pricedManually = true;
      }
    }
    dispatch(
      addSalesRowsAction({
        tab,
        atIndex,
        rows: newProductItems,
        moved: transfer,
      })
    );

    // Fetch profiles for new rows.
    if (!transfer) {
      const profilePromises = newProductItems
        .filter(({ code }) => code)
        .map(({ rowId, code }) => dispatch(fetchSalesProfileAction({ tab, rowId, code })).unwrap());
      const results = await Promise.allSettled(profilePromises);
      return results.every(({ status }) => status === 'fulfilled');
    }
  }
  return true;
});

export const salesRateAdjustment = createAsyncThunk(
  'quote/productsTab/salesRateAdjustment',
  async (payload: { percentage?: number; reset?: boolean }, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const tab = state.quoteScreen.tabs.active as QuoteProductTabs;
    const productsState = getProductsStateByTab(tab, state.quoteScreen.productsTab);
    const selected = productsState?.sales.selected || [];

    selected.forEach((rowId: string) => {
      if (payload.reset) {
        thunkAPI.dispatch(
          resetSalesMarginPercentAction({
            tab,
            rowId,
          })
        );
      } else if (!isNil(payload.percentage)) {
        if (payload.percentage < 100) {
          thunkAPI.dispatch(
            updateSalesMarginPercentAction({
              tab,
              rowId,
              value: payload.percentage,
            })
          );
        } else {
          const message = `Percentage can not be greater than 99.99%`;
          thunkAPI.dispatch(enqueueNotificationAction({ message, options: { variant: 'info' } }));
        }
      }
    });
  }
);

export const updateSalesMarginPercent = (
  state: ProductsTabState,
  { payload }: { payload: GridRowLocation & { value: number } }
) => {
  const row = findSalesRow(state, payload);
  if (row && !isNil(row.priceEach) && !isNil(row.originalCost)) {
    const newPercentage = payload.value;
    row.priceEach = round2Decimals(row.originalCost / (1 - newPercentage / 100));
    recalculateSalesRow(state, row, true);
  }
};

export const resetSalesMarginPercent = (state: ProductsTabState, { payload }: { payload: GridRowLocation }) => {
  const item = findSalesRow(state, payload);
  if (item && item.pricing) {
    const { retailPrice, originalCost } = item.pricing;
    if (retailPrice !== item.priceEach || originalCost !== item.originalCost) {
      item.priceEach = retailPrice;
      item.originalCost = originalCost;
      recalculateSalesRow(state, item, true);
    }
  }
};
