import { styled } from '@mui/material/styles';

export const PageMessageContainer = styled('div')`
  font-family: Roboto, sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;
