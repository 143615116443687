import { Popover, Typography } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { isNil, sum } from 'lodash';
import React from 'react';
import { currencyFormatter } from 'utils';
import { IRentalGridRow } from '../../interfaces';

export const FeeBreakdownCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const { erFee, aqFee } = props.value as IRentalGridRow;
  const isValid = !(isNil(erFee) && isNil(aqFee));
  const total = sum([erFee, aqFee, 0]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // Only shows the popover if one of the fees is applicable
    if (isValid && total > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className="text-wrap-ellipsis" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {isValid && currencyFormatter(total)}
      </div>
      {isValid && total > 0 && open && (
        <Popover
          sx={{ pointerEvents: 'none' }}
          PaperProps={{ sx: { p: 1 } }}
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {!!erFee && (
            <Typography className={!!aqFee ? 'mb-2' : ''}>
              ERF: <strong>{currencyFormatter(erFee)}</strong>
            </Typography>
          )}
          {!!aqFee && (
            <Typography>
              AQF: <strong>{currencyFormatter(aqFee)}</strong>
            </Typography>
          )}
        </Popover>
      )}
    </div>
  );
};
