import { IApiEnvelope, IService, IServiceProfile } from 'interfaces';
import { IServicePhase } from 'interfaces/ServicesPhase';
import { ConfigurableRequest } from './configurable-request';

export class ServicesService extends ConfigurableRequest {
  private static instance: ServicesService;

  private constructor() {
    super('services');
  }

  public async getPhases(): Promise<IServicePhase[]> {
    const result: IApiEnvelope<IServicePhase> = await this.get('/phases');
    return result.data;
  }

  public async getPhaseServices(phaseId: string, branchId: string): Promise<IService[]> {
    const queryParams: any = { branchId };
    const result: IApiEnvelope<IService> = await this.get(`/phases/${phaseId}/services`, queryParams);
    return result.data;
  }

  public async getServiceProfile(phaseId: string, serviceId: string, branchId: string, accountId: string) {
    const queryParams: any = { branchId, accountId };
    const result: IApiEnvelope<IServiceProfile> = await this.get(
      `/phases/${phaseId}/services/${serviceId}/profile`,
      queryParams
    );
    const [data] = result.data;
    return data;
  }

  public static getInstance(): ServicesService {
    if (!ServicesService.instance) {
      ServicesService.instance = new ServicesService();
    }
    return ServicesService.instance;
  }
}
