import { ApprovalProfile, ApprovalTiers } from '../approval/types';
import { AppConfigurationService } from './app-configuration.service';
import { ConfigurableRequest } from './configurable-request';

export class ApprovalService extends ConfigurableRequest {
  private static instance: ApprovalService;

  private constructor() {
    super('quotes');
    this.baseUrl = AppConfigurationService.getInstance().appConfiguration.approvalURL;
  }

  public async getApprovalProfile(quoteId: string): Promise<ApprovalProfile> {
    return (await this.get(`/${quoteId}/approval/profile`)).data[0];
  }

  public async getChangeOrderApprovalProfile(quoteId: string, changeOrderId: string): Promise<ApprovalProfile> {
    return (await this.get(`/${quoteId}/changeOrders/${changeOrderId}/approval/profile`)).data[0];
  }

  public async approve(quoteId: string, tierId: ApprovalTiers, allTiers: boolean): Promise<void> {
    await this.post(`/${quoteId}/approval/approve`, {}, {}, {}, { tierId, allTiers });
  }

  public async approveChangeOrder(
    quoteId: string,
    changeOrderId: string,
    tierId: ApprovalTiers,
    allTiers: boolean
  ): Promise<void> {
    await this.post(`/${quoteId}/changeOrders/${changeOrderId}/approval/approve`, {}, {}, {}, { tierId, allTiers });
  }

  public async reject(quoteId: string, tierId: ApprovalTiers, reason: string): Promise<void> {
    await this.post(`/${quoteId}/approval/reject`, {}, {}, {}, { tierId, reason });
  }

  public async rejectChangeOrder(
    quoteId: string,
    changeOrderId: string,
    tierId: ApprovalTiers,
    reason: string
  ): Promise<void> {
    await this.post(`/${quoteId}/changeOrders/${changeOrderId}/approval/reject`, {}, {}, {}, { tierId, reason });
  }

  public static getInstance(): ApprovalService {
    if (!ApprovalService.instance) {
      ApprovalService.instance = new ApprovalService();
    }
    return ApprovalService.instance;
  }
}
