import { CrudActions, QuoteProductTabs } from 'enums';
import { GridRowLocation, IRateLevel, ISalesGridRow, ISalesProductProfile, WorkflowStates } from 'interfaces';
import { isNil } from 'lodash';
import { rgbaFormatter, round2Decimals } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { resetProductRowState, updateDescription } from './products-tab.helpers';
import { ProductsTabState, SalesRowUpdate } from './products-tab.types';

const negativeSalesMarginColor = rgbaFormatter([244, 67, 54, 1]);

export const findSalesRow = (state: ProductsTabState, location: GridRowLocation) =>
  state[location.tab].sales.rows.find((row) => row.rowId === location.rowId);

export function updateSalesRowProp<T extends keyof ISalesGridRow>(
  state: ProductsTabState,
  update: SalesRowUpdate<T>,
  recalculate: boolean
) {
  const row = findSalesRow(state, update.location);
  if (row) {
    row[update.prop] = update.value;
    if (recalculate) {
      recalculateSalesRow(state, row, true);
    } else {
      touchSalesRow(state, row);
    }
  }
}

export const touchSalesRow = (state: ProductsTabState, row: ISalesGridRow) => {
  row.status.touched = true;
  if (row.status.existingItem) {
    row.status.action = CrudActions.UPDATE;
  }
  state[row.tab].sales.status.touched = true;
};

export const initLoadedSalesRow = (state: ProductsTabState, row: ISalesGridRow) => {
  // Use global Units config until we get product profile.
  row.units = state.salesProductUnits;

  const { quantity, priceEach, originalCost } = row;

  if (isNil(quantity) || isNil(priceEach)) {
    row.extension = undefined;
  }

  if (isNil(quantity) || isNil(priceEach) || isNil(originalCost)) {
    row.marginDollars = undefined;
  }

  if (isNil(priceEach)) {
    row.marginPercentage = undefined;
  }

  updateMarginColor(row);
};

const updateMarginColor = (row: ISalesGridRow) => {
  if (!isNil(row.marginPercentage) && row.marginPercentage < 0) {
    row.marginColor = negativeSalesMarginColor;
  } else {
    row.marginColor = undefined;
  }
};

/**
 * Update all derived properties when something about the row changes.
 */
export const recalculateSalesRow = (state: ProductsTabState, row: ISalesGridRow, touched: boolean) => {
  if (row.profileFetched && state.quoteStateId === WorkflowStates.OPEN) {
    const { quantity, priceEach, originalCost } = row;

    const redlineLevel = findRedlineLevel(row);
    let redlineLevelId = redlineLevel?.id;
    if (redlineLevelId !== row.redlineLevelId) {
      row.redlineLevelId = redlineLevelId;
      touched = true;
    }
    if (updateRedlineLevelColor(row, redlineLevel)) {
      touched = true;
    }

    let extension;
    if (!isNil(priceEach) && !isNil(quantity)) {
      extension = round2Decimals(priceEach * quantity);
    }
    if (extension !== row.extension) {
      row.extension = extension;
      touched = true;
    }

    let marginPercentage;
    if (!isNil(priceEach)) {
      marginPercentage = priceEach === 0 ? 0 : round2Decimals(((priceEach - (originalCost || 0)) / priceEach) * 100);
      if (marginPercentage >= 100) {
        marginPercentage = 99.99;
      }
    }
    if (marginPercentage !== row.marginPercentage) {
      row.marginPercentage = marginPercentage;
      updateMarginColor(row);
      touched = true;
    }

    let marginDollars;
    if (!(isNil(quantity) || isNil(priceEach) || isNil(originalCost))) {
      marginDollars = round2Decimals(quantity * (priceEach - originalCost));
    }
    if (marginDollars !== row.marginDollars) {
      row.marginDollars = marginDollars;
      touched = true;
    }

    if (touched) {
      touchSalesRow(state, row);
    }
  }
};

const findRedlineLevel = (row: ISalesGridRow) => {
  const { priceEach, pricedManually, colors } = row;
  if (!pricedManually && !isNil(priceEach) && colors?.length) {
    return colors.find((level) => priceEach >= level.minimum && priceEach <= level.maximum);
  }
};

const updateRedlineLevelColor = (row: ISalesGridRow, redlineLevel: IRateLevel | undefined) => {
  let redlineLevelColor;
  const rgba = redlineLevel?.color.code.rgba;
  if (rgba) {
    redlineLevelColor = rgbaFormatter(rgba);
  }
  if (redlineLevelColor !== row.redlineLevelColor) {
    row.redlineLevelColor = redlineLevelColor;
    return true;
  }
  return false;
};

export const createSalesRow = (tab: QuoteProductTabs): ISalesGridRow => ({
  profileFetched: false,
  pricedManually: false,
  tab,
  rowId: uuidv4(),
  code: '',
  unitId: 'EACH',
  status: {
    existingItem: false,
    action: CrudActions.CREATE,
    touched: false,
    fetched: false,
    fetching: false,
    error: false,
  },
});

export const updateSalesRowFromProfile = (
  state: ProductsTabState,
  item: ISalesGridRow,
  profile: ISalesProductProfile
) => {
  const isDraftQuote = state.quoteStateId === WorkflowStates.OPEN;

  const product = profile.body.entity;
  const { pricing, units, override, descriptionEditable, redline } = profile.body.custom;

  item.status.fetched = true;
  item.status.fetching = false;
  item.status.error = false;
  item.profileFetched = true;

  item.code = product.code;
  item.colors = redline?.levels;

  if (isDraftQuote) {
    let updated = false;

    item.salesProductId = product.id;
    item.pricing = pricing;
    item.product = product;
    item.units = units;

    if (updateDescription(item, product.description, descriptionEditable)) {
      updated = true;
    }

    const pricedManually = override ?? false;
    if (item.pricedManually !== pricedManually) {
      item.pricedManually = pricedManually;
      updated = true;
    }

    const { retailPrice, originalCost } = pricing;
    if (isNil(item.priceEach) && !isNil(retailPrice)) {
      item.priceEach = retailPrice;
      updated = true;
    }

    if (isNil(item.originalCost) && !isNil(originalCost)) {
      item.originalCost = originalCost;
      updated = true;
    }

    delete item.rentalProduct;
    delete item.rentalProductTransfer;

    recalculateSalesRow(state, item, updated);
  } else {
    updateRedlineLevelColor(item, findRedlineLevel(item));
  }
};

export const resetSalesItemState = (item: ISalesGridRow) => {
  resetProductRowState(item);
  item.profileFetched = false;
  item.salesProductId = '';
  item.pricing = undefined;
  item.priceEach = undefined;
  item.originalCost = undefined;
  item.marginDollars = undefined;
  item.marginPercentage = undefined;
  item.marginColor = undefined;
  delete item.rentalProduct;
  delete item.rentalProductTransfer;
};
