import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { QuoteService } from '../../api';
import { QuoteGroups } from '../../enums';
import { IFetchQuoteResult, IQuote } from '../../interfaces';
import { clearClientSideValidationErrors } from '../quote-screen/review-tab/actions';
import { RootState } from '../types';

export const fetchQuoteAction = createAsyncThunk<
  IFetchQuoteResult,
  { quoteId: string; groups: QuoteGroups[]; reset?: boolean }
>('session/fetchQuoteAction', async (payload, thunkAPI) => {
  if (payload.reset) {
    // Clear client side validation errors on cancel.
    thunkAPI.dispatch(clearClientSideValidationErrors());

    const state = thunkAPI.getState() as RootState;
    // Gets a copy from the quote stored in the store and returns it
    const quote = cloneDeep(state.session.quote.data) as IQuote;
    return { quote, groups: payload.groups, reset: true };
  } else {
    try {
      const response = await QuoteService.getInstance().getQuoteGroups(payload.quoteId, payload.groups);
      return {
        quote: response,
        groups: payload.groups,
        reset: false,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
});
