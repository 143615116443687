import { MenuItem, Select, SelectProps } from '@mui/material';
import React from 'react';

export interface DropDownOption {
  id: string | number;
  text: string | number;
}

interface DropDownProps extends SelectProps {
  options: DropDownOption[];
}

export const DropDown: React.FC<DropDownProps> = ({ options, ...selectProps }) => {
  return (
    <Select
      {...selectProps}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {options.map((option: DropDownOption, idx: number) => (
        <MenuItem key={idx} value={option.id}>
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};
