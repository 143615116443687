import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IValidationResponse, IValidationResponseSection, QuotePrintOptions } from '../../../interfaces';
import { RoutePaths } from '../../../routes/routes';
import { fetchQuoteAction } from '../../session/quote.action-creators';
import { clearClientSideValidationErrors, setClientSideValidationErrors } from './actions';
import { setQuotePrintOptions, updateReviewValuesFromQuote } from './review-tab.reducers';
import { makeValidationItemKey } from './review-tab.slice.helpers';
import {
  ErrorCodeSet,
  ReviewTabState,
  ScheduleValidationTargetGroups,
  ValidationGroupNames,
  ValidationGroups,
  ValidationGroupWithLookup,
  ValidationItem,
  ValidationState,
} from './review-tab.types';
import { ValidationField } from './validation/validation';

const createValidationGroup = (
  name: string,
  isProductGroup: boolean,
  routePath?: string
): ValidationGroupWithLookup => ({
  isProductGroup,
  name,
  routePath,
  items: [],
  errorCodes: {},
  errorsByRowId: {},
});

const createClientSideValidationGroup = () => createValidationGroup('Required Fields', false, 'customer');

const createValidationGroups = (): ValidationGroups => ({
  // Todo: Group names i18n
  clientSide: createClientSideValidationGroup(),
  general: createValidationGroup('General', false),
  customer: createValidationGroup('Customer', false, 'customer'),
  details: createValidationGroup('Details', false, 'details'),
  scopeChanges: createValidationGroup('Scope Changes', false, RoutePaths.ScopeChanges),
  rental: createValidationGroup('Rental', true, 'products/rental'),
  optionalRental: createValidationGroup('Optional Rental', true, 'optional/rental'),
  sales: createValidationGroup('Sales', true, 'products/sales'),
  optionalSales: createValidationGroup('Optional Sales', true, 'optional/sales'),
  services: createValidationGroup('Services', true, 'products/services'),
  optionalServices: createValidationGroup('Optional Services', true, 'optional/services'),
  statement: createValidationGroup('Statement of Work', false, 'statement'),
});

const validationInitialState: ValidationState = {
  valid: true,
  requestFailed: false,
  errorCount: 0,
  groups: createValidationGroups(),
};

const initialState: ReviewTabState = {
  status: {
    fetching: false,
    fetched: false,
  },
  errorsDrawer: { show: false, pinned: false },
  quotePrintOptions: {
    printOption: QuotePrintOptions.DETAIL,
    summaryDescription: '',
  },
  validation: validationInitialState,
};

const addValidationItems = (
  state: ReviewTabState,
  group: ValidationGroupWithLookup,
  responseSection?: IValidationResponseSection
) => {
  const errors = responseSection?.errors;
  if (errors && errors.length) {
    errors.forEach((error) => {
      const { code, detailId } = error;
      const item: ValidationItem = {
        ...error,
        detailId: group.isProductGroup ? detailId : undefined,
        key: makeValidationItemKey(error),
      };
      group.items.push(item);
      if (group.isProductGroup) {
        if (detailId) {
          if (!group.errorsByRowId[detailId]) {
            group.errorsByRowId[detailId] = {};
          }
          group.errorsByRowId[detailId][code] = true;
        }
      } else {
        group.errorCodes[code] = true;
      }
    });
  }
};

const removeErrorsFromValidationGroup = (
  state: ReviewTabState,
  group: ValidationGroupWithLookup,
  errorCodeSet: ErrorCodeSet,
  field: ValidationField,
  detailId: string | undefined
) => {
  const groupErrorCount = group.items.length;
  field.errorCodes.forEach((code) => {
    if (errorCodeSet[code]) {
      delete errorCodeSet[code];
      group.items = group.items.filter((item) => !(item.code === code && item.detailId === detailId));
    }
  });

  // Reduce error count and update valid flag based on new total error count.
  state.validation.errorCount -= groupErrorCount - group.items.length;
  state.validation.valid = state.validation.errorCount === 0;
};

const setValidationErrorCount = (state: ReviewTabState, errorCount: number, toggleOpen = true) => {
  state.validation.errorCount = errorCount;
  const valid = errorCount === 0;
  state.validation.valid = valid;
  if (valid) {
    // Always close the drawer if error count is down to zero.
    state.errorsDrawer.show = false;
  } else if (toggleOpen) {
    // Open the drawer if there are errors, but only if toggleOpen is true.
    state.errorsDrawer.show = true;
  }
  return valid;
};

export const reviewTabSlice = createSlice({
  name: 'quote/reviewTab',
  initialState,
  reducers: {
    reset: (state: ReviewTabState) => {
      state.validation = validationInitialState;
    },
    setQuotePrintOptions,
    markCompletePending(state: ReviewTabState) {
      state.status.fetching = true;
    },
    markCompleteRejected(state: ReviewTabState) {
      state.status.fetching = false;
      state.status.fetched = true;
    },
    markCompleteFulfilled(state: ReviewTabState) {
      state.status.fetching = false;
      state.status.fetched = true;
    },
    validateQuotePending(state: ReviewTabState) {
      state.status.fetching = true;
      state.validation = validationInitialState;
    },
    validationFailed(
      state: ReviewTabState,
      action: PayloadAction<{
        response: IValidationResponse | undefined;
        scheduleValidationTargetGroup: ScheduleValidationTargetGroups;
      }>
    ) {
      const { response, scheduleValidationTargetGroup } = action.payload;

      state.status.fetching = false;
      state.status.fetched = true;

      const valid = setValidationErrorCount(state, response?.errors?.length || 0);

      // Rebuild validation groups for display in errors drawer and for error lookup.
      state.validation.groups = createValidationGroups();
      if (response && !valid) {
        const { groups } = state.validation;
        addValidationItems(state, groups.general, response.general);

        addValidationItems(state, groups.customer, response.jobsite);
        addValidationItems(state, groups.scopeChanges, response.scopeChange);
        addValidationItems(state, groups.details, response.application);
        addValidationItems(state, groups[scheduleValidationTargetGroup], response.schedule);

        addValidationItems(state, groups.rental, response.rental);
        addValidationItems(state, groups.optionalRental, response.optionalRental);
        addValidationItems(state, groups.sales, response.sales);
        addValidationItems(state, groups.optionalSales, response.optionalSales);
        addValidationItems(state, groups.services, response.services);
        addValidationItems(state, groups.optionalServices, response.optionalServices);

        addValidationItems(state, groups.statement, response.statement);
      }
    },
    validationRequestFailed(state: ReviewTabState) {
      state.status.fetching = false;
      state.status.fetched = true;
      state.validation.valid = true;
      state.validation.requestFailed = true;
      state.validation.groups = createValidationGroups();
    },
    validateQuoteFulfilled(state: ReviewTabState) {
      state.status.fetching = false;
      state.status.fetched = true;
      state.validation.valid = true;
      state.validation.groups = createValidationGroups();
    },
    /**
     * Dispatched when user edits a field. Clears all error codes associated with that field.
     * This removes error styling from the field and also removes those errors from errors drawer.
     */
    clearErrorsForValidationField(state: ReviewTabState, { payload: field }: PayloadAction<ValidationField>) {
      const group = state.validation.groups[field.validationGroup];
      removeErrorsFromValidationGroup(state, group, group.errorCodes, field, undefined);
    },
    clearErrorsForProductValidationField(
      state: ReviewTabState,
      action: PayloadAction<{ rowIds: string[]; field: ValidationField }>
    ) {
      const { rowIds, field } = action.payload;
      const group = state.validation.groups[field.validationGroup];
      for (let rowId of rowIds) {
        const rowErrors = group.errorsByRowId[rowId];
        if (rowErrors) {
          removeErrorsFromValidationGroup(state, group, rowErrors, field, rowId);
        }
      }
    },
    clearAllValidationErrorsForProduct(
      state: ReviewTabState,
      action: PayloadAction<{ rowId: string; groupName: ValidationGroupNames }>
    ) {
      const { rowId, groupName } = action.payload;
      const group = state.validation.groups[groupName];
      const rowErrors = group.errorsByRowId[rowId];
      if (rowErrors) {
        delete group.errorsByRowId[rowId];
        const groupErrorCount = group.items.length;
        group.items = group.items.filter((item) => !(item.detailId === rowId));
        state.validation.errorCount -= groupErrorCount - group.items.length;
        state.validation.valid = state.validation.errorCount === 0;
      }
    },
    toggleErrorsDrawer: (state: ReviewTabState, action: { payload: boolean | undefined }) => {
      state.errorsDrawer.show = action.payload !== undefined ? action.payload : !state.errorsDrawer.show;
    },
    setErrorsDrawerPinned: (state: ReviewTabState, action: { payload: boolean }) => {
      state.errorsDrawer.pinned = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuoteAction.fulfilled, updateReviewValuesFromQuote);

    builder.addCase(setClientSideValidationErrors, (state, { payload }) => {
      const prevErrorCount = state.validation.groups.clientSide.items.length;
      state.validation.groups.clientSide = createClientSideValidationGroup();
      addValidationItems(state, state.validation.groups.clientSide, {
        errors: payload,
        valid: payload.length > 0,
      });
      setValidationErrorCount(state, state.validation.errorCount - prevErrorCount + payload.length);
    });

    builder.addCase(clearClientSideValidationErrors, (state) => {
      const prevErrorCount = state.validation.groups.clientSide.items.length;
      state.validation.groups.clientSide = createClientSideValidationGroup();
      setValidationErrorCount(state, state.validation.errorCount - prevErrorCount, false);
    });
  },
});
