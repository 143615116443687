import { PayloadAction } from '@reduxjs/toolkit';
import { convertToRentalGridRow, convertToSalesGridRow, convertToServicesGrid } from 'converters';
import { CrudActions, QuoteGroups, QuoteInnerTabs, QuoteProductTabs, QuoteTabs } from 'enums';
import {
  IFetchQuoteResult,
  IProductGridRow,
  IQuoteFees,
  IRentalDetailEventBody,
  IRentalGridRow,
  ISalesDetailEventBody,
  ISaveQuoteResult,
  IServicesDetailEventBody,
  IServicesGrid,
  IServicesGridRow,
} from 'interfaces';
import { findLastIndex } from 'lodash';
import { IFetchChangeOrderGroupsResult, ISaveChangeOrderResult } from '../../change-order/actions';
import { ChangeOrderGroups } from '../../change-order/types';
import { FetchStatusState, UiStatusState } from '../../types';
import { getProductsStateByTab } from '../../utils';
import { updateNotesStatusAction } from './notes.actions';
import { AddProductRowsPayload, AddServiceRowsPayload, DeleteItemPayload } from './products-tab.action-creators';
import {
  GridRef,
  GridSelectionState,
  ProductGroupState,
  ProductsTabState,
  ReratingStates,
  ServicesState,
} from './products-tab.types';
import {
  BulkRentalDurationUpdateInternalPayload,
  BulkRentalPeriodUpdateInternalPayload,
  BulkRentalQuantityUpdateInternalPayload,
} from './rental.action-creators';
import { initLoadedRentalRow, recalculateRentalRow, recalculateRentalRowFees, touchRentalRow } from './rental.helpers';
import { initLoadedSalesRow } from './sales.helpers';
import { findServiceRow, touchServicesGrid, updateServicesDriverAndTruckTotals } from './services.helpers';

export const afterFetchQuoteProductsPendingAction = (state: ProductsTabState, action: any) => {
  const payload: IFetchQuoteResult = action.meta.arg;
  const productsState = getProductsStateByTab(QuoteTabs.Products, state);
  const optionalState = getProductsStateByTab(QuoteTabs.Optional, state);

  if (payload.groups.indexOf(QuoteGroups.OPTIONAL_NOTES) > -1) {
    state.optional.notes.status.fetching = true;
  }

  if (productsState) {
    if (payload.groups.indexOf(QuoteGroups.RENTAL) > -1) {
      if (!payload.reset) {
        productsState.rental.status.fetched = false;
      }
      productsState.rental.status.fetching = true;
    }
    if (payload.groups.indexOf(QuoteGroups.SALES) > -1) {
      productsState.sales.status.fetching = true;
    }
    if (payload.groups.indexOf(QuoteGroups.SERVICES) > -1) {
      productsState.services.status.fetching = true;
    }
  }
  if (optionalState) {
    if (payload.groups.indexOf(QuoteGroups.OPTIONAL_RENTAL) > -1) {
      if (!payload.reset) {
        optionalState.rental.status.fetched = false;
      }
      optionalState.rental.status.fetching = true;
    }
    if (payload.groups.indexOf(QuoteGroups.OPTIONAL_SALES) > -1) {
      optionalState.sales.status.fetching = true;
    }
    if (payload.groups.indexOf(QuoteGroups.OPTIONAL_SERVICES) > -1) {
      optionalState.services.status.fetching = true;
    }
  }
};

export const afterFetchQuoteProductsRejectAction = (state: ProductsTabState, action: any) => {
  const payload: IFetchQuoteResult = action.meta.arg;
  const productsState = getProductsStateByTab(QuoteTabs.Products, state);
  const optionalState = getProductsStateByTab(QuoteTabs.Products, state);

  if (payload.groups.indexOf(QuoteGroups.OPTIONAL_NOTES) > -1) {
    state.optional.notes.status.fetching = false;
    state.optional.notes.status.fetched = false;
  }

  if (productsState) {
    if (payload.groups.indexOf(QuoteGroups.RENTAL) > -1) {
      productsState.rental.status.fetching = false;
      productsState.rental.status.fetched = false;
    }
    if (payload.groups.indexOf(QuoteGroups.SALES) > -1) {
      productsState.sales.status.fetching = false;
      productsState.sales.status.fetched = false;
    }
    if (payload.groups.indexOf(QuoteGroups.SERVICES) > -1) {
      productsState.services.status.fetching = false;
      productsState.services.status.fetched = false;
    }
  }
  if (optionalState) {
    if (payload.groups.indexOf(QuoteGroups.OPTIONAL_RENTAL) > -1) {
      optionalState.rental.status.fetching = false;
      optionalState.rental.status.fetched = false;
    }
    if (payload.groups.indexOf(QuoteGroups.OPTIONAL_SALES) > -1) {
      optionalState.sales.status.fetching = false;
      optionalState.sales.status.fetched = false;
    }
    if (payload.groups.indexOf(QuoteGroups.OPTIONAL_SERVICES) > -1) {
      optionalState.services.status.fetching = false;
      optionalState.services.status.fetched = false;
    }
  }
};

function updateGroupStatusFromQuote(status: UiStatusState & FetchStatusState, reset: boolean) {
  if (reset) {
    status.touched = false;
    status.valid = true;
  } else {
    status.fetched = true;
  }
  status.fetching = false;
}

function updateRentalProductsFromQuote(
  state: ProductsTabState,
  tab: QuoteProductTabs,
  reset: boolean,
  products?: IRentalDetailEventBody[]
) {
  const { rental } = state[tab];
  updateGroupStatusFromQuote(rental.status, reset);
  rental.rows = products?.map((event) => convertToRentalGridRow(event, tab)) || [];
  for (let row of rental.rows) {
    initLoadedRentalRow(state, row);
  }
}

function updateSalesProductsFromQuote(
  state: ProductsTabState,
  tab: QuoteProductTabs,
  reset: boolean,
  products?: ISalesDetailEventBody[]
) {
  const { sales } = state[tab];
  updateGroupStatusFromQuote(sales.status, reset);
  sales.rows = products?.map((event) => convertToSalesGridRow(event, tab)) || [];
  for (let row of sales.rows) {
    initLoadedSalesRow(state, row);
  }
}

function updateServicesFromQuote(
  services: ServicesState,
  tab: QuoteProductTabs,
  reset: boolean,
  serviceEvents?: IServicesDetailEventBody[]
) {
  updateGroupStatusFromQuote(services.status, reset);
  services.grids = serviceEvents?.map((event) => convertToServicesGrid(event, tab)) || [];
}

/**
 * When quote is reset, we rerate again to reapply profiles to products and services.
 * In case we previously failed to save rerating changes, this will also trigger the
 * rerating UI flow or autosave.
 */
const updateReratingState = (state: ProductsTabState, resetting: boolean) => {
  if (resetting) {
    state.reratingState = ReratingStates.NotRerated;
  }
};

const updateFeesFromQuote = (state: ProductsTabState, fees: IQuoteFees) => {
  const multiplier = fees.fuelSurchargeMultiplier || 0;
  state.feesStatus.touched = false;
  state.fscMultiplier = multiplier;
  state.fscPercentage = multiplier * 100;
  state.defaultFscMultiplier = fees.defaultFuelSurchargeMultiplier;
  state.applyEnvironmentalRecovery = fees.applyEnvironmentalRecovery;
  state.waiveEnvironmentalRecovery = fees.canWaiveEnvironmentalRecovery && fees.waiveEnvironmentalRecovery;
  state.applyAirQuality = fees.applyAirQuality;
  state.waiveAirQuality = fees.canWaiveAirQuality && fees.waiveAirQuality;
};

export const afterFetchQuoteProductsFulfillAction = (
  state: ProductsTabState,
  action: PayloadAction<IFetchQuoteResult>
) => {
  const { groups, reset, quote } = action.payload;

  updateReratingState(state, reset);

  // Fees must be updated before other groups because they are used to recalculate rows and totals.
  if (groups.indexOf(QuoteGroups.FEES) > -1) {
    updateFeesFromQuote(state, quote.header.fees);
  }

  if (groups.indexOf(QuoteGroups.OPTIONAL_NOTES) > -1) {
    const { notes } = state.optional;
    updateGroupStatusFromQuote(notes.status, reset);
    notes.content = quote.detail?.optionalNotes?.content || '';
  }

  const { detail } = quote;
  const productsState = getProductsStateByTab(QuoteTabs.Products, state);
  if (productsState) {
    if (groups.indexOf(QuoteGroups.RENTAL) > -1) {
      updateRentalProductsFromQuote(state, QuoteTabs.Products, reset, detail?.rentalProducts);
    }
    if (groups.indexOf(QuoteGroups.SALES) > -1) {
      updateSalesProductsFromQuote(state, QuoteTabs.Products, reset, detail?.salesProducts);
    }
    if (groups.indexOf(QuoteGroups.SERVICES) > -1) {
      updateServicesFromQuote(productsState.services, QuoteTabs.Products, reset, detail?.services);
    }
  }

  const optionalState = getProductsStateByTab(QuoteTabs.Optional, state);
  if (optionalState) {
    if (groups.indexOf(QuoteGroups.OPTIONAL_RENTAL) > -1) {
      updateRentalProductsFromQuote(state, QuoteTabs.Optional, reset, detail?.optionalRentalProducts);
    }
    if (groups.indexOf(QuoteGroups.OPTIONAL_SALES) > -1) {
      updateSalesProductsFromQuote(state, QuoteTabs.Optional, reset, detail?.optionalSalesProducts);
    }
    if (groups.indexOf(QuoteGroups.OPTIONAL_SERVICES) > -1) {
      updateServicesFromQuote(optionalState.services, QuoteTabs.Optional, reset, detail?.optionalServices);
    }
  }
};

export const fetchChangeOrderGroupsFulfilled = (
  state: ProductsTabState,
  action: PayloadAction<IFetchChangeOrderGroupsResult>
) => {
  const { reset, changeOrder, groups } = action.payload;
  const { detail } = changeOrder;

  updateReratingState(state, reset);

  if (groups.indexOf(ChangeOrderGroups.FEES) > -1) {
    const fees = changeOrder.header?.fees;
    if (fees) {
      updateFeesFromQuote(state, fees);
    }
  }
  const productsState = state.products;
  if (groups.indexOf(ChangeOrderGroups.RENTAL) > -1) {
    updateRentalProductsFromQuote(state, QuoteTabs.Products, reset, detail?.rentalProducts);
  }
  if (groups.indexOf(ChangeOrderGroups.SALES) > -1) {
    updateSalesProductsFromQuote(state, QuoteTabs.Products, reset, detail?.salesProducts);
  }
  if (groups.indexOf(ChangeOrderGroups.SERVICES) > -1) {
    updateServicesFromQuote(productsState.services, QuoteTabs.Products, reset, detail?.services);
  }
};

export const afterSaveQuoteProductsFulfillAction = (
  state: ProductsTabState,
  action: PayloadAction<ISaveQuoteResult>
) => {
  const { groups, quote } = action.payload;

  state.feesStatus.touched = false;
  updateProductsTabStatusAfterSave(state, QuoteTabs.Products);
  updateProductsTabStatusAfterSave(state, QuoteTabs.Optional);
  updateNotesStatusAction(state, { payload: { touched: false, valid: true } });

  if (groups.indexOf(QuoteGroups.RENTAL) > -1) {
    syncItemsAfterSave(state, QuoteTabs.Products, QuoteInnerTabs.Rental, quote.detail?.rentalProducts);
  }
  if (groups.indexOf(QuoteGroups.SALES) > -1) {
    syncItemsAfterSave(state, QuoteTabs.Products, QuoteInnerTabs.Sales, quote.detail?.salesProducts);
  }
  if (groups.indexOf(QuoteGroups.SERVICES) > -1) {
    syncItemsAfterSave(state, QuoteTabs.Products, QuoteInnerTabs.Services, quote.detail?.services);
  }

  if (groups.indexOf(QuoteGroups.OPTIONAL_RENTAL) > -1) {
    syncItemsAfterSave(state, QuoteTabs.Optional, QuoteInnerTabs.Rental, quote.detail?.optionalRentalProducts);
  }
  if (groups.indexOf(QuoteGroups.OPTIONAL_SALES) > -1) {
    syncItemsAfterSave(state, QuoteTabs.Optional, QuoteInnerTabs.Sales, quote.detail?.optionalSalesProducts);
  }
  if (groups.indexOf(QuoteGroups.OPTIONAL_SERVICES) > -1) {
    syncItemsAfterSave(state, QuoteTabs.Optional, QuoteInnerTabs.Services, quote.detail?.optionalServices);
  }
};

export const saveChangeOrderGroupsFulfilled = (
  state: ProductsTabState,
  action: PayloadAction<ISaveChangeOrderResult>
) => {
  const { changeOrder, groups } = action.payload;
  state.feesStatus.touched = false;
  updateProductsTabStatusAfterSave(state, QuoteTabs.Products);
  if (groups.indexOf(ChangeOrderGroups.RENTAL) > -1) {
    syncItemsAfterSave(state, QuoteTabs.Products, QuoteInnerTabs.Rental, changeOrder.detail?.rentalProducts);
  }
  if (groups.indexOf(ChangeOrderGroups.SALES) > -1) {
    syncItemsAfterSave(state, QuoteTabs.Products, QuoteInnerTabs.Sales, changeOrder.detail?.salesProducts);
  }
  if (groups.indexOf(ChangeOrderGroups.SERVICES) > -1) {
    syncItemsAfterSave(state, QuoteTabs.Products, QuoteInnerTabs.Services, changeOrder.detail?.services);
  }
};

const updateProductsTabStatusAfterSave = (state: ProductsTabState, tab: QuoteTabs) => {
  const productsState = getProductsStateByTab(tab, state);
  if (productsState) {
    productsState.rental.status.valid = true;
    productsState.rental.status.touched = false;
    productsState.sales.status.valid = true;
    productsState.sales.status.touched = false;
    productsState.services.status.valid = true;
    productsState.services.status.touched = false;
  }
};

export const addProductRows = <T extends IProductGridRow>(
  productGroupState: ProductGroupState<T>,
  payload: AddProductRowsPayload<T>
) => {
  const { moved, rows } = payload;

  // Ensure that new rows have their tab property set correctly.
  const rowsToAdd = rows.map((r) => ({ ...r, tab: payload.tab }));

  // Find the final insertion index for the new rows.
  let { atIndex } = payload;

  if (atIndex === null) {
    // Insert new rows at the end, after last touched row.
    atIndex = findLastIndex(productGroupState.rows, ({ status }) => status.touched) + 1;
  } else if (atIndex < 0) {
    atIndex = 0;
  }

  if (moved) {
    productGroupState.status.touched = true;
  }
  productGroupState.rows.splice(atIndex, 0, ...rowsToAdd);
};

export const addServiceRows = (state: ProductsTabState, { payload }: PayloadAction<AddServiceRowsPayload>) => {
  const { tab, rows } = payload;
  const { services } = state[tab];
  const grid = services.grids.find((grid) => grid.gridId === payload.gridId);
  if (grid) {
    touchServicesGrid(state, grid);
    const rowsToAdd = rows.map<IServicesGridRow>((r) => ({ ...r, tab: payload.tab }));
    // Services are always inserted at the end.
    grid.rows.splice(grid.rows.length, 0, ...rowsToAdd);
  }
};

export const deleteItem = (state: ProductsTabState, { payload }: PayloadAction<DeleteItemPayload>) => {
  const { rowId, tab, innerTab, gridId } = payload;
  const productsState = getProductsStateByTab(tab, state);
  if (productsState) {
    productsState[innerTab].status.touched = true;

    const deleteProductRow = (rows: IProductGridRow[], rowId: string) => {
      const rowIndex = rows.findIndex((row) => row.rowId === rowId);
      if (rowIndex > -1) {
        const { status } = rows[rowIndex];
        if (status.existingItem) {
          status.action = CrudActions.DELETE;
        } else {
          rows.splice(rowIndex, 1);
        }
      }
    };

    if (innerTab === QuoteInnerTabs.Rental) {
      deleteProductRow(productsState.rental.rows, rowId);
    } else if (innerTab === QuoteInnerTabs.Sales) {
      deleteProductRow(productsState.sales.rows, rowId);
    } else if (innerTab === QuoteInnerTabs.Services) {
      const { grid, row } = findServiceRow(state, { tab, gridId: gridId!, rowId });
      if (grid && row) {
        grid.rows = grid.rows.filter((row) => row.rowId !== rowId);
        if (row.driverAndTruck && row.driverAndTruck.count > 0) {
          updateServicesDriverAndTruckTotals(state, tab);
        }
        touchServicesGrid(state, grid);
      }
    }
  }
};

// Todo: Move this and below to rental-actions file.
const updateRentalRows = (
  state: ProductsTabState,
  payload:
    | BulkRentalPeriodUpdateInternalPayload
    | BulkRentalQuantityUpdateInternalPayload
    | BulkRentalDurationUpdateInternalPayload,
  setValue: (row: IRentalGridRow) => boolean
) => {
  const { tab, rowIds } = payload;
  const { rental } = state[tab];
  for (let row of rental.rows) {
    if (rowIds[row.rowId] && setValue(row)) {
      recalculateRentalRow(state, row, true);
    }
  }
};

export const bulkRentalPeriodUpdate = (
  state: ProductsTabState,
  { payload }: PayloadAction<BulkRentalPeriodUpdateInternalPayload>
) => {
  updateRentalRows(state, payload, (row) => {
    if (row.periodId !== payload.period.id) {
      row.periodId = payload.period.id;
      return true;
    }
    return false;
  });
};

export const bulkRentalQuantityUpdate = (
  state: ProductsTabState,
  { payload }: PayloadAction<BulkRentalQuantityUpdateInternalPayload>
) => {
  updateRentalRows(state, payload, (row) => {
    if (row.quantity !== payload.quantity) {
      row.quantity = payload.quantity;
      return true;
    }
    return false;
  });
};

export const bulkRentalDurationUpdate = (
  state: ProductsTabState,
  { payload }: PayloadAction<BulkRentalDurationUpdateInternalPayload>
) => {
  updateRentalRows(state, payload, (row) => {
    if (row.duration !== payload.duration) {
      row.duration = payload.duration;
      return true;
    }
    return false;
  });
};

const findGrid = (state: ProductsTabState, { gridId, innerTab, tab }: GridRef) => {
  const productsState = getProductsStateByTab(tab, state);
  if (productsState) {
    switch (innerTab) {
      case QuoteInnerTabs.Rental:
        return productsState.rental;
      case QuoteInnerTabs.Sales:
        return productsState.sales;
      case QuoteInnerTabs.Services:
        return productsState.services.grids.find((g) => g.gridId === gridId);
    }
  }
  return null;
};

export const setGridSelection = (state: ProductsTabState, { payload }: PayloadAction<GridRef & GridSelectionState>) => {
  const grid = findGrid(state, payload);
  if (grid) {
    grid.selected = payload.selected;
    grid.selectedById = payload.selectedById;

    // Avoid mutating `insertionRow` unnecessarily.
    grid.insertionRow = Object.assign(grid.insertionRow, payload.insertionRow);
  }
};

/**
 * Update local product rows to match saved rows.
 */
const syncItemsAfterSave = (
  state: ProductsTabState,
  tab: QuoteTabs,
  innerTab: QuoteInnerTabs,
  savedItems: { id: string }[] | undefined
) => {
  const productsState = getProductsStateByTab(tab, state);

  if (productsState) {
    const savedItemIds = new Set(savedItems?.map(({ id }) => id));
    const updateItems = <T extends IProductGridRow | IServicesGrid>(items: T[], getItemId: (item: T) => string) => {
      if (items.length > 0) {
        // Remove deleted items since they have now been deleted from DB.
        items = items.filter((item) => item.status.action !== CrudActions.DELETE);
        items.forEach((item) => {
          const { status } = item;
          status.existingItem = savedItemIds.has(getItemId(item));
          status.action = status.existingItem ? CrudActions.READ : CrudActions.CREATE;
        });
      }
      return items;
    };

    if (innerTab === QuoteInnerTabs.Rental) {
      productsState.rental.rows = updateItems(productsState.rental.rows, ({ rowId }) => rowId);
    }
    if (innerTab === QuoteInnerTabs.Sales) {
      productsState.sales.rows = updateItems(productsState.sales.rows, ({ rowId }) => rowId);
    }
    if (innerTab === QuoteInnerTabs.Services) {
      productsState.services.grids = updateItems(productsState.services.grids, ({ gridId }) => gridId);
    }
  }
};

export const recalculateRentalFees = (state: ProductsTabState) => {
  const updateRows = (rows: IRentalGridRow[]) => {
    for (const row of rows) {
      if (recalculateRentalRowFees(state, row)) {
        touchRentalRow(state, row);
      }
    }
  };
  updateRows(state.products.rental.rows);
  updateRows(state.optional.rental.rows);
};

export const updateWaiveEnvironmentalRecovery = (state: ProductsTabState, { payload }: PayloadAction<boolean>) => {
  state.feesStatus.touched = true;
  state.waiveEnvironmentalRecovery = payload;
  recalculateRentalFees(state);
};

export const updateWaiveAirQuality = (state: ProductsTabState, { payload }: PayloadAction<boolean>) => {
  state.feesStatus.touched = true;
  state.waiveAirQuality = payload;
  recalculateRentalFees(state);
};
