import { PayloadAction } from '@reduxjs/toolkit';
import {
  convertFetchResponseToAccount,
  convertFetchResponseToBillRequirements,
  convertFetchResponseToContact,
  convertFetchResponseToJobsite,
  convertFetchResponseToMarketSegment,
} from 'converters';
import { QuoteGroups } from 'enums';
import { IFetchQuoteResult } from 'interfaces';
import { setAccountAction } from './account.actions';
import { setBillRequirements } from './bill-requirements.actions';
import { setAccountContactAction } from './contact.actions';
import { CustomerTabState } from './customer-tab.types';
import { updateJobsite } from './jobsite.actions';
import { setMarketSegment } from './market-segment.reducers';

/*
 * Shared Action-Reducer to handle Quote Fetch/Save
 */

export const fetchQuoteCustomerPending = (state: CustomerTabState, action: any) => {
  const payload: IFetchQuoteResult = action.meta.arg;
  if (payload.groups.indexOf(QuoteGroups.ACCOUNT) > -1) {
    state.status.fetching = true;
  }
};

export const fetchQuoteCustomerRejected = (state: CustomerTabState, action: any) => {
  const payload: IFetchQuoteResult = action.meta.arg;
  if (payload.groups.indexOf(QuoteGroups.ACCOUNT) > -1) {
    state.status.fetching = false;
    state.status.fetched = false;
  }
};

export const fetchQuoteCustomerFulfilled = (state: CustomerTabState, { payload }: PayloadAction<IFetchQuoteResult>) => {
  if (payload.groups.indexOf(QuoteGroups.ACCOUNT) > -1) {
    const { cloneOptions } = payload;

    let status: {
      touched?: boolean;
      valid?: boolean;
    } = {};
    if (payload.reset) {
      status = {
        touched: false,
        valid: true,
      };
    } else if (cloneOptions) {
      status = {
        touched: true,
      };
    }
    state.status.fetching = false;
    state.account.status.valid = false;

    // When cloning, we are fetching source quote data. Do not set
    // fetched = true because it will prevent us from loading account
    // data for new quote after initial save.
    if (!cloneOptions) {
      state.status.fetched = true;
    }

    if (!cloneOptions || cloneOptions.account) {
      state.account.status.valid = true;

      const account = convertFetchResponseToAccount(payload.quote);
      setAccountAction(state, { payload: { account, ...status } });

      const contact = convertFetchResponseToContact(payload.quote);
      setAccountContactAction(state, { payload: { contact, ...status } });

      if (!contact.id && cloneOptions) {
        // Prevents overwriting cloned empty contact after fetching account
        // contacts for the first time.
        state.contact.clonedEmptyContact = true;
      }
    }
    if (!cloneOptions || cloneOptions.jobsite) {
      updateJobsite(state, { jobsite: convertFetchResponseToJobsite(payload.quote), touched: status.touched });
    }
    setMarketSegment(state, {
      payload: { marketSegment: convertFetchResponseToMarketSegment(payload.quote), ...status },
    });
    setBillRequirements(state, {
      payload: { billRequirements: convertFetchResponseToBillRequirements(payload.quote), ...status },
    });
  }
};

export const saveQuoteCustomerFulfilled = (state: CustomerTabState) => {
  state.account.status.valid = true;
  state.contact.status.valid = true;
  state.billRequirements.status.valid = true;

  state.account.status.touched = false;
  state.contact.status.touched = false;
  state.jobsite.status.touched = false;
  state.marketSegment.status.touched = false;
  state.billRequirements.status.touched = false;
};
