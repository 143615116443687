import { Keys } from './keys';

export class LocalStorage {
  private static remove(key: string): void {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      return;
    }
  }

  private static get(key: string): string | null {
    try {
      return window.localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  }

  private static save(key: string, value: string): boolean {
    try {
      window.localStorage.setItem(key, value);
      return window.localStorage.getItem(key) === value;
    } catch (e) {
      return false;
    }
  }

  public static saveIdToken = (value: string): boolean => {
    return LocalStorage.save(Keys.ID_TOKEN, value);
  };
  public static saveAccessToken = (value: string): boolean => {
    return LocalStorage.save(Keys.ACCESS_TOKEN, value);
  };
  public static saveRefreshToken = (value: string): boolean => {
    return LocalStorage.save(Keys.REFRESH_TOKEN, value);
  };

  public static readIdToken = (): string => {
    return LocalStorage.get(Keys.ID_TOKEN) || '';
  };
  public static readAccessToken = (): string => {
    return LocalStorage.get(Keys.ACCESS_TOKEN) || '';
  };
  public static readRefreshToken = (): string => {
    return LocalStorage.get(Keys.REFRESH_TOKEN) || '';
  };

  public static removeIdToken = (): void => {
    LocalStorage.remove(Keys.ID_TOKEN);
  };
  public static removeAccessToken = (): void => {
    LocalStorage.remove(Keys.ACCESS_TOKEN);
  };
  public static removeRefreshToken = (): void => {
    LocalStorage.remove(Keys.REFRESH_TOKEN);
  };
}
