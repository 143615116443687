import { QuoteGroups } from 'enums';
import { IOutboundGroup } from 'interfaces';
import { OptionalTabSelectors, ProductSelectors } from 'rdx';
import { RootState } from '../rdx/types';

/*
 * Save
 */
export const convertFeesToSaveRequest = (state: RootState): IOutboundGroup | null => {
  const fuelSurchargeMultiplier = ProductSelectors.fscMultiplier(state);
  const defaultFuelSurchargeMultiplier = ProductSelectors.defaultFscMultiplier(state);
  const canWaiveEnvironmentalRecovery = ProductSelectors.canWaiveEnvironmentalRecovery(state);
  const waiveEnvironmentalRecovery = ProductSelectors.waiveEnvironmentalRecovery(state);
  const canWaiveAirQuality = ProductSelectors.canWaiveAirQuality(state);
  const waiveAirQuality = ProductSelectors.waiveAirQuality(state);

  return {
    id: QuoteGroups.FEES,
    content: {
      header: {
        fees: {
          fuelSurchargeMultiplier,
          defaultFuelSurchargeMultiplier,
          canWaiveEnvironmentalRecovery,
          waiveEnvironmentalRecovery,
          canWaiveAirQuality,
          waiveAirQuality,
        },
      },
    },
  };
};

export const convertNotesToSaveRequest = (state: RootState): IOutboundGroup | null => {
  const notes = OptionalTabSelectors.notes(state);
  return !!notes
    ? {
        id: QuoteGroups.OPTIONAL_NOTES,
        content: {
          detail: {
            optionalNotes: {
              content: notes,
            },
          },
        },
      }
    : null;
};
