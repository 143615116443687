import { IRentalGridRow } from '../../../../interfaces';
import { ValidationGroupNames } from '../review-tab.types';
import { makeValidationField, ProductValidationFieldsByColId } from './validation';

const RentalRowValidationCodes = {
  EMPTY_QUANTITY: '_3_0_0',
  EMPTY_PERIOD: '_3_0_1',
  EMPTY_DURATION: '_3_0_2',
  DAY_RATE_TOO_LOW: '_3_0_3',
  DAY_RATE_TOO_HIGH: '_3_0_4',
  WEEK_RATE_TOO_LOW: '_3_0_5',
  WEEK_RATE_TOO_HIGH: '_3_0_6',
  INVALID_LENGTH_DESCRIPTION: '_3_0_7',
  EMPTY_RATE_DAY: '_3_0_8',
  EMPTY_RATE_WEEK: '_3_0_9',
  EMPTY_RATE_MONTH: '_3_0_10',
  EMPTY_RATE_CYCLE: '_3_0_11',
  OBSOLETE_PRODUCT: '_3_0_13',
};

const OptionalRentalRowValidationCodes = {
  EMPTY_QUANTITY: '_3_1_0',
  EMPTY_PERIOD: '_3_1_1',
  EMPTY_DURATION: '_3_1_2',
  DAY_RATE_TOO_LOW: '_3_1_3',
  DAY_RATE_TOO_HIGH: '_3_1_4',
  WEEK_RATE_TOO_LOW: '_3_1_5',
  WEEK_RATE_TOO_HIGH: '_3_1_6',
  INVALID_LENGTH_DESCRIPTION: '_3_1_7',
  EMPTY_RATE_DAY: '_3_1_8',
  EMPTY_RATE_WEEK: '_3_1_9',
  EMPTY_RATE_MONTH: '_3_1_10',
  EMPTY_RATE_CYCLE: '_3_1_11',
  OBSOLETE_PRODUCT: '_3_1_13',
};

const makeFields = (
  validationGroup: ValidationGroupNames,
  codes: typeof RentalRowValidationCodes
): ProductValidationFieldsByColId<IRentalGridRow> => {
  const makeField = (errorCodes: string[]) => makeValidationField(validationGroup, errorCodes);
  return {
    code: makeField([codes.OBSOLETE_PRODUCT]),
    quantity: makeField([codes.EMPTY_QUANTITY]),
    periodId: makeField([codes.EMPTY_PERIOD]),
    duration: makeField([codes.EMPTY_DURATION]),
    quotedRateDay: makeField([codes.DAY_RATE_TOO_LOW, codes.DAY_RATE_TOO_HIGH, codes.EMPTY_RATE_DAY]),
    quotedRateWeek: makeField([codes.WEEK_RATE_TOO_LOW, codes.WEEK_RATE_TOO_HIGH, codes.EMPTY_RATE_WEEK]),
    quotedRateCycle: makeField([codes.EMPTY_RATE_MONTH, codes.EMPTY_RATE_CYCLE]),
    description: makeField([codes.INVALID_LENGTH_DESCRIPTION]),
  };
};

export const RentalRowValidationFieldsByColId = makeFields('rental', RentalRowValidationCodes);

export const OptionalRentalRowValidationFieldsByColId = makeFields('optionalRental', OptionalRentalRowValidationCodes);
