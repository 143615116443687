import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConfigurationService } from 'api';
import { convertFetchResponseToDetailsTab } from 'converters';
import { QuoteGroups } from 'enums';
import { IConfigurationApplication, IFetchQuoteResult, IField } from 'interfaces';
import { DetailsTabState } from './details-tab.types';

/*
 * Shared Action-Reducer to handle Quote Fetch/Save
 */

export const afterFetchQuoteDetailsPendingAction = (state: DetailsTabState, action: any) => {
  const payload: IFetchQuoteResult = action.meta.arg;
  if (payload.groups.indexOf(QuoteGroups.DETAILS) > -1) {
    state.status.fetching = true;
  }
};

export const afterFetchQuoteDetailsRejectAction = (state: DetailsTabState, action: any) => {
  const payload: IFetchQuoteResult = action.meta.arg;
  if (payload.groups.indexOf(QuoteGroups.DETAILS) > -1) {
    state.status.fetching = false;
    state.status.fetched = false;
  }
};

export const afterFetchQuoteDetailsFulfillAction = (state: DetailsTabState, action: any) => {
  const payload: IFetchQuoteResult = action.payload;
  if (payload.groups.indexOf(QuoteGroups.DETAILS) > -1) {
    if (payload.reset) {
      state.status.touched = false;
      state.status.valid = true;
    }
    state.status.fetching = false;
    state.status.fetched = true;
    state.details = convertFetchResponseToDetailsTab(payload.quote);
  }
};

export const afterSaveQuoteDetailsFulfillAction = (state: DetailsTabState) => {
  updateDetailsTabStatusAction(state, { payload: { touched: false, valid: true } });
};

/*
 * Slice Specific Reducers
 */

export const fetchDetailsConfigAction = createAsyncThunk('quote/detailsTab/fetchDetailsConfigAction', () =>
  ConfigurationService.getInstance().readApplicationsConfiguration()
);

export const updateDetailsTab = (state: DetailsTabState, { payload }: { payload: { values: any } }) => {
  state.status.touched = true;
  state.status.valid = true;

  state.details.project = payload.values.project || {};
  state.details.application = payload.values.application || {};
  state.details.notes = payload.values.notes || {};
  state.details.engineering = payload.values.engineering || {};
  state.details.schedule = payload.values.schedule || {};
};

export const setDetailsApplicationCodeAction = (state: DetailsTabState, { payload }: { payload: string }) => {
  state.status.touched = true;
  state.details.application.id = payload;
  const application: IConfigurationApplication | undefined = state.config.data?.find(
    (app: IConfigurationApplication) => app.id === payload
  );
  if (application) {
    const fields: IField[] = application.fields.sort((fieldA: IField, fieldB: IField) => {
      return fieldA.options.sortOrder < fieldB.options.sortOrder ? -1 : 1;
    });
    state.details.application.fields = fields.map((field) => {
      const value = field.options.visible ? '' : field.content.value.value;
      return { id: field.id, value };
    });
  } else {
    state.details.application.fields = [];
  }
};

export const updateDetailsTabStatusAction = (
  state: DetailsTabState,
  { payload }: { payload: { touched?: boolean; valid?: boolean } }
) => {
  if (payload.valid !== undefined) {
    state.status.valid = payload.valid;
  }
  if (payload.touched !== undefined) {
    state.status.touched = payload.touched;
  }
};
