import {
  IApiEnvelope,
  IConfigurationApplication,
  IMarketSector,
  IMarketSegment,
  IProductUnit,
  IRentalPeriod,
} from 'interfaces';
import { ConfigurationIds } from '../enums';
import { DocumentType } from '../interfaces/Documents';
import { ConfigurableRequest } from './configurable-request';

export interface QuoteLostReason {
  id: string;
  code: string;
  name: string;
  additional: boolean;
}

export class ConfigurationService extends ConfigurableRequest {
  private static instance: ConfigurationService;

  private constructor() {
    super('configuration');
  }

  public async getMarketSectors(): Promise<IMarketSector[]> {
    const result: IApiEnvelope<IMarketSector> = await this.get(`/marketSectors`);
    return result.data;
  }

  private async getConfiguration<T>(id: ConfigurationIds): Promise<T[]> {
    return (await this.get(`/codes/${id}`)).data[0].elements;
  }

  public async getMarketSegments() {
    return this.getConfiguration<IMarketSegment>(ConfigurationIds.MARKET_SEGMENTS);
  }

  public async getDocumentTypes() {
    return this.getConfiguration<DocumentType>(ConfigurationIds.DOCUMENT_TYPES);
  }

  public async getSalesProductUnits() {
    return this.getConfiguration<IProductUnit>(ConfigurationIds.SALES_PRODUCT_UNITS);
  }

  public async getServiceRateTypes() {
    return this.getConfiguration<IServiceRateType>(ConfigurationIds.RATE_TYPES);
  }

  public async getRentalPeriods() {
    return this.getConfiguration<IRentalPeriod>(ConfigurationIds.RENTAL_RATE_PERIODS);
  }

  public async getRentalProductUnits() {
    return this.getConfiguration<IProductUnit>(ConfigurationIds.RENTAL_PRODUCT_UNITS);
  }

  public async readApplicationsConfiguration(): Promise<IConfigurationApplication[]> {
    const result: IApiEnvelope<IConfigurationApplication> = await this.get('/applications');
    return result.data;
  }

  public async getQuoteLostReasons() {
    return this.getConfiguration<QuoteLostReason>(ConfigurationIds.QUOTE_LOST_REASONS);
  }

  public static getInstance(): ConfigurationService {
    if (!ConfigurationService.instance) {
      ConfigurationService.instance = new ConfigurationService();
    }
    return ConfigurationService.instance;
  }
}

export interface IServiceRateType {
  id: string;
  name: string;
}
