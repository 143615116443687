import { ApprovalTiers } from '../../approval/types';
import { IQuoteApproval, IQuoteReviewMarkCompleteOptions, ITotalQuoteHealth, QuoteTypes } from '../../interfaces';

export enum FetchStatuses {
  NotFetched = 'NotFetched',
  Fetching = 'Fetching',
  Fetched = 'Fetched',
  Error = 'Error',
}

export enum FetchableItems {
  Branches = 'Branches',
  BranchDetails = 'BranchDetails',
  Quote = 'Quote',
  QuoteDocument = 'QuoteDocument',
}

export interface AppLocation {
  title?: string;
  upPath?: string;
}

export interface AppState {
  location: AppLocation;
  fetchStatuses: {
    [type in FetchableItems]: FetchStatuses;
  };
}

export interface ApprovalBranchInfo {
  id: string;
  code: string;
  name: string;
}

interface ApprovalBranchTierInfo {
  tierId: ApprovalTiers;
  count: number;
}

interface ApprovalBranchPendings {
  distinct: {
    count: number;
  };
  tier: ApprovalBranchTierInfo[];
}

export interface ApprovalBranch {
  branch: ApprovalBranchInfo;
  pendings: {
    changeOrders: ApprovalBranchPendings;
    quotes: ApprovalBranchPendings;
  };
}

export interface ApprovalBranchQuote {
  id: string;
  typeId: QuoteTypes;
  header: {
    approval: IQuoteApproval;
    health: ITotalQuoteHealth;
    main: {
      code: string;
      parentQuoteId?: string;
      account: {
        name: string;
      };
    };
    totals: {
      grandTotal: number;
      rateLockdown: boolean;
      salesSubtotal: number;
      grandTotalRequired: number;
      fscFeeRequiredTotal: number;
      grandTotalOptional: number;
      fscFeeOptionalTotal: number;
    };
    review: IQuoteReviewMarkCompleteOptions;
    jobsite?: {
      jobName?: string;
    };
    ownership?: {
      assignedTo?: {
        details?: {
          fullName?: string;
        };
      };
    };
    fees?: {
      fuelSurchargeMultiplier?: number;
    };
  };
}

export interface ApprovalBranchDetails {
  branch: ApprovalBranchInfo;
  pendings: {
    sources: ApprovalBranchQuote[];
  };
}
