import { ProductsTabState } from './products-tab.types';

export const updateNotesStatusAction = (state: ProductsTabState, action: any) => {
  const payload: { touched?: boolean; valid?: boolean } = action.payload;
  if (payload.touched !== undefined) {
    state.optional.notes.status.touched = payload.touched;
  }
  if (payload.valid !== undefined) {
    state.optional.notes.status.valid = payload.valid;
  }
};

export const setOptionalProductsNotes = (state: ProductsTabState, action: any) => {
  const payload: { touched?: boolean; valid?: boolean; notes: string } = action.payload;
  if (payload.touched !== undefined) {
    state.optional.notes.status.touched = payload.touched;
  }
  if (payload.valid !== undefined) {
    state.optional.notes.status.valid = payload.valid;
  }
  state.optional.notes.content = payload.notes;
};
