import { CrudActions } from 'enums';
import i18next from 'i18next';
import {
  ICrudStatus,
  IGridRow,
  IProductGridRow,
  IRateTier,
  IRentalGridRow,
  ISalesGridRow,
  IServicesGridRow,
} from 'interfaces';
import { isNil } from 'lodash';
import { i18nKeys } from '../../../internationalization/i18nKeys';
import { rgbaFormatter } from '../../../utils';

export const updateProductRowBeforeFetchingProfile = (row: IProductGridRow | undefined, code: string) => {
  if (row) {
    if (row.status.existingItem && row.code !== code) {
      row.status.action = CrudActions.UPDATE;
    }
    row.code = code;
    row.status.fetched = false;
    row.status.fetching = true;
    row.status.touched = true;
  }
};

export const updateProductRowAfterProfileRejection = (row: IProductGridRow | undefined) => {
  if (row) {
    row.status.fetching = false;
    row.status.error = true;
  }
};

export const resetProductRowState = (item: IRentalGridRow | ISalesGridRow) => {
  item.code = '';
  item.description = undefined;
  item.status.error = false;
  item.status.touched = item.status.existingItem || !!item.code;
  item.status.fetched = false;
  item.status.fetching = false;
  delete item.quantity;
  item.colors = undefined;
  item.product = undefined;
  item.units = undefined;
  item.unitId = 'EACH';
  item.extension = undefined;
  item.redlineLevelId = undefined;
  item.redlineLevelColor = undefined;
};

/**
 * Used to find new index when moving product or service rows up or down.
 * Skips invisible deleted rows with CRUD action DELETE.
 * @param rows
 * @param rowIndex
 * @param shiftDirection
 * @return Insertion index or NULL if row cannot be moved in the given direction.
 */
export const findTargetIndexForRowShift = (
  rows: Array<{ status: ICrudStatus }>,
  rowIndex: number,
  shiftDirection: 'up' | 'down'
) => {
  if (shiftDirection === 'up') {
    for (let i = rowIndex - 1; i >= 0; i--) {
      if (rows[i].status.action !== CrudActions.DELETE) {
        return i;
      }
    }
  } else {
    for (let i = rowIndex + 1; i < rows.length; i++) {
      if (rows[i].status.action !== CrudActions.DELETE) {
        return i + 1;
      }
    }
  }
  return null;
};

export const validateDescription = (row: IRentalGridRow | IServicesGridRow, validationMessages: string[]) => {
  if (row.descriptionEditable) {
    const length = row.description?.trim().length ?? 0;
    const min = 3;
    const max = 255;
    if (length < min || length > max) {
      validationMessages.push(i18next.t(i18nKeys.productsTab.invalidDescription, { min, max }));
    }
  }
};

export const updateRateTier = (row: IRentalGridRow | IServicesGridRow, tier: IRateTier | undefined) => {
  row.tier = tier;
  const rateTierId = row.tier?.id;
  const tierRgba = row.tier?.color.code.rgba;
  const rateTierColor = tierRgba ? rgbaFormatter(tierRgba) : undefined;
  if (rateTierId !== row.rateTierId || rateTierColor !== row.rateTierColor) {
    row.rateTierId = rateTierId;
    row.rateTierColor = rateTierColor;
    return true;
  }
  return false;
};

/**
 * Preconditions: Row's `redlineLevelId` and `colors` have been updated.
 */
export const updateRedlineLevelColor = (row: IRentalGridRow | IServicesGridRow) => {
  const { redlineLevelId, colors } = row;
  let redlineLevelColor;
  if (redlineLevelId && colors) {
    const rgba = colors.find(({ id }) => id === redlineLevelId)?.color?.code.rgba;
    if (rgba) {
      redlineLevelColor = rgbaFormatter(rgba);
    }
  }
  if (redlineLevelColor !== row.redlineLevelColor) {
    row.redlineLevelColor = redlineLevelColor;
    return true;
  }
  return false;
};

export const updateDescription = (row: IGridRow, description: string, editable: boolean | undefined) => {
  row.descriptionEditable = editable;
  if ((isNil(row.description) || !editable) && row.description !== description) {
    row.description = description;
    return true;
  }
  return false;
};
