import { IUser } from '../interfaces';

export interface ApprovalProfile {
  body: {
    keys: ApprovalKey[];
    status: {
      outcomeReason?: string;
    };
  };
}

export interface ApprovalKey {
  tierId: ApprovalTiers;
  required: boolean;
  applicable: boolean;
  completed: boolean;
  outcomeBy?: IUser;
  outcomeId?: ApprovalOutcomes;
  outcomeTimestamp?: number;
}

export enum ApprovalTiers {
  BRANCH = 'BRANCH',
  REGION = 'REGION',
  COMPANY = 'COMPANY',
}

export enum ApprovalOutcomes {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface ApprovalState {
  fetchingProfile: boolean;
  profile: ApprovalProfile | null;
}
