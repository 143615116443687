import { omitBy } from 'lodash';

export const AUTH_CALLBACK_DATA_STORAGE_KEY = 'AuthCallbacks';

// Callback data expires after 5 minutes.
const AUTH_CALLBACK_DATA_EXPIRATION_TIME = 5 * 60 * 1000;

const proofKeyCharSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

function generateProofKey() {
  return window.crypto
    .getRandomValues(new Uint8Array(40))
    .reduce((result, x) => result + proofKeyCharSet[x % proofKeyCharSet.length], '');
}

async function digestMessage(message: string) {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export interface CallbackData {
  expiresAt: number;
  data: any;
}

function loadCallbacks(): Record<string, CallbackData> {
  const data = localStorage.getItem(AUTH_CALLBACK_DATA_STORAGE_KEY);
  if (data) {
    try {
      const parsedData = JSON.parse(data);
      if (parsedData && typeof parsedData === 'object') {
        // Clean data by removing expired items.
        const itemCount = Object.keys(parsedData).length;
        let filteredData = parsedData;
        if (itemCount > 0) {
          const now = Date.now();
          filteredData = omitBy(parsedData, ({ expiresAt }) => !Number.isFinite(expiresAt) || expiresAt <= now);
          if (Object.keys(filteredData).length < itemCount) {
            // Save data if any items were removed.
            saveCallbacks(filteredData);
          }
        }
        return filteredData;
      }
    } catch {}
  }
  return {};
}

function saveCallbacks(data: Record<string, CallbackData>) {
  if (Object.keys(data).length === 0) {
    clearAuthCallbacks();
  } else {
    localStorage.setItem(AUTH_CALLBACK_DATA_STORAGE_KEY, JSON.stringify(data));
  }
}

export function clearAuthCallbacks() {
  localStorage.removeItem(AUTH_CALLBACK_DATA_STORAGE_KEY);
}

export async function saveAuthCallbackData(data: any): Promise<string> {
  const item: CallbackData = {
    expiresAt: Date.now() + AUTH_CALLBACK_DATA_EXPIRATION_TIME,
    data,
  };
  const state = await digestMessage(generateProofKey());
  const callbacksData = loadCallbacks();
  callbacksData[state] = item;
  saveCallbacks(callbacksData);
  return state;
}

export interface LoadAuthCallbackDataResult {
  success: boolean;
  data: any;
}

export async function loadAuthCallbackData(state: string | null): Promise<LoadAuthCallbackDataResult> {
  const result: LoadAuthCallbackDataResult = { success: false, data: undefined };

  // Callback must have the state parameter.
  if (state) {
    const allCallbackData = loadCallbacks();

    // Look up stored data by state.
    const item = allCallbackData[state];
    if (item) {
      delete allCallbackData[state];
      saveCallbacks(allCallbackData);
      result.success = true;
      result.data = item.data;
    }
  }

  return result;
}
