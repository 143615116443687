import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApprovalTiers } from '../../approval/types';
import { IMarketSector, IMarketSegment } from '../../interfaces';
import { FetchStatuses } from '../../mobile/redux/types';
import { IQuoteStatusData, QuoteStatusAdapter } from '../../utils/QuoteStatusAdapter';
import {
  fetchMarketSectorsAction,
  fetchMarketSegmentsAction,
  fetchQuoteLostReasonsAction,
  fetchServiceRateTypesAction,
} from './config.actions';
import { enqueueNotificationAction } from './notifications.action-creators';
import { closeNotificationAction, enqueueNotification, removeNotificationAction } from './notifications.actions';
import { fetchQuoteAction } from './quote.action-creators';
import {
  fetchChangeOrdersAction,
  fetchQuoteForCloneAction,
  fetchQuoteFulfilled,
  fetchQuotePending,
  fetchQuoteRejected,
  saveQuoteAction,
  saveQuoteFulfillAction,
  saveQuotePendingAction,
  saveQuoteRejectAction,
} from './quote.actions';
import { CloneDialogConfig, CloneQuoteState, PdfViewerState, SessionQuoteState, SessionState } from './session.types';
import {
  fetchChangeOrderHealthAction,
  fetchQuoteHealthAction,
  fetchQuoteHealthFulfillAction,
  fetchQuoteHealthPendingAction,
  fetchQuoteHealthRejectAction,
} from './tqh.actions';
import { fetchBranchUsersAction, fetchUserBranchesAction, fetchUserProfileAction } from './user.actions';
import {
  fetchUserBranchesFulfilled,
  fetchUserBranchesPending,
  fetchUserBranchesRejected,
  fetchUserProfileFulfilled,
  fetchUserProfilePending,
  fetchUserProfileRejected,
  setActiveBranch,
  updateActiveBranch,
} from './user.reducers';

const initialQuoteState: SessionQuoteState = {
  new: true,
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  data: null,
  changeOrders: {
    fetching: false,
    fetched: false,
    changeOrders: [],
  },
  clone: null,
};

const initialTqhState = {
  status: {
    fetching: false,
    fetched: false,
  },
  data: null,
};

const initialState: SessionState = {
  marketSegmentsConfig: {
    status: {
      fetching: false,
      fetched: false,
    },
    marketSegments: [],
  },
  marketSectorConfig: {
    status: {
      fetching: false,
      fetched: false,
    },
    marketSectors: [],
  },
  serviceRateTypes: [],
  quoteLostReasonsConfig: {
    fetching: false,
    fetched: false,
    reasons: [],
  },
  user: {
    profile: {
      fetching: false,
      fetched: false,
      data: null,
    },
    approval: {
      tierFilters: {},
    },
    branches: {
      fetching: false,
      fetched: false,
      permissions: {
        read: false,
        write: false,
      },
      active: '',
      data: [],
    },
  },
  pdfViewer: {
    quoteId: null,
    changeOrderId: null,
    canSave: false,
    saveFileName: null,
    canSendToZohoSign: false,
  },
  notifications: [],
  quote: initialQuoteState,
  tqh: initialTqhState,
  cloneDialog: null,
  branchUsers: [],
  branchUsersFetchStatus: FetchStatuses.NotFetched,
};

interface OpenPdfViewerPayload extends Pick<PdfViewerState, 'changeOrderId' | 'quoteId'> {
  quoteStatusData: IQuoteStatusData;
  code: string;
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    resetQuote: (state: SessionState) => {
      state.quote = initialQuoteState;
      state.tqh = initialTqhState;
    },

    /* User */
    setActiveBranch,

    openPdfViewer(state, { payload }: PayloadAction<OpenPdfViewerPayload>) {
      const { quoteId, changeOrderId, quoteStatusData, code } = payload;
      const status = new QuoteStatusAdapter(quoteStatusData);
      const canSave = status.closed || status.approved;
      state.pdfViewer = {
        quoteId,
        changeOrderId,
        canSave,
        saveFileName: canSave ? `${code}.pdf` : null,
        canSendToZohoSign: status.approved,
      };
    },
    closePdfViewer(state) {
      state.pdfViewer = initialState.pdfViewer;
    },

    /* Notifications */
    closeNotificationAction,
    removeNotificationAction,

    openCloneDialog: (state, { payload }: PayloadAction<CloneDialogConfig>) => {
      state.cloneDialog = payload;
    },

    closeCloneDialog: (state) => {
      state.cloneDialog = null;
    },

    setClone: (state, action: PayloadAction<CloneQuoteState>) => {
      state.quote.clone = action.payload;
    },

    approvalTierFilterChanged(state, action: PayloadAction<{ tierId: ApprovalTiers; checked: boolean }>) {
      const { tierId, checked } = action.payload;
      state.user.approval.tierFilters[tierId] = checked;
    },
  },
  extraReducers: (builder) => {
    // Market sectors configuration.
    builder.addCase(fetchMarketSectorsAction.pending, (state: SessionState) => {
      state.marketSectorConfig.status.fetching = true;
    });
    builder.addCase(fetchMarketSectorsAction.rejected, (state: SessionState) => {
      state.marketSectorConfig.status.fetching = false;
      state.marketSectorConfig.status.fetched = false;
    });
    builder.addCase(
      fetchMarketSectorsAction.fulfilled,
      (state: SessionState, { payload }: PayloadAction<IMarketSector[]>) => {
        state.marketSectorConfig.status.fetching = false;
        state.marketSectorConfig.status.fetched = true;
        state.marketSectorConfig.marketSectors = payload;
      }
    );

    // Market segments configuration.
    builder.addCase(fetchMarketSegmentsAction.pending, (state: SessionState) => {
      state.marketSegmentsConfig.status.fetching = true;
    });
    builder.addCase(fetchMarketSegmentsAction.rejected, (state: SessionState) => {
      state.marketSegmentsConfig.status.fetching = false;
      state.marketSegmentsConfig.status.fetched = false;
    });
    builder.addCase(
      fetchMarketSegmentsAction.fulfilled,
      (state: SessionState, { payload }: PayloadAction<IMarketSegment[]>) => {
        state.marketSegmentsConfig.status.fetching = false;
        state.marketSegmentsConfig.status.fetched = true;
        state.marketSegmentsConfig.marketSegments = payload;
      }
    );
    builder.addCase(fetchServiceRateTypesAction.fulfilled, (state, { payload }) => {
      state.serviceRateTypes = payload;
    });

    /* User */
    builder.addCase(fetchUserProfileAction.pending, fetchUserProfilePending);
    builder.addCase(fetchUserProfileAction.rejected, fetchUserProfileRejected);
    builder.addCase(fetchUserProfileAction.fulfilled, fetchUserProfileFulfilled);

    builder.addCase(fetchUserBranchesAction.pending, fetchUserBranchesPending);
    builder.addCase(fetchUserBranchesAction.rejected, fetchUserBranchesRejected);
    builder.addCase(fetchUserBranchesAction.fulfilled, fetchUserBranchesFulfilled);

    builder.addCase(fetchBranchUsersAction.pending, (state) => {
      state.branchUsersFetchStatus = FetchStatuses.Fetching;
      state.branchUsers = [];
    });
    builder.addCase(fetchBranchUsersAction.rejected, (state) => {
      state.branchUsersFetchStatus = FetchStatuses.Error;
      state.branchUsers = [];
    });
    builder.addCase(fetchBranchUsersAction.fulfilled, (state, { payload: users }) => {
      state.branchUsersFetchStatus = FetchStatuses.Fetched;
      state.branchUsers = users;
    });

    /* Quote */
    builder.addCase(fetchQuoteAction.pending, fetchQuotePending);
    builder.addCase(fetchQuoteAction.rejected, fetchQuoteRejected);
    builder.addCase(fetchQuoteAction.fulfilled, fetchQuoteFulfilled);

    builder.addCase(fetchQuoteForCloneAction.pending, (state) => {
      state.quote.fetching = true;
    });
    builder.addCase(fetchQuoteForCloneAction.rejected, fetchQuoteRejected);
    builder.addCase(fetchQuoteForCloneAction.fulfilled, (state, action) => {
      state.quote.fetching = false;
      state.quote.fetched = true;
      updateActiveBranch(state, action.payload.quote.header.main.branch.id || '');
    });

    builder.addCase(saveQuoteAction.pending, saveQuotePendingAction);
    builder.addCase(saveQuoteAction.rejected, saveQuoteRejectAction);
    builder.addCase(saveQuoteAction.fulfilled, saveQuoteFulfillAction);

    builder.addCase(fetchQuoteHealthAction.pending, fetchQuoteHealthPendingAction);
    builder.addCase(fetchQuoteHealthAction.rejected, fetchQuoteHealthRejectAction);
    builder.addCase(fetchQuoteHealthAction.fulfilled, fetchQuoteHealthFulfillAction);

    builder.addCase(fetchChangeOrderHealthAction.pending, fetchQuoteHealthPendingAction);
    builder.addCase(fetchChangeOrderHealthAction.rejected, fetchQuoteHealthRejectAction);
    builder.addCase(fetchChangeOrderHealthAction.fulfilled, fetchQuoteHealthFulfillAction);

    builder.addCase(enqueueNotificationAction, enqueueNotification);

    builder.addCase(fetchChangeOrdersAction.pending, (state) => {
      state.quote.changeOrders.fetched = false;
      state.quote.changeOrders.fetching = true;
      state.quote.changeOrders.changeOrders = [];
    });
    builder.addCase(fetchChangeOrdersAction.rejected, (state) => {
      state.quote.changeOrders.fetching = false;
    });
    builder.addCase(fetchChangeOrdersAction.fulfilled, (state, action) => {
      state.quote.changeOrders.fetched = true;
      state.quote.changeOrders.fetching = false;
      state.quote.changeOrders.changeOrders = action.payload;
    });

    builder.addCase(fetchQuoteLostReasonsAction.pending, (state) => {
      state.quoteLostReasonsConfig.fetching = true;
    });
    builder.addCase(fetchQuoteLostReasonsAction.rejected, (state) => {
      state.quoteLostReasonsConfig.fetching = false;
    });
    builder.addCase(fetchQuoteLostReasonsAction.fulfilled, (state, action) => {
      state.quoteLostReasonsConfig.fetching = false;
      state.quoteLostReasonsConfig.fetched = true;
      state.quoteLostReasonsConfig.reasons = action.payload;
    });
  },
});
