import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ProductsService } from '../../../api';
import { QuoteInnerTabs, QuoteProductTabs } from '../../../enums';
import { GridRowLocation, ISalesProductProfile } from '../../../interfaces';
import { QuoteSelectors } from '../../session';
import { AppDispatch } from '../../store';
import { AppThunkApiConfig, RootState } from '../../types';
import { deleteItemAction } from './products-tab.action-creators';
import { showProductNotFoundMessage } from './products-tab.notifications';
import { getProductSelectors, ProductSelectors } from './products-tab.selectors';
import { FetchProductProfileParams } from './products-tab.types';

export const fetchSalesProfileInternalAction = createAsyncThunk<ISalesProductProfile, FetchProductProfileParams>(
  'quote/productsTab/fetchSalesProfileInternalAction',
  async (params, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    try {
      return (
        state.quoteScreen.productsTab.profiles.sales[params.code] ||
        (await ProductsService.getInstance().getSalesProfile(params.code, QuoteSelectors.branchId(state) || ''))
      );
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchSalesProfileAction = createAsyncThunk<void, FetchProductProfileParams, AppThunkApiConfig>(
  'quote/productsTab/fetchSalesProfileAction',
  async (params, thunkAPI) => {
    try {
      await thunkAPI.dispatch(fetchSalesProfileInternalAction(params)).unwrap();
    } catch (e: any) {
      if (e.statusCode === 404) {
        showProductNotFoundMessage(thunkAPI.dispatch);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchSalesProfiles = async (state: RootState, dispatch: AppDispatch, tab: QuoteProductTabs) => {
  // Get all sales rows that are not deleted and have a sales product object.
  let rows = getProductSelectors(tab)
    .notDeletedSalesRows(state)
    .filter(({ product, rentalProductTransfer }) => !rentalProductTransfer && product);

  // For submitted quotes, fetch profiles only for rows for which we are missing information.
  if (!ProductSelectors.isDraftQuote(state)) {
    // Todo: Should skip misc products since they don't have redlines (verify)
    rows = rows.filter(({ redlineLevelColor }) => !redlineLevelColor);
  }
  const promises = rows.map(({ rowId, code }) =>
    dispatch(fetchSalesProfileInternalAction({ tab, rowId, code: code! }))
  );

  const productsNotFound = await Promise.allSettled(promises.map((promise) => promise.unwrap())).then((outcomes) =>
    outcomes.some((outcome) => outcome.status === 'rejected' && outcome.reason?.statusCode === 404)
  );

  return { productsNotFound };
};

export const fetchSalesProfilesAction = createAsyncThunk<void, { tab: QuoteProductTabs }, AppThunkApiConfig>(
  'quote/productsTab/fetchSalesProfilesAction',
  async ({ tab }, { dispatch, getState }) => {
    await fetchSalesProfiles(getState(), dispatch, tab);
  }
);

export const resetSalesMarginPercentAction = createAction<GridRowLocation>(
  'quote/productsTab/resetSalesMarginPercentAction'
);

export const updateSalesMarginPercentAction = createAction<any>('quote/productsTab/updateSalesMarginPercentAction');

export const deleteSelectedSalesRowsAction = createAsyncThunk(
  'quote/productsTab/deleteSelectedSalesRowsAction',
  async (payload: { tab: QuoteProductTabs }, thunkAPI) => {
    const { tab } = payload;
    const selectedRowIds = getProductSelectors(tab).selectedSalesRowIds(thunkAPI.getState() as RootState);
    for (const rowId of selectedRowIds) {
      thunkAPI.dispatch(deleteItemAction({ tab, innerTab: QuoteInnerTabs.Sales, rowId }));
    }
  }
);
