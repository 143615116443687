import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConfigurationService } from 'api';

export const fetchMarketSegmentsAction = createAsyncThunk('session/fetchMarketSegmentsAction', () =>
  ConfigurationService.getInstance().getMarketSegments()
);

export const fetchQuoteLostReasonsAction = createAsyncThunk('session/fetchQuoteLostReasons', async () => {
  return await ConfigurationService.getInstance().getQuoteLostReasons();
});

export const fetchServiceRateTypesAction = createAsyncThunk('session/fetchServiceRateTypes', async () => {
  return await ConfigurationService.getInstance().getServiceRateTypes();
});

export const fetchMarketSectorsAction = createAsyncThunk('session/fetchMarketSectors', async () =>
  ConfigurationService.getInstance().getMarketSectors()
);
