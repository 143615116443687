import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapValues } from 'lodash';
import { AppLocation, AppState, FetchableItems, FetchStatuses } from '../types';

const initialState: AppState = {
  location: {},
  fetchStatuses: mapValues(FetchableItems, () => FetchStatuses.NotFetched),
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLocation(state, { payload }: PayloadAction<AppLocation>) {
      state.location = payload;
    },
    setBranchesFetchStatus(state, { payload }: PayloadAction<FetchStatuses>) {
      state.fetchStatuses[FetchableItems.Branches] = payload;
    },
    setBranchDetailsFetchStatus(state, { payload }: PayloadAction<FetchStatuses>) {
      state.fetchStatuses[FetchableItems.BranchDetails] = payload;
    },
    setQuoteFetchStatus(state, { payload }: PayloadAction<FetchStatuses>) {
      state.fetchStatuses[FetchableItems.Quote] = payload;
    },
    setDocumentFetchStatus(state, { payload }: PayloadAction<FetchStatuses>) {
      state.fetchStatuses[FetchableItems.QuoteDocument] = payload;
    },
  },
  extraReducers: () => {},
});
