import React from 'react';
import { highlightWord } from './Highlight.utils';
import { IHighlightProps } from './Highlight.props';

export const Highlight: React.FC<IHighlightProps> = ({ match, text, wrapperComponent: Component }) => {
  const chunks = highlightWord(match, text);
  const result = chunks.map((item, index) => {
    if (item.highlighted) {
      if (Component) {
        return <Component key={index}>{item.chunk}</Component>;
      }
      return <strong key={index}>{item.chunk}</strong>;
    } else {
      return item.chunk;
    }
  });

  return <>{result}</>;
};
