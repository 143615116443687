import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { cloneDeep } from 'lodash';
import { QuoteService } from '../../api';
import {
  convertFeesToSaveRequest,
  convertRentalTabToSaveRequest,
  convertSalesTabToSaveRequest,
  convertServicesTabToSaveRequest,
} from '../../converters';
import { QuoteTabs } from '../../enums';
import { IChangeOrder, IOutboundGroup } from '../../interfaces';
import { i18nKeys } from '../../internationalization/i18nKeys';
import { QuoteScreenSelectors } from '../quote-screen';
import { enqueueNotificationAction } from '../session';
import { RootState } from '../types';
import { ChangeOrderGroups } from './types';

export interface IFetchChangeOrderGroupsResult {
  reset: boolean;
  changeOrder: IChangeOrder;
  groups: ChangeOrderGroups[];
}

export const fetchChangeOrderGroupsAction = createAsyncThunk(
  'changeOrder/fetchChangeOrderGroupsAction',
  async (
    payload: {
      quoteId: string;
      changeOrderId: string;
      groups: ChangeOrderGroups[];
      reset?: boolean;
      showErrorMessage?: boolean;
    },
    thunkAPI
  ): Promise<IFetchChangeOrderGroupsResult> => {
    const { reset, quoteId, changeOrderId, groups, showErrorMessage = true } = payload;
    if (reset) {
      const state = thunkAPI.getState() as RootState;
      const changeOrder = cloneDeep(state.changeOrder.changeOrder);
      if (changeOrder) {
        return { changeOrder, groups, reset: true };
      } else {
        // It should not be possible to get here, but just in case, reject the action silently.
        throw new Error('Cannot reset because there is no change order data.');
      }
    } else {
      try {
        const response = await QuoteService.getInstance().getChangeOrderGroups(quoteId, changeOrderId, groups);
        return {
          changeOrder: response,
          groups,
          reset: false,
        };
      } catch (e) {
        if (showErrorMessage) {
          thunkAPI.dispatch(
            enqueueNotificationAction({
              message: i18next.t(i18nKeys.changeOrder.errorFetchingGroupsMessage),
              options: { variant: 'error' },
            })
          );
        }
        throw e;
      }
    }
  }
);

export interface ISaveChangeOrderResult {
  changeOrder: IChangeOrder;
  groups: ChangeOrderGroups[];
}

export const saveChangeOrderGroupsStartAction = createAction<ChangeOrderGroups[]>(
  'changeOrder/saveChangeOrderGroupsStartAction'
);

export const saveChangeOrderGroupsAction = createAsyncThunk<
  ISaveChangeOrderResult,
  {
    quoteId: string;
    changeOrderId: string;
  },
  { rejectValue: ChangeOrderGroups[] }
>('changeOrder/saveChangeOrderGroupsAction', async (arg, thunkAPI) => {
  const { quoteId, changeOrderId } = arg;
  const state = thunkAPI.getState() as RootState;
  const includedGroupTypes: ChangeOrderGroups[] = [];
  const groups: IOutboundGroup[] = [];
  try {
    const addGroup = (group: IOutboundGroup | null, groupId: ChangeOrderGroups) => {
      if (group) {
        groups.push(group);
        includedGroupTypes.push(groupId);
      }
    };

    if (state.quoteScreen.productsTab.products.rental.status.touched) {
      const groupId = ChangeOrderGroups.RENTAL;
      addGroup(convertRentalTabToSaveRequest(state, QuoteTabs.Products, groupId), groupId);
    }
    if (state.quoteScreen.productsTab.products.sales.status.touched) {
      const groupId = ChangeOrderGroups.SALES;
      addGroup(convertSalesTabToSaveRequest(state, QuoteTabs.Products, groupId), groupId);
    }
    if (state.quoteScreen.productsTab.products.services.status.touched) {
      const groupId = ChangeOrderGroups.SERVICES;
      addGroup(convertServicesTabToSaveRequest(state, QuoteTabs.Products, groupId), groupId);
    }
    if (state.changeOrder.headerTouched) {
      const { changeOrder } = state;
      const header = cloneDeep(changeOrder.changeOrder?.header);
      if (header) {
        const { scopeChanges, deliveryTimestamp, completionTimestamp } = changeOrder;
        header.main.scopeChange = scopeChanges;
        header.schedule.deliveryTimestamp = deliveryTimestamp ?? null;
        header.schedule.completionTimestamp = completionTimestamp ?? null;
        addGroup(
          {
            id: ChangeOrderGroups.HEADER,
            content: {
              header,
            },
          },
          ChangeOrderGroups.HEADER
        );
      }
    }

    if (QuoteScreenSelectors.feesTouched(state)) {
      addGroup(convertFeesToSaveRequest(state), ChangeOrderGroups.FEES);
    }

    // Mark included group as being saved.
    thunkAPI.dispatch(saveChangeOrderGroupsStartAction(includedGroupTypes));

    const response = await QuoteService.getInstance().saveChangeOrderGroups(quoteId, changeOrderId, { groups });

    thunkAPI.dispatch(
      enqueueNotificationAction({
        message: i18next.t(i18nKeys.changeOrder.groupsSavedMessage),
        options: { variant: 'success' },
      })
    );

    return {
      changeOrder: response,
      groups: includedGroupTypes,
    };
  } catch (e) {
    thunkAPI.dispatch(
      enqueueNotificationAction({
        message: i18next.t(i18nKeys.changeOrder.errorSavingGroupsMessage),
        options: { variant: 'error' },
      })
    );
    return thunkAPI.rejectWithValue(includedGroupTypes);
  }
});
