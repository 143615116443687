import { IAccount, IApiEnvelope, IContact } from 'interfaces';
import { ConfigurableRequest } from './configurable-request';

export type AccountSearchResponse = IApiEnvelope<{ elements: IAccount[] }>;

export class AccountsService extends ConfigurableRequest {
  private static instance: AccountsService;

  private constructor() {
    super('accounts');
  }

  public async searchAccounts(query: string): Promise<IAccount[]> {
    const queryParams = { query };
    const result: AccountSearchResponse = await this.get('/search', queryParams);
    const [{ elements }] = result.data;
    return elements;
  }

  public async getAccountContacts(accountId: string): Promise<IContact[]> {
    const result: IApiEnvelope<IContact> = await this.get(`/${accountId}/contacts`);
    return result.data;
  }

  public async getAccount(accountId: string): Promise<IAccount> {
    const result: IApiEnvelope<IAccount> = await this.get(`/${accountId}`);
    const [customer]: IAccount[] = result.data;
    return customer;
  }

  public static getInstance(): AccountsService {
    if (!AccountsService.instance) {
      AccountsService.instance = new AccountsService();
    }
    return AccountsService.instance;
  }
}
