import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IApiEnvelope } from 'interfaces/ApiEnvelope';
import { isNil } from 'lodash';
import { refreshTokenInterceptor } from '../utils/refresh-token-interceptor';
import { AppConfigurationService } from './app-configuration.service';
import { BaseRequest } from './base-request';
import { HttpMethod } from './http-method.types';

export abstract class ConfigurableRequest extends BaseRequest<IApiEnvelope<any>> {
  protected constructor(plural: string) {
    super(plural);
    this.baseUrl = AppConfigurationService.getInstance().appConfiguration.apiURL;
  }

  public async request(
    url: string,
    method: HttpMethod,
    params: { [key: string]: string } = {},
    query: { [key: string]: string } = {},
    headers: {} = {},
    body: {} = {}
  ): Promise<IApiEnvelope<any>> {
    let request = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      data: Array.isArray(body) ? body : { ...body },
    };

    let newUrl = this.baseUrl;
    if (url) {
      newUrl += `/${url}`;
    }
    // Make sure query is an Object
    const newQuery = query || {};

    if (headers) {
      request.headers = { ...request.headers, ...headers };
    }

    if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
      request = { ...request, ...params };
    }

    // Generate an array of query strings
    const queryArray: string[] = [];
    Object.entries(newQuery).forEach(([key, value]) => {
      if (!isNil(value)) {
        queryArray.push(`${key}=${encodeURIComponent(value)}`);
      }
    });

    // Only add the query string when there is at least one query parameter
    if (queryArray.length) {
      newUrl += `?${queryArray.join('&')}`;
    }

    const customAxios = axios.create();
    customAxios.interceptors.request.use(refreshTokenInterceptor);

    const config: AxiosRequestConfig = {
      ...request,
      url: newUrl,
      validateStatus: (status) => (status >= 200 && status < 300) || (status >= 400 && status < 600),
    };

    const result: AxiosResponse<IApiEnvelope<any>> = await customAxios(config);
    if (result.status >= 400 && result.status < 600) {
      // eslint-disable-next-line no-throw-literal
      throw {
        ...result.data.metadata,
        responseErrors: result?.data?.data && result?.data?.data[0] ? result?.data?.data[0] : [],
      };
    }
    return result.data;
  }
}
