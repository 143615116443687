import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuoteInnerTabs, QuoteTabs } from '../../../enums';
import { SetEnabledTabsPayload, TabsState, TabState } from './tabs.types';

const initialState: TabsState = {
  active: QuoteTabs.Customer,
  innerTab: QuoteInnerTabs.Rental,
  tabs: Object.values(QuoteTabs).reduce((tabs, tab) => {
    tabs[tab] = { disabled: true };
    return tabs;
  }, {} as { [tab in QuoteTabs]: TabState }),
};

export const tabsSlice = createSlice({
  name: 'quote/tabs',
  initialState,
  reducers: {
    setActiveTab(state, { payload }: PayloadAction<{ tab: QuoteTabs; innerTab?: QuoteInnerTabs }>) {
      const { innerTab = QuoteInnerTabs.Rental, tab } = payload;
      state.active = tab;
      state.innerTab = innerTab;
      state.tabs[tab].disabled = false;
    },
    setEnabledTabsAction(state, { payload }: PayloadAction<SetEnabledTabsPayload>) {
      for (const tab of Object.values(QuoteTabs)) {
        state.tabs[tab].disabled = !payload[tab];
      }
    },
  },
  extraReducers: () => {},
});
