import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { ApprovalService } from '../../api/approval.service';
import { i18nKeys } from '../../internationalization/i18nKeys';
import { enqueueNotificationAction } from '../../rdx';

export const fetchQuoteApprovalProfileAction = createAsyncThunk(
  'approval/fetchQuoteApprovalProfile',
  async (arg: { quoteId: string }, thunkAPI) => {
    try {
      return await ApprovalService.getInstance().getApprovalProfile(arg.quoteId);
    } catch {
      thunkAPI.dispatch(
        enqueueNotificationAction({
          message: i18next.t(i18nKeys.approval.errorLoadingProfile),
          options: { variant: 'error' },
        })
      );
      return null;
    }
  }
);

export const fetchChangeOrderApprovalProfileAction = createAsyncThunk(
  'approval/fetchChangeOrderApprovalProfile',
  async (arg: { quoteId: string; changeOrderId: string }, thunkAPI) => {
    try {
      return await ApprovalService.getInstance().getChangeOrderApprovalProfile(arg.quoteId, arg.changeOrderId);
    } catch {
      thunkAPI.dispatch(
        enqueueNotificationAction({
          message: i18next.t(i18nKeys.approval.errorLoadingProfile),
          options: { variant: 'error' },
        })
      );
      return null;
    }
  }
);
