import { createSlice } from '@reduxjs/toolkit';
import { ApprovalState } from '../types';
import { fetchChangeOrderApprovalProfileAction, fetchQuoteApprovalProfileAction } from './actions';

const initialState: ApprovalState = {
  fetchingProfile: false,
  profile: null,
};

export const approvalSlice = createSlice({
  name: 'approval',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuoteApprovalProfileAction.pending, (state) => {
      state.fetchingProfile = true;
    });
    builder.addCase(fetchQuoteApprovalProfileAction.fulfilled, (state, action) => {
      state.fetchingProfile = false;
      state.profile = action.payload;
    });

    builder.addCase(fetchChangeOrderApprovalProfileAction.pending, (state) => {
      state.fetchingProfile = true;
    });
    builder.addCase(fetchChangeOrderApprovalProfileAction.fulfilled, (state, action) => {
      state.fetchingProfile = false;
      state.profile = action.payload;
    });
  },
});
