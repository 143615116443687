import { isFinite } from 'lodash';

export const round2Decimals = (number: number) =>
  Number.isFinite(number) ? Math.round((number + Number.EPSILON) * 100) / 100 : number;

export const isFiniteNumber = (value: any): value is number => Number.isFinite(value);

export const isPositiveNumber = (value: any): value is number => isFiniteNumber(value) && value > 0;

export const checkPositiveNumber = <T>(value: any, defaultValue: T) => (isPositiveNumber(value) ? value : defaultValue);

export const numberToString = (value: number | undefined | null) => (isFinite(value) ? `${value}` : '');
