import moment, { Moment } from 'moment';

export class TimeUtils {
  public static readonly DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
  public static readonly DEFAULT_TIME_FORMAT = 'h:mm A';
  public static readonly DEFAULT_DATE_TIME_FORMAT = `${TimeUtils.DEFAULT_DATE_FORMAT} ${TimeUtils.DEFAULT_TIME_FORMAT}`;
  public static readonly LL_DATE_FORMAT = 'LL';

  private static readonly SECONDS_IN_DAY: number = 86400;
  private constructor() {}

  public static now(): number {
    return moment().valueOf();
  }

  public static timestampFromMoment(value: Moment | null) {
    return value?.isValid() ? value.valueOf() : undefined;
  }

  public static momentFromTimestamp(timestamp: number | undefined) {
    return timestamp ? moment(timestamp) : null;
  }

  public static addDays(timestamp: number, days: number): number {
    const timestampMoment: Moment = moment(timestamp);
    timestampMoment.add(this.daysToSeconds(days), 'seconds');
    return timestampMoment.valueOf();
  }

  public static daysToSeconds(days: number): number {
    return days * this.SECONDS_IN_DAY;
  }

  private static formatTimestamp =
    (format: string) =>
    (timeStamp: number): string =>
      moment(timeStamp).format(format);

  public static formatMmDdYy = TimeUtils.formatTimestamp(TimeUtils.DEFAULT_DATE_FORMAT);
  public static formatLl = TimeUtils.formatTimestamp(TimeUtils.LL_DATE_FORMAT);
  public static formatDateDefault = TimeUtils.formatTimestamp(TimeUtils.DEFAULT_DATE_FORMAT);
  public static formatTimeDefault = TimeUtils.formatTimestamp(TimeUtils.DEFAULT_TIME_FORMAT);
  public static formatDateTimeDefault = TimeUtils.formatTimestamp(TimeUtils.DEFAULT_DATE_TIME_FORMAT);
}
