import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, TypographyProps, useTheme } from '@mui/material';
import { Typography } from '@mui/material/';
import React, { useState } from 'react';
import { ValidationGroup } from '../../../rdx';
import { ComponentIds } from '../../../utils/component-ids';
import { ErrorRowList } from './ErrorRowList';

interface IExpandableErrorProps {
  group: ValidationGroup;
  type: 'critical-error' | 'error' | 'warning';
  expanded?: boolean;
  index?: number;
}

export const ExpandableError: React.FC<IExpandableErrorProps> = (props) => {
  const { group, type, index } = props;

  const theme = useTheme();

  const notCollapsible = type === 'critical-error';
  const titleColor: TypographyProps['color'] = type === 'critical-error' ? 'error' : 'textPrimary';
  const titleFontWeight = type === 'critical-error' ? '500' : 'normal';
  const messageColor = type === 'error' ? undefined : theme.palette.error.main;

  const [expanded, setExpanded] = useState(notCollapsible || (props.expanded ?? false));
  const errorCount = group.items.length;

  return (
    <Accordion expanded={expanded} data-test={ComponentIds.ErrorsDrawerGroup} data-test-index={index}>
      <AccordionSummary
        style={{
          display: 'flex',
          fontSize: 24,
          cursor: notCollapsible ? 'default' : 'pointer',
        }}
        expandIcon={notCollapsible ? undefined : <ExpandMoreIcon />}
        onClick={notCollapsible ? undefined : () => setExpanded(!expanded)}
      >
        <Badge
          badgeContent={<span data-test={ComponentIds.ErrorsDrawerGroupMessageCount}>{errorCount}</span>}
          color="error"
          overlap="rectangular"
          sx={{
            '& 	.MuiBadge-badge': {
              right: -3,
              top: 5,
            },
          }}
        >
          {type === 'warning' ? (
            <WarningIcon color="primary" fontSize="inherit" />
          ) : (
            <ErrorIcon color="primary" fontSize="inherit" />
          )}
        </Badge>
        <Box ml={2} display="flex" alignItems="center">
          <Typography variant="h2" color={titleColor} data-test={ComponentIds.ErrorsDrawerGroupTitle}>
            <Box fontWeight={titleFontWeight}>{group.name}</Box>
          </Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails style={{ padding: 0 }}>
        <ErrorRowList group={group} textColor={messageColor} />
      </AccordionDetails>
    </Accordion>
  );
};
