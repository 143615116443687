import i18next from 'i18next';
import { capitalize, isNil, min } from 'lodash';
import { IRentalDetailEventBody } from '../interfaces';
import { i18nKeys } from '../internationalization/i18nKeys';

export interface RateLockdownRow {
  id: string;
  type: 'product' | 'rld';
  rowNumber?: number;
  quantity?: number;
  unit?: string;
  code?: string;
  description: string;
  dayRate?: number;
  weekRate?: number;
  cycleRate?: number;
  period?: string;
  duration?: number;
}

export interface RateLockdownProducts {
  rental: RateLockdownRow[];
  optionalRental: RateLockdownRow[];
}

const checkLockdown = (
  quotedRate: number | undefined,
  lockdownRate: number | undefined,
  listRate: number | undefined
) => {
  // Product is a rate lockdown exception if it has lockdown rate and
  // quoted rate is smaller than both lockdown rate and list rate.
  if (!isNil(quotedRate) && !isNil(lockdownRate) && quotedRate < min([lockdownRate, listRate])!) {
    // We compare rate with both list and lockdown rates, but we always display lockdown rate.
    return lockdownRate;
  }
  return undefined;
};

export const makeRateLockdownRows = (rows?: IRentalDetailEventBody[]): RateLockdownRow[] =>
  rows?.reduce((rldRows, row) => {
    let lockdownRateDay = checkLockdown(row.quotedRateDay, row.lockdownRateDay, row.listRateDay);
    let lockdownRateWeek = checkLockdown(row.quotedRateWeek, row.lockdownRateWeek, row.listRateWeek);
    let lockdownRateCycle = checkLockdown(row.quotedRateCycle, row.lockdownRateCycle, row.listRateCycle);
    if (!isNil(lockdownRateDay) || !isNil(lockdownRateWeek) || !isNil(lockdownRateCycle)) {
      rldRows.push({
        id: row.id,
        type: 'product',
        rowNumber: row.rowIndex,
        quantity: row.quantity,
        unit: capitalize(row.unitId),
        code: row.rentalProduct.code,
        description: row.description!,
        dayRate: row.quotedRateDay,
        weekRate: row.quotedRateWeek,
        cycleRate: row.quotedRateCycle,
        duration: row.duration ?? undefined,
        period: capitalize(row.periodId),
      });
      rldRows.push({
        id: `${row.id}-rld`,
        type: 'rld',
        description: i18next.t(i18nKeys.rateLockdown.dialog.rldRatesDescription),
        dayRate: lockdownRateDay,
        weekRate: lockdownRateWeek,
        cycleRate: lockdownRateCycle,
      });
    }
    return rldRows;
  }, [] as RateLockdownRow[]) || [];

export const makeRateLockdownProducts = (
  rentalRows: IRentalDetailEventBody[] | undefined,
  hasRentalExceptions: boolean,
  optionalRentalRows: IRentalDetailEventBody[] | undefined,
  hasOptionalRentalExceptions: boolean
): RateLockdownProducts => ({
  rental: hasRentalExceptions ? makeRateLockdownRows(rentalRows) : [],
  optionalRental: hasOptionalRentalExceptions ? makeRateLockdownRows(optionalRentalRows) : [],
});

export const makeChangeOrderRateLockdownProducts = (
  rentalRows: IRentalDetailEventBody[] | undefined,
  hasRentalExceptions: boolean
) => makeRateLockdownProducts(rentalRows, hasRentalExceptions, undefined, false);
