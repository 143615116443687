import { Box } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { ISectionCardProps } from './SectionCard.props';
import { SectionContainer } from './SectionCard.style';

export const SectionCard: React.FC<ISectionCardProps> = (props: ISectionCardProps) => {
  const {
    title,
    noPadding,
    noVerticalPadding,
    noHorizontalPadding,
    sectionCardClassName,
    renderHelpTip,
  }: ISectionCardProps = props;

  return (
    <SectionContainer className={sectionCardClassName}>
      <Box className="section-title" sx={{ display: 'flex', columnGap: 0.5 }}>
        <span>{title}</span>
        {renderHelpTip?.()}
      </Box>
      <div
        className={classNames({
          'section-content': true,
          'no-padding': noPadding,
          'no-vertical-padding': noVerticalPadding,
          'no-horizontal-padding': noHorizontalPadding,
        })}
      >
        {props.children}
      </div>
    </SectionContainer>
  );
};
