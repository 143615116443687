import { SessionState } from './session.types';

export const enqueueNotification = (state: SessionState, { payload }: { payload: any }) => {
  const notification = payload;
  const key = notification.options?.key || new Date().getTime() + Math.random();
  state.notifications.push({
    key,
    ...notification,
  });
};

export const closeNotificationAction = (state: SessionState, { payload }: { payload: { key: any } }) => {
  if (payload.key) {
    const notification = state.notifications.find((i) => i.key === payload.key);
    if (notification) {
      notification.dismissed = true;
    }
  } else {
    state.notifications.forEach((item: any) => {
      item.dismissed = true;
    });
  }
};

export const removeNotificationAction = (state: SessionState, { payload }: { payload: { key: any } }) => {
  if (payload.key) {
    state.notifications = state.notifications.filter((i) => i.key !== payload.key);
  }
};
