import { styled } from '@mui/material/styles';

export const SideMenuContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100%;
  transition: all 0.2s ease-in;
  overflow: hidden;
  z-index: 100;

  &.opened {
    width: 200px;

    .logo {
      img {
        width: 135px;

        &.small {
          opacity: 0;
        }

        &.full {
          opacity: 1;
        }
      }
    }

    .about {
      span {
        opacity: 1;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .logo {
    position: relative;
    background-color: #1d3f76;
    height: 70px;
    margin-bottom: 10px;
    cursor: pointer;

    img {
      width: 35px;
      margin: auto;
      position: absolute;
      left: -10px;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      transition: all 0.1s ease-in;

      &.small {
        opacity: 1;
      }
    }

    .caret {
      position: absolute;
      top: 20px;
      right: 0;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        right: 10px;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid white;
      }

      &:after {
        content: '';
        position: absolute;
        right: 13px;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #1d3f76;
      }
    }
  }

  .about {
    border-top: 1px solid #ccc;
    padding-left: 15px;

    span {
      opacity: 0;
    }

    button {
      span {
        opacity: 1;
      }
    }
  }
`;

export const MenuItem = styled('div')`
  display: flex;
  padding: 5px 25px;
  height: 60px;
  width: 200px;

  :hover {
  }

  &.active {
    span {
      color: #1d3f76;
    }

    svg {
      fill: #1d3f76;
    }
  }

  span {
    padding-left: 10px;
    white-space: nowrap;
    color: #898989;
    margin-top: 3px;
    font-size: 1.2em;
    opacity: 0;
    transition: all 0.1s ease-in;
  }

  svg {
    fill: #c8c0b7;
    filter: drop-shadow(2px 2px 2px #e2e0de);
    width: 30px;
    height: 30px;
  }

  .opened & {
    span {
      opacity: 1;
    }
  }
`;
