import { FetchStatuses } from '../../mobile/redux/types';
import { RootState } from '../types';

export const SessionSelectors = {
  pdfViewer: (state: RootState) => state.session.pdfViewer,
  fetching: (state: RootState) => state.session.quote.fetching,
  jobsiteId: (state: RootState) => state.session.quote.data?.header?.jobsite?.id,
  cloneDialog: (state: RootState) => state.session.cloneDialog,
  branchUsers: (state: RootState) => state.session.branchUsers,
  branchUsersFetched: (state: RootState) => state.session.branchUsersFetchStatus === FetchStatuses.Fetched,
};
