import { CrudActions, QuoteProductTabs, RatePeriods } from 'enums';
import i18n from 'i18next';
import {
  CanonicalRatePeriods,
  GridRowLocation,
  IRentalGridRow,
  IRentalGridRowLocal,
  IRentalProductProfile,
  IRentalProfileRatePeriod,
  RentalRatePeriod,
  WorkflowStates,
} from 'interfaces';
import { isNil, keyBy, min } from 'lodash';
import { round2Decimals } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { i18nKeys } from '../../../internationalization/i18nKeys';
import {
  resetProductRowState,
  updateDescription,
  updateRateTier,
  updateRedlineLevelColor,
  validateDescription,
} from './products-tab.helpers';
import { ProductsTabState, RentalRowUpdate } from './products-tab.types';
import { calcWoscoRatePercentage } from './rental.utils';

export const findRentalRow = (state: ProductsTabState, location: GridRowLocation) =>
  state[location.tab].rental.rows.find((row) => row.rowId === location.rowId);

export function updateRentalRowProp<T extends keyof IRentalGridRow>(
  state: ProductsTabState,
  update: RentalRowUpdate<T>,
  recalculate: boolean
) {
  const row = findRentalRow(state, update.location);
  if (row) {
    row[update.prop] = update.value;
    if (recalculate) {
      recalculateRentalRow(state, row, true);
    } else {
      touchRentalRow(state, row);
    }
  }
}

function getCanonicalRatePeriodId(periodId: RatePeriods): CanonicalRatePeriods;
function getCanonicalRatePeriodId(periodId: RatePeriods | undefined): CanonicalRatePeriods | undefined;
function getCanonicalRatePeriodId(periodId: RatePeriods | undefined) {
  return periodId === RatePeriods.MONTHLY ? RatePeriods.CYCLE : periodId;
}

const getRentalRowActiveRatePeriod = (row: IRentalGridRow) => {
  const periodId = getCanonicalRatePeriodId(row.periodId);
  if (periodId) {
    if (periodId === RatePeriods.DAILY) {
      return row.dayPeriod;
    } else if (periodId === RatePeriods.WEEKLY) {
      return row.weekPeriod;
    } else if (periodId === RatePeriods.CYCLE) {
      return row.cyclePeriod;
    }
  }
  return null;
};

/**
 * Precondition: Quoted Rate extension must already be correctly calculated.
 */
const calcDefaultRateExtension = (row: IRentalGridRow) => {
  const { ratedManually, quantity, duration, extension, defaultRateExtension } = row;
  if (ratedManually) {
    row.defaultRateExtension = extension;
  } else {
    const listRate = getRentalRowActiveRatePeriod(row)?.listRate;
    if (isNil(quantity) || isNil(duration) || isNil(listRate)) {
      row.defaultRateExtension = undefined;
    } else {
      row.defaultRateExtension = round2Decimals(quantity * listRate * duration);
    }
  }
  return row.defaultRateExtension !== defaultRateExtension;
};

const makeDefaultRentalRatePeriod = (id: RentalRatePeriod['id']): RentalRatePeriod => ({
  id,
  violatesLockdown: false,
  valid: true,
  editable: false,
  editorParams: {
    numeric: true,
    currency: true,
  },
});

export const makeRentalRowDefaults = (): IRentalGridRowLocal => ({
  profileFetched: false,
  dayPeriod: makeDefaultRentalRatePeriod(RatePeriods.DAILY),
  weekPeriod: makeDefaultRentalRatePeriod(RatePeriods.WEEKLY),
  cyclePeriod: makeDefaultRentalRatePeriod(RatePeriods.CYCLE),
  cyclePeriodType: RatePeriods.CYCLE,
  percentageOfRateEditable: false,
  validationMessages: [],
});

export const initLoadedRentalRow = (state: ProductsTabState, row: IRentalGridRow) => {
  // Use global Units and Periods config until we get product profile.
  row.units = state.rentalProductUnits;
  row.periods = state.rentalPeriods;

  // Duration must be either greater than zero or undefined.
  if ((row.duration ?? 0) < 1) {
    row.duration = undefined;
  }

  const { quantity, periodId, duration } = row;
  const quotedRate = getRentalRowActiveRatePeriod(row)?.quotedRate;

  if (isNil(quantity) || isNil(quotedRate) || isNil(duration)) {
    row.extension = undefined;
  }
  calcDefaultRateExtension(row);

  if (isNil(quotedRate) || !periodId) {
    row.percentageOfRate = undefined;
  }

  if (row.extension === undefined) {
    row.rppFee = undefined;
    row.erFee = undefined;
    row.aqFee = undefined;
  }

  recalculateRentalRowLockdown(row);
};

export const recalculateRentalRowFees = (state: ProductsTabState, row: IRentalGridRow) => {
  const { extension, product, rentalProtectionPlan } = row;

  // Fees will be undefined if we don't have a valid product and extension.
  let rppFee, erFee, aqFee;
  if (product && extension !== undefined) {
    // Do not touch previously calculated RPP fee unless we have RPP fee config from profile.
    rppFee = row.rppFee;
    if (rentalProtectionPlan) {
      // Can calculate and overwrite RPP fee now.
      const { applicable, multiplier } = rentalProtectionPlan;
      rppFee = applicable && multiplier ? round2Decimals(multiplier * extension) : 0;
    }

    // We can update ERF and AQF without waiting for profile because
    // product has both applicable and multiplier props. Fees will be
    // recalculated again using the latest fee configs if we retrieve
    // the profile.
    const { erFeeApplies, erFeeMultiplier, aqFeeApplies, aqFeeMultiplier } = product;
    erFee =
      erFeeApplies && erFeeMultiplier && state.applyEnvironmentalRecovery && !state.waiveEnvironmentalRecovery
        ? round2Decimals(erFeeMultiplier * extension)
        : 0;

    aqFee =
      aqFeeApplies && aqFeeMultiplier && state.applyAirQuality && !state.waiveAirQuality
        ? round2Decimals(aqFeeMultiplier * extension)
        : 0;
  }
  if (row.rppFee === rppFee && row.erFee === erFee && row.aqFee === aqFee) {
    return false;
  }
  row.rppFee = rppFee;
  row.erFee = erFee;
  row.aqFee = aqFee;
  return true;
};

const recalculateRentalRowLockdown = (row: IRentalGridRow) => {
  for (const period of [row.dayPeriod, row.weekPeriod, row.cyclePeriod]) {
    period.effectiveLockdownRate = getRentalPeriodEffectiveLockdownRate(period);
    if (isNil(period.effectiveLockdownRate) || isNil(period.quotedRate)) {
      period.violatesLockdown = false;
    } else {
      period.violatesLockdown = period.quotedRate < period.effectiveLockdownRate;
    }
  }
};

/**
 * Builds array of validation messages for the row to display in R.V. cell.
 */
const validateRentalRow = (row: IRentalGridRow) => {
  const keys = i18nKeys.rental.validationMessages;
  const validationMessages: string[] = [];
  const { quantity, periodId, duration, dayPeriod, weekPeriod, cyclePeriod, cyclePeriodType } = row;

  const addValidationMessage = (key: string, options?: object) => {
    validationMessages.push(i18n.t(key, options));
  };

  validateDescription(row, validationMessages);

  if (row.status.touched) {
    if (isNil(quantity)) {
      addValidationMessage(keys.missingQuantity);
    }
    if (isNil(periodId)) {
      addValidationMessage(keys.missingPeriod);
    }
    if (isNil(duration)) {
      addValidationMessage(keys.missingDuration);
    }
  }

  let dayRateValid = true;
  let weekRateValid = true;

  const validateRates =
    dayPeriod.quotedRate !== dayPeriod.listRate ||
    weekPeriod.quotedRate !== weekPeriod.listRate ||
    cyclePeriod.quotedRate !== cyclePeriod.listRate;

  if (validateRates) {
    if (!isNil(weekPeriod.quotedRate)) {
      if (!isNil(dayPeriod.quotedRate)) {
        if (dayPeriod.quotedRate < weekPeriod.quotedRate / 7) {
          dayRateValid = false;
          addValidationMessage(keys.dayRateTooLowRelativeToWeekRate);
        } else if (dayPeriod.quotedRate > weekPeriod.quotedRate) {
          dayRateValid = false;
          addValidationMessage(keys.dayRateGreaterThanWeekRate);
        }
      }
      if (!isNil(cyclePeriod.quotedRate)) {
        if (weekPeriod.quotedRate < cyclePeriod.quotedRate / 4) {
          weekRateValid = false;
          addValidationMessage(keys.weekRateTooLowRelativeToCycleRate, { context: cyclePeriodType });
        } else if (weekPeriod.quotedRate > cyclePeriod.quotedRate) {
          weekRateValid = false;
          addValidationMessage(keys.weekRateGreaterThanCycleRate, { context: cyclePeriodType });
        }
      }
    }

    // Rate lockdown
    if (dayPeriod.violatesLockdown) {
      addValidationMessage(keys.dayRateLockdown);
    }
    if (weekPeriod.violatesLockdown) {
      addValidationMessage(keys.weekRateLockdown);
    }
    if (cyclePeriod.violatesLockdown) {
      addValidationMessage(keys.cycleRateLockdown, { context: cyclePeriodType });
    }
  }

  dayPeriod.valid = dayRateValid;
  weekPeriod.valid = weekRateValid;

  if (row.ratedManually && periodId && isNil(getRentalRowActiveRatePeriod(row)?.quotedRate)) {
    addValidationMessage(keys.missingRate);
  }

  if (row.validationMessages.length !== 0 || validationMessages.length !== 0) {
    row.validationMessages = validationMessages;
  }
};

/**
 * Update all derived properties when something about the row changes.
 * This should only be called for Draft quotes.
 */
export const recalculateRentalRow = (state: ProductsTabState, row: IRentalGridRow, touched: boolean) => {
  if (row.profileFetched && state.quoteStateId === WorkflowStates.OPEN) {
    const { quantity, periodId, duration, ratedManually } = row;
    const ratePeriod = getRentalRowActiveRatePeriod(row);
    const { quotedRate, listRate } = ratePeriod || {};
    row.percentageOfRateEditable = false;

    // Extension
    let extension;
    if (!(isNil(quantity) || isNil(quotedRate) || isNil(duration))) {
      extension = round2Decimals(quantity * quotedRate * duration);
    }
    if (extension !== row.extension) {
      row.extension = extension;
      touched = true;
    }

    if (calcDefaultRateExtension(row)) {
      touched = true;
    }

    // Percentage of rate
    let percentageOfRate;
    if (ratePeriod && !isNil(quotedRate)) {
      if (ratedManually) {
        percentageOfRate = 100;
      } else if (listRate) {
        percentageOfRate = round2Decimals((quotedRate * 100) / listRate);
      }
    }
    if (percentageOfRate !== row.percentageOfRate) {
      row.percentageOfRate = percentageOfRate;
      touched = true;
    }

    row.percentageOfRateEditable = !!(periodId && !ratedManually && row.tier);

    let redlineLevelId: string | undefined;
    if (!ratedManually && !isNil(quotedRate)) {
      const selectedRatePeriod = row.rates?.find(({ id }) => id === periodId);
      if (selectedRatePeriod) {
        redlineLevelId = selectedRatePeriod.redline?.levels.find(
          (level) => quotedRate >= level.minimum && quotedRate <= level.maximum
        )?.id;
      }
    }
    if (redlineLevelId !== row.redlineLevelId) {
      row.redlineLevelId = redlineLevelId;
      touched = true;
    }
    if (updateRedlineLevelColor(row)) {
      touched = true;
    }

    let woscoRate, woscoRatePercentage;
    if (ratePeriod && ratePeriod.woscoRate) {
      woscoRate = ratePeriod.woscoRate;
      if (!isNil(ratePeriod.quotedRate)) {
        woscoRatePercentage = calcWoscoRatePercentage(ratePeriod.quotedRate, woscoRate);
      }
    }
    if (woscoRate !== row.woscoRate || woscoRatePercentage !== row.woscoRatePercentage) {
      row.woscoRate = woscoRate;
      row.woscoRatePercentage = woscoRatePercentage;
      touched = true;
    }

    if (recalculateRentalRowFees(state, row)) {
      touched = true;
    }

    recalculateRentalRowLockdown(row);
    validateRentalRow(row);

    if (touched) {
      touchRentalRow(state, row);
    }
  }
};

export const touchRentalRow = (state: ProductsTabState, row: IRentalGridRow) => {
  row.status.touched = true;
  if (row.status.existingItem) {
    row.status.action = CrudActions.UPDATE;
  }
  state[row.tab].rental.status.touched = true;
};

export const createRentalRow = (tab: QuoteProductTabs): IRentalGridRow => ({
  ...makeRentalRowDefaults(),
  tab,
  rowId: uuidv4(),
  code: '',
  unitId: 'EACH',
  ratedManually: false,
  status: {
    existingItem: false,
    action: CrudActions.CREATE,
    touched: false,
    fetched: false,
    fetching: false,
    error: false,
  },
});

export const updateRentalRowFromProfile = (
  state: ProductsTabState,
  item: IRentalGridRow,
  profile: IRentalProductProfile
) => {
  const product = profile.body.entity;
  const {
    units,
    rentalProtectionPlan,
    periods,
    airQuality,
    descriptionEditable,
    environmentalRecovery,
    override,
    rates,
  } = profile.body.custom;

  item.status.fetching = false;
  item.status.fetched = true;
  item.status.error = false;
  item.profileFetched = true;

  item.code = product.code;
  item.cyclePeriodType = periods?.some(({ id }) => id === RatePeriods.MONTHLY)
    ? RatePeriods.MONTHLY
    : RatePeriods.CYCLE;
  item.colors = rates.redline.levels;

  if (state.quoteStateId === WorkflowStates.OPEN) {
    let updated = false;

    if (updateRateTier(item, rates.tier)) {
      updated = true;
    }

    item.rentalProductId = product.id;

    if (item.product?.categoryTypeId !== product.categoryTypeId) {
      updated = true;
    }

    item.product = product;

    item.environmentalRecovery = environmentalRecovery;
    item.rentalProtectionPlan = rentalProtectionPlan;
    item.airQuality = airQuality;
    item.units = units;
    item.periods = periods;
    item.rates = rates.periods;

    if (updateDescription(item, product.description, descriptionEditable)) {
      updated = true;
    }

    const ratedManually = override ?? false;
    if (item.ratedManually !== ratedManually) {
      item.ratedManually = ratedManually;
      updated = true;
    }

    const ratePeriodsById = rates.periods.reduce(
      (byId: { [periodId in RatePeriods]?: IRentalProfileRatePeriod }, ratePeriod) => {
        byId[getCanonicalRatePeriodId(ratePeriod.id)] = ratePeriod;
        return byId;
      },
      {}
    );

    let updateDependentPeriods = false;
    const { dayPeriod, weekPeriod, cyclePeriod } = item;
    for (let period of [dayPeriod, weekPeriod, cyclePeriod]) {
      let periodUpdated = false;
      const periodData = ratePeriodsById[period.id];
      period.editable = periodData?.enabled === true;
      period.sourceId = getCanonicalRatePeriodId(periodData?.sourceId);
      if (periodData && (period.editable || period.sourceId)) {
        if (period.sourceId) {
          updateDependentPeriods = true;
        }

        const listRate = periodData.rate ?? undefined;
        const lockdownRate = periodData.rateLockdown ?? undefined;
        if (ratedManually) {
          // Clear list rate in case product changed from automatically to manually rated.
          if (period.listRate) {
            period.listRate = undefined;
            periodUpdated = true;
          }
        } else {
          if (isNil(period.quotedRate)) {
            if (!isNil(listRate)) {
              period.quotedRate = listRate;

              // Update to any period's quoted rate has to be saved so that we can
              // display correct rates in the grid after quote is submitted.
              updated = true;
            }
          } else if (!item.status.existingItem && item.tier?.modifications) {
            // This is a new row that already has quoted rate for this period.
            // Make sure that this rate is within the tier limits.
            const { minimum, maximum, increase = true, decrease = true } = item.tier.modifications;
            let { quotedRate } = period;

            // Ensure rate is within [min,max].
            if (!isNil(minimum) && period.quotedRate < minimum) {
              quotedRate = minimum;
            } else if (!isNil(maximum) && period.quotedRate > maximum) {
              quotedRate = maximum;
            }

            // If rate violates increase/decrease rules, reset it to list rate.
            if (
              !isNil(listRate) &&
              ((!increase && period.quotedRate > listRate) || (!decrease && period.quotedRate < listRate))
            ) {
              quotedRate = listRate;
            }

            if (quotedRate !== period.quotedRate) {
              period.quotedRate = quotedRate;
              updated = true;
            }
          }
          if (period.listRate !== listRate) {
            period.listRate = listRate;
            periodUpdated = true;
          }
        }
        if (period.lockdownRate !== lockdownRate) {
          period.lockdownRate = lockdownRate;
          updated = true;
        }

        // We don't need to save period's wosco rate changes. We only have to save if
        // this rate affects derived properties needed to display the row.
        period.woscoRate = periodData.woscoRate || undefined;
      } else {
        if (!(isNil(period.quotedRate) && isNil(period.listRate) && isNil(period.lockdownRate))) {
          period.quotedRate = undefined;
          period.listRate = undefined;
          period.lockdownRate = undefined;
          updated = true;
        }
      }
      if (period.editable && item.tier?.modifications) {
        const { increase, decrease, minimum, maximum } = item.tier.modifications;
        period.editorParams = {
          numeric: true,
          currency: true,
          originValue: period.listRate,
          increase,
          decrease,
          minValue: minimum,
          maxValue: maximum,
        };
      } else {
        period.editorParams = {
          numeric: true,
          currency: true,
        };
      }

      // Mark the row for save only if current period changed.
      if (periodUpdated && period.id === item.periodId) {
        updated = true;
      }
    }

    if (updateDependentPeriods) {
      const periods = [dayPeriod, weekPeriod, cyclePeriod];
      const periodsById = keyBy(periods, ({ id }) => id);
      for (const period of periods) {
        if (period.sourceId) {
          const quotedRate = periodsById[period.sourceId]?.quotedRate;
          if (quotedRate !== period.quotedRate) {
            period.quotedRate = quotedRate;
            updated = true;
          }
        }
      }
    }

    let periodId;
    if (periods.length === 1) {
      // If product supports only one period, automatically select it.
      periodId = periods[0].id;
    } else if (item.periodId) {
      // Keep currently selected period only if it is still supported.
      periodId = periods.find(({ id }) => id === item.periodId)?.id;
    }
    if (periodId !== item.periodId) {
      item.periodId = periodId;
      updated = true;
    }

    recalculateRentalRow(state, item, updated);
  } else {
    updateRateTier(item, rates.tier);
    updateRedlineLevelColor(item);
  }
};

export const resetRentalItemState = (state: ProductsTabState, item: IRentalGridRow) => {
  Object.assign(item, makeRentalRowDefaults());
  resetProductRowState(item);
  item.rentalProductId = '';
  item.environmentalRecovery = undefined;
  item.rentalProtectionPlan = undefined;
  item.airQuality = undefined;
  item.tier = undefined;
  item.rates = undefined;
  item.periods = undefined;
  item.periodId = undefined;
  item.duration = undefined;
  delete item.descriptionEditable;
  item.ratedManually = false;
  item.rateTierId = undefined;
  item.rateTierColor = undefined;
  item.rppFee = undefined;
  item.erFee = undefined;
  item.aqFee = undefined;
  item.percentageOfRate = undefined;
  item.woscoRate = undefined;
  item.woscoRatePercentage = undefined;
};

/**
 * Get effective lockdown rate for the purpose of alerting user that the rate they entered
 * is a rate lockdown violation. Sometimes list rate is below lockdown rate, so effective
 * lockdown rate is the minimum of the two. This allows user to enter rates that are below
 * lockdown as long as they are equal to or greater than the list rate.
 */
const getRentalPeriodEffectiveLockdownRate = ({ lockdownRate, listRate }: RentalRatePeriod) =>
  isNil(lockdownRate) || lockdownRate <= 0 ? undefined : min([lockdownRate, listRate]);
