import { styled } from '@mui/material/styles';

export const QuoteDataContainer = styled('span')`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;

  color: white;
  font-size: 12px;

  padding: 5px 10px 5px 0;
`;
