import { RootState } from '../types';

export const TqhSelectors = {
  tqh: (state: RootState) => state.session.tqh.data,
  totals: (state: RootState) => TqhSelectors.tqh(state)?.totals,
  rentalTotals: (state: RootState) => TqhSelectors.tqh(state)?.rental.totals,
  tqhColor: (state: RootState) => TqhSelectors.tqh(state)?.redline?.color.code.rgba || [],
  tqhPercentage: (state: RootState) => TqhSelectors.totals(state)?.percentageOfRateTotal || 0,
  tqhDollarDiffTotal: (state: RootState) => TqhSelectors.totals(state)?.dollarDifferenceTotal || 0,
  tqhFetching: (state: RootState) => state.session.tqh.status.fetching,
};
