import { Popover, Typography } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

interface IPopoverCellRendererProps {
  width?: number;
}

type Props = ICellRendererParams & IPopoverCellRendererProps;

export const PopoverCellRenderer: React.FC<Props> = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className="text-wrap-ellipsis" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {props.value}
      </div>
      {open && (
        <Popover
          sx={{ pointerEvents: 'none' }}
          PaperProps={{ sx: { p: 1 } }}
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div style={{ maxWidth: props.width || 250 }}>
            <Typography>{props.value}</Typography>
          </div>
        </Popover>
      )}
    </div>
  );
};
