import { IQuoteReviewPrintOptions, IValidationError } from '../../../interfaces';
import { FetchStatusState } from '../../types';

export interface ValidationItem extends IValidationError {
  key: string;
}

export type ErrorCodeSet = {
  [ErrorCode: string]: true;
};

export interface ValidationGroup {
  name: string;
  routePath?: string;
  items: ValidationItem[];
}

export interface ValidationGroupWithLookup extends ValidationGroup {
  isProductGroup: boolean;
  errorCodes: ErrorCodeSet;
  errorsByRowId: {
    [RowId: string]: ErrorCodeSet;
  };
}

export interface ValidationGroups {
  clientSide: ValidationGroupWithLookup;
  general: ValidationGroupWithLookup;
  customer: ValidationGroupWithLookup;
  details: ValidationGroupWithLookup;
  scopeChanges: ValidationGroupWithLookup;
  rental: ValidationGroupWithLookup;
  optionalRental: ValidationGroupWithLookup;
  sales: ValidationGroupWithLookup;
  optionalSales: ValidationGroupWithLookup;
  services: ValidationGroupWithLookup;
  optionalServices: ValidationGroupWithLookup;
  statement: ValidationGroupWithLookup;
}

export enum ScheduleValidationTargetGroups {
  details = 'details',
  scopeChanges = 'scopeChanges',
}

export type ValidationGroupNames = keyof ValidationGroups;

export interface ValidationState {
  valid: boolean;
  requestFailed: boolean;
  errorCount: number;
  groups: ValidationGroups;
}

export interface ReviewTabState {
  status: FetchStatusState;
  errorsDrawer: { show: boolean; pinned: boolean };
  quotePrintOptions: IQuoteReviewPrintOptions;
  validation: ValidationState;
}
