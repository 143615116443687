import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { approvalSlice } from '../approval/redux/slice';
import { appSlice } from '../mobile/redux/app/slice';
import { opsPackSlice } from '../OpsPack/redux/slice';
import { changeOrderSlice } from './change-order/slice';
import { customerTabSlice, detailsTabSlice, productsTabSlice, reviewTabSlice, tabsSlice } from './quote-screen';
import { statementTabSlice } from './quote-screen/statement-tab/statement-tab.slice';
import { sessionSlice } from './session';
import { QuoteScreenState } from './types';

const createStore = () =>
  configureStore({
    reducer: {
      session: sessionSlice.reducer,
      quoteScreen: combineReducers<QuoteScreenState>({
        tabs: tabsSlice.reducer,
        customerTab: customerTabSlice.reducer,
        detailsTab: detailsTabSlice.reducer,
        productsTab: productsTabSlice.reducer,
        reviewTab: reviewTabSlice.reducer,
        statementTab: statementTabSlice.reducer,
      }),
      changeOrder: changeOrderSlice.reducer,
      opsPack: opsPackSlice.reducer,
      approval: approvalSlice.reducer,
      [appSlice.name]: appSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
  });

let store: ReturnType<typeof createStore>;

export const getStore = () => store!;

export const initStore = () => {
  if (!store) {
    store = createStore();
  }
  return getStore();
};

export type AppDispatch = typeof store.dispatch;
