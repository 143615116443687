import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'rdx/types';

const activeBranchId = (state: RootState) => state.session.user.branches.active;
const branches = (state: RootState) => state.session.user.branches.data;

const activeBranch = createSelector(activeBranchId, branches, (branchId, branches) =>
  branches.find(({ id }) => id === branchId)
);

const approvalTierFilters = (state: RootState) => state.session.user.approval.tierFilters;

export const UserSelectors = {
  profile: (state: RootState) => state.session.user.profile.data,
  profileFetched: (state: RootState) => state.session.user.profile.fetched,
  approval: (state: RootState) => state.session.user.profile.data?.approval,
  approverViewEnabled: (state: RootState) => state.session.user.approval.approverViewEnabled,
  approvalTierFilters,
  approvalTierFiltersActive: createSelector(approvalTierFilters, (approvalTierFilters) =>
    Object.values(approvalTierFilters).some((selected) => selected)
  ),
  branches,
  activeBranchId,
  activeBranch,
  permissions: (state: RootState) => state.session.user.branches.permissions,
  permissionsReady: (state: RootState) => state.session.user.profile.fetched && state.session.user.branches.fetched,
  isWriter: (state: RootState) =>
    UserSelectors.permissionsReady(state) ? UserSelectors.permissions(state).write : undefined,
};
