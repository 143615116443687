import { stubFalse } from 'lodash';
import { createContext } from 'react';
import { approvalSlice } from '../approval/redux/slice';
import { ApprovalTiers } from '../approval/types';
import { IQuoteReviewMarkCompleteOptions, IValidationResponse, QuoteTypes } from '../interfaces';
import { opsPackSlice } from '../OpsPack/redux/slice';
import { OpsPackService } from '../OpsPack/service/OpsPackService';
import {
  AppDispatch,
  customerTabSlice,
  detailsTabSlice,
  productsTabSlice,
  reviewTabSlice,
  ScheduleValidationTargetGroups,
  sessionSlice,
} from '../rdx';
import { changeOrderSlice } from '../rdx/change-order/slice';
import { statementTabSlice } from '../rdx/quote-screen/statement-tab/statement-tab.slice';
import { RootState } from '../rdx/types';
import { IQuoteStatusData } from '../utils/QuoteStatusAdapter';
import { IZohoSign } from '../zoho/ZohoSignContext';
import { QuoteFacadeSelectorsType } from './selectors';

type Selector<T> = (state: RootState) => T;

export abstract class AbstractQuoteFacade {
  constructor(protected readonly dispatch: AppDispatch, public readonly quoteId: string) {}

  abstract readonly type: QuoteTypes;

  // Features
  abstract readonly canEditCustomerTab: boolean;
  abstract readonly supportsCustomerTabRecentQuotes: boolean;
  abstract readonly supportsDetailsTab: boolean;
  abstract readonly supportsScopeChanges: boolean;
  abstract readonly supportsOptionalProducts: boolean;
  abstract readonly supportsStatementOfWork: boolean;
  abstract readonly supportsAttachedDocuments: boolean;
  abstract readonly supportsPrintPreviewSummaryOption: boolean;
  abstract readonly supportsOpsPack: boolean;
  abstract readonly supportsGoToParent: boolean;

  abstract readonly selectFetchingOrSaving: Selector<boolean>;
  abstract readonly selectQuoteSaved: Selector<boolean>;
  abstract readonly selectQuoteTouched: Selector<boolean>;

  abstract readonly selectors: QuoteFacadeSelectorsType;

  selectHasUnsavedChanges = (state: RootState) => {
    return this.selectQuoteSaved(state) && this.selectQuoteTouched(state);
  };

  abstract init(): void;

  abstract fetchReviewData(): void;

  /**
   * Determines into which validation group schedule (date) errors are placed.
   */
  protected abstract readonly scheduleValidationTargetGroup: ScheduleValidationTargetGroups;

  validate = async () => {
    try {
      this.dispatch(reviewTabSlice.actions.validateQuotePending());
      await this.sendValidationRequest();
      this.dispatch(reviewTabSlice.actions.validateQuoteFulfilled());
    } catch (e: any) {
      if (e.statusCode === 500) {
        this.dispatch(reviewTabSlice.actions.validationRequestFailed());
      } else {
        const response = e.responseErrors;
        this.dispatch(
          reviewTabSlice.actions.validationFailed({
            response,
            scheduleValidationTargetGroup: this.scheduleValidationTargetGroup,
          })
        );
      }
    }
  };

  protected abstract sendValidationRequest(): Promise<IValidationResponse>;

  abstract saveAndValidate(): void;

  abstract save(redirect: (to: string) => void): Promise<boolean>;

  abstract resetChanges(): void;

  resetState = () => {
    this.dispatch(sessionSlice.actions.resetQuote());
    this.dispatch(changeOrderSlice.actions.reset());
    this.dispatch(customerTabSlice.actions.reset());
    this.dispatch(detailsTabSlice.actions.reset());
    this.dispatch(productsTabSlice.actions.reset());
    this.dispatch(reviewTabSlice.actions.reset());
    this.dispatch(statementTabSlice.actions.reset());
    this.dispatch(opsPackSlice.actions.reset());
    this.dispatch(approvalSlice.actions.reset());
    OpsPackService.getInstance().reset();
  };

  markComplete = async (review: IQuoteReviewMarkCompleteOptions): Promise<void> => {
    try {
      this.dispatch(reviewTabSlice.actions.markCompletePending());
      await this.sendMarkCompleteRequest(review);
      this.dispatch(reviewTabSlice.actions.markCompleteFulfilled());
      await this.fetchApprovalProfile();
      await this.fetchHeaderWithPromise();
    } catch (e) {
      this.dispatch(reviewTabSlice.actions.markCompleteRejected());
      throw e;
    }
  };

  protected abstract sendMarkCompleteRequest(review: IQuoteReviewMarkCompleteOptions): Promise<IValidationResponse>;

  abstract fetchHeader(): void;

  protected abstract fetchHeaderWithPromise(): Promise<unknown>;

  abstract readonly submitForApprovalWarningsHeaderKey: string;

  abstract openPdf(quoteStatusData: IQuoteStatusData, code: string): void;

  selectGoToParentDisabled: Selector<boolean> = stubFalse;

  goToParent = () => {};

  abstract fetchApprovalProfile(): Promise<any>;

  approve = async (tier: ApprovalTiers, allTiers: boolean) => {
    await this.sendApproveRequest(tier, allTiers);
    await this.fetchApprovalProfile();
    this.fetchHeader();
  };

  protected abstract sendApproveRequest(tier: ApprovalTiers, allTiers: boolean): Promise<void>;

  reject = async (tier: ApprovalTiers, reason: string) => {
    await this.sendRejectRequest(tier, reason);
    await this.fetchApprovalProfile();
    this.fetchHeader();
  };

  protected abstract sendRejectRequest(tier: ApprovalTiers, reason: string): Promise<void>;

  markWon = async () => {
    await this.sendMarkWonRequest();
    this.fetchHeader();
  };

  protected abstract sendMarkWonRequest(): Promise<void>;

  markLost = async (reasonId: string, comment: string) => {
    await this.sendMarkLostRequest(reasonId, comment);
    this.fetchHeader();
  };

  protected abstract sendMarkLostRequest(reasonId: string, comment: string): Promise<void>;

  protected abstract fetchHeaderAndUpdateFees(): void;

  reopen = async () => {
    // Ensures fetching of the latest product and service profiles for proper rerating.
    this.dispatch(productsTabSlice.actions.emptyProfileCache());
    await this.sendReopenRequest();
    await this.fetchApprovalProfile();
    this.fetchHeaderAndUpdateFees();
  };

  protected abstract sendReopenRequest(): Promise<void>;

  abstract deleteQuote(): Promise<void>;

  abstract sendToZohoSign(zohoSign: IZohoSign): Promise<void>;
}

export const QuoteFacadeContext = createContext<AbstractQuoteFacade>(null as unknown as AbstractQuoteFacade);
