import React from 'react';

export interface IZohoSign {
  authorize: () => Promise<boolean>;
  sendQuote: (id: string) => Promise<true | undefined>;
  sendChangeOrder: (quoteId: string, changeOrderId: string) => Promise<true | undefined>;
}

export const ZohoSignContext = React.createContext<IZohoSign>({
  authorize: () => Promise.reject(),
  sendQuote: () => Promise.reject(),
  sendChangeOrder: () => Promise.reject(),
});
