import { IGridRow } from '../../../../interfaces';
import { ValidationGroupNames } from '../../../index';

export interface ValidationField {
  validationGroup: ValidationGroupNames;
  errorCodes: string[];
}

export const makeValidationField = (validationGroup: ValidationGroupNames, errorCodes: string[]) => ({
  validationGroup,
  errorCodes,
});

export interface QuoteValidationField extends ValidationField {
  key: QuoteValidationFieldKeys;
}

const makeQuoteValidationField = (
  key: QuoteValidationFieldKeys,
  groupName: ValidationGroupNames,
  errorCodes: string[]
) => ({
  ...makeValidationField(groupName, errorCodes),
  key,
});

export enum QuoteValidationFieldKeys {
  Account = 'Account',
  JobName = 'JobName',
  JobNameLocal = 'JobNameLocal',
  MarketSegment = 'MarketSegment',
  MarketSector = 'MarketSector',
  TaxExempt = 'TaxExempt',
  WorkOfImprovement = 'WorkOfImprovement',
  PrevailingWage = 'PrevailingWage',
  ScopeChange = 'ScopeChange',
  Application = 'Application',
  ApplicationMaterial = 'ApplicationMaterial',
  DeliveryDate = 'DeliveryDate',
  CompletionDate = 'CompletionDate',
  ChangeOrderDeliveryDate = 'ChangeOrderDeliveryDate',
  ChangeOrderCompletionDate = 'ChangeOrderCompletionDate',
  StatementTemplate = 'StatementTemplate',
}

export type QuoteValidationFieldsMap = {
  [FieldKey in QuoteValidationFieldKeys]: QuoteValidationField;
};

class QuoteValidationCodes {
  public static readonly MISSING_APPLICATION = '_required_application';
  public static readonly MISSING_APPLICATION_MATERIAL = '_required_application_material';
}

class JobsiteValidationCodes {
  public static MISSING_JOB_NAME: string = '_required_jobName';
  public static MISSING_MARKET_SECTOR: string = '_required_marketSector';
}

export enum ClientSideValidationCodes {
  MissingJobsiteName = 'MissingJobsiteName',
  MissingMarketSegment = 'MissingMarketSegment',
  MissingTaxExempt = 'MissingTaxExempt',
  MissingWorkOfImprovement = 'MissingWorkOfImprovement',
  MissingPrevailingWage = 'MissingPrevailingWage',
  AccountClosed = 'AccountClosed',
}

class ScopeChangeValidationCodes {
  public static MISSING_SCOPE_CHANGE: string = '_required_scope_change';
  public static INVALID_LENGTH_SCOPE_CHANGE: string = '_length_scope_change';
}

class ScheduleValidationCodes {
  public static MISSING_ESTIMATED_DELIVERY_DATE: string = '_required_delivery_date';
  public static MISSING_ESTIMATED_COMPLETION_DATE: string = '_required_completion_date';
  public static LOWER_COMPLETION_THAN_DELIVERY_DATE: string = '_lower_completion_than_delivery_date';
}

class StatementValidationCodes {
  public static MISSING_STATEMENT_OF_WORK: string = '_required_statement_of_work';
}

/**
 * Validation fields that are not in product/service grids.
 */
export const QuoteValidationFields: QuoteValidationFieldsMap = {
  Account: makeQuoteValidationField(QuoteValidationFieldKeys.Account, 'clientSide', [
    ClientSideValidationCodes.AccountClosed,
  ]),
  JobName: makeQuoteValidationField(QuoteValidationFieldKeys.JobName, 'customer', [
    JobsiteValidationCodes.MISSING_JOB_NAME,
  ]),
  JobNameLocal: makeQuoteValidationField(QuoteValidationFieldKeys.JobNameLocal, 'clientSide', [
    ClientSideValidationCodes.MissingJobsiteName,
  ]),
  MarketSegment: makeQuoteValidationField(QuoteValidationFieldKeys.MarketSegment, 'clientSide', [
    ClientSideValidationCodes.MissingMarketSegment,
  ]),

  MarketSector: makeQuoteValidationField(QuoteValidationFieldKeys.MarketSector, 'customer', [
    JobsiteValidationCodes.MISSING_MARKET_SECTOR,
  ]),

  TaxExempt: makeQuoteValidationField(QuoteValidationFieldKeys.TaxExempt, 'clientSide', [
    ClientSideValidationCodes.MissingTaxExempt,
  ]),
  WorkOfImprovement: makeQuoteValidationField(QuoteValidationFieldKeys.WorkOfImprovement, 'clientSide', [
    ClientSideValidationCodes.MissingWorkOfImprovement,
  ]),
  PrevailingWage: makeQuoteValidationField(QuoteValidationFieldKeys.PrevailingWage, 'clientSide', [
    ClientSideValidationCodes.MissingPrevailingWage,
  ]),
  ScopeChange: makeQuoteValidationField(QuoteValidationFieldKeys.ScopeChange, 'scopeChanges', [
    ScopeChangeValidationCodes.MISSING_SCOPE_CHANGE,
    ScopeChangeValidationCodes.INVALID_LENGTH_SCOPE_CHANGE,
  ]),
  Application: makeQuoteValidationField(QuoteValidationFieldKeys.Application, 'details', [
    QuoteValidationCodes.MISSING_APPLICATION,
  ]),
  ApplicationMaterial: makeQuoteValidationField(QuoteValidationFieldKeys.ApplicationMaterial, 'details', [
    QuoteValidationCodes.MISSING_APPLICATION_MATERIAL,
  ]),
  DeliveryDate: makeQuoteValidationField(QuoteValidationFieldKeys.DeliveryDate, 'details', [
    ScheduleValidationCodes.MISSING_ESTIMATED_DELIVERY_DATE,
  ]),
  CompletionDate: makeQuoteValidationField(QuoteValidationFieldKeys.CompletionDate, 'details', [
    ScheduleValidationCodes.MISSING_ESTIMATED_COMPLETION_DATE,
    ScheduleValidationCodes.LOWER_COMPLETION_THAN_DELIVERY_DATE,
  ]),
  ChangeOrderDeliveryDate: makeQuoteValidationField(QuoteValidationFieldKeys.ChangeOrderDeliveryDate, 'scopeChanges', [
    ScheduleValidationCodes.MISSING_ESTIMATED_DELIVERY_DATE,
  ]),
  ChangeOrderCompletionDate: makeQuoteValidationField(
    QuoteValidationFieldKeys.ChangeOrderCompletionDate,
    'scopeChanges',
    [
      ScheduleValidationCodes.MISSING_ESTIMATED_COMPLETION_DATE,
      ScheduleValidationCodes.LOWER_COMPLETION_THAN_DELIVERY_DATE,
    ]
  ),
  StatementTemplate: makeQuoteValidationField(QuoteValidationFieldKeys.StatementTemplate, 'statement', [
    StatementValidationCodes.MISSING_STATEMENT_OF_WORK,
  ]),
};

export type ProductValidationFieldsByColId<RowType extends IGridRow> = {
  [ColId in keyof RowType | string]?: ValidationField;
};
