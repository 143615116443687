import { Box, Button } from '@mui/material';
import { FileCopy, PostAddOutlined } from '@mui/icons-material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SetEnabledTabsPayload, tabsSlice } from 'rdx';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { isQuoteTab, QuoteInnerTabs, QuoteTabs } from '../../enums';
import { QuoteFacadeContext } from '../../facade/AbstractQuoteFacade';
import { productsTabSlice } from '../../rdx';
import { UserSelectors } from '../../rdx/session/user.selectors';
import { RootState } from '../../rdx/types';
import { RoutePaths } from '../../routes/routes';
import { ComponentIds } from '../../utils/component-ids';
import { QuoteData } from '../QuoteData';
import { TabButton } from '../TabButton';
import { TabsContainer } from './TabsBar.styles';

interface TabsBarProps {
  redirect: (to: string) => void;
}

export const TabsBar: React.FC<TabsBarProps> = ({ redirect }) => {
  const match = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const params: any = useParams();

  const quote = useContext(QuoteFacadeContext);

  const permissions = useSelector(UserSelectors.permissions);
  const branches = useSelector(UserSelectors.branches);
  const activeBranch = useSelector(UserSelectors.activeBranchId);

  const tabs = useSelector((state: RootState) => state.quoteScreen.tabs?.tabs);

  const quoteSaved = useSelector(quote.selectQuoteSaved);
  const quoteTouched = useSelector(quote.selectQuoteTouched);

  useEffect(() => {
    const path = location.pathname;
    let [route] = path.split('/').slice(-1);
    if (route) {
      if (params.id === 'new' && route !== 'customer') {
        dispatch(tabsSlice.actions.setEnabledTabsAction({ [QuoteTabs.Customer]: true }));
        redirect(`/quote/new/customer${location.search}`);
      } else {
        dispatch(productsTabSlice.actions.toggleSearchDrawer({ opened: false }));

        let innerTab: QuoteInnerTabs | undefined;
        if (route === QuoteInnerTabs.Rental || route === QuoteInnerTabs.Sales || route === QuoteInnerTabs.Services) {
          innerTab = route;
          const [parentRoute] = path.split('/').slice(-2);
          route = parentRoute;
        }
        let tab = route;
        if (route === 'change-order') {
          tab = QuoteTabs.ChangeOrder;
        } else if (route === RoutePaths.ScopeChanges) {
          tab = QuoteTabs.ScopeChanges;
        }
        if (isQuoteTab(tab)) {
          dispatch(tabsSlice.actions.setActiveTab({ tab, innerTab }));
        }
      }
    }
  }, [dispatch, location.pathname, location.search, params.id, redirect]);

  useEffect(() => {
    if (activeBranch && branches.length) {
      let hasPermissions = false;
      const current = branches.find((b: any) => b.id === activeBranch);
      if (current && current.permissions.write) {
        hasPermissions = true;
      }
      if (params.id === 'new' && !hasPermissions) {
        redirect('/landing');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, branches, activeBranch, params.id]);

  const { statementTabEnabled } = useSelector(quote.selectors.statementTabEnabled);
  const { approvalTabEnabled } = useSelector(quote.selectors.approvalTabEnabled);
  const supportsChangeOrders = useSelector(quote.selectors.supportsChangeOrders);
  const { changeOrdersTabEnabled } = useSelector(quote.selectors.changeOrdersTabEnabled);

  useEffect(() => {
    const enabledTabs: SetEnabledTabsPayload = {
      [QuoteTabs.Customer]: true,
    };
    if (quoteSaved) {
      enabledTabs[QuoteTabs.Details] = true;
      enabledTabs[QuoteTabs.ScopeChanges] = true;
      enabledTabs[QuoteTabs.Products] = true;
      enabledTabs[QuoteTabs.Optional] = true;
      enabledTabs[QuoteTabs.Statement] = statementTabEnabled;
      enabledTabs[QuoteTabs.Documents] = true;
      enabledTabs[QuoteTabs.Approval] = approvalTabEnabled;
      enabledTabs[QuoteTabs.ChangeOrder] = changeOrdersTabEnabled;
      enabledTabs[QuoteTabs.Review] = !quoteTouched;
    }
    dispatch(tabsSlice.actions.setEnabledTabsAction(enabledTabs));
  }, [dispatch, quote, quoteSaved, quoteTouched, changeOrdersTabEnabled, statementTabEnabled, approvalTabEnabled]);

  const { supportsGoToParent } = quote;
  const goToParentDisabled = useSelector(quote.selectGoToParentDisabled);

  return (
    <TabsContainer>
      <TabButton
        to={`${match.url}/customer`}
        text="Customer"
        icon={<PersonIcon />}
        disabled={tabs?.customer.disabled}
        data-test={ComponentIds.CustomerTab}
      />
      {quote.supportsDetailsTab && (
        <TabButton
          to={`${match.url}/details`}
          text="Details"
          icon={<DescriptionIcon />}
          disabled={tabs?.details.disabled}
          data-test={ComponentIds.DetailsTab}
        />
      )}
      {quote.supportsScopeChanges && (
        <TabButton
          to={`${match.url}/${RoutePaths.ScopeChanges}`}
          text="Scope Changes"
          icon={<DescriptionIcon />}
          disabled={tabs?.scopeChanges.disabled}
        />
      )}
      <TabButton
        to={`${match.url}/products`}
        text="Products"
        icon={<FormatListNumberedIcon />}
        disabled={tabs?.products.disabled}
        data-test={ComponentIds.ProductsTab}
      />
      {quote.supportsOptionalProducts && (
        <TabButton
          to={`${match.url}/optional`}
          text="Optional"
          icon={<FormatListNumberedRtlIcon />}
          disabled={tabs?.optional.disabled}
          data-test={ComponentIds.OptionalTab}
        />
      )}
      {quote.supportsStatementOfWork && (
        <TabButton
          to={`${match.url}/statement`}
          text="Statement Of Work"
          icon={<AssignmentIcon />}
          disabled={tabs?.statement.disabled}
        />
      )}
      {quote.supportsAttachedDocuments && (
        <TabButton
          to={`${match.url}/documents`}
          text="Documents"
          icon={<AttachFileIcon />}
          disabled={tabs?.documents.disabled}
        />
      )}
      <TabButton
        to={`${match.url}/${RoutePaths.Review}`}
        text="Review"
        icon={<VisibilityIcon />}
        disabled={tabs?.review.disabled}
        data-test={ComponentIds.ReviewTab}
      />
      <TabButton
        to={`${match.url}/${RoutePaths.Approval}`}
        text="Approval & Status"
        icon={<CheckCircleOutlineIcon />}
        disabled={tabs?.approval.disabled}
        data-test={ComponentIds.ApprovalTab}
      />
      {supportsChangeOrders && (
        <TabButton
          to={`${match.url}/change-order`}
          text="Change Order"
          icon={<PostAddOutlined />}
          disabled={tabs?.changeOrder.disabled}
        />
      )}
      <Box display="flex" alignItems="center" px={1}>
        <QuoteData />
        {supportsGoToParent && (
          <Button size="small" startIcon={<FileCopy />} disabled={goToParentDisabled} onClick={quote.goToParent}>
            Back to Quote
          </Button>
        )}
      </Box>
    </TabsContainer>
  );
};
