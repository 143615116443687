import 'reflect-metadata';
import MobileDetect from 'mobile-detect';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { container as diContainer, DependencyContainer } from 'tsyringe';
import { AppConfigurationService } from './api';
import { PageMessage } from './components';
import { underTest } from './constants';
import { registerDependencies } from './di/registrations';

let mobile = false;

// @ts-ignore
const { Cypress } = window;
if (Cypress) {
  mobile = !!Cypress.env('mobile');
} else {
  const md = new MobileDetect(navigator.userAgent);
  mobile = !!md.phone();
}

registerDependencies();

export interface ISalesProCypressTest {
  onAppStarted?(container: DependencyContainer): void;
}

if (underTest) {
  // Allow Cypress test to access DI container for mocking/spying.
  const test: ISalesProCypressTest = Cypress.env('salesProTest');
  test?.onAppStarted?.(diContainer);
}

const importApp = () =>
  mobile
    ? import(
        /* webpackChunkName: "mobile" */
        './mobile/App'
      )
    : import(
        /* webpackChunkName: "desktop" */
        './App'
      );

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<PageMessage text="loading..." />);

Promise.all([importApp(), AppConfigurationService.getInstance().init()]).then(
  ([{ App }]) => {
    root.render(<App />);
  },
  (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
  }
);
