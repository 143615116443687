import { PayloadAction } from '@reduxjs/toolkit';
import { ProductsTabState, WindowBounds } from './products-tab.types';

// Todo: Rename all Drawer-related symbols.
export const toggleSearchDrawer = (
  state: ProductsTabState,
  { payload }: PayloadAction<{ opened?: boolean; initialQuery?: string }>
) => {
  state.searchOpened = payload.opened ?? !state.searchOpened;
  state.initialSearchQuery = payload.initialQuery ?? '';
};

export const clearInitialSearchQuery = (state: ProductsTabState) => {
  state.initialSearchQuery = '';
};

export const saveSearchWindowBounds = (state: ProductsTabState, { payload }: PayloadAction<WindowBounds>) => {
  state.searchWindowBounds = payload;
};
