import { CrudActions, QuoteGroups } from '../enums';
import { IOutboundGroup } from '../interfaces';
import { IDetailEventMetadata } from '../interfaces/DetailEvent';
import { ChangeOrderGroups } from '../rdx/change-order/types';

/**
 * Minimal row type required by {@link convertRowsToSaveEvents}.
 */
export interface ConvertibleRow {
  status: {
    action: CrudActions;
  };
}

/**
 * Signature for the convert callback parameter passed to {@link convertRowsToSaveEvents}.
 * This function is called for every modified row and should create and return the event
 * object for that row.
 *
 * @param state State parameter originally passed to {@link convertRowsToSaveEvents}
 * @param row Row to convert to event.
 * @param timestamp Timestamp to use for the event.
 * @param action - {@link CrudActions} to use for the event.
 * @param rowNumber Row number to use for the event.
 */
export type ConvertRowToEvent<State, RowType, EventType> = (
  state: State,
  row: RowType,
  timestamp: number,
  action: CrudActions,
  rowNumber: number
) => EventType;

/**
 * Signature for isRowSavable function passed to {@link convertRowsToSaveEvents}.
 * This function is called for every modified row and should return whether that row
 * is valid enough to be saved, e.g. is it associated with a valid product?
 */
export type IsRowSavable<RowType> = (row: RowType) => boolean;

/**
 * Converts an array of products or service grids to an array of event objects for saving.
 * @param state State value to pass through to <code>convert</code> callback.
 * @param rows Rows to convert to save events.
 * @param isRowSavable See {@link IsRowSavable}
 * @param convert See {@link ConvertRowToEvent}
 */
export const convertRowsToSaveEvents = <State, RowType extends ConvertibleRow, EventType>(
  state: State,
  rows: RowType[],
  isRowSavable: IsRowSavable<RowType>,
  convert: ConvertRowToEvent<State, RowType, EventType>
) => {
  let timestamp = Date.now() - rows.length;
  let rowNumber = 1;
  return rows.reduce((events: EventType[], row) => {
    let { action } = row.status;
    if (action === CrudActions.DELETE) {
      events.push(convert(state, row, timestamp++, action, rowNumber));
      // Do not increment rowNumber since deleting a row moves subsequent rows up.
    } else if (!isRowSavable(row)) {
      if (action !== CrudActions.CREATE) {
        // Delete existing row because it is not associated with a product any more.
        events.push(convert(state, row, timestamp++, CrudActions.DELETE, rowNumber));
      }
      // Do not increment rowNumber because we are deleting a row or NOT adding one.
    } else {
      if (action !== CrudActions.READ) {
        events.push(convert(state, row, timestamp++, action, rowNumber));
      }
      // Increment row number because we are creating, updating, or skipping a row at
      // current position, so next event will have to be at next row number.
      rowNumber++;
    }
    return events;
  }, []);
};

export const createProductsGroup = (events: any[], groupId: QuoteGroups | ChangeOrderGroups): IOutboundGroup | null =>
  events.length
    ? {
        id: groupId,
        content: {
          events,
        },
      }
    : null;

export const makeDetailEventMetadata = (rowId: string, timestamp: number, action: CrudActions, index: number) => {
  const metadata: IDetailEventMetadata = { action, timestamp };
  if (action === CrudActions.CREATE) {
    metadata.atIndex = index;
  } else {
    metadata.rowId = rowId;
  }
  return metadata;
};
