import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { ConfirmDialogProps } from './ConfirmDialog.props';

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  return (
    <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="confirm-dialog">
      <DialogTitle className="text-white">
        {!!props.icon && (
          <span className="mr-3" style={{ position: 'relative', top: 5 }}>
            {props.icon}
          </span>
        )}
        {props.title || 'Confirm'}
      </DialogTitle>
      <DialogContent>
        {props.message && <Typography color="textSecondary">{props.message}</Typography>}
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onCancel} color="secondary">
          {props.cancelBtnLabel || 'Cancel'}
        </Button>
        <Button variant="contained" onClick={props.onConfirm}>
          {props.confirmBtnLabel || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
