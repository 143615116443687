import { CheckCircle } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import classNames from 'classnames/bind';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logoFull from '../../assets/images/SalesPro-alt-horizontal-white-trans.svg';
import logo from '../../assets/images/SalesPro-color-on-white.svg';
import { AboutModal } from '../../containers/Partials/AboutModal';
import { QuoteFacadeContext } from '../../facade/AbstractQuoteFacade';
import { i18nKeys } from '../../internationalization/i18nKeys';
import { QuoteSelectors } from '../../rdx';
import { UserSelectors } from '../../rdx/session/user.selectors';
import { ConfirmDialog } from '../ConfirmDialog';
import { MenuItem, SideMenuContainer } from './SideMenu.style';

const newQuotePathname = '/quote/new/customer';

export const SideMenu: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const quote = useContext(QuoteFacadeContext);

  const permissions = useSelector(UserSelectors.permissions);
  const hasUnsavedChanges = useSelector(quote.selectHasUnsavedChanges);

  const [opened, setOpened] = useState(false);
  const [openAboutDialog, setOpenAboutDialog] = useState(false);

  const handleOpenAboutDialog = () => {
    setOpenAboutDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenAboutDialog(false);
  };

  const approverViewEnabled = useSelector(UserSelectors.approverViewEnabled);

  const cloning = useSelector(QuoteSelectors.cloning);

  const [navigationPathToConfirm, setNavigationPathToConfirm] = useState<string | null>(null);

  const navigate = (path: string) => {
    history.push(path, { prev: location.pathname });
  };

  const onNewQuoteClick = (e: any) => {
    if (!permissions.write) {
      e.preventDefault();
    } else if (cloning) {
      e.preventDefault();
      window.location.assign(newQuotePathname);
    } else {
      onNavigationLinkClick(e, newQuotePathname);
    }
  };

  const onNavigationLinkClick = (e: any, path: string) => {
    e.preventDefault();
    if (!hasUnsavedChanges || !permissions.write) {
      navigate(path);
    } else {
      setNavigationPathToConfirm(path);
    }
  };

  return (
    <>
      <SideMenuContainer className={classNames({ opened: opened })}>
        <div className="logo" onClick={() => setOpened(!opened)} style={{ height: '100%', maxHeight: '64px' }}>
          <img className="full" src={logoFull} alt={t('landing.salesPro')} />
          <img className="small" src={logo} alt={t('landing.salesPro')} />
          <span className="caret" />
        </div>

        <Grid container direction="column" justifyContent="space-between" className="h-100">
          <Grid item className="flex-grow-1">
            <Link to="/landing" onClick={(e) => onNavigationLinkClick(e, '/landing')}>
              <MenuItem className={classNames({ active: location.pathname.startsWith('/landing') })}>
                <HomeIcon />
                <span>{t('sideMenu.home')}</span>
              </MenuItem>
            </Link>

            {approverViewEnabled && (
              <Link to="/branches" onClick={(e) => onNavigationLinkClick(e, '/branches')}>
                <MenuItem className={classNames({ active: location.pathname.startsWith('/branches') })}>
                  <CheckCircle />
                  <span>Approval</span>
                </MenuItem>
              </Link>
            )}

            <Link
              to={newQuotePathname}
              className={classNames({ 'disabled-link': !permissions.write })}
              onClick={onNewQuoteClick}
            >
              <MenuItem className={classNames({ active: location.pathname.startsWith('/quote') })}>
                <NoteAddIcon />
                <span>{t('sideMenu.newQuote')}</span>
              </MenuItem>
            </Link>
          </Grid>
          <Box sx={{ display: 'flex', placeContent: 'center', width: '100%', py: 1 }}>
            <Button
              onClick={() => {
                window.open(
                  'https://forms.zohopublic.com/rainforrent/form/SalesProSupport/formperma/_m-XBXFcHzvrQj6xv7rIvSzRjgWNGk2odXA-sZypv7w',
                  '_blank',
                  'noopener'
                );
              }}
              size="medium"
              sx={{ maxWidth: '90%', px: 0.5 }}
            >
              <Typography noWrap={true}>{t(i18nKeys.help.helpButton)}</Typography>
            </Button>
          </Box>
          <Grid item className="flex-grow-0" style={{ maxHeight: 50 }}>
            <div className="about">
              <IconButton onClick={handleOpenAboutDialog} size="large">
                <InfoIcon />
              </IconButton>
              <span>{t('sideMenu.about')}</span>
            </div>
          </Grid>
        </Grid>
      </SideMenuContainer>

      <AboutModal onClose={handleCloseDialog} open={openAboutDialog} />
      {navigationPathToConfirm && (
        <ConfirmDialog
          open={true}
          title={t(i18nKeys.discardChangesDialog.title)}
          message={t(i18nKeys.discardChangesDialog.message, { context: quote.type })}
          onConfirm={() => navigate(navigationPathToConfirm)}
          onCancel={() => setNavigationPathToConfirm(null)}
        />
      )}
    </>
  );
};
