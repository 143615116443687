import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'rdx/types';
import { makeRateLockdownProducts } from '../../approval/rate-lockdown';
import { AccountTypes } from '../../enums';

const slice = (state: RootState) => state.session.quote;
const data = (state: RootState) => slice(state).data;
const header = (state: RootState) => data(state)?.header;
const account = (state: RootState) => header(state)?.main?.account;
const ownership = (state: RootState) => header(state)?.ownership;
const schedule = (state: RootState) => header(state)?.schedule;
const permissions = (state: RootState) => data(state)?.extensions?.permissions;
const detail = (state: RootState) => data(state)?.detail;
const changeOrders = (state: RootState) => slice(state).changeOrders;

const rentalProducts = (state: RootState) => detail(state)?.rentalProducts;
const optionalRentalProducts = (state: RootState) => detail(state)?.optionalRentalProducts;
const hasRentalRateLockdownExceptions = (state: RootState) => header(state)?.totals.rateLockdownRequired ?? false;
const hasOptionalRentalRateLockdownExceptions = (state: RootState) =>
  header(state)?.totals.rateLockdownOptional ?? false;

const workflowState = (state: RootState) => data(state)?.stateId;
const approval = (state: RootState) => header(state)?.approval;
const outcome = (state: RootState) => header(state)?.outcome;

export const QuoteSelectors = {
  new: (state: RootState) => slice(state).new,
  loading: (state: RootState) => slice(state).fetching || slice(state).saving,
  fetched: (state: RootState) => slice(state).fetched,
  saved: (state: RootState) => slice(state).saved,

  quoteId: (state: RootState) => data(state)?.id,
  permissions,
  workflowState,
  approval,
  outcome,
  assignedTo: (state: RootState) => ownership(state)?.assignedTo,
  preparedBy: (state: RootState) => ownership(state)?.preparedBy,
  estimator: (state: RootState) => ownership(state)?.estimator,
  supportSpecialist: (state: RootState) => ownership(state)?.supportSpecialist,
  preparedTimestamp: (state: RootState) => ownership(state)?.preparedTimestamp,
  deliveryTimestamp: (state: RootState) => schedule(state)?.deliveryTimestamp,
  completionTimestamp: (state: RootState) => schedule(state)?.completionTimestamp,
  branchId: (state: RootState) => header(state)?.main?.branch.id,
  branchCode: (state: RootState) => header(state)?.main?.branch.code,
  number: (state: RootState) => header(state)?.main?.code,
  account,
  accountId: (state: RootState) => account(state)?.id,
  accountName: (state: RootState) => account(state)?.name,
  exemptFromFuelSurcharge: (state: RootState) => account(state)?.exemptFromFuelSurcharge === true,
  isProspectAccount: (state: RootState) => header(state)?.main?.account.accountTypeId === AccountTypes.PROSPECT,
  marketSegmentId: (state: RootState) => header(state)?.main.marketSegmentId,
  approvalRequestInfo: (state: RootState) => header(state)?.review,
  migration: (state: RootState) => header(state)?.migration,
  migrated: (state: RootState) => header(state)?.migration?.migrated === true,
  cloned: (state: RootState) => header(state)?.clone.cloned === true,
  cloneData: (state: RootState) => header(state)?.clone,
  statementTabEnabled: (state: RootState) => {
    const quoteHeader = header(state);
    return quoteHeader ? !quoteHeader.migration?.migrated : undefined;
  },
  jobsite: (state: RootState) => header(state)?.jobsite,
  fees: (state: RootState) => header(state)?.fees,
  currentFees: (state: RootState) => data(state)?.currentFees,

  canBeCompleted: (state: RootState) => permissions(state)?.canBeCompleted ?? false,
  canCudDocuments: (state: RootState) => permissions(state)?.canCudDocuments ?? false,
  canCudStatement: (state: RootState) => permissions(state)?.canCudStatement ?? false,
  canGenerateOpsPack: (state: RootState) => permissions(state)?.canGenerateOpsPack ?? false,
  canRegenerateOpsPack: (state: RootState) => permissions(state)?.canRegenerateOpsPack ?? false,
  canCudChangeOrders: (state: RootState) => permissions(state)?.canCudChangeOrders ?? false,
  canBeWon: (state: RootState) => permissions(state)?.canBeWon ?? false,
  canBeLost: (state: RootState) => permissions(state)?.canBeLost ?? false,
  canBeReopened: (state: RootState) => permissions(state)?.canBeReopened ?? false,
  canCudOptionalNotes: (state: RootState) => permissions(state)?.canCudOptionalNotes !== true,

  rentalProducts,
  salesProducts: (state: RootState) => detail(state)?.salesProducts,
  services: (state: RootState) => detail(state)?.services,
  optionalRentalProducts,
  optionalSalesProducts: (state: RootState) => detail(state)?.optionalSalesProducts,
  optionalServices: (state: RootState) => detail(state)?.optionalServices,

  fetchingChangeOrders: (state: RootState) => changeOrders(state).fetching,
  changeOrdersFetched: (state: RootState) => changeOrders(state).fetched,
  changeOrders: (state: RootState) => changeOrders(state).changeOrders,

  clone: (state: RootState) => slice(state).clone,
  cloning: (state: RootState) => !!slice(state).clone,
  cloningAccount: (state: RootState) => !!slice(state).clone?.options.account,

  hasRentalRateLockdownExceptions,
  hasOptionalRentalRateLockdownExceptions,
  hasRateLockdownExceptions: (state: RootState) =>
    hasRentalRateLockdownExceptions(state) || hasOptionalRentalRateLockdownExceptions(state),

  rateLockdownProducts: createSelector(
    rentalProducts,
    hasRentalRateLockdownExceptions,
    optionalRentalProducts,
    hasOptionalRentalRateLockdownExceptions,
    makeRateLockdownProducts
  ),
};
