import { GetApp } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { base64StringToBlob } from 'blob-util';
import { useSnackbar } from 'notistack';
import { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs, PDFPageProxy } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useDispatch, useSelector } from 'react-redux';
import { PdfService } from '../../api/pdf.service';
import { AppEnvironmentContext } from '../../AppEnvironmentContext';
import { ZOHO_SIGN_ENABLED } from '../../constants';
import { i18nKeys } from '../../internationalization/i18nKeys';
import { SessionSelectors, sessionSlice } from '../../rdx';
import { ComponentIds } from '../../utils/component-ids';
import { ZohoSignContext } from '../../zoho/ZohoSignContext';
import { ZohoSignIcon } from '../../zoho/ZohoSignIcon';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FIRST_PAGE = 1;
const INITIAL_SCALE = 1;
const MIN_SCALE = 1;
const MAX_SCALE = 2;
const SCALE_INCREMENT = 1;
const ROTATED_NONE = 0;
const ROTATED_LEFT = 270;
const ROTATED_RIGHT = 90;
const PAGE_PADDING = 8;

interface PdfDocumentData {
  url: string;
}

const keys = i18nKeys.pdfViewer;

export const PdfViewerModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { quoteId, changeOrderId } = useSelector(SessionSelectors.pdfViewer);
  const [documentData, setDocumentData] = useState<PdfDocumentData>();

  useEffect(() => {
    if (!quoteId) {
      return;
    }
    let cancelled = false;
    PdfService.getInstance()
      .generateQuotePdf(quoteId, changeOrderId)
      .then((data) => {
        if (cancelled) {
          // Discard data because document load was cancelled.
          return;
        }
        let blob;
        if (data) {
          try {
            blob = base64StringToBlob(data, 'application/pdf');
            setDocumentData({ url: URL.createObjectURL(blob) });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('PDF Viewer failed to create Blob from PDF data.', e);
          }
        }
        if (!blob) {
          enqueueSnackbar(t(changeOrderId ? keys.errorGeneratingChangeOrderPdf : keys.errorGeneratingQuotePdf), {
            variant: 'error',
          });
          dispatch(sessionSlice.actions.closePdfViewer());
        }
      });
    return () => {
      cancelled = true;
    };
  }, [dispatch, enqueueSnackbar, quoteId, changeOrderId, t]);

  if (!quoteId) {
    return null;
  }

  const onClose = () => {
    if (documentData) {
      URL.revokeObjectURL(documentData.url);
      setDocumentData(undefined);
    }
    dispatch(sessionSlice.actions.closePdfViewer());
  };

  return <PdfViewerDialog documentData={documentData} onClose={onClose} />;
};

interface IPdfViewerDialogProps {
  documentData?: PdfDocumentData;
  onClose: () => void;
}

const PdfViewerDialog: React.FC<IPdfViewerDialogProps> = (props) => {
  const { documentData } = props;
  const { t } = useTranslation();
  const { mobile } = useContext(AppEnvironmentContext);
  const { quoteId, changeOrderId, canSave, saveFileName, canSendToZohoSign } = useSelector(SessionSelectors.pdfViewer);
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState(FIRST_PAGE);
  const [rotation, setRotation] = useState(ROTATED_NONE);

  const [scale, setScale] = useState(INITIAL_SCALE);
  const [pageWidth, setPageWidth] = useState(0);

  useEffect(() => {
    if (mobile) {
      const updatePageWidth = () => {
        setPageWidth(window.innerWidth - PAGE_PADDING * 2);
      };
      updatePageWidth();
      window.addEventListener('resize', updatePageWidth);
      return () => {
        window.removeEventListener('resize', updatePageWidth);
      };
    }
  }, [mobile]);

  const onDocumentLoadSuccess = (document: PDFDocumentProxy) => {
    setNumPages(document.numPages);
  };

  const [renderedPageNumber, setRenderedPageNumber] = useState(0);

  const onPageRenderSuccess = (page: PDFPageProxy) => {
    setRenderedPageNumber(page.pageNumber);
  };

  const zoomIn = () => {
    if (scale < MAX_SCALE) {
      setScale(scale + SCALE_INCREMENT);
    }
  };

  const zoomOut = () => {
    if (scale > MIN_SCALE) {
      setScale(scale - SCALE_INCREMENT);
    }
  };

  const updatePageNumber = (pageNumber: number) => {
    // Clear rendered page number every time page changes.
    setRenderedPageNumber(0);
    setPageNumber(pageNumber);
  };

  const goToPrevPage = () => {
    if (pageNumber > FIRST_PAGE) {
      updatePageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (numPages && pageNumber < numPages) {
      updatePageNumber(pageNumber + 1);
    }
  };

  const goToFirstPage = () => {
    updatePageNumber(FIRST_PAGE);
  };

  const goToLastPage = () => {
    numPages && updatePageNumber(numPages);
  };

  const rotateLeft = () => {
    setRotation((rotation + 360 - 90) % 360);
  };

  const rotateRight = () => {
    setRotation((rotation + 90) % 360);
  };

  const documentLoaded = !!documentData;

  const renderButton = (tooltip: string, icon: JSX.Element, onClick: () => void, disabled: boolean) => (
    <Tooltip title={tooltip}>
      <span>
        <IconButton
          sx={{
            color: 'white',
            '&.Mui-disabled': {
              color: 'rgba(255,255,255,0.26)',
            },
          }}
          onClick={onClick}
          disabled={!documentLoaded || disabled}
          size="large"
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );

  const saveToFile = () => {
    if (documentData) {
      const link = document.createElement('a');
      link.href = documentData.url;
      link.download = saveFileName!;
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const zohoSign = useContext(ZohoSignContext);
  const [sendingToZohoSign, setSendingToZohoSign] = useState(false);

  const sendToZohoSign = async () => {
    if (quoteId) {
      setSendingToZohoSign(true);
      try {
        if (changeOrderId) {
          await zohoSign.sendChangeOrder(quoteId, changeOrderId);
        } else {
          await zohoSign.sendQuote(quoteId);
        }
      } finally {
        setSendingToZohoSign(false);
      }
    }
  };

  return (
    <Dialog open={true} fullScreen={mobile} maxWidth="xl" fullWidth>
      <AppBar position="static">
        <Toolbar className="display-flex justify-content-center" style={{ minHeight: 'auto' }} disableGutters>
          {renderButton(t(keys.zoomOut), <ZoomOutIcon />, zoomOut, scale === MIN_SCALE)}
          {renderButton(t(keys.zoomIn), <ZoomInIcon />, zoomIn, scale === MAX_SCALE)}
          {renderButton(t(keys.rotateLeft), <RotateLeftIcon />, rotateLeft, rotation === ROTATED_LEFT)}
          {renderButton(t(keys.rotateRight), <RotateRightIcon />, rotateRight, rotation === ROTATED_RIGHT)}
          {canSave && saveFileName && renderButton(t(keys.save), <GetApp />, saveToFile, false)}
          {ZOHO_SIGN_ENABLED &&
            canSendToZohoSign &&
            renderButton(t(i18nKeys.zoho.sign.send), <ZohoSignIcon />, sendToZohoSign, false)}

          <IconButton
            sx={{
              color: 'white',
              position: 'absolute',
              right: 0,
            }}
            onClick={props.onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{
          padding: 0,
          display: 'flex',
          justifyItems: 'stretch',
          alignItems: 'stretch',
        }}
        data-test={ComponentIds.PdfDocumentContainer}
        data-test-rendered-page-number={renderedPageNumber}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            justifyItems: 'center',
            alignContent: 'stretch',
            alignItems: 'center',
            flex: 1,
            minHeight: '60vh',
            '.react-pdf__Page': {
              p: `${PAGE_PADDING}px`,
            },
            '.react-pdf__Page__canvas': {
              boxShadow: '0 0 6px 3px gray',
            },
          }}
        >
          <Box flex={1} />
          {!documentLoaded && <CircularProgress color="inherit" />}
          {documentLoaded && (!mobile || pageWidth > 0) && (
            <Document
              file={documentData}
              externalLinkTarget="_blank"
              onLoadSuccess={onDocumentLoadSuccess}
              options={{
                cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                cMapPacked: true,
              }}
              loading={<CircularProgress color="inherit" />}
            >
              <Page
                pageNumber={pageNumber}
                scale={mobile ? undefined : scale}
                width={mobile ? pageWidth * scale : undefined}
                rotate={rotation}
                loading={<CircularProgress color="inherit" />}
                renderTextLayer={false}
                renderInteractiveForms={false}
                onRenderSuccess={onPageRenderSuccess}
              />
            </Document>
          )}
          <Box flex={1} />
        </Box>
      </DialogContent>
      <Toolbar
        className="bg-gray display-flex justify-content-center align-items-center"
        style={{ minHeight: 'auto' }}
        disableGutters
      >
        {documentLoaded && (
          <>
            <IconButton onClick={goToFirstPage} disabled={pageNumber === FIRST_PAGE} size="large">
              <FirstPageIcon />
            </IconButton>
            <IconButton onClick={goToPrevPage} disabled={pageNumber === FIRST_PAGE} size="large">
              <NavigateBeforeIcon />
            </IconButton>
            <Typography className="mx-3">
              {t(keys.pageIndicatorPage)} <b>{pageNumber}</b> {t(keys.pageIndicatorOf)} <b>{numPages}</b>
            </Typography>
            <IconButton
              onClick={goToNextPage}
              disabled={pageNumber === numPages}
              data-test={ComponentIds.PdfNextPageButton}
              size="large"
            >
              <NavigateNextIcon />
            </IconButton>
            <IconButton onClick={goToLastPage} disabled={pageNumber === numPages} size="large">
              <LastPageIcon />
            </IconButton>
          </>
        )}
      </Toolbar>

      {sendingToZohoSign && (
        <Backdrop
          sx={{
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
          }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
      )}
    </Dialog>
  );
};
