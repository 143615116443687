import { CrudActions, QuoteGroups, QuoteProductTabs, QuoteTabs } from 'enums';
import { IOutboundGroup, ISalesDetailEvent, ISalesDetailEventBody, ISalesGridRow } from 'interfaces';
import { getProductSelectors } from 'rdx';
import { ChangeOrderGroups } from '../rdx/change-order/types';
import { RootState } from '../rdx/types';
import { convertRowsToSaveEvents, createProductsGroup, makeDetailEventMetadata } from './common';

/*
 * Save
 */
export const convertToSalesDetailEvent = (
  state: null,
  row: ISalesGridRow,
  timestamp: number,
  action: CrudActions,
  rowIndex: number
) => {
  const event: ISalesDetailEvent = {
    metadata: makeDetailEventMetadata(row.rowId, timestamp, action, rowIndex),
  };
  if (action !== CrudActions.DELETE) {
    event.data = {
      rentalProductTransfer: row.rentalProductTransfer,
      rentalProduct: row.rentalProduct,
      salesProduct: {
        // Will be an empty object if row is a rental transfer and row.product is undefined.
        ...row.product,
      },
      id: row.rowId,
      rowIndex,
      description: row.description,
      quantity: row.quantity,
      unitId: row.unitId,
      extension: row.extension ?? 0,
      priceEach: row.priceEach,
      originalCost: row.originalCost,
      marginDollars: row.marginDollars ?? 0,
      marginPercentage: row.marginPercentage,
      pricedManually: row.pricedManually,
      redlineLevelId: row.redlineLevelId,
      redlineLevelColor: row.redlineLevelColor,
    };
  }
  return event;
};

const isSalesRowSavable = (row: ISalesGridRow) => {
  return !!(row.product || row.rentalProduct);
};

export const convertSalesTabToSaveRequest = (
  state: RootState,
  tab: QuoteTabs,
  groupId: QuoteGroups | ChangeOrderGroups
): IOutboundGroup | null => {
  const rows = getProductSelectors(tab).salesRows(state);
  const events = convertRowsToSaveEvents(null, rows, isSalesRowSavable, convertToSalesDetailEvent);
  return createProductsGroup(events, groupId);
};

/*
 * Fetch
 */
export const convertToSalesGridRow = (event: ISalesDetailEventBody, tab: QuoteProductTabs): ISalesGridRow => {
  let fetched = false;
  let touched = false;
  let profileFetched = false;
  if (event.rentalProductTransfer === true) {
    // There is no sales product profile for rental transfers.
    // All fields are entered by user.
    fetched = true;
    touched = true;
    profileFetched = true;
  }
  return {
    tab,
    rowId: event.id,
    profileFetched,
    pricedManually: event.pricedManually,
    salesProductId: event.salesProduct?.id,
    description: event.description,
    quantity: event.quantity,
    unitId: event.unitId,
    code: event.salesProduct?.code || event.rentalProduct?.code,
    extension: event.extension,
    priceEach: event.priceEach,
    originalCost: event.originalCost,
    marginDollars: event.marginDollars,
    marginPercentage: event.marginPercentage,
    product: event.salesProduct,
    rentalProductTransfer: event.rentalProductTransfer,
    rentalProduct: event.rentalProduct,
    status: {
      existingItem: true,
      action: CrudActions.READ,
      fetched,
      fetching: false,
      error: false,
      touched,
    },
    redlineLevelId: event.redlineLevelId,
    redlineLevelColor: event.redlineLevelColor,
  };
};
