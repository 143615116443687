export enum ComponentIds {
  // Landing page
  LpQuoteNumber = 'LpQuoteNumber',
  LpCloneSourceLink = 'LpCloneSourceLink',

  QuoteToolbarState = 'QuoteToolbarState',
  ToolbarPrevailingWageStatus = 'ToolbarPrevailingWageStatus',
  SaveButton = 'SaveButton',
  CancelButton = 'CancelButton',

  DeleteButton = 'DeleteButton',
  DeleteQuoteDialog = 'DeleteQuoteDialog',
  NotAllowedToDeleteQuoteDialog = 'NotAllowedToDeleteQuoteDialog',

  QuoteStatus = 'QuoteStatus',
  AssignedTo = 'AssignedTo',

  // Tab bar
  CustomerTab = 'CustomerTab',
  DetailsTab = 'DetailsTab',
  ProductsTab = 'ProductsTab',
  OptionalTab = 'OptionalTab',
  ReviewTab = 'ReviewTab',
  ApprovalTab = 'ApprovalTab',
  QuoteNumber = 'QuoteNumber',

  RentalTab = 'RentalTab',
  SalesTab = 'SalesTab',
  ServicesTab = 'ServicesTab',

  AccountAutocomplete = 'AccountAutocomplete',
  WaiveErfDropdown = 'WaiveErfDropdown',
  WaiveAqfDropdown = 'WaiveAqfDropdown',
  CustomerContactSection = 'CustomerContactSection',
  ContactName = 'ContactName',

  JobsiteSection = 'JobsiteSection',
  JobsiteName = 'JobsiteName',
  JobsiteUseContactInformationButton = 'JobsiteUseContactInformationButton',
  JobsiteContact = 'JobsiteContact',
  JobsitePoNumber = 'JobsitePoNumber',
  JobsitePhoneNumber = 'JobsitePhoneNumber',
  JobsitePhoneNumberExt = 'JobsitePhoneNumberExt',
  JobsiteCellPhoneNumber = 'JobsiteCellPhoneNumber',
  JobsiteAddress = 'JobsiteAddress',
  JobsiteCity = 'JobsiteCity',
  JobsiteState = 'JobsiteState',
  JobsiteZip = 'JobsiteZip',

  AdditionalAccountInfoCreditRating = 'AdditionalAccountInfoCreditRating',
  AdditionalAccountInfoCustomerSegment = 'AdditionalAccountInfoCustomerSegment',
  AdditionalAccountInfoCreditLimit = 'AdditionalAccountInfoCreditLimit',
  AdditionalAccountInfoCurrentBalance = 'AdditionalAccountInfoCurrentBalance',
  AdditionalAccountInfoCustomerSince = 'AdditionalAccountInfoCustomerSince',
  AdditionalAccountInfoPoRequired = 'AdditionalAccountInfoPoRequired',
  AdditionalAccountInfoErf = 'AdditionalAccountInfoErf',
  AdditionalAccountInfoAqf = 'AdditionalAccountInfoAqf',
  AdditionalAccountInfoContractRateCode = 'AdditionalAccountInfoContractRateCode',

  TaxExempt = 'TaxExempt',
  WorkOfImprovement = 'WorkOfImprovement',
  PrevailingWage = 'PrevailingWage',
  ProjectName = 'ProjectName',
  ProjectUseJobsiteName = 'ProjectUseJobsiteName',
  ProjectDescription = 'ProjectDescription',
  DetailsApplication = 'DetailsApplication',
  DetailsInternalNotes = 'DetailsInternalNotes',
  DetailsProjectName = 'DetailsProjectName',
  DetailsUseJobsiteName = 'DetailsUseJobsiteName',
  DetailsProjectOverview = 'DetailsProjectOverview',
  DetailsEngineeringJobNumber = 'DetailsEngineeringJobNumber',
  DetailsEngineeringComments = 'DetailsEngineeringComments',
  EstimatorsDropdown = 'EstimatorsDropdown',
  SupportSpecialistsDropdown = 'SupportSpecialistsDropdown',

  AddProductsButton = 'AddProductsButton',
  ProductsActionsButton = 'ProductsActionsButton',
  DeleteProductsMenuItem = 'DeleteProductsMenuItem',
  ImportProductsMenuItem = 'ImportProductsMenuItem',
  CsvImportFileInput = 'CsvImportFileInput',

  ProductSearchInput = 'ProductSearchInput',
  ProductSearchResultsGrid = 'ProductSearchResultsGrid',
  AddServicesToPackageButton = 'AddServicesToPackageButton',
  AddServicesToPackageMenu = 'AddServicesToPackageMenu',

  RateLockdownDialog = 'RateLockdownDialog',
  RateLockdownDialogOkButton = 'RateLockdownDialogOkButton',

  Rental = 'Rental',
  RentalGrid = 'RentalGrid',
  RentalWoscoRatePercentageCellTooltipRate = 'RentalWoscoRatePercentageCellTooltipRate',

  BulkEditPopup = 'BulkEditPopup',
  BulkEditApplyButton = 'BulkEditApplyButton',
  BulkEditCloseButton = 'BulkEditCloseButton',
  RentalQtyHeaderEditButton = 'RentalQtyHeaderEditButton',
  RentalDurationHeaderEditButton = 'RentalDurationHeaderEditButton',
  RentalPercentOfRateHeaderEditButton = 'RentalPercentOfRateHeaderEditButton',
  SalesMarginPercentageHeaderEditButton = 'SalesMarginPercentageHeaderEditButton',

  Sales = 'Sales',
  SalesGrid = 'SalesGrid',

  Services = 'Services',
  ServicePackageGrid = 'ServicePackageGrid',

  OptionalNotes = 'OptionalNotes',
  FscPercentage = 'FscPercentage',
  ProductsFscTotal = 'ProductsFscTotal',
  ChangeOrderScopeChanges = 'ChangeOrderScopeChanges',
  DeliveryDate = 'DeliveryDate',
  CompletionDate = 'CompletionDate',
  ParentQuoteDeliveryDate = 'ParentQuoteDeliveryDate',
  ParentQuoteCompletionDate = 'ParentQuoteCompletionDate',
  PrintOptionDetailed = 'PrintOptionDetailed',
  PrintOptionSummary = 'PrintOptionSummary',
  EditPrintOptionSummary = 'EditPrintOptionSummary',
  PrintPreviewButton = 'PrintPreviewButton',
  PrintSummaryDescriptionSaveButton = 'PrintSummaryDescriptionSaveButton',
  ReviewTabSendToZohoSignButton = 'ReviewTabSendToZohoSignButton',

  UpdateFscDialog = 'UpdateFscDialog',
  UpdateFscDialogUpdateButton = 'UpdateFscDialogUpdateButton',
  UpdateFscDialogKeepButton = 'UpdateFscDialogKeepButton',

  QuoteChangesDialog = 'QuoteChangesDialog',
  RentalTotalsChangesTable = 'RentalTotalsChangesTable',
  SalesTotalsChangesTable = 'SalesTotalsChangesTable',
  ServicesTotalsChangesTable = 'ServicesTotalsChangesTable',
  QuoteChangesDialogSaveButton = 'QuoteChangesDialogSaveButton',

  ChangeOrders = 'ChangeOrders',
  CreateChangeOrderButton = 'CreateChangeOrderButton',
  ChangeOrderRowActionsButton = 'ChangeOrderRowActionsButton',
  ChangeOrderPreviewMenuItem = 'ChangeOrderPreviewMenuItem',
  ChangeOrderMarkWonMenuItem = 'ChangeOrderMarkWonMenuItem',
  ChangeOrderMarkLostMenuItem = 'ChangeOrderMarkLostMenuItem',
  ChangeOrderDeleteMenuItem = 'ChangeOrderDeleteMenuItem',

  QuotePreviewMenuItem = 'QuotePreviewMenuItem',
  QuoteCloneMenuItem = 'QuoteCloneMenuItem',
  QuoteSendToZohoSignMenuItem = 'QuoteSendToZohoSignMenuItem',
  QuoteMarkWonMenuItem = 'QuoteMarkWonMenuItem',
  QuoteMarkLostMenuItem = 'QuoteMarkLostMenuItem',
  QuoteDeleteMenuItem = 'QuoteDeleteMenuItem',

  // PDF Dialog
  PdfDocumentContainer = 'PdfDocumentContainer',
  PdfNextPageButton = 'PdfNextPageButton',

  // Errors Drawer
  ErrorsDrawerCloseButton = 'ErrorsDrawerCloseButton',
  ErrorsDrawerGroup = 'ErrorsDrawerGroup',
  ErrorsDrawerGroupMessageCount = 'ErrorsDrawerGroupMessageCount',
  ErrorsDrawerGroupTitle = 'ErrorsDrawerGroupTitle',

  SubmitForApproval = 'SubmitForApproval',

  // Approval Tab
  ApprovalKey = 'ApprovalKey',
  ApprovalOutcomeLabel = 'ApprovalOutcomeLabel',
  ApprovalOutcomeUser = 'ApprovalOutcomeUser',
  ApprovalOutcomeTime = 'ApprovalOutcomeTime',
  ApprovalFinalStatus = 'ApprovalFinalStatus',
  ApprovalMarkWon = 'ApprovalMarkWon',
  ApprovalMarkLost = 'ApprovalMarkLost',
  ApprovalReopen = 'ApprovalReopen',
  ConfirmApprovalActionButton = 'ConfirmApprovalActionButton',

  ZohoAuthPromptDialog = 'ZohoAuthPromptDialog',
  ZohoAuthPromptDialogTitle = 'ZohoAuthPromptDialogTitle',
  ZohoAuthPromptDialogText = 'ZohoAuthPromptDialogText',
  ZohoAuthPromptDialogAuthorizeButton = 'ZohoAuthPromptDialogAuthorizeButton',
  ZohoAuthPromptDialogCancelButton = 'ZohoAuthPromptDialogCancelButton',

  ZohoSignLinkHandlerText = 'ZohoSignLinkHandlerText',
  ZohoSignLinkConfirmationDialog = 'ZohoSignLinkConfirmationDialog',
  ZohoSignLinkConfirmationDialogText = 'ZohoSignLinkConfirmationDialogText',
  ZohoSignLinkConfirmationDialogOkButton = 'ZohoSignLinkConfirmationDialogOkButton',
  ZohoSignLinkConfirmationDialogCancelButton = 'ZohoSignLinkConfirmationDialogCancelButton',

  SalesProLoginErrorDialog = 'SalesProLoginErrorDialog',
  SalesProLoginErrorDialogOkButton = 'SalesProLoginErrorDialogOkButton',

  UserMenuButton = 'UserMenuButton',
  UserMenuLogoutMenuItem = 'UserMenuLogoutMenuItem',
}
