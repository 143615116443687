import { styled } from '@mui/material/styles';
import React from 'react';

const Circle = styled('span')`
  display: inline-block;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  background-color: #ccc;
  margin-right: 7px;
  position: relative;
  top: 3px;
`;

interface IStatusIndicatorProps {
  color?: string;
}

export const StatusIndicator: React.FC<IStatusIndicatorProps> = (props) => {
  return <Circle style={{ backgroundColor: props.color || '#ccc' }} />;
};
