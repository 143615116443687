import { createAction, createAsyncThunk, PayloadActionCreator } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { getGridValidationGroupNameByLocation } from '../../../containers/Quote/Tabs/Products/Tabs/grid-validation';
import { CrudActions, QuoteInnerTabs, QuoteProductTabs, QuoteTabs } from '../../../enums';
import { IGridRow, IProductGridRow, IRentalGridRow, ISalesGridRow, IServicesGridRow } from '../../../interfaces';
import { AppDispatch } from '../../store';
import { AppThunkApiConfig, RootState } from '../../types';
import { QuoteScreenSelectors } from '../quote-screen.selectors';
import { reviewTabSlice } from '../review-tab';
import { findTargetIndexForRowShift } from './products-tab.helpers';
import { IServicesGridInfo, OptionalTabSelectors, ProductsTabSelectors } from './products-tab.selectors';
import { MoveProductRowsResult } from './products-tab.types';
import { fetchRentalProfiles } from './rental.action-creators';
import { addRentalItems } from './rental.actions';
import { createRentalRow } from './rental.helpers';
import { fetchSalesProfiles } from './sales.action-creators';
import { addSalesItems } from './sales.actions';
import { createSalesRow } from './sales.helpers';
import { fetchServiceProfiles } from './services.actions';

interface AddRowsPayload<T extends IGridRow> {
  tab: QuoteProductTabs;
  rows: T[];
}

export interface AddProductRowsPayload<T extends IProductGridRow> extends AddRowsPayload<T> {
  atIndex: number | null;
  moved?: boolean;
}

export const addRentalRowsAction = createAction<AddProductRowsPayload<IRentalGridRow>>(
  'quote/productsTab/addRentalRowsAction'
);

export const addSalesRowsAction = createAction<AddProductRowsPayload<ISalesGridRow>>(
  'quote/productsTab/addSalesRowsAction'
);

export interface AddServiceRowsPayload extends AddRowsPayload<IServicesGridRow> {
  gridId?: string;
}

export const addServiceRowsAction = createAction<AddServiceRowsPayload>('quote/productsTab/addServiceRowsAction');

export interface DeleteItemPayload {
  tab: QuoteProductTabs;
  innerTab: QuoteInnerTabs;
  gridId?: string;
  rowId: string;
}

export const deleteItemInternalAction = createAction<DeleteItemPayload>('quote/productsTab/deleteItemInternal');

export const deleteItemAction = createAsyncThunk(
  'quote/productsTab/deleteItem',
  async (payload: DeleteItemPayload, thunkAPI) => {
    thunkAPI.dispatch(deleteItemInternalAction(payload));
    thunkAPI.dispatch(
      reviewTabSlice.actions.clearAllValidationErrorsForProduct({
        rowId: payload.rowId,
        groupName: getGridValidationGroupNameByLocation(payload.tab as QuoteProductTabs, payload.innerTab),
      })
    );
  }
);

export interface ShiftProductRowPayload {
  tab: QuoteProductTabs;
  innerTab: QuoteInnerTabs;
  rowIndex: number;
  direction: 'up' | 'down';
}

export const shiftProductRowAction = createAsyncThunk<void, ShiftProductRowPayload, AppThunkApiConfig>(
  'quote/productsTab/shiftProductRow',
  (payload, { dispatch, getState }) => {
    const { tab, innerTab } = payload;
    const state = getState();
    if (innerTab === QuoteInnerTabs.Rental) {
      const rows = state.quoteScreen.productsTab[tab].rental.rows;
      shiftProductRow(rows, payload, addRentalRowsAction, dispatch);
    } else if (innerTab === QuoteInnerTabs.Sales) {
      const rows = state.quoteScreen.productsTab[tab].sales.rows;
      shiftProductRow(rows, payload, addSalesRowsAction, dispatch);
    }
  }
);

const shiftProductRow = <T extends IProductGridRow>(
  rows: T[],
  payload: ShiftProductRowPayload,
  addRowsActionCreator: PayloadActionCreator<AddProductRowsPayload<T>>,
  dispatch: AppDispatch
) => {
  const { tab, innerTab, rowIndex, direction } = payload;
  const toIndex = findTargetIndexForRowShift(rows, rowIndex, direction);
  if (toIndex !== null) {
    const row = rows[rowIndex];
    dispatch(
      addRowsActionCreator({
        tab,
        moved: true,
        rows: [copyProductRow(row)],
        atIndex: toIndex,
      })
    );
    dispatch(deleteItemAction({ tab, innerTab, rowId: row.rowId }));
  }
};

const copyProductRow = <T extends IProductGridRow>(row: T): T => ({
  ...row,
  rowId: v4(),
  status: {
    existingItem: false,
    action: CrudActions.CREATE,
    touched: true,
    fetched: true,
    fetching: false,
    error: false,
  },
});

export interface MoveProductRowsParam {
  tab: QuoteProductTabs;
  innerTab: QuoteInnerTabs.Rental | QuoteInnerTabs.Sales;
  rowIds: string[];
  beforeRowId: string | null;
}

export const moveProductRowsAction = createAsyncThunk<MoveProductRowsResult, MoveProductRowsParam, AppThunkApiConfig>(
  'quote/productsTab/moveProductRowsAction',
  (param, { dispatch, getState }) => {
    const { tab, innerTab } = param;
    const state = getState();
    if (innerTab === QuoteInnerTabs.Rental) {
      const rows = state.quoteScreen.productsTab[tab].rental.rows;
      return moveProductRows(rows, param, addRentalRowsAction, dispatch);
    } else {
      const rows = state.quoteScreen.productsTab[tab].sales.rows;
      return moveProductRows(rows, param, addSalesRowsAction, dispatch);
    }
  }
);

const moveProductRows = <T extends IProductGridRow>(
  rows: T[],
  payload: MoveProductRowsParam,
  addRowsActionCreator: PayloadActionCreator<AddProductRowsPayload<T>>,
  dispatch: AppDispatch
) => {
  const { tab, innerTab, rowIds, beforeRowId } = payload;
  const rowIdSet = new Set(rowIds);
  const newRows = [];
  const result: MoveProductRowsResult = {};
  let rowsRemovedBeforeToIndex = 0;
  let toIndex: number | null = null;
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    if (row.rowId === beforeRowId) {
      toIndex = i;
    }
    if (rowIdSet.has(row.rowId)) {
      const newRow = copyProductRow(row);
      newRows.push(newRow);

      // Map old row id to new row id so that new rows can be focused/selected/highlighted.
      result[row.rowId] = newRow.rowId;

      // Keep track of the number of rows removed before finding the insertion row.
      // This number will be subtracted from insertion index to account for deleted
      // rows. Only newly added rows are actually deleted from the list. Existing rows
      // are simply marked to be deleted on save, but are not removed from the list,
      // so they do not affect the insertion index.
      if (toIndex === null && !row.status.existingItem) {
        rowsRemovedBeforeToIndex++;
      }
    }
  }
  if (toIndex !== null) {
    toIndex -= rowsRemovedBeforeToIndex;
  }

  // Delete original rows.
  for (const rowId of rowIds) {
    dispatch(deleteItemAction({ tab, innerTab, rowId }));
  }

  // Add copied rows.
  dispatch(
    addRowsActionCreator({
      tab,
      moved: true,
      rows: newRows,
      atIndex: toIndex,
    })
  );

  return result;
};

export const addEmptyItem = createAsyncThunk(
  'quote/productsTab/addEmptyItem',
  async (payload: { tab: QuoteProductTabs; innerTab: QuoteInnerTabs; atIndex: number | null }, thunkAPI) => {
    const { tab, innerTab, atIndex } = payload;
    if (innerTab === QuoteInnerTabs.Rental) {
      thunkAPI.dispatch(addRentalRowsAction({ tab, atIndex, rows: [createRentalRow(tab)] }));
    } else if (innerTab === QuoteInnerTabs.Sales) {
      thunkAPI.dispatch(addSalesRowsAction({ tab, atIndex, rows: [createSalesRow(tab)] }));
    }
  }
);

/**
 * Used to move Rental or Sales products between Products/Optional.
 * Also used to move (transfer) Rental products to Sales.
 * Not used for services.
 */
export const moveProductsToTabAction = createAsyncThunk<
  void,
  {
    fromTab: QuoteProductTabs;
    toTab: QuoteProductTabs;
    fromInnerTab?: QuoteInnerTabs;
    toInnerTab: QuoteInnerTabs;
    rows: IProductGridRow[];
  },
  AppThunkApiConfig
>('quote/productsTab/moveProductsToTabAction', async (payload, { dispatch }) => {
  if (payload.rows.length) {
    payload.rows.forEach((row: IGridRow) => {
      dispatch(
        deleteItemAction({
          tab: payload.fromTab,
          innerTab: payload.fromInnerTab || payload.toInnerTab,
          rowId: row.rowId,
        })
      );
    });

    if (!payload.fromInnerTab) {
      // Not a Rental to Sales transfer - just moving rows of the same
      // type between Products and Optional tabs.
      const { toTab: tab, toInnerTab: innerTab } = payload;
      const atIndex = null;
      const moved = true;
      if (innerTab === QuoteInnerTabs.Rental) {
        const rows = (payload.rows as IRentalGridRow[]).map(copyProductRow);
        dispatch(addRentalRowsAction({ tab, atIndex, rows, moved }));
      } else if (innerTab === QuoteInnerTabs.Sales) {
        const rows = (payload.rows as ISalesGridRow[]).map(copyProductRow);
        dispatch(addSalesRowsAction({ tab, atIndex, rows, moved }));
      }
    } else {
      if (payload.fromInnerTab === QuoteInnerTabs.Rental && payload.toInnerTab === QuoteInnerTabs.Sales) {
        const sourceRentalRows = payload.rows as IRentalGridRow[];
        dispatch(
          addSalesItems({
            tab: payload.toTab,
            atIndex: null,
            products: sourceRentalRows.map(({ code = '', product, description = '', quantity }) => ({
              code,
              rentalProduct: product,
              description,
              quantity,
            })),
            transfer: true,
          })
        );
      }
      if (payload.fromInnerTab === QuoteInnerTabs.Sales && payload.toInnerTab === QuoteInnerTabs.Rental) {
        // Currently we do not allow moving rows from sales to rental, so this code is not used.
        // TODO: Do the same as from rental to sales to avoid fetch and populate desc
        dispatch(addRentalItems({ tab: payload.toTab, atIndex: null, products: payload.rows as any[] }));
      }
    }
  }
});

export type TotalsType = ReturnType<typeof ProductsTabSelectors.totals>;
export type RentalTotalsType = TotalsType['rental'];
export type SalesTotalsType = TotalsType['sales'];
export type ServiceTotalsType = TotalsType['services'];

export interface QuoteDiffSnapshot {
  totals: TotalsType;
  rentalRows: IRentalGridRow[];
  salesRows: ISalesGridRow[];
  servicesGridInfos: IServicesGridInfo[];
}

export interface QuoteDiff {
  requiredProductsSnapshotBefore: QuoteDiffSnapshot;
  optionalProductsSnapshotBefore: QuoteDiffSnapshot;
  requiredProductsSnapshotAfter: QuoteDiffSnapshot;
  optionalProductsSnapshotAfter: QuoteDiffSnapshot;
}

export interface RerateQuoteResult {
  diff: QuoteDiff | null;
  productsNotFound: boolean;
}

export const updateFscMultiplierAction = createAction<number>('quote/productsTab/updateFscMultiplier');

export const rerateDraftQuoteAction = createAsyncThunk<
  RerateQuoteResult,
  { newFuelSurchargeMultiplier?: number },
  AppThunkApiConfig
>('quote/productsTab/rerateDraftQuoteAction', async (params, { getState, dispatch }) => {
  const getSnapshot = (
    state: RootState,
    selectors: typeof ProductsTabSelectors | typeof OptionalTabSelectors
  ): QuoteDiffSnapshot => ({
    totals: selectors.totals(state),
    rentalRows: selectors.rentalRows(state),
    salesRows: selectors.salesRows(state),
    servicesGridInfos: selectors.serviceGridInfos(state),
  });

  let state = getState();

  const requiredProductsSnapshotBefore = getSnapshot(state, ProductsTabSelectors);
  const optionalProductsSnapshotBefore = getSnapshot(state, OptionalTabSelectors);

  if (params.newFuelSurchargeMultiplier !== undefined && params.newFuelSurchargeMultiplier >= 0) {
    dispatch(updateFscMultiplierAction(params.newFuelSurchargeMultiplier));
  }

  const productsNotFound = [
    await fetchRentalProfiles(state, dispatch, QuoteTabs.Products),
    await fetchRentalProfiles(state, dispatch, QuoteTabs.Optional),
    await fetchSalesProfiles(state, dispatch, QuoteTabs.Products),
    await fetchSalesProfiles(state, dispatch, QuoteTabs.Optional),
    await fetchServiceProfiles(state, dispatch, QuoteTabs.Products),
    await fetchServiceProfiles(state, dispatch, QuoteTabs.Optional),
  ].some((result) => result.productsNotFound === true);

  state = getState();
  const result: RerateQuoteResult = {
    diff: null,
    productsNotFound,
  };

  if (QuoteScreenSelectors.touched(state)) {
    const requiredProductsSnapshotAfter = getSnapshot(state, ProductsTabSelectors);
    const optionalProductsSnapshotAfter = getSnapshot(state, OptionalTabSelectors);

    result.diff = {
      requiredProductsSnapshotBefore,
      optionalProductsSnapshotBefore,
      requiredProductsSnapshotAfter,
      optionalProductsSnapshotAfter,
    };
  }

  return result;
});
