import { get } from 'lodash';
import { ColDef } from 'ag-grid-community';

export const getTableToolBarFilters = (columns: ColDef[]): { id: string; text: string }[] => {
  return columns.filter((i) => !!i.filter).map((i) => ({ id: i.field || '', text: i.headerName || '' }));
};

export const doTableToolBarFilter = (
  filter: { column: string; value: string } | null,
  columns: ColDef[],
  node: any
) => {
  if (!filter || !node) {
    return true;
  }
  const cols = getTableToolBarFilters(columns);

  const { data } = node;
  const regex = new RegExp(filter.value, 'i');
  if (filter && filter.column === 'all') {
    let match = false;
    for (let index = 0; index < cols.length; index++) {
      const col = cols[index];
      const colValue = get(data, col.id) || '';
      if (colValue.match(regex)) {
        match = true;
        break;
      }
    }
    return match;
  } else {
    const colValue = get(data, filter.column);
    return colValue && colValue.match(regex);
  }
};
