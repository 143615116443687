import { Popover, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const JobsiteCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span
        className="display-block text-wrap-ellipsis"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ cursor: 'help' }}
      >
        {props.value}
      </span>
      {open && (
        <Popover
          sx={{ pointerEvents: 'none' }}
          PaperProps={{ sx: { p: 1 } }}
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Table className="table-bordered">
            <TableBody>
              <TableRow>
                <TableCell className="bg-gray">
                  <strong>{t('quote.jobName')}</strong>
                </TableCell>
                <TableCell>{props.data.header.jobsite.jobName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="bg-light-brown">
                  <strong>{t('quote.PONumber')}</strong>
                </TableCell>
                <TableCell>{props.data.header.jobsite.poNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="bg-light-brown">
                  <strong>{t('quote.address')}</strong>
                </TableCell>
                <TableCell>{props.data.header.jobsite.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="bg-light-brown">
                  <strong>{t('quote.city')}</strong>
                </TableCell>
                <TableCell>{props.data.header.jobsite.city}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="bg-light-brown">
                  <strong>{t('quote.state')}</strong>
                </TableCell>
                <TableCell>{props.data.header.jobsite.state}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="bg-light-brown">
                  <strong>{t('quote.zip')}</strong>
                </TableCell>
                <TableCell>{props.data.header.jobsite.zipCode}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Popover>
      )}
    </div>
  );
};
