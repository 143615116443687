import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { QuoteService } from '../../../api';
import { i18nKeys } from '../../../internationalization/i18nKeys';
import { enqueueNotificationAction } from '../../session';
import { RootState } from '../../types';
import { StatementSelectors } from './statement-tab.selectors';

const SOW_MAX_SIZE = Math.round(1024 * 1024 * 3 * 0.9);

export interface IStatementOfWorkEditor {
  save(): void;
}

let editor: IStatementOfWorkEditor | null = null;

export const setStatementOfWorkEditor = (newEditor: IStatementOfWorkEditor | null) => {
  editor = newEditor;
};

export const statementOfWorkDeletedAction = createAction('quote/statementTab/statementOfWorkDeletedAction');

export const saveStatementOfWorkAction = createAsyncThunk(
  'quote/statementTab/saveStatementOfWorkAction',
  async (payload: undefined, thunkAPI) => {
    let state = thunkAPI.getState() as RootState;

    const touched = StatementSelectors.touched(state);
    if (touched) {
      if (editor) {
        editor.save();
      }

      state = thunkAPI.getState() as RootState;
      const statement = StatementSelectors.statement(state);
      const savedStatement = StatementSelectors.savedStatement(state);
      if (statement) {
        const size = new TextEncoder().encode(JSON.stringify(statement)).length;
        if (size > SOW_MAX_SIZE) {
          thunkAPI.dispatch(
            enqueueNotificationAction({
              message: i18next.t(i18nKeys.statementTab.statementTooLarge),
              options: { variant: 'error' },
            })
          );
          return thunkAPI.rejectWithValue('Statement too large');
        }

        try {
          if (savedStatement) {
            if (savedStatement.templateId !== statement.templateId) {
              await QuoteService.getInstance().deleteStatementOfWork(statement.quoteId);
              thunkAPI.dispatch(statementOfWorkDeletedAction());
              await QuoteService.getInstance().createStatementOfWork(statement);
            } else {
              await QuoteService.getInstance().updateStatementOfWork(statement);
            }
          } else {
            await QuoteService.getInstance().createStatementOfWork(statement);
          }
        } catch (e) {
          thunkAPI.dispatch(
            enqueueNotificationAction({
              message: i18next.t(i18nKeys.statementTab.errorSavingStatement),
              options: { variant: 'error' },
            })
          );
          throw e;
        }
      }
    }
  }
);
