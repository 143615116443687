import axios, { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import { inject, injectable } from 'tsyringe';
import { AppConfigurationService } from '../api';
import { DiTokens } from '../di/di-tokens';
import { cognitoCallbackPath } from '../routes/routes';
import { clearAuthCallbacks, loadAuthCallbackData, saveAuthCallbackData } from '../utils/auth-utils';
import { toNonBlankString } from '../utils/strings';
import type { ICognitoLocations } from './cognito-locations';
import { LocalStorage } from './localStorage';
import { CognitoCodeGrantTokens, CognitoTokens } from './types';

interface RefreshTokenGrant {
  grant_type: 'refresh_token';
  refresh_token: string;
}

interface AuthorizationCodeGrant {
  grant_type: 'authorization_code';
  code: string;
}

export class CognitoTokensError {}

export class CognitoTokensUnauthorizedError {}

export interface ICognito {
  login(): Promise<void>;
  handleCallback(): Promise<string>;
  logout(): Promise<void>;
  refreshTokens(): Promise<string>;
}

@injectable()
export class Cognito implements ICognito {
  private navigationPromise: Promise<void> | null = null;

  constructor(@inject(DiTokens.CognitoLocations) private cognitoLocations: ICognitoLocations) {}

  /**
   * Navigates to Cognito login URL, which may open Okta login,
   * or automatically return auth code if there is already an
   * active Okta session. In either case, the callback is handled
   * by /auth/callback route, which redirects to the destination
   * route if there are no problems, or shows an error dialog and
   * redirects to login page if auth failed.
   */
  login = async () => {
    if (!this.navigationPromise) {
      // Determine and save app route to use at the end of auth flow.
      let route = '/';
      if (!window.location.pathname.toLowerCase().startsWith('/auth/')) {
        route = `${window.location.pathname}${window.location.search}`;
      }
      this.cognitoLocations.login(await saveAuthCallbackData(route));
      this.navigationPromise = new Promise(() => {});
    }
    return this.navigationPromise;
  };

  logout = () => {
    if (!this.navigationPromise) {
      clearAuthCallbacks();

      LocalStorage.removeAccessToken();
      LocalStorage.removeRefreshToken();
      LocalStorage.removeIdToken();

      this.cognitoLocations.logout();

      // We return a promise that never resolves because
      // we are navigating to another page anyway.
      this.navigationPromise = new Promise(() => {});
    }
    return this.navigationPromise;
  };

  async handleCallback() {
    const params = new URLSearchParams(window.location.search);

    const error = params.get('error');
    if (error) {
      throw new Error(error);
    }

    const code = params.get('code');
    if (!code) {
      throw new Error('other');
    }

    // Verify state and extract target route.
    const { success, data } = await loadAuthCallbackData(params.get('state'));
    const route = toNonBlankString(data, null);
    if (!success || !route) {
      throw new Error('other');
    }

    const tokens = await this.getTokens({ grant_type: 'authorization_code', code });
    LocalStorage.saveAccessToken(tokens.access_token);
    LocalStorage.saveRefreshToken(tokens.refresh_token);
    LocalStorage.saveIdToken(tokens.id_token);

    return route;
  }

  public async refreshTokens() {
    const refresh_token = LocalStorage.readRefreshToken();
    const tokens = await this.getTokens({ grant_type: 'refresh_token', refresh_token });
    LocalStorage.saveAccessToken(tokens.access_token);
    LocalStorage.saveIdToken(tokens.id_token);
    return tokens.id_token;
  }

  private async getTokens<TGrant extends RefreshTokenGrant | AuthorizationCodeGrant>(grant: TGrant) {
    try {
      const { cognitoURL, clientID, baseRoute } = AppConfigurationService.getInstance().appConfiguration;
      const config: AxiosRequestConfig = {
        method: 'post',
        baseURL: cognitoURL,
        url: '/oauth2/token',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: queryString.stringify({
          ...grant,
          client_id: clientID,
          redirect_uri: `${baseRoute}${cognitoCallbackPath}`,
        }),
      };
      type TResult = TGrant extends RefreshTokenGrant ? CognitoTokens : CognitoCodeGrantTokens;
      const tokens = (await axios<TResult>(config)).data;
      if (tokens) {
        return tokens;
      }
    } catch (e: any) {
      if (axios.isAxiosError(e) && e.response && Number.isFinite(e.response.status)) {
        const { status } = e.response;
        if (status >= 400 && status < 500) {
          throw new CognitoTokensUnauthorizedError();
        }
      }
    }
    throw new CognitoTokensError();
  }
}
