import axios from 'axios';
import { headerGroupsSet, QuoteGroups } from 'enums';
import i18next from 'i18next';
import {
  IApiEnvelope,
  IChangeOrder,
  IMetadataPagination,
  IOutboundGroupRequest,
  IQuote,
  IQuoteReviewMarkCompleteOptions,
  IQuoteReviewPrintOptions,
  IStatementOfWork,
  IValidationResponse,
} from 'interfaces';
import { ITotalQuoteHealth } from 'interfaces/TotalQuoteHealth';
import { isString } from 'lodash';
import {
  CreateDocumentData,
  CreateDocumentResponse,
  QuoteDocument,
  ReadDocumentResponse,
  UpdateDocumentData,
} from '../interfaces/Documents';
import { i18nKeys } from '../internationalization/i18nKeys';
import { ChangeOrderGroups } from '../rdx/change-order/types';
import { ZOHO_AUTH_HEADER_NAME } from '../zoho/constants';
import { AuditEventStatuses } from '../zoho/types';
import { ConfigurableRequest } from './configurable-request';

type UploadDocumentSuccess = {
  status: 'success';
};

type UploadDocumentError = {
  status: 'error';
  errors: string[];
};

export type UploadDocumentResult = UploadDocumentSuccess | UploadDocumentError;

export interface UploadProgressEvent {
  loaded: number;
  total?: number;
}

export type UploadProgressCallback = (event: UploadProgressEvent) => void;

export interface IUploadQuoteDocumentService {
  uploadDocument(
    quoteId: string,
    body: CreateDocumentData,
    file: File,
    onUploadProgress: UploadProgressCallback
  ): Promise<UploadDocumentResult>;
}

export type CloneQuoteOptions = {
  account: boolean;
  rates: boolean;
  jobsite: boolean;
  statement: boolean;
};

export type CloneQuoteUrlSearchParams = CloneQuoteOptions & {
  cloneFromId: string;
};

export class QuoteService extends ConfigurableRequest implements IUploadQuoteDocumentService {
  private static instance: QuoteService;

  private constructor() {
    super('quotes');
  }

  public async getQuotesByAccount(
    accountId: string,
    page: number,
    perPage: number,
    order?: string
  ): Promise<{ elements: IQuote[]; metadata: IMetadataPagination }> {
    order = order ? order : 'header.ownership.preparedTimestamp:desc';
    const queryParams = { page, perPage, order };
    const result: IApiEnvelope<any> = await this.get(`/accounts/${accountId}`, queryParams);
    const [data] = result.data;
    return { elements: data.elements, metadata: data.metadata };
  }

  public async createQuote(data: IQuote): Promise<IQuote> {
    const result: IApiEnvelope<IQuote> = await this.post(`/`, {}, {}, {}, data);
    const [savedQuote]: IQuote[] = result.data;
    return savedQuote;
  }

  public async getQuoteGroups(quoteId: string, groups: QuoteGroups[]): Promise<IQuote> {
    // Always remove all HEADER sub-groups and request entire HEADER instead.
    groups = groups.filter((group) => !headerGroupsSet.has(group));
    groups.push(QuoteGroups.HEADER);
    const queryParams = { values: groups.join(',') };
    const result: IApiEnvelope<IQuote> = await this.get(`/${quoteId}/groups`, queryParams);
    const [quote] = result.data;
    return quote;
  }

  public async getChangeOrderGroups(
    quoteId: string,
    changeOrderId: string,
    groups: ChangeOrderGroups[]
  ): Promise<IChangeOrder> {
    if (!groups.includes(ChangeOrderGroups.HEADER)) {
      groups = [...groups, ChangeOrderGroups.HEADER];
    }
    const queryParams = { values: groups.join(',') };
    const path = `/${quoteId}/changeOrders/${changeOrderId}/groups`;
    const result: IApiEnvelope<IChangeOrder> = await this.get(path, queryParams);
    return result.data[0];
  }

  public async getChangeOrderExtensions(quoteId: string, changeOrderId: string): Promise<IChangeOrder> {
    const queryParams = { extensions: true };
    const path = `/${quoteId}/changeOrders/${changeOrderId}`;
    const result: IApiEnvelope<IChangeOrder> = await this.get(path, queryParams);
    return result.data[0];
  }

  public async saveQuoteGroups(quoteId: string, request: IOutboundGroupRequest): Promise<IQuote> {
    const result: IApiEnvelope<IQuote> = await this.post(`/${quoteId}/groups`, {}, {}, {}, request);
    const [quote]: IQuote[] = result.data;
    return quote;
  }

  public async saveChangeOrderGroups(
    quoteId: string,
    changeOrderId: string,
    request: IOutboundGroupRequest
  ): Promise<IChangeOrder> {
    const path = `/${quoteId}/changeOrders/${changeOrderId}/groups`;
    const result: IApiEnvelope<IChangeOrder> = await this.post(path, {}, {}, {}, request);
    return result.data[0];
  }

  public async search(
    query: string,
    branchId: string,
    page: number,
    perPage: number
  ): Promise<{ elements: IQuote[]; metadata: IMetadataPagination }> {
    const queryParams = { query, branchId, page, perPage };
    const result: IApiEnvelope<any> = await this.get(`/search`, queryParams);
    const [data] = result.data;
    return data;
  }

  public async completeQuote(quoteId: string, review: IQuoteReviewMarkCompleteOptions): Promise<IValidationResponse> {
    const result: IApiEnvelope<IValidationResponse> = await this.post(`/${quoteId}/complete`, {}, {}, {}, { review });
    return result.data[0];
  }

  public async completeChangeOrder(
    quoteId: string,
    changeOrderId: string,
    review: IQuoteReviewMarkCompleteOptions
  ): Promise<IValidationResponse> {
    const path = `/${quoteId}/changeOrders/${changeOrderId}/complete`;
    const result: IApiEnvelope<IValidationResponse> = await this.post(path, {}, {}, {}, { review });
    return result.data[0];
  }

  public async validateQuote(quoteId: string): Promise<IValidationResponse> {
    const result: IApiEnvelope<IValidationResponse> = await this.post(`/${quoteId}/validate`);
    return result.data[0];
  }

  public async validateChangeOrder(quoteId: string, changeOrderId: string): Promise<IValidationResponse> {
    const path = `/${quoteId}/changeOrders/${changeOrderId}/validate`;
    const result: IApiEnvelope<IValidationResponse> = await this.post(path);
    return result.data[0];
  }

  public async getQuoteHealth(quoteId: string): Promise<ITotalQuoteHealth> {
    const result: IApiEnvelope<ITotalQuoteHealth> = await this.get(`/${quoteId}/health`);
    const [tqh]: ITotalQuoteHealth[] = result.data;
    return tqh;
  }

  public async getChangeOrderHealth(quoteId: string, changeOrderId: string): Promise<ITotalQuoteHealth> {
    const path = `/${quoteId}/changeOrders/${changeOrderId}/health`;
    const result: IApiEnvelope<ITotalQuoteHealth> = await this.get(path);
    return result.data[0];
  }

  public async uploadDocument(
    quoteId: string,
    body: CreateDocumentData,
    file: File,
    onUploadProgress: UploadProgressCallback
  ): Promise<UploadDocumentResult> {
    try {
      const response: IApiEnvelope<CreateDocumentResponse> = await this.post(`/${quoteId}/documents`, {}, {}, {}, body);
      const instance = axios.create({ onUploadProgress });
      delete instance.defaults.headers.put['Content-Type'];
      try {
        await instance.put(response.data[0].uploadUrl, file);
        return {
          status: 'success',
        };
      } catch (e) {
        // Delete this document since we failed to upload the file to cloud storage.
        await this.deleteDocument(quoteId, response.data[0].document.id);
        return {
          status: 'error',
          errors: [i18next.t(i18nKeys.documentsTab.api.errorUploadingFile)],
        };
      }
    } catch (e: any) {
      let errors: string[];
      if (e.responseErrors?.errors?.length > 0) {
        errors = e.responseErrors.errors.map((error: any) => error.message);
      } else {
        errors = [i18next.t(i18nKeys.documentsTab.api.errorAddingDocument)];
      }
      return {
        status: 'error',
        errors,
      };
    }
  }

  public async listDocuments(quoteId: string): Promise<QuoteDocument[]> {
    const result: IApiEnvelope<QuoteDocument> = await this.get(`/${quoteId}/documents`);
    return result.data;
  }

  public async readDocument(quoteId: string, documentId: string): Promise<ReadDocumentResponse> {
    const response: IApiEnvelope<ReadDocumentResponse> = await this.get(`/${quoteId}/documents/${documentId}`);
    return response.data[0];
  }

  public async deleteDocument(quoteId: string, documentId: string): Promise<void> {
    await this.delete(`/${quoteId}/documents/${documentId}`);
  }

  public async updateDocument(quoteId: string, documentId: string, body: UpdateDocumentData): Promise<QuoteDocument> {
    try {
      const response: IApiEnvelope<QuoteDocument> = await this.put(
        `/${quoteId}/documents/${documentId}`,
        {},
        {},
        {},
        body
      );
      return response.data[0];
    } catch (e: any) {
      throw e.responseErrors?.errors?.map((error: any) => error.message);
    }
  }

  public async saveQuotePrintOptions(quoteId: string, printOptions: IQuoteReviewPrintOptions): Promise<void> {
    await this.post(`/${quoteId}/printOption`, {}, {}, {}, { review: printOptions });
  }

  public async sendToZohoSign(zohoAuth: string, quoteId: string, changeOrderId?: string): Promise<string> {
    const basePath = changeOrderId ? `/${quoteId}/change-orders/${changeOrderId}` : `/${quoteId}`;
    const result = await this.post(`${basePath}/sign`, {}, {}, { [ZOHO_AUTH_HEADER_NAME]: zohoAuth });
    const { eventId } = result.data[0];
    if (!isString(eventId)) {
      throw new Error('No event ID in response');
    }
    return eventId;
  }

  public async getZohoSignStatus(
    eventId: string,
    quoteId: string,
    changeOrderId?: string
  ): Promise<AuditEventStatuses> {
    const basePath = changeOrderId ? `/${quoteId}/change-orders/${changeOrderId}` : `/${quoteId}`;
    const result = await this.get(`${basePath}/sign/status`, { eventId }, {});
    return result.data[0].status;
  }

  public async readStatementOfWork(quoteId: string): Promise<IStatementOfWork | undefined> {
    try {
      const result: IApiEnvelope<IStatementOfWork> = await this.get(`/${quoteId}/statement`);
      return result.data[0];
    } catch (error: any) {
      if (error?.statusCode === 404) {
        return undefined;
      }
      throw error;
    }
  }

  public async createStatementOfWork(statement: IStatementOfWork): Promise<void> {
    await this.post(`/${statement.quoteId}/statement`, {}, {}, {}, statement);
  }

  public async updateStatementOfWork(statement: IStatementOfWork): Promise<void> {
    await this.put(`/${statement.quoteId}/statement`, {}, {}, {}, statement);
  }

  public async deleteStatementOfWork(quoteId: string): Promise<void> {
    try {
      await this.delete(`/${quoteId}/statement`);
    } catch (error: any) {
      // 400 means statement not found.
      if (error?.statusCode !== 400) {
        throw error;
      }
    }
  }

  public async listChangeOrders(quoteId: string): Promise<IChangeOrder[]> {
    const result: IApiEnvelope<IChangeOrder> = await this.get(`/${quoteId}/changeOrders`);
    return result.data;
  }

  public async createChangeOrder(quoteId: string): Promise<IChangeOrder> {
    const result: IApiEnvelope<IChangeOrder> = await this.post(`/${quoteId}/changeOrders`);
    return result.data[0];
  }

  public async cloneQuote(sourceQuoteId: string, params: CloneQuoteOptions, data: IQuote): Promise<IQuote> {
    params = {
      ...params,
      account: false,
      jobsite: false,
    };
    const result: IApiEnvelope<IQuote> = await this.post(`/${sourceQuoteId}/clone`, {}, params, {}, data);
    return result.data[0];
  }

  public async assignQuoteToUser(quoteId: string, userId: string): Promise<void> {
    await this.post(`/${quoteId}/assign`, {}, {}, {}, { userId });
  }

  public async markWon(quoteId: string): Promise<void> {
    await this.post(`/${quoteId}/outcome/won`);
  }

  public async markChangeOrderWon(quoteId: string, changeOrderId: string): Promise<void> {
    await this.post(`/${quoteId}/changeOrders/${changeOrderId}/outcome/won`);
  }

  public async markLost(quoteId: string, reasonId: string, reasonText: string): Promise<void> {
    await this.post(`/${quoteId}/outcome/lost`, {}, {}, {}, { reasonId, reasonText });
  }

  public async markChangeOrderLost(
    quoteId: string,
    changeOrderId: string,
    reasonId: string,
    reasonText: string
  ): Promise<void> {
    await this.post(`/${quoteId}/changeOrders/${changeOrderId}/outcome/lost`, {}, {}, {}, { reasonId, reasonText });
  }

  public async reopen(quoteId: string): Promise<void> {
    await this.post(`/${quoteId}/reopen`);
  }

  public async reopenChangeOrder(quoteId: string, changeOrderId: string): Promise<void> {
    await this.post(`/${quoteId}/changeOrders/${changeOrderId}/reopen`);
  }

  public async deleteQuote(quoteId: string): Promise<void> {
    await this.delete(`/${quoteId}`);
  }

  public async deleteChangeOrder(quoteId: string, changeOrderId: string): Promise<void> {
    await this.delete(`/${quoteId}/changeOrders/${changeOrderId}`);
  }

  public static getInstance(): QuoteService {
    if (!QuoteService.instance) {
      QuoteService.instance = new QuoteService();
    }
    return QuoteService.instance;
  }
}
