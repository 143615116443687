import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { QuoteInnerTabs, QuoteProductTabs, QuoteTabs } from 'enums';
import { isNil } from 'lodash';
import { getProductsStateByTab } from 'rdx/utils';
import { round2Decimals } from 'utils';
import { AppThunkApiConfig, RootState } from '../../types';
import { addRentalRowsAction, deleteItemAction } from './products-tab.action-creators';
import { addRentalProductParamProps, AddRentalProductParamWithExtras, ProductsTabState } from './products-tab.types';
import {
  fetchRentalProfileAction,
  resetRentalPercentOfRateAction,
  updateRentalPercentOfRateAction,
} from './rental.action-creators';
import { createRentalRow, findRentalRow, recalculateRentalRow, resetRentalItemState } from './rental.helpers';

export const resetRentalProfile = (state: ProductsTabState, action: any) => {
  const { tab, rowId }: { tab: QuoteTabs; rowId: string } = action.payload;
  if (tab && rowId) {
    const productsState = getProductsStateByTab(tab, state);
    if (productsState) {
      const item = productsState.rental.rows.find((r) => r.rowId === rowId);
      if (item) {
        productsState.rental.status.touched = true;
        resetRentalItemState(state, item);
      }
    }
  }
};

export const addRentalItems = createAsyncThunk<
  boolean,
  {
    tab: QuoteProductTabs;
    atIndex: number | null;
    products: AddRentalProductParamWithExtras[];
    replaceRowId?: string;
  },
  AppThunkApiConfig
>('quote/productsTab/addRentalItems', async (payload, { dispatch }) => {
  const { products, tab, atIndex, replaceRowId } = payload;
  if (Array.isArray(products)) {
    if (replaceRowId && !isNil(atIndex) && atIndex >= 0) {
      dispatch(
        deleteItemAction({
          tab,
          innerTab: QuoteInnerTabs.Rental,
          rowId: replaceRowId,
        })
      );
    }
    const rows = products.map((p) => {
      const row = {
        ...createRentalRow(tab),
        ...addRentalProductParamProps.pickAllProps(p),
      };

      // Update extra fields that cannot be copied to the row directly.
      row.dayPeriod.quotedRate = p.dayRate;
      row.weekPeriod.quotedRate = p.weekRate;
      row.cyclePeriod.quotedRate = p.cycleRate;

      return row;
    });

    dispatch(
      addRentalRowsAction({
        tab,
        atIndex,
        rows,
      })
    );

    // Fetch profiles for new rows.
    const profilePromises = rows
      .filter(({ code }) => code)
      .map(({ rowId, code }) => dispatch(fetchRentalProfileAction({ tab, rowId, code })).unwrap());
    const results = await Promise.allSettled(profilePromises);
    return results.every(({ status }) => status === 'fulfilled');
  }
  return true;
});

export const rentalRateAdjustment = createAsyncThunk(
  'quote/productsTab/rentalRateAdjustment',
  async (payload: { percentage?: number; reset?: boolean }, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const tab = state.quoteScreen.tabs.active;
    const productsState = getProductsStateByTab(tab, state.quoteScreen.productsTab);
    const selected = productsState?.rental.selected || [];
    selected.forEach((rowId: string) => {
      if (payload.reset) {
        thunkAPI.dispatch(
          resetRentalPercentOfRateAction({
            tab,
            rowId,
          })
        );
      } else if (!isNil(payload.percentage)) {
        thunkAPI.dispatch(
          updateRentalPercentOfRateAction({
            tab,
            rowId,
            value: payload.percentage,
          })
        );
      }
    });
  }
);

export const updateRentalPercentOfRate = (
  state: ProductsTabState,
  { payload }: { payload: { tab: QuoteProductTabs; rowId: string; value: number } }
) => {
  const row = findRentalRow(state, payload);
  if (row?.percentageOfRateEditable) {
    let updated = false;
    const factor = payload.value / 100;
    for (const rate of [row.dayPeriod, row.weekPeriod, row.cyclePeriod]) {
      if (!isNil(rate.quotedRate) && !isNil(rate.listRate)) {
        const newRate = round2Decimals(rate.listRate * factor);
        if (newRate !== rate.quotedRate) {
          const noDecrease = row.tier?.modifications.decrease === false;
          const noIncrease = row.tier?.modifications.increase === false;
          if (!(newRate < rate.listRate && noDecrease) && !(newRate > rate.listRate && noIncrease)) {
            rate.quotedRate = newRate;
            updated = true;
          }
        }
      }
    }
    if (updated) {
      recalculateRentalRow(state, row, true);
    }
  }
};

export const resetRentalPercentOfRate = (
  state: ProductsTabState,
  { payload }: { payload: { tab: QuoteProductTabs; rowId: string } }
) => {
  const row = findRentalRow(state, payload);
  if (row?.percentageOfRateEditable) {
    let updated = false;
    for (const rate of [row.dayPeriod, row.weekPeriod, row.cyclePeriod]) {
      if (!isNil(rate.quotedRate) && !isNil(rate.listRate) && rate.quotedRate !== rate.listRate) {
        rate.quotedRate = rate.listRate;
        updated = true;
      }
    }
    if (updated) {
      recalculateRentalRow(state, row, true);
    }
  }
};

export const updateRateLockDown = (
  state: ProductsTabState,
  { payload }: PayloadAction<ProductsTabState['rateLockDown']>
) => {
  state.rateLockDown = payload;
};
