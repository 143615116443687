import { RootState } from '../../types';

const slice = (state: RootState) => state.quoteScreen.statementTab;

export const StatementSelectors = {
  touched: (state: RootState) => slice(state).touched,
  statementFetched: (state: RootState) => slice(state).statementFetched,
  fetchingStatement: (state: RootState) => slice(state).fetchingStatement,
  statement: (state: RootState) => slice(state).statement,
  savedStatement: (state: RootState) => slice(state).savedStatement,
  isOriginalStatement: (state: RootState) => slice(state).isOriginalStatement,
  statementUpdateId: (state: RootState) => slice(state).statementUpdateId,
  templatesFetched: (state: RootState) => slice(state).templatesFetched,
  fetchingTemplates: (state: RootState) => slice(state).fetchingTemplates,
  templates: (state: RootState) => slice(state).templates,
  saving: (state: RootState) => slice(state).saving,
  touchedSinceTemplateChange: (state: RootState) => slice(state).touchedSinceTemplateChange,
};
