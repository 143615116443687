export enum QuoteGroups {
  HEADER = 'HEADER',
  ACCOUNT = 'ACCOUNT',
  DETAILS = 'DETAILS',
  REVIEW = 'REVIEW',
  FEES = 'FEES',
  RENTAL = 'RENTAL',
  SALES = 'SALES',
  SERVICES = 'SERVICES',
  OPTIONAL_RENTAL = 'OPTIONAL_RENTAL',
  OPTIONAL_SALES = 'OPTIONAL_SALES',
  OPTIONAL_SERVICES = 'OPTIONAL_SERVICES',
  OPTIONAL_NOTES = 'OPTIONAL_NOTES',
}

export const AllQuoteGroups = Object.values(QuoteGroups);

export const headerGroupsSet = new Set([
  QuoteGroups.ACCOUNT,
  QuoteGroups.DETAILS,
  QuoteGroups.REVIEW,
  QuoteGroups.FEES,
  QuoteGroups.HEADER,
]);
