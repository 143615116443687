import { styled } from '@mui/material/styles';

export const StyledBannerContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: white;
  background-color: #ef4c62;

  height: auto;
  min-height: 69px;

  .wrapper {
    padding: 0 7px;

    &.icon-wpr {
      flex: 1;
      & > * {
        font-size: 2em;
      }
    }

    &.texts-wpr {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding: 8px 0;

      .title {
        font-size: 1.125em;
      }
      .sub-title {
        font-size: 0.875em;
      }
    }

    &.sync-icon-wrapper {
      flex: 1;

      &.spinning {
        animation-name: spin;
        animation-duration: 2048ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }

      & > * {
        padding: 4px;
        border-radius: 50%;
        box-sizing: border-box;
        & > * {
          font-size: 2.5em;
        }
      }
    }

    &.settings-icon-wpr {
      flex: 1;
      & > * {
        padding: 4px;
        border-radius: 50%;
        box-sizing: border-box;
        & > * {
          font-size: 2.5em;
        }
      }
    }

    &.close-icon-wpr {
      flex: 1;
      & > * {
        padding: 4px;
        border-radius: 50%;
        box-sizing: border-box;
        & > * {
          font-size: 2.5em;
        }
      }
    }
  }
`;
