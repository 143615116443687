import { useLayoutEffect, useRef } from 'react';

export function useUnmountedState() {
  const unmountedRef = useRef({ unmounted: false });

  // Always the same object, so will never cause the following effect to run again.
  const unmountedState = unmountedRef.current;

  useLayoutEffect(() => {
    // Normally this statement is not necessary because this effect should run
    // only once. However, Fast Refresh can run the effect again if the component
    // is reloaded. If this happens, we just set it right back to false when the
    // effect runs again.
    unmountedState.unmounted = false;
    return () => {
      unmountedState.unmounted = true;
    };
  }, [unmountedState]);

  // Always the same object, so will not cause rerunning of dependent effects.
  return unmountedState;
}
