import { styled } from '@mui/material/styles';

export const StyledContainerErrorsFound = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;

  padding: 8px 8px 8px 45px;

  width: min-content;
  height: 100%;
  max-height: 69px;

  color: white;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(239, 76, 98, 1) 33%, rgba(239, 76, 98, 1) 100%);

  cursor: pointer;
`;
