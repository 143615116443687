export interface HighlightedChunks {
  chunk: string;
  highlighted: boolean;
}

export const highlightWord = (word: string, text: string): HighlightedChunks[] => {
  const chunks: HighlightedChunks[] = [];
  if (!word || !text) {
    chunks.push({
      chunk: text,
      highlighted: false,
    });
    return chunks;
  }

  const lowercase = word.toLowerCase();
  let startIndex = word.toLowerCase().indexOf(lowercase);

  if (startIndex === -1) {
    chunks.push({
      chunk: text,
      highlighted: false,
    });
    return chunks;
  }

  let endIndex = 0;
  while (text.toLowerCase().indexOf(lowercase, endIndex) !== -1) {
    text = text.substring(endIndex);
    startIndex = text.toLowerCase().indexOf(lowercase);
    endIndex = startIndex + lowercase.toLowerCase().length;
    chunks.push({
      chunk: text.substring(0, startIndex),
      highlighted: false,
    });
    chunks.push({
      chunk: text.substring(startIndex, endIndex),
      highlighted: true,
    });
  }
  chunks.push({
    chunk: text.substring(endIndex),
    highlighted: false,
  });

  return chunks.filter((item) => !!item.chunk);
};
