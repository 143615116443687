import { createAction, createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { ConfigurationService, ProductsService } from '../../../api';
import { getValidationFieldForProductColumn } from '../../../containers/Quote/Tabs/Products/Tabs/grid-validation';
import { QuoteInnerTabs, QuoteProductTabs } from '../../../enums';
import { IRentalGridRow, IRentalPeriod, IRentalProductProfile } from '../../../interfaces';
import { i18nKeys } from '../../../internationalization/i18nKeys';
import { enqueueNotificationAction, QuoteSelectors } from '../../session';
import { AppDispatch } from '../../store';
import { AppThunkApiConfig, RootState } from '../../types';
import { reviewTabSlice } from '../review-tab';
import { deleteItemAction } from './products-tab.action-creators';
import { showProductNotFoundMessage } from './products-tab.notifications';
import { getProductSelectors, ProductSelectors } from './products-tab.selectors';
import { FetchProductProfileParams, RentalProductCategory } from './products-tab.types';

export const fetchRentalProfileInternalAction = createAsyncThunk<IRentalProductProfile, FetchProductProfileParams>(
  'quote/productsTab/fetchRentalProfileInternalAction',
  async (params, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    try {
      return (
        state.quoteScreen.productsTab.profiles.rental[params.code] ||
        (await ProductsService.getInstance().getRentalProfile(
          params.code,
          QuoteSelectors.branchId(state) || '',
          QuoteSelectors.accountId(state) || '',
          QuoteSelectors.marketSegmentId(state) || ''
        ))
      );
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchRentalProfileAction = createAsyncThunk<void, FetchProductProfileParams, AppThunkApiConfig>(
  'quote/productsTab/fetchRentalProfileAction',
  async (params, thunkAPI) => {
    try {
      await thunkAPI.dispatch(fetchRentalProfileInternalAction(params)).unwrap();
    } catch (e: any) {
      if (e.statusCode === 404) {
        showProductNotFoundMessage(thunkAPI.dispatch);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const fetchRentalProfiles = async (state: RootState, dispatch: AppDispatch, tab: QuoteProductTabs) => {
  let rows = getProductSelectors(tab)
    .notDeletedRentalRows(state)
    .filter(({ code }) => code);

  // For submitted quotes, fetch profiles only for rows for which we are missing information.
  if (!ProductSelectors.isDraftQuote(state)) {
    rows = rows.filter(({ rateTierColor, redlineLevelColor }) => !rateTierColor || !redlineLevelColor);
  }
  const promises = rows.map(({ rowId, code }) =>
    dispatch(fetchRentalProfileInternalAction({ tab, rowId, code: code! }))
  );

  const productsNotFound = await Promise.allSettled(promises.map((promise) => promise.unwrap())).then((outcomes) =>
    outcomes.some((outcome) => outcome.status === 'rejected' && outcome.reason?.statusCode === 404)
  );

  return { productsNotFound };
};

export const fetchRentalProfilesAction = createAsyncThunk<void, { tab: QuoteProductTabs }, AppThunkApiConfig>(
  'quote/productsTab/fetchRentalProfilesAction',
  async ({ tab }, { getState, dispatch }) => {
    await fetchRentalProfiles(getState(), dispatch, tab);
  }
);

export const resetRentalPercentOfRateAction = createAction<any>('quote/productsTab/resetRentalRatesAction');
export const updateRentalPercentOfRateAction = createAction<any>('quote/productsTab/updateRentalRatesAction');

export const fetchRentalCategoriesAction = createAsyncThunk<RentalProductCategory[], undefined>(
  'quote/productsTab/fetchRentalCategoriesAction',
  async (param, thunkAPI) => {
    const showError = () => {
      thunkAPI.dispatch(
        enqueueNotificationAction({
          message: i18next.t(i18nKeys.productsTab.errorLoadingCategories),
          options: { variant: 'error' },
        })
      );
    };
    const branchId = QuoteSelectors.branchId(thunkAPI.getState() as RootState);
    if (!branchId) {
      showError();
      return thunkAPI.rejectWithValue('Branch ID not available');
    }
    try {
      return await ProductsService.getInstance().getRentalCategories(branchId);
    } catch (e) {
      showError();
      throw e;
    }
  }
);

type StringSet = { [rowId: string]: true };

const selectRowsForBulkUpdate = (
  state: RootState,
  tab: QuoteProductTabs,
  checkRow: (row: IRentalGridRow) => 'update' | 'skip' | 'invalid'
) => {
  const selectors = getProductSelectors(tab);
  const selectedRowIds = selectors.selectedRentalRowIdsSet(state);
  const rows = selectors.rentalRows(state);
  const rowIdSet: StringSet = {};
  let skippedInvalidRows = false;
  for (let row of rows) {
    if (selectedRowIds[row.rowId] && row.product) {
      switch (checkRow(row)) {
        case 'update':
          rowIdSet[row.rowId] = true;
          break;
        case 'invalid':
          skippedInvalidRows = true;
      }
    }
  }
  const rowIds = Object.keys(rowIdSet);
  return { rowIdSet, rowIds, skippedInvalidRows };
};

const clearValidationErrors = (
  dispatch: Dispatch,
  tab: QuoteProductTabs,
  rowIds: string[],
  colId: keyof IRentalGridRow
) => {
  const field = getValidationFieldForProductColumn(tab, QuoteInnerTabs.Rental, colId);
  if (field) {
    dispatch(reviewTabSlice.actions.clearErrorsForProductValidationField({ rowIds, field }));
  }
};

export interface BulkRentalPeriodUpdatePayload {
  tab: QuoteProductTabs;
  period: IRentalPeriod;
}

export const bulkRentalPeriodUpdateAction = createAsyncThunk(
  'quote/productsTab/bulkRentalPeriodUpdate',
  async ({ tab, period }: BulkRentalPeriodUpdatePayload, thunkAPI) => {
    const { rowIdSet, rowIds, skippedInvalidRows } = selectRowsForBulkUpdate(
      thunkAPI.getState() as RootState,
      tab,
      (row) => {
        if (row.periodId === period.id) {
          return 'skip';
        }
        // Update row only if it supports the given period.
        return row.periods?.find(({ id }) => id === period.id) ? 'update' : 'invalid';
      }
    );

    if (rowIds.length > 0) {
      // Update period for all rows.
      thunkAPI.dispatch(
        bulkRentalPeriodUpdateInternalAction({
          tab,
          period,
          rowIds: rowIdSet,
        })
      );

      clearValidationErrors(thunkAPI.dispatch, tab, rowIds, 'periodId');
    }
    return skippedInvalidRows;
  }
);

export interface BulkRentalPeriodUpdateInternalPayload extends BulkRentalPeriodUpdatePayload {
  rowIds: StringSet;
}

export const bulkRentalPeriodUpdateInternalAction = createAction<BulkRentalPeriodUpdateInternalPayload>(
  'quote/productsTab/bulkRentalPeriodUpdateInternal'
);

export interface BulkRentalQuantityUpdatePayload {
  tab: QuoteProductTabs;
  quantity: number;
}

export const bulkRentalQuantityUpdateAction = createAsyncThunk(
  'quote/productsTab/bulkRentalQuantityUpdate',
  async ({ tab, quantity }: BulkRentalQuantityUpdatePayload, thunkAPI) => {
    const { rowIdSet, rowIds } = selectRowsForBulkUpdate(thunkAPI.getState() as RootState, tab, (row) =>
      row.quantity !== quantity ? 'update' : 'skip'
    );

    if (rowIds.length > 0) {
      thunkAPI.dispatch(
        bulkRentalQuantityUpdateInternalAction({
          tab,
          quantity,
          rowIds: rowIdSet,
        })
      );

      clearValidationErrors(thunkAPI.dispatch, tab, rowIds, 'quantity');
    }
  }
);

export interface BulkRentalQuantityUpdateInternalPayload extends BulkRentalQuantityUpdatePayload {
  rowIds: StringSet;
}

export const bulkRentalQuantityUpdateInternalAction = createAction<BulkRentalQuantityUpdateInternalPayload>(
  'quote/productsTab/bulkRentalQuantityUpdateInternal'
);

export interface BulkRentalDurationUpdatePayload {
  tab: QuoteProductTabs;
  duration: number;
}

export const bulkRentalDurationUpdateAction = createAsyncThunk(
  'quote/productsTab/bulkRentalDurationUpdate',
  async ({ tab, duration }: BulkRentalDurationUpdatePayload, thunkAPI) => {
    const { rowIdSet, rowIds } = selectRowsForBulkUpdate(thunkAPI.getState() as RootState, tab, (row) =>
      row.duration !== duration ? 'update' : 'skip'
    );

    if (rowIds.length > 0) {
      thunkAPI.dispatch(
        bulkRentalDurationUpdateInternalAction({
          tab,
          duration,
          rowIds: rowIdSet,
        })
      );

      clearValidationErrors(thunkAPI.dispatch, tab, rowIds, 'duration');
    }
  }
);

export interface BulkRentalDurationUpdateInternalPayload extends BulkRentalDurationUpdatePayload {
  rowIds: StringSet;
}

export const bulkRentalDurationUpdateInternalAction = createAction<BulkRentalDurationUpdateInternalPayload>(
  'quote/productsTab/bulkRentalDurationUpdateInternal'
);

export const fetchRentalPeriodsAction = createAsyncThunk(
  'quote/productsTab/fetchRentalPeriods',
  async () => await ConfigurationService.getInstance().getRentalPeriods()
);

export const fetchRentalProductUnitsAction = createAsyncThunk(
  'quote/productsTab/fetchRentalProductUnits',
  async () => await ConfigurationService.getInstance().getRentalProductUnits()
);

export const fetchSalesProductUnitsAction = createAsyncThunk(
  'quote/productsTab/fetchSalesProductUnitsAction',
  async () => await ConfigurationService.getInstance().getSalesProductUnits()
);

export const deleteSelectedRentalRowsAction = createAsyncThunk(
  'quote/productsTab/deleteSelectedRentalRowsAction',
  async (payload: { tab: QuoteProductTabs }, thunkAPI) => {
    const { tab } = payload;
    const selectedRowIds = getProductSelectors(tab).selectedRentalRowIds(thunkAPI.getState() as RootState);
    for (const rowId of selectedRowIds) {
      thunkAPI.dispatch(deleteItemAction({ tab, innerTab: QuoteInnerTabs.Rental, rowId }));
    }
  }
);
