import { createAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { OptionsObject, VariantType } from 'notistack';

interface NotificationPayload {
  message: string;
  options: OptionsObject;
}

export const enqueueNotificationAction = createAction<NotificationPayload>('session/enqueueNotificationAction');

export const showNotification = (dispatch: any, variant: VariantType, messageKey: string, args?: object) => {
  const message = i18next.t(messageKey, args);

  // Log warning and error messages to console for diagnostics.
  if (variant === 'warning' || variant === 'error') {
    // eslint-disable-next-line no-console
    console.warn(message);
  }

  dispatch(
    enqueueNotificationAction({
      message,
      options: { variant },
    })
  );
};
