import React, { useEffect, useState } from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';
import { DropDown } from '../DropDown/DropDown';
import { ToolBarSummary, DropDownTextField } from './TableToolBar.style';
import { IQuotesToolBarProps } from './TableToolBar.props';

import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';

export const TableToolBar: React.FC<IQuotesToolBarProps> = ({
  color,
  totalCount,
  timeFrame,
  columns,
  result,
  onFilterChange,
}) => {
  const [column, setColumn] = useState('all');
  const [value, setValue] = useState('');

  const filterableColumns = columns
    .filter((i) => i.filter && i.field)
    .map((i) => ({ id: i.field || '', text: i.headerName || '' }));

  useEffect(() => {
    onFilterChange && onFilterChange({ column, value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, value]);

  return (
    <AppBar position="static" color={color || 'primary'}>
      <Toolbar className="display-flex justify-content-between py-0 px-3">
        <Box display="flex" alignItems="center" alignContent="stretch">
          {(totalCount || timeFrame) && (
            <ToolBarSummary>
              {totalCount && (
                <>
                  <strong>{result} </strong> Quotes filtered out of <strong>{totalCount || 0}</strong>
                </>
              )}
              {timeFrame && (
                <>
                  &nbsp;&#8729; Last <strong>{timeFrame || 0}</strong> Days
                </>
              )}
            </ToolBarSummary>
          )}
          <DropDownTextField
            className={classNames({ bordered: color === 'default' })}
            placeholder="Filter by column value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            startAdornment={
              <DropDown
                options={[{ id: 'all', text: 'All' }, ...filterableColumns]}
                value={column}
                onChange={(e: any) => setColumn(e.target.value)}
              />
            }
            endAdornment={<SearchIcon />}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
