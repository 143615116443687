import { IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useState } from 'react';
import { DropDown } from '../DropDown/DropDown';
import { ITablePaginationProps } from './TablePagination.props';

const defaultPageSizeOptions = [
  { id: 10, text: 10 },
  { id: 20, text: 20 },
  { id: 30, text: 30 },
];

export const TablePagination: React.FC<ITablePaginationProps> = (props) => {
  const { page, totalCount, pageCount, perPage, onPage, onPageChange, showPageSize } = props;
  const [pageSizeOptions] = useState(props.pageSizeOptions || defaultPageSizeOptions);
  const renderNavigationButtons = () => {
    return (
      <div className="display-flex justify-content-end align-items-center mx-3" style={{ height: 50 }}>
        <IconButton className="mx-1" size="small" onClick={() => onPageChange(1, perPage)} disabled={page <= 1}>
          <FirstPageIcon />
        </IconButton>
        <IconButton
          className="mx-1"
          size="small"
          onClick={() => onPageChange(page - 1, perPage)}
          disabled={page - 1 < 1}
        >
          <NavigateBeforeIcon />
        </IconButton>

        <div className="mx-2">
          {`${(page - 1) * perPage + 1}-${
            page * perPage > totalCount ? (page - 1) * perPage + onPage : page * perPage
          } of ${totalCount}`}
        </div>

        <IconButton
          className="mx-1"
          size="small"
          onClick={() => onPageChange(page + 1, perPage)}
          disabled={page + 1 > pageCount}
        >
          <NavigateNextIcon />
        </IconButton>
        <IconButton
          className="mx-1"
          size="small"
          onClick={() => onPageChange(pageCount, perPage)}
          disabled={page >= pageCount}
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  };

  return !showPageSize ? (
    renderNavigationButtons()
  ) : (
    <div className="display-flex justify-content-between w-100">
      <div className="mx-3 mt-1">
        Page <strong>{page}</strong> of <strong>{pageCount}</strong>, Per Page: &nbsp;
        <DropDown
          options={pageSizeOptions}
          value={perPage}
          onChange={(e: any) => {
            const newPageSize = e.target.value;
            onPageChange(1, newPageSize);
          }}
        />
      </div>
      <div>{renderNavigationButtons()}</div>
    </div>
  );
};
