import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const TabButtonWrapper = styled('div')`
  & {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-right: 2px solid rgba(255, 255, 255, 0.25);
  }
`;

export const StyledButton = styled(NavLink)`
  & {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 15px 4px;
    border: none;
    border-bottom: 5px solid transparent;
    width: fit-content;
    font-weight: bold;
    font-size: 0.9rem;
    color: white;
    height: 100%;
  }

  &.active {
    color: #1e3f76;
    border-color: #1e3f76;
    cursor: default;

    &:visited {
      color: #1e3f76;
    }
  }

  &.active:hover {
    border-color: #1e3f76;
    color: #1e3f76;
  }

  &:hover {
    transition: all 384ms ease;
    border-color: white;
    color: white;
  }

  &:visited {
    color: white;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    border-color: transparent;
  }

  svg {
    font-size: 1.4em;
    margin-right: 4px;
  }

  @media (max-width: 1330px) {
    & {
      flex-direction: column;
    }
  }
`;
